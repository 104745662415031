<template lang="html">
  <div class="position-page-wrapper my-0 mx-auto">
    <v-row no-gutters>
      <v-col cols="12" md="4" v-if="$vuetify.breakpoint.width>=600 || routeName=='Watchlist'">
        <Watchlist class="watchList"/>
      </v-col>
      <v-col cols="12" md="8" v-if="$vuetify.breakpoint.width>=600 || routeName=='Position'">
        <div ref="analyticsComponent">
          <PositionAnalytics class="analytics"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Watchlist from './PositionWatchlist.vue';
import PositionAnalytics from "./PositionAnalytics.vue";
import UserDataMixin from "@/mixins/getUserDataMixin";
export default {
  name: "Position",
  components: {
    PositionAnalytics,
    Watchlist
  },
  mixins: [UserDataMixin],
  data(){
    return{
    }
  },
  destroyed(){
    this.$store.dispatch("unsubscribeFromLocation",this.$route.name);
  },
  computed:{
    routeName(){
      return this.$route.name;
    }
  },
  mounted(){
    this.$root.$refs.Position = this;
  },
  methods:{
    print(a) {
      console.log(a);
    },
  }
}
</script>
<style lang="scss" scoped>
.banner{
  margin-bottom: 10px;
  background-color: #2F4AED;
  color:#fff;
  padding: 10px;
  text-align: center;
  font-size: 1.2rem;
  .link{
    color:#fff !important;
    text-decoration: underline;
    cursor: pointer;
  }
  .close-banner{
    float: right;
    cursor: pointer;
  }
}
.position-page-wrapper{
  max-width: 2500px;
}
.watchList,.analytics{
  height: 90vh;
  min-height: 500px;
  max-height: 1700px;
  overflow: hidden;
}
@media screen and (max-width: 960px) {
  .watchList,.analytics{
    height: auto;
    min-height: 650px;
    max-height:none;
  }
  .banner{
    font-size: 0.9rem;
  }
}
</style>