import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import VueAxios from 'vue-axios'
// import Vuex from 'vuex';
import VueRouter from 'vue-router';
import VueObserveVisibility from 'vue-observe-visibility'
import store from './store'
import Meta from 'vue-meta';
import 'es6-promise/auto'
import router from './routes/routes';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
import 'highcharts/modules/stock'; // Import the stock module
// import Highcharts from "highcharts";
import Highcharts from "highcharts/highstock";
import HighchartsVue from "highcharts-vue";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import {Tabs, Tab} from 'vue-tabs-component';
import Notifications from 'vue-notification'
import VueWorker from 'vue-worker'
import VueDarkModeSwitch from 'vue-dark-mode-switch';
Vue.use(VueDarkModeSwitch);
Vue.use(VueWorker)
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);

HighchartsMore(Highcharts);
HighchartsNoData(Highcharts);
Vue.use(VueObserveVisibility)
Vue.use(ElementUI, { locale });
// Vue.use(Vuex)
Vue.use(VueRouter);
Vue.use(HighchartsVue);
Vue.use(require('vue-cookies'))
Vue.use(VueAxios, axios);
Vue.use(Notifications)
Vue.use(Meta);
Vue.config.productionTip = false
Vue.prototype.$snackbar = false
export const EventBus = new Vue();
export const Router = router;

new Vue({
  store,
  vuetify,
  // Vuex,
  router,
  render: h => h(App),
}).$mount('#app')
