var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"order-btns",style:({top:_vm.clickPointGraphData.chartY+'px',left: _vm.clickPointGraphData.chartX+'px'}),attrs:{"elevation":"4","rounded":"lg"}},[_c('div',[_c('div',{staticStyle:{"font-size":"0.875rem"}},[_vm._v(_vm._s(_vm.clickPointGraphData.strike)+" - "+_vm._s(_vm.clickPointGraphData.segment=='ce'?'Call':'Put'))]),(_vm.clickPointGraphData.seriesData)?_c('div',{staticStyle:{"white-space":"nowrap","font-size":"0.6rem"}},[_vm._v(" "+_vm._s(_vm.clickPointGraphData.seriesData.name)+" - "+_vm._s(_vm._f("float")(_vm.clickPointGraphData.seriesData.val,2))+" ")]):_vm._e()]),_c('div',{staticClass:"mt-2 d-flex justify-space-around align-center"},[_c('button',{staticClass:"buy-ins-btn",on:{"click":function($event){return _vm.$emit(
            'add-from-charts',
            _vm.clickPointGraphData.strike,
            _vm.clickPointGraphData.segment,
            'b'
        )}}},[_vm._v("B")]),_c('button',{staticClass:"sell-ins-btn",on:{"click":function($event){return _vm.$emit(
            'add-from-charts',
            _vm.clickPointGraphData.strike,
            _vm.clickPointGraphData.segment,
            's'
        )}}},[_vm._v("S")])])])}
var staticRenderFns = []

export { render, staticRenderFns }