export const getAccessToken="https://6629wqe1v1.execute-api.ap-south-1.amazonaws.com/default/getV2AccessToken";
export const aliceblueWebsocketUrl="wss://f5zy.aliceblueonline.com/";

export const aliceBlueLoginRedirectUrl="https://ant.aliceblueonline.com/?appcode=oIEVWdKO5H1L0GT";
export const aliceBlueSignUpRedirectUrl="https://alicebluepartner.com/open-myaccount/?P=WMPN760";


export const landingPageURL="https://www.1lyoptions.com/";

export const placeOrderUrl="https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/placeOrder/executePlaceOrder";
export const modifyOrderUrl="https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/placeOrder/modifyOrder";

export const getOrderHistoryUrl="https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/placeOrder/orderHistory";