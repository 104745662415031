<template>
  <div class="margin-benefit row1">
    <h2 class="heading1">Unbeatable Margin Benefit</h2>
    <h3 class="sub-heading">Hedge your Positions and get Margin Benefit on 1LY Options</h3>
    <div class="cards">
        <!-- Card -->
        <MarginCard v-for="(card, index) in cards" :key="index" :cardInfo="card" :index="index"/>
    </div>
  </div>
</template>

<script>
import MarginCard from './MarginCard.vue';
export default {
    components: { MarginCard },
    data(){
        return{
            cards: [
                {
                    type:'recomended',
                    rows: [
                        {id:'NIFTY22SEPFUT', points:'+50', rate:'17,687.85'},
                        {id:'NIFTY22SEP17450PE', points:'+50', rate:'249.00'},
                    ],
                    marginRate:'47,562',
                    mb:'70,200'    
                },
                {
                    type:'low margin',
                    rows: [
                        {id:'NIFTY22SEPFUT', points:'+50', rate:'17,687.85'},
                        {id:'NIFTY22SEP17450PE', points:'+50', rate:'341.00'},
                    ],
                    marginRate:'40,862',
                    mb:'81,400'    
                },
                {
                    type:'low margin',
                    rows: [
                        {id:'NIFTY22SEPFUT', points:'+50', rate:'17,687.65'},
                        {id:'NIFTY22SEP17450PE', points:'+50', rate:'78.00'},
                    ],
                    marginRate:'70,862',
                    mb:'39,400'    
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>

.margin-benefit{
    margin-bottom: 200px;
    @media only screen and (max-width:580px){
         margin-bottom: 69px;
    }
    
    .heading1{
        text-align: center;
        font-size: 40px;
        margin-bottom: 12px;
        @media only screen and (max-width:980px){
            font-size: 28px;
        }
        @media only screen and (max-width:580px){
            font-size: 20px;
        }
    }
    .sub-heading{
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        color: #888888;
        margin-bottom: 51px;
        @media only screen and (max-width:980px){
            font-size: 16px;
        }
        @media only screen and (max-width:580px){
            font-size: 10px;
        }
    }
    .cards{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        justify-content: center;
        @media only screen and (max-width:1140px){
            grid-template-columns: repeat(1, 1fr);
            
        }
    }
}

</style>