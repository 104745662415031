<template>
  <div>
    <v-dialog
      :disabled="defaultPositionsList.length == 0"
      :fullscreen="$vuetify.breakpoint.xs"
      v-model="modalVisible"
      width="auto"
      persistent
      content-class="share-verified-pl-modal styled-scroll"
    >
      <template>
        <v-card style="position: relative" class="share-verified-pl-wrapper" min-height="400">
          <div class="close-icon" @click="()=>{if(submittingData) return;$emit('close-modal');}">
            <v-icon color="--dialog-header-text">mdi-close</v-icon>
          </div>

          <div
            class="row no-gutters default-style justify-space-between pa-5 pb-3"
            style="height: 100%"
          >
            <div
              class="col-12 col-sm-12 pr-0 pr-sm-2"
            >
              <div
                class="share-positions-heading"
                @click="print(defaultPositionsList)"
              >
                New Verified P&L
              </div>

              <div class="verified-section mt-3 d-flex justify-space-between">
                <!-- LEFT -->
                <div>
                  <div>
                    <v-icon
                      small
                      color="var(--primary-button-color)"
                      class="mr-2"
                      >mdi-check-circle</v-icon
                    >
                    <span class="sub-heading">P&L verified by 1LY Options</span>
                  </div>
                  <div>
                    <span @dblclick="showTable=!showTable">on @</span>
                    {{ lastRefreshed.toDateString() }},
                    {{
                      lastRefreshed.toLocaleTimeString("en-US", {
                        hour12: true,
                        hour: "numeric",
                        minute: "numeric",
                      })
                    }}
                  </div>
                </div>
              </div>
              <div v-if="noPositions">
                <v-alert 
                  color="var(--red-text)" 
                  icon="mdi-alert-circle-outline" 
                  type="warning" 
                  dense 
                  dismissible
                >No Positions To Publish</v-alert>
              </div>
              <div class="my-2 row no-gutters justify-center">
                <div class="col-auto"></div>
                  <TwitterCard
                    :showUser="showUser"
                    :totalPL="totalPL"
                    :generatedTime="lastRefreshed"
                  />
              </div>

              <div class="my-2" v-if="showTable">
                <v-simple-table
                  class="share-positions-table"
                  fixed-header
                  :height="xsScreen ? 'auto' : 400"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Instrument</th>
                        <th class="text-center">Type</th>
                        <th class="text-center">Qty</th>
                        <th class="text-center">Buy Avg</th>
                        <th class="text-center">Sell Avg</th>
                        <th class="text-center">LTP</th>
                        <th class="text-center">Day's P&L</th>
                        <th class="text-center">Net P&L</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(pos, index) in filteredList"
                        :key="pos.trading_symbol + index"
                        :class="{ 'closed-instrument': pos.net_quantity == 0 }"
                      >
                        <td class="text-left">{{ pos.formatted_ins_name }}</td>
                        <td class="text-center">
                          <span
                            class="product-type"
                            :class="pos.prod_type == 'NRML' ? 'nrml' : 'mis'"
                          >
                            {{ pos.prod_type }}
                          </span>
                        </td>
                        <td
                          class="text-center"
                          :class="
                            pos.net_quantity > 0 ? 'green-text' : 'red-text'
                          "
                        >
                          {{ pos.net_quantity }}
                        </td>
                        <td class="text-center">
                          {{ pos.average_buy_price | float(2) }}
                        </td>
                        <td class="text-center">
                          {{ pos.average_sell_price | float(2) }}
                        </td>
                        <td class="text-center">
                          {{ pos.ltp | float(2) }}
                        </td>
                        <td
                          class="text-center"
                          :class="
                            plObj[pos.code].daysPL < 0
                              ? 'red-text'
                              : 'green-text'
                          "
                        >
                          {{ plObj[pos.code].daysPL > 0 ? "+" : ""
                          }}{{ plObj[pos.code].daysPL | float(2) }}
                        </td>
                        <td
                          class="text-center"
                          :class="
                            plObj[pos.code].netPL < 0
                              ? 'red-text'
                              : 'green-text'
                          "
                        >
                          {{ plObj[pos.code].netPL > 0 ? "+" : ""
                          }}{{ plObj[pos.code].netPL | float(2) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>

              <div class="d-flex align-center" v-if="portfolioCreated">
                <div
                  v-ripple
                  class="col styled-scroll generated-link-div primary-button d-flex align-center px-3"
                  @click="redirectToUrl(portfolioLink)"
                >
                  {{ portfolioLink }}
                </div>
                <button 
                  v-ripple
                  @click="copyLink()" 
                  class="primary-button mx-1 px-2" 
                  style="background-color: #575757;">
                  <v-icon small color="white">{{copied?'mdi-receipt-text-check-outline':'mdi-content-copy'}}</v-icon>
                </button>
                <button
                  v-ripple
                  class="col-auto primary-button py-0"
                  style="
                    background-color: #1da1f2;
                    color: white;
                  "
                  @click="shareOnTwitter"
                >
                  <v-icon color="white">mdi-twitter</v-icon>
                  Share on Twitter
                </button>
              </div>
              <div class="share-positions-footer my-3">
                <!-- PUBLISH BTN -->
                <button
                  v-ripple
                  class="primary-button d-inline-flex"
                  @click="submitVerifiedPnL"
                  :disabled="noPositions"
                  :class="{'disabled-btn':noPositions}"
                >
                  {{ portfolioCreated ? "Published" : "Publish" }}
                  <span class="custom-loader" v-if="submittingData">
                    <v-icon small color="var(--primary-button-text-color)"
                      >mdi-cached</v-icon
                    >
                  </span>
                  <v-icon
                    small
                    color="var(--primary-button-text-color)"
                    v-if="portfolioCreated"
                    >mdi-check-circle-outline</v-icon
                  >
                </button>

                <!-- CANCEL BTN -->
                <button
                  v-ripple
                  class="primary-button light-button mx-2"
                  @click="()=>{if(submittingData) return;$emit('close-modal');}"
                >
                  {{portfolioCreated?'Close':'Cancel'}}
                </button>

                <!-- SHOW USERNAME BTN -->
                <button 
                  class="pale-btn" 
                  :class="{'disabled-pale-btn':portfolioCreated}"
                  @click="()=>{if(!portfolioCreated)showUser=!showUser;}"
                >
                  <v-icon :small="xsScreen" v-ripple class="mx-2">{{showUser?'mdi-eye':'mdi-eye-off'}}</v-icon>
                  <span>Username</span>
                </button>

                <!-- DOWNLOAD BTN -->
                <button 
                  class="pale-btn mx-1" 
                  @click="downloadImg" 
                  :class="{'disabled-pale-btn':!portfolioCreated}"
                >
                  <v-icon :small="xsScreen" v-ripple class="mx-2">mdi-download</v-icon>
                  <span>Download</span>
                </button>
              </div>
              <TwitterCard
                :showUser="showUser"
                :totalPL="totalPL"
                :generatedTime="lastRefreshed"
                :hidden="true"
              />
              <div id="img-output"></div>
            </div>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import html2canvas from "html2canvas";
import UserDataMixin from "@/mixins/getUserDataMixin";
import AWS from "aws-sdk";
import { endpointVerifiedPL,verifiedMetaDataUrl } from "../../getService";
import TwitterCard from "./TwitterCardVerifiedPnL.vue";
import { EventBus } from '../../../main';
// Configure AWS SDK
AWS.config.update({
  accessKeyId: "AKIA5IL3UMD763OK54FQ",
  secretAccessKey: "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",
  region: "ap-south-1",
});
const s3 = new AWS.S3();

export default {
  emits: ["close-modal"],
  components: {
    TwitterCard,
  },
  created(){
    EventBus.$on("user_settings", (value) => {
      this.userSettings = value
    });
  },
  mixins: [UserDataMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    defaultPositionsList: {
      type: Array,
      default() {
        return [];
      },
    },
    realizedPL: {
      default() {
        return 0;
      },
    },
    unrealisedPL: {
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      // flags
      modalVisible: this.showModal,
      submittingData: false,
      copied:false,
      showUser:false,
      showTable:false,

      // Component data
      compUnrealisedPL:this.unrealisedPL,
      compRealizedPL:this.realizedPL,
      lastRefreshed: new Date(),
      positionsList: this.defaultPositionsList,
      plObj: {},

      // Generated after publishing verified P/L
      publicPortfolioKey: "",
      generatedImgLocation:null,
      userSettings:JSON.parse(localStorage.getItem('user_settings'))
    };
  },
  beforeMount(){
    this.publicPortfolioKey = "";
    this.generatedImgLocation=null;
  },
  watch: {
    showModal(val)  {
      this.modalVisible = val;
      if(val)
      this.refreshComponentData();
    },
  },
  computed: {
    noPositions() {
      return this.positionsList.length == 0;
    },
    totalPL() {
      let netPL = 0;
      // for (let pos of this.positionsList) {
      //   if (this.plObj[pos.code]) {
      //     netPL += +this.plObj[pos.code].netPL;
      //   }
      // }
      netPL = parseFloat(this.compRealizedPL) + parseFloat(this.compUnrealisedPL);
      return netPL.toFixed(0);
    },
    filteredList() {
      let list = this.positionsList;
      const nifty = list.filter((el) => el.symbol === "NIFTY");
      const banknifty = list.filter((el) => el.symbol === "BANKNIFTY");
      const finnifty = list.filter((el) => el.symbol === "FINNIFTY");
      list = list.filter((el) => !el.symbol.includes("NIFTY"));
      list = nifty.concat(banknifty, finnifty, list);
      const closedPos = list.filter((el) => el.net_quantity == 0);
      list = list.filter((el) => el.net_quantity != 0);
      list = list.concat(closedPos);
      return list;
    },
    portfolioCreated(){
      return this.publicPortfolioKey && !this.submittingData;
    },
    portfolioLink() {
      // let url="https://1lyoptions.com" + `/verifiedpnl/${this.publicPortfolioKey}`;
      const url=verifiedMetaDataUrl+'?portfolio_key='+this.publicPortfolioKey;
      return url;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    xsScreen() {
      return this.$vuetify.breakpoint.xs;
    },
    insSub() {
      return (el) => {
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
  },
  methods: {
    print(data=null) {
      console.log(data);
    },
    /**
     * @function Submit_Verified_P/L_and_generate_portfolio_link
     */
    submitVerifiedPnL() {
      if (this.publicPortfolioKey) return;
      this.submittingData = true;
      const neededKeys = [
        "ltp",
        "exchange",
        "segment",
        "sell_quantity",
        "buy_quantity",
        "net_quantity",
        "average_buy_price",
        "actual_average_buy_price",
        "average_sell_price",
        "actual_average_sell_price",
        "buy_amount",
        "sell_amount",
        "cf_sell_quantity",
        "cf_buy_quantity",
        "cf_quantity",
        "symbol",
        "average_price",
        "trading_symbol",
        "realized_mtm",
        "unrealized_mtm",
        "net_amount_mtm",
        "prod_type",
        "strike_price",
        "expiry_date",
        "code",
        "formatted_ins_name",
        "fut_code",
        "underlying_code",
        "lot_size",
        "Type",
        "daynet_realised",
      ];
      const list = this.positionsList.map((pos) => {
        let obj = {
          daysPL: this.plObj[''+pos.code].daysPL,
          netPL: this.plObj[''+pos.code].netPL,
        };
        for (let key of neededKeys) {
          obj[key] = pos[key];
        }
        return obj;
      });
      const payload = {
        positions: list,
        operation: "set",
        showUser:this.showUser,
        created_on: this.lastRefreshed.toJSON(),
        net_realized: this.compRealizedPL,
        net_unrealized: this.compUnrealisedPL,
        user_id: this.userId,
        accessTokenAnt: this.accessToken,
      };
      axios
        .post(endpointVerifiedPL, payload)
        .then((res) => {
          if (res.data.errorMessage) throw res;
          this.$notify({
            group: "success",
            type: "success",
            title: "success",
            text: "Verified P&L published successfully",
          });
          this.publicPortfolioKey = res.data.portfolio_key;
          this.generateImage();
          this.submittingData = false;
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            group: "success",
            type: "error",
            title: "Error",
            text: "Error while publishing verified P&L",
          });
          this.submittingData = false;
        });
    },
    /**
     * @function Generate_P/L-Image_From_HTML
     */
    generateImage(){
      if(!this.publicPortfolioKey) return;

      const node = document.getElementById("tweet-image-box");
      html2canvas(node)
        .then((canvas) => {
          // this.attachImage(canvas);
          // if(canvas)
          // return
          
          // AWS IMAGE POSTING
          canvas.toBlob((blob) => {
            const file = new File([blob], "canvas-image.jpg");
            const uploadParams = {
              Bucket: "1lyoptions-verifiedpnl-images",
              Key: `images/${this.publicPortfolioKey}_portfolio_key.jpg`,
              Body: file,
              ContentType: "image/jpg",
            };

            s3.upload(uploadParams, (err, data) => {
              if (err) {
                console.error("Error uploading image:", err);
              } else {
                this.generatedImgLocation = data.Location;
              }
            });
          }, "image/jpg");
        })
        .catch((error) => {
          console.error("Error capturing HTML element as image:", error);
        });
    },
    /**
     * @function redirect_to_twitter_To_Share
     */
    shareOnTwitter() {
      const baseUrl = "https://twitter.com/intent/tweet";
      const urlParam = encodeURIComponent(this.portfolioLink);
      const textParam = encodeURIComponent(
        "Verified and validated! Here's my verified P&L from @aliceblue_india, supported by @1lyOptions #VerifiedBy1lyOptions #TransparentTrading #Nifty #NiftyBank #Nifty50 #NiftyOptions"
      );

      const shareUrl = `${baseUrl}?url=${urlParam}&text=${textParam}`;
      window.open(shareUrl);
    },
    refreshComponentData(){
      this.formattedDefaultPositions();
      this.setPLData();
      this.compUnrealisedPL=this.unrealisedPL;
      this.compRealizedPL=this.realizedPL;
      this.lastRefreshed = new Date();
      this.publicPortfolioKey = "";
      this.generatedImgLocation=null;
    },
    // Sets the initial data for the positions list
    // the data from websocket is recorded on the time of opening the modal
    formattedDefaultPositions() {
      let formattedPositions = [];
      for (let pos of this.defaultPositionsList) {
        let newIns = {
          ...pos,
        };
        // if(!this.insSub(newIns.code)){
        //   this.$emit("close-modal");
        //   return;
        // }
        newIns.ltp = this.insSub(newIns.code)?.ltp;
        formattedPositions.push(newIns);
      }
      this.positionsList = formattedPositions;
    },
    // sets the net and days PL for each position as a objext i.e. plObj
    setPLData() {
      let obj = {};
      for (let pos of this.positionsList) {
        const pl = this.getDaysAndNetPL("both", pos);
        obj[pos.code] = pl;
      }
      this.plObj = obj;
      return obj
    },
    copyLink(){
      navigator.clipboard.writeText(this.portfolioLink);
      this.copied=true;
      setTimeout(() => {
        this.copied=false;
      }, 2000);
    },
    downloadImg(){
      if(!this.portfolioCreated) return;
      
      const node = document.getElementById("tweet-image-box");
      html2canvas(node)
        .then((canvas) => {
          let link = document.createElement('a');
          link.download = this.publicPortfolioKey+'_portfolio_key.png';
          link.href = canvas.toDataURL();
          link.click();
        })
    },
    attachImage(canvas){
      var image = new Image();
      image.src = canvas.toDataURL();
      image.addEventListener('click',()=>{
        image.remove();
      })
      document.getElementById('img-output').appendChild(image);
    },
    redirectToUrl(url) {
      window.open(url, "_blank");
    },
    // returns the days and net PL for a single position
    getDaysAndNetPL(key, position) {
      let daysPL = 0;
      let netPL = 0;
      let closePrice = this.insSub(position.code)
        ? this.insSub(position.code).close
        : 0;

      let ltp = position.ltp;

      if (position.net_quantity !== 0) {
        let amount =
          position.net_quantity > 0
            ? position.buy_amount
            : position.sell_amount;
        netPL = (
          parseFloat((ltp - amount) * position.net_quantity) +
          position.realized_mtm
        ).toFixed(2);
        daysPL =
          position.Type == "DAY1"
            ? netPL
            : position.Type == "DAYNET"
            ? (netPL - (closePrice - amount) * position.cf_quantity).toFixed(2)
            : (netPL - (closePrice - amount) * position.net_quantity).toFixed(
                2
              );
      } else {
        let amount =
          position.cf_quantity > 0 ? position.buy_amount : position.sell_amount;
        netPL = position.net_amount_mtm;
        daysPL =
          position.Type == "DAY1"
            ? netPL
            : position.Type == "DAYNET"
            ? (
                position.daynet_realised -
                (closePrice - amount) * position.cf_quantity
              ).toFixed(2)
            : (
                position.realized_mtm -
                (closePrice - amount) * position.cf_quantity
              ).toFixed(2);
      }
      return key == "days"
        ? daysPL
        : key == "both"
        ? { daysPL: +daysPL, netPL: +netPL }
        : netPL;
    },
  },
  mounted(){
    this.showUser = !this.userSettings.mask_username
  }
};
</script>
<style scoped lang="scss">
@import "../../../assets/css/global.scss";
.default-style {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  color: var(--text-color);
}
.sub-heading {
  color: #909090;
  font-size: 0.8em;
}
.disabled-btn{
  opacity: 0.7;
  cursor: default;
}
.light-button {
  background-color: var(--chip-background);
  color: var(--text-color);
  border: solid 1px #90909055;
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.share-verified-pl-wrapper {
  overflow: hidden;
  min-width: 800px;
  max-width: 51vw;
  .share-positions-heading {
    font-size: 1.7em;
    line-height: 28px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .verified-section {
    font-size: 1.2em;
  }
  .share-positions-footer{
    white-space: nowrap;
    .pale-btn{
      all: unset;
      color: var(--text-color);
      cursor: pointer;
      font-size: 1.2em;
    }
    .disabled-pale-btn *{
      color: var(--disabled-text);
      cursor:default;
    }
    .primary-button{
      height: auto !important;
    }
  }
}
// Table section
.share-positions-table {
  table {
    .product-type {
      padding: 5px;
      border-radius: 5px;
    }
    th {
      background-color: var(--option-header-bg);
      white-space: nowrap;
      font-weight: 500;
      font-size: 1em !important;
    }

    tr {
      color: var(--text-color);
    }
    td,
    th {
      font-size: 1em !important;
      height: 3em !important;
      border-bottom: 1px solid #90909045 !important;
      padding: 0 10px !important;
      .nrml {
        color: var(--option-strike-text);
        background-color: var(--option-strike-bg);
      }
      .mis {
        color: var(--mis-label-text);
        background-color: var(--mis-label-bg);
      }
    }
    td:first-child {
      white-space: nowrap;
    }

    tr.closed-instrument {
      color: var(--disabled-text);
      td {
        background-color: var(--option-even-row);

        .nrml {
          color: var(--disable-nrml-text);
          background-color: var(--option-strike-bg);
        }
        .mis {
          color: var(--disable-mis-text);
          background-color: var(--mis-label-bg);
        }
      }
    }
  }
}
.generated-link-div {
  overflow: auto;
  background: var(--chip-background);
  color: var(--text-color);
  white-space: nowrap;
}
.generated-link-div::-webkit-scrollbar {
  display: none !important;
}
@media screen and (min-width: 1850px) {
  .share-verified-pl-wrapper {
    min-width: 800px;
  }
}
@media screen and (max-width: 600px) {
  .share-verified-pl-wrapper {
    min-width: auto;
    max-width: none;
    font-size: 0.85em;
    > .row {
      min-height: 100vh;
    }
  }
}
@media screen and (min-width: 600px) {
  .pale-btn{
    float: right !important;
  }
}

// animation
.custom-loader {
  animation: loader 1s infinite;
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
<style lang="scss">
.share-verified-pl-wrapper {
  .share-positions-table {
    .v-data-table__wrapper {
      overflow: hidden;
    }
  }
  .share-positions-table:hover .v-data-table__wrapper {
    overflow: auto;
  }

  // Mobile view
  @media screen and (max-width: 600px) {
    .share-positions-table .v-data-table__wrapper {
      overflow: auto;
    }
  }
}
</style>
