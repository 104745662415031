<template>
  <div>
    <v-dialog v-model="isSavedStrategyClicked" width="auto">
      <template>
        <v-card class="saved-strategy-dialog">
          <v-card-text>
            <v-form>
              <v-container class="px-0">
                <v-row no-gutters justify-space-between>
                  <v-col>
                    <v-text-field
                      class="strategy-name"
                      v-model="strategyName"
                      outlined
                      label="Strategy Name"
                      placeholder="Enter Strategy Name"
                      style="height: 40px !important"
                      @input="getStrategyInput()"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-autocomplete
                      style="min-width: 100px"
                      v-model="selectedDirection"
                      :items="savedStrategyLabels"
                      outlined
                      dense
                      height="30px"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <div v-if="isStrategyEmpty">
                  <span class="red-text">Please input strategy name</span>
                </div>
              </v-container>
            </v-form>
            <v-simple-table dense class="save-strategy-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Lot</th>
                    <th>Strike</th>
                    <th>Expiry</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ins, index) in saveStrategyData" :key="index">
                    <td class="text-left">
                      <v-chip
                        v-if="ins.segment === 'CE'"
                        label
                        small
                        color="var(--call-background)"
                        text-color="var(--green-text)"
                      >
                        Call
                      </v-chip>
                      <v-chip
                        v-if="ins.segment === 'PE'"
                        label
                        small
                        color="var(--put-background)"
                        text-color="var(--red-text)"
                      >
                        Put
                      </v-chip>
                      <v-chip
                        v-if="ins.segment === 'FUT'"
                        label
                        small
                        color="var(--buy-background)"
                        text-color="var(--primary-color)"
                      >
                        Fut
                      </v-chip>
                    </td>
                    <td>
                      <input
                        style="width: 65px"
                        class="save-strategy-input"
                        type="text"
                        v-model="ins.quantity"
                        @input="handleQuantityInput($event, 'quantity', index)"
                      />
                    </td>
                    <td style="min-width: 120px">
                      <span
                        style="
                          margin-right: 0.5rem;
                          color: var(--option-row-text);
                        "
                        >{{ ins.strike_options }}</span
                      >
                      <input
                        v-if="ins.segment !== 'FUT'"
                        style="width: 50px"
                        class="save-strategy-input"
                        type="text"
                        v-model="ins.strike_limit"
                        @input="setIntegerValue($event, 'strike_limit', index)"
                      />
                    </td>
                    <td>
                      <div
                        style="
                          display: flex;
                          align-items: baseline;
                          margin-top: 0.5rem;
                        "
                      >
                        <span style="margin-right: 0.5rem; width: 120px">
                          <v-autocomplete
                            v-model="ins.selectedExpiry"
                            :items="
                              ins.segment == 'FUT'
                                ? ['Monthly']
                                : ['Monthly', 'Weekly']
                            "
                            outlined
                            dense
                            @change="
                              changeExpiryOptions(ins.selectedExpiry, index)
                            "
                          ></v-autocomplete>
                        </span>
                        <span
                          style="
                            margin-right: 1rem;
                            color: var(--option-row-text);
                          "
                          >+</span
                        >
                        <span>
                          <input
                            style="width: 40px"
                            class="save-strategy-input"
                            type="text"
                            v-model="ins.expiry_limit"
                            @input="
                              setIntegerValue($event, 'expiry_limit', index)
                            "
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#b3b3b3"
              flat
              outlined=""
              @click.stop="isSavedStrategyClicked = false"
              >Cancel</v-btn
            >
            <v-btn
              v-if="!isSaveStrategyButtonClicked"
              color="var(--primary-button-color)"
              dark
              flat
              @click="saveStrategyApi()"
              >Save</v-btn
            >
            <v-btn
              v-else
              color="var(--primary-button-color)"
              dark
              flat
              style="pointer-events:none"
            >
              <span class="custom-loader" v-if="isSaveStrategyButtonClicked">
                <v-icon small
                  >mdi-cached</v-icon
                >
              </span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { saveStrategyCall } from "../getService";
import { getSelectedExpiry } from "../Dashboard/dashboardService";
import { EventBus } from "../../main";
import UserDataMixin from "@/mixins/getUserDataMixin";
export default {
  mixins:[UserDataMixin],
  data: () => ({
    strategyName: "",
    isStrategyEmpty: false,
    savedStrategyLabels: ["Bullish", "Bearish", "Neutral"],
    saveStrategyData: [],
    selectedDirection: "Bullish",
    timerId: null,
    getSavedStrategiesApi: false,
    isSaveStrategyButtonClicked: false,
  }),
  props: {
    saveStrategyVisible: Boolean,
    strategyCreatorList: null,
    strategyCreatorStrikes: [],
    selectedInstrument: null,
    peExpiry: [],
    ceExpiry: [],
    futExpiry: [],
    saveStrategyDataPass: null,
    isEditStrategy: Boolean,
  },
  computed: {
    isSavedStrategyClicked: {
      get() {
        return this.saveStrategyVisible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
  },
  watch: {
    strategyCreatorList() {
      this.loadSaveStrategyData();
    },
    saveStrategyDataPass() {
      this.saveStrategyData = this.saveStrategyDataPass;
    },
  },
  methods: {
    getStrategyInput() {
      if (this.strategyName == "") {
        this.isStrategyEmpty = true;
      } else {
        this.isStrategyEmpty = false;
      }
    },
    loadSaveStrategyData() {
      if (this.isEditStrategy) {
        this.strategyName = this.saveStrategyDataPass.name;
        this.saveStrategyData = this.saveStrategyDataPass.structure;
      } else {
        this.saveStrategyData = [];
        let index = 0;
        for (let ins of this.strategyCreatorList) {
          this.saveStrategyData.push({
            strike_price: ins.strike,
            trading_symbol: ins.trading_symbol,
            formatted_ins_name: ins.formatted_ins_name,
            code: ins.code,
            quantity: ins.quantity,
            expiry: ins.expiry,
            segment:
              ins.segment == "c" ? "CE" : ins.segment == "p" ? "PE" : "FUT",
            lot_size: this.selectedInstrument.lot_size,
            expiry_options: "m",
            expiry_limit: 0,
            strike_limit: this.getStrikeLimit(
              "limit",
              ins.segment,
              index,
              ins.strike
            ),
            strike_options: this.getStrikeLimit(
              "options",
              ins.segment,
              index,
              ins.strike
            ),
            selectedExpiry: "Monthly",
          });
          this.getExpiryOptions("expiry_options", index, ins.expiry),
            (index = index + 1);
        }
      }
    },
    getExpiryOptions(change, index, value) {
      let newDate = new Date(value);
      let selectedMonth = newDate.getMonth();
      let monthlyExpiry = getSelectedExpiry(this.peExpiry);
      let newMonthly = new Date(monthlyExpiry);
      let currentMonth = newMonthly.getMonth();
      if (value == monthlyExpiry || selectedMonth > currentMonth) {
        this.$set(this.saveStrategyData[index], change, "m");
        this.$set(this.saveStrategyData[index], "selectedExpiry", "Monthly");
      } else {
        this.$set(this.saveStrategyData[index], change, "w");
        this.$set(this.saveStrategyData[index], "selectedExpiry", "Weekly");
      }
      if (this.saveStrategyData[index].selectedExpiry == "Weekly") {
        this.$set(
          this.saveStrategyData[index],
          "expiry_limit",
          this.peExpiry.indexOf(value)
        );
      } else if (
        value != monthlyExpiry &&
        this.saveStrategyData[index].selectedExpiry == "Monthly"
      ) {
        if (selectedMonth > currentMonth) {
          this.$set(
            this.saveStrategyData[index],
            "expiry_limit",
            selectedMonth - currentMonth
          );
        }
      }

      if (
        this.saveStrategyData[index].segment == "FUT" &&
        value != monthlyExpiry
      ) {
        this.$set(this.saveStrategyData[index], "selectedExpiry", "Monthly");
        if (selectedMonth > currentMonth) {
          this.$set(
            this.saveStrategyData[index],
            "expiry_limit",
            selectedMonth - currentMonth
          );
        }
      }
    },
    handleQuantityInput(event, change, index) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        if (
          event.target.value == "" ||
          isNaN(event.target.value) ||
          event.target.value == "0"
        ) {
          this.saveStrategyData[index].quantity =
            this.selectedInstrument.lot_size;
        } else {
          this.saveStrategyData[index].quantity = this.saveStrategyData[
            index
          ].quantity
            .toString()
            .replace(/[^\d]/g, "");
          let qty = Math.ceil(
            parseInt(event.target.value) / this.selectedInstrument.lot_size
          );
          if (qty == 0) {
            this.saveStrategyData[index].quantity =
              this.selectedInstrument.lot_size;
          } else {
            this.saveStrategyData[index].quantity =
              qty * this.selectedInstrument.lot_size;
          }
        }
      }, 1500);
    },

    setIntegerValue(event, change, index) {
      let value = event.target.value.toString().replace(/[^\d-]/g, "");
      this.$set(this.saveStrategyData[index], change, value);
      if (change == "expiry_limit") {
        if (
          this.saveStrategyData[index].expiry_options == "m" &&
          event.target.value > 3
        ) {
          this.$set(this.saveStrategyData[index], change, 3);
        } else if (
          this.saveStrategyData[index].expiry_options == "w" &&
          event.target.value > 7
        ) {
          this.$set(this.saveStrategyData[index], change, 7);
        }
      }
      if (change == "strike_limit") {
        if (event.target.value < 0) {
          this.$set(this.saveStrategyData[index], "strike_options", "ITM");
        } else if (event.target.value > 0) {
          this.$set(this.saveStrategyData[index], "strike_options", "OTM");
        } else {
          this.$set(this.saveStrategyData[index], "strike_options", "ATM");
        }
      }
      if (
        change == "strike_limit" &&
        this.saveStrategyData[index].segment == "CE"
      ) {
        if (
          event.target.value < this.strategyCreatorStrikes[index].atmLevels[0]
        ) {
          this.$set(
            this.saveStrategyData[index],
            change,
            this.strategyCreatorStrikes[index].atmLevels[0]
          );
        } else if (
          event.target.value >
          this.strategyCreatorStrikes[index].atmLevels[
            this.strategyCreatorStrikes[index].atmLevels.length - 1
          ]
        ) {
          this.$set(
            this.saveStrategyData[index],
            change,
            this.strategyCreatorStrikes[index].atmLevels[
              this.strategyCreatorStrikes[index].atmLevels.length - 1
            ]
          );
        }
      } else if (
        change == "strike_limit" &&
        this.saveStrategyData[index].segment == "PE"
      ) {
        if (
          event.target.value > this.strategyCreatorStrikes[index].atmLevels[0]
        ) {
          this.$set(
            this.saveStrategyData[index],
            change,
            this.strategyCreatorStrikes[index].atmLevels[0]
          );
        } else if (
          event.target.value <
          this.strategyCreatorStrikes[index].atmLevels[
            this.strategyCreatorStrikes[index].atmLevels.length - 1
          ]
        ) {
          this.$set(
            this.saveStrategyData[index],
            change,
            this.strategyCreatorStrikes[index].atmLevels[
              this.strategyCreatorStrikes[index].atmLevels.length - 1
            ]
          );
        }
      }
    },

    changeExpiryOptions(value, index) {
      this.$set(this.saveStrategyData[index], "expiry_limit", 0);
      if (value == "Monthly") {
        this.$set(this.saveStrategyData[index], "expiry_options", "m");
      } else {
        this.$set(this.saveStrategyData[index], "expiry_options", "w");
      }
    },
    getStrikeLimit(value, segment, index, strike) {
      let strike_limit;
      let strike_options;
      let atmValue;
      if (segment != "fut") {
        if (segment == "c") {
          atmValue =
            this.strategyCreatorStrikes[index].atmLevels[
              this.strategyCreatorStrikes[index].strikes.indexOf(strike)
            ];
        } else {
          atmValue =
            this.strategyCreatorStrikes[index].atmLevels[
              this.strategyCreatorStrikes[index].strikes.indexOf(strike)
            ];
        }
        strike_limit = atmValue;
        strike_options = atmValue < 0 ? "ITM" : atmValue > 0 ? "OTM" : "ATM";
      } else {
        strike_limit = "";
        strike_options = "";
      }
      if (value == "limit") {
        return strike_limit;
      } else {
        return strike_options;
      }
    },
    saveStrategyApi() {
      let payload = {};
      let spot_price =
        this.selectedInstrument.exchange == "NSE"
          ? this.compactMarketDataScripts(
              this.selectedInstrument.underlying_code
            )["details"]
            ? this.compactMarketDataScripts(
                this.selectedInstrument.underlying_code
              )["details"]["Last Traded Price"]
            : 0
          : this.compactMarketDataScripts(this.selectedInstrument.fut_code)[
              "details"
            ]
          ? this.compactMarketDataScripts(this.selectedInstrument.fut_code)[
              "details"
            ]["Last Traded Price"]
          : 0;
      if (spot_price == 0) {
        spot_price =
          this.selectedInstrument.exchange == "NSE"
            ? this.selectedInstrument.ltp
            : this.selectedInstrument.fut_ltp;
      }
      if (this.isEditStrategy) {
        payload = {
          id: this.saveStrategyDataPass.id,
          code:
            this.selectedInstrument.exchange == "NSE"
              ? this.selectedInstrument.underlying_code
              : this.selectedInstrument.fut_code,
          spot_price: spot_price,
          user_id: this.userId,
          name: this.strategyName,
          direction: this.selectedDirection,
          param_list: this.saveStrategyData,
        };
      } else {
        payload = {
          code:
            this.selectedInstrument.exchange == "NSE"
              ? this.selectedInstrument.underlying_code
              : this.selectedInstrument.fut_code,
          spot_price: spot_price,
          user_id: this.userId,
          name: this.strategyName,
          direction: this.selectedDirection,
          param_list: this.saveStrategyData,
        };
      }
      if (this.strategyName == "") {
        this.isStrategyEmpty = true;
        return;
      } else {
        this.isStrategyEmpty = false;
        this.isSaveStrategyButtonClicked = true;
        axios.post(saveStrategyCall, payload).then((res) => {
          if (res.data.status_code != 200) {
            this.$notify({
              group: "success",
              type: "error",
              title: "Error",
              text: "Strategy is not saved",
            });
          } else {
            this.$notify({
              group: "success",
              type: "success",
              title: "Success",
              text: res.data.body.message,
            });
            EventBus.$emit("getSavedStrategiesApi", true);
          }
          this.isSavedStrategyClicked = false;
          this.isSaveStrategyButtonClicked = false;
        });
      }
    },
  },
  mounted() {
    this.loadSaveStrategyData();
  },
};
</script>

<style lang="scss">
.strategy-name {
  min-width: 175px !important;
}
.v-btn[disabled] {
  opacity: 0.6;
}
.saved-strategy-dialog {
  min-width: 350px;
  padding: 12px 0;
  .v-text-field--outlined .v-label {
    top: 5px !important;
  }
  .v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 9px !important;
  }
  .v-input__slot {
    min-height: 25px !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  .v-label {
    font-size: 0.875em;
  }
  .v-input__icon {
    width: 15px;
    min-width: 15px;
    height: 15px;
  }
  .v-text-field--outlined .v-label--active {
    transform: translateY(-15px) scale(0.75);
  }
  input {
    height: 30px;
    font-size: 0.875em;
  }
}

.save-strategy-table {
  th {
    background-color: var(--chip-background);
    border-bottom: none !important;
    color: var(--text-color) !important;
  }
  td {
    border-bottom: none !important;
    margin-bottom: 1rem;
  }
  input.save-strategy-input {
    border: 1px solid var(--border-color);
    padding: 8px;
  }
}
.custom-loader {
  animation: loader 1s infinite;
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
