export default{
  NSE: (code) => {
    return JSON.stringify({
      k: `NSE|${code}#NSE`,
      t: "ud",
      m: "compact_marketdata",
    })
  },
  NFO: (code) => {
    return JSON.stringify({
      k: `NFO|${code}#NFO`,
      t: "ud",
      m: "compact_marketdata",
    })
  },
  MCX: (code) => {
    return JSON.stringify({
      k: `MCX|${code}#MCX`,
      t: "ud",
      m: "compact_marketdata",
    })
  },
  CDS: (code) => {
    return JSON.stringify({
      k: `CDS|${code}#CDS`,
      t: "ud",
      m: "compact_marketdata",
    })
  },
  BSE: (code) => {
    return JSON.stringify({
      k: `BSE|${code}#BSE`,
      t: "ud",
      m: "compact_marketdata",
    })
  },
  BFO: (code) => {
    return JSON.stringify({
      k: `BFO|${code}#BFO`,
      t: "ud",
      m: "compact_marketdata",
    })
  },
}