<template>
  <v-card class="white-card pa-3 px-5 my-0 mx-auto default-style" style="min-height: 70vh;">
    <v-card-title class="row no-gutters justify-space-between pa-0">
      <div class="page-title pt-3">Order book</div>
      <v-spaver></v-spaver>
      <div class="rounded-pill col-12 col-md-auto">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          dense
          single-line
          hide-details
          class="search-resize my-2 my-sm-0"
        ></v-text-field>
      </div>
      <v-spaver></v-spaver>
      <div></div>
    </v-card-title>
    <div id="container">
      <tabs
        :options="{ useUrlFragment: false }"
        @changed="tabChanged"
        cache-lifetime="10"
      >
        <!-- Orders tab -->
        <tab name="Orders">
          <div class="order-book">
            <div class="order-book-table" v-if="orderList.length">
              <v-data-table
                :headers="headers"
                :items="orderList"
                class="order-book-table-hover"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                :search="search"
                hide-default-footer
                multi-sort
                @page-count="pageCount = $event"
                mobile-breakpoint="0"
              >
                <template v-slot:item.status="{ item }">
                  <span
                    class="text-status-green"
                    v-if="item.status === 'success'"
                  >
                    {{ item.status }}
                  </span>
                  <span class="text-status-red" v-if="item.status === 'rejected'">
                    {{ item.status }}
                  </span>
                  <span v-if="item.status === 'pending'">
                    {{ item.status }}
                  </span>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
              </div>
              <!-- eslint-disable-next-line -->
            </div>
            <div v-if="sorryMessage" class="free-table">
              <img src="@/assets/free.svg" alt="" />
              <h1>Oop's</h1>
              <p>Nothing is there in your order book.</p>
              <!-- <button> <i class="el-icon-back"></i>  Back </button> -->
            </div>
          </div>
        </tab>
        <!-- Pending orders tab -->
        <tab name="Pending Orders">
          <div class="pending-order-book">
            <div class="pending-order-table-content">
              <div
                class="pending-order-book-table"
                v-if="pendingOrderList.length"
              >
                <v-data-table
                  :headers="pendingHeaders"
                  :items="pendingOrderList"
                  class="table-hover"
                  :search="search"
                  hide-default-footer
                  multi-sort
                  mobile-breakpoint="0"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.order_id"
                        @mouseover="handleCurrentChange(item)"
                        @mouseleave="handleMouse()"
                      >
                        <td>{{ item.formatted_ins_name }}</td>
                        <td>{{ item.Pcode }}</td>
                        <td>
                          <span v-if="currentRow" style="position: absolute">
                            <button
                              v-on:click="handleDelete(item)"
                              v-show="
                                currentRow['oms_order_id'] === item.oms_order_id
                              "
                              class="cancel-btn"
                            >
                              Cancel
                            </button>
                            <button
                              v-on:click="handleEdit(item)"
                              v-show="
                                currentRow['oms_order_id'] === item.oms_order_id
                              "
                              class="modify-btn"
                            >
                              Modify
                            </button>
                          </span>
                          <span> {{ item.Qty }} </span>
                        </td>

                        <td>
                          <span class="text--grey lighten-1">
                            {{ item.Prc }}
                          </span>
                        </td>

                        <td>{{ item.orderentrytime }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </div>
              <div v-if="sorryMessage" class="free-table">
                <img src="@/assets/free.svg" alt="" />
                <h1>Oop's</h1>
                <p>Nothing is there in your order book.</p>
                <!-- <button> <i class="el-icon-back"></i>  Back </button> -->
              </div>
            </div>
          </div>
        </tab>
      </tabs>
      <PlaceOrderModal
        v-if="showPlaceOrderDialog"
        :visible="showPlaceOrderDialog"
        @close="
          showPlaceOrderDialog = false;
          closeModal();
        "
        :order_location="'order_book'"
        :strategyCreator="strategyCreatorList"
        :selectedInstrument="selectedInstrument"
      >
      </PlaceOrderModal>
      <CancelOrderModal
        v-if="isCancelModalVisible"
        @close="closeModal"
        :data="[cancelData]"
      >
      </CancelOrderModal>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  // getBalanceCall,
  getOrderList,
getPendingOrdersUrl,
} from "./getService";
import moment from "moment";
import PlaceOrderModal from "./modals/PlaceOrderModal.vue";
import CancelOrderModal from "@/components/CancelOrderModal";
import UserDataMixin from "@/mixins/getUserDataMixin";

export default {
  mixins:[UserDataMixin],
  data: () => ({
    orderList: [],
    pendingOrderList: [],
    value1: "",
    search: "",
    showTable: true,
    sorryMessage: false,
    currentRow: null,
    currentTab: "orders",
    selectedInstrument: null,
    strategyCreatorList: [],
    cancelData: null,
    showPlaceOrderDialog: false,
    isCancelModalVisible: false,
    availableFund: localStorage.getItem("balance"),
    page: 1,
    pageCount: 0,
    itemsPerPage: 12,
    headers: [
      {
        text: "Instrument",
        sortable: true,
        value: "trading_symbol",
        class: "th-head",
        align: "center",
        cellClass: "tb-instrument",
      },
      {
        text: "Traded On",
        value: "added_on",
        sortable: true,
        width: "150px",
        class: "v-data-table-header",
        align: "center",
        cellClass: "tb-date",
      },
      {
        text: "Order Id",
        value: "order_id",
        sortable: true,
        class: "v-data-table-header",
        align: "center",
        cellClass: "tb-order",
      },
      {
        text: "Response",
        value: "response.rejectionreason",
        sortable: true,
        class: "v-data-table-header",
        align: "center",
        width: "350px",
        cellClass: "tb-reason",
      },
      {
        text: "Status",
        value: "status",
        sortable: true,
        class: "v-data-table-header",
        align: "center",
        cellClass: "tb-status",
      },
    ],
    pendingHeaders: [
      {
        text: "Instrument",
        sortable: true,
        value: "trading_symbol",
        class: "th-head",
        align: "center",
        cellClass: "tb-instrument",
      },
      {
        text: "Product Type",
        value: "product",
        sortable: true,
        class: "v-data-table-header",
        align: "center",
        cellClass: "tb-order",
      },
      {
        text: "Quantity",
        value: "quantity",
        sortable: true,
        class: "v-data-table-header",
        align: "center",
        width: "350px",
        cellClass: "tb-reason",
      },
      {
        text: "Price",
        value: "price",
        sortable: true,
        class: "v-data-table-header",
        align: "center",
        cellClass: "tb-status",
      },
      {
        text: "Time",
        value: "added_on",
        sortable: true,
        class: "v-data-table-header",
        align: "center",
        cellClass: "tb-date",
      },
    ],
  }),
  components: {
    PlaceOrderModal,
    CancelOrderModal,
  },
  methods: {
    tabChanged(selectedTab) {
      if (selectedTab.tab.name === "Pending Orders") {
        this.getPendingOrdersAlice(this.userId);
        localStorage.setItem("current_tab", "pending");
        this.currentTab = "pending";
        this.sorryMessage = false;
      } else {
        localStorage.setItem("current_tab", "orders");
        this.currentTab = "orders";
        this.sorryMessage = false;
      }
    },
    handleChange(val) {
      console.log("val", val);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    handleMouse() {
      this.currentRow = null;
    },
    handleEdit(item) {
      console.log("coming here", item);
      this.selectedInstrument = {
        name: item.Sym,
        exchange: item.Exchange,
        lot_size: item.lot_size,
        tick_size: parseFloat(item.ticksize),
        underlying_code: item.token,
        fut_code: item.token,
      };
      this.strategyCreatorList = [];
      item.ExpDate = moment(item.ExpDate, "DD MMM, YYYY").format("DD MMM YYYY");
      this.strategyCreatorList.push({
        trading_symbol: item.Trsym,
        formatted_ins_name: item.formatted_ins_name,
        strike_price: parseFloat(item.strikePrice),
        strike: parseFloat(item.strikePrice),
        segment:
          item.optionType == "PE" ? "p" : item.optionType == "CE" ? "c" : "fut",
        name: item.Sym,
        exchange: item.Exchange == "NSE" ? "NFO" : item.Exchange,
        ltp: item.ltp ? item.ltp : 0,
        expiry: item.ExpDate,
        quantity: item.Qty > 0 ? item.Qty : -item.Qty,
        code: item.token,
        nestOrderNumber: item.Nstordno,
        filledQuantity: item.Fillshares
      });
      this.showPlaceOrderDialog = true;
    },
    //11 Jul 2023
    handleDelete(row) {
      this.cancelData = row;
      this.isCancelModalVisible = true;
    },
    closeModal() {
      this.showPlaceOrderDialog = false;
      this.isCancelModalVisible = false;
      let user_id = this.userId;
      this.getPendingOrdersAlice(user_id);
    },
    getPendingOrdersAlice(clientId) {
      console.log(clientId);
      const payload = {
        path: "orderbook",
        user_id: this.userId,
        access_token: this.accessToken,
      };
      axios
        .post(getPendingOrdersUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          let order_list = res.data;
          if (order_list.length > 0) {
            this.pendingOrderList = order_list.filter(
              (order) =>
                (order.Status == "open") | (order.Status == "trigger pending")
            );
          }
          if (this.pendingOrderList.length == 0) {
            this.showTable = false;
            this.sorryMessage = true;
          } else {
            for (let i = 0; i < this.pendingOrderList.length; i++) {
              this.pendingOrderList[i]["OrderTime"] = new Date(
                this.pendingOrderList[i]["order_entry_time"] * 1000
              ).toLocaleString();
            }
            console.log("PendingOrderList", this.pendingOrderList);
            this.sorryMessage = false;
          }
        });
    },
  },
  name: "orderBook",
  mounted() {
    let payload = { user_id: this.userId };
    axios.post(getOrderList, payload).then((res) => {
      this.orderList = res.data;
      if (this.orderList.length == 0) {
        this.showTable = false;
        this.sorryMessage = true;
      } else {
        this.showTable = true;
      }
    });
  },
};
</script>

<style lang="scss">
@import "../assets/css/global.scss";

.order-book {
  padding: 0px 60px;
  .v-select.v-text-field:not(.v-text-field--single-line) input {
    visibility: hidden !important;
  }
  .order-book-table {
    width: 100%;
    overflow: hidden;
  }
  .order-book-table-hover {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 14px;
    border-collapse: collapse;
    background: white;
    width: inherit;
  }
  td,
  .order-book-table-hover th {
    border: none;
    padding: 10px;
    text-align: center;
    color: var(--option-row-text);
  }
  .tb-status {
    background: #e6f4ff;
  }
  span {
    color: var(--primary-button-text-color);
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875em;
    line-height: 16px;
  }
  .v-data-table-header {
    background-color: var(--primary-button-color);
    color: var(--primary-button-text-color);
  }
  .free-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    h1 {
      color: var(--red-text);
    }
    p {
      font-size: 1em;
      line-height: 18px;
      margin-top: 10px;
      text-align: center;
    }
    button {
      padding: 10px 20px;
      color: #fff;
      border-radius: 10px;
      background: #0d4593;
      margin-top: 10px;
    }
    i {
      margin-right: 5px;
    }
  }
  .order-table-filter {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      margin-right: 10px;
    }
  }
  .text-status-green {
    color: var(--green-text);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 14px;
  }
  .text-status-red {
    color: var(--red-text);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 14px;
  }
}
td.text-center tb-status {
  background: #e6f4ff;
}
.pending-order-book {
  padding: 0px 60px;
  .order-book-table {
    width: 100%;
    overflow: hidden;
  }
  .order-book-table-hover {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 14px;
    border-collapse: collapse;
    background: white;
    width: inherit;
  }
  td,
  .order-book-table-hover th {
    border: none;
    padding: 10px;
    text-align: center;
  }
  td {
    color: var(--text-color);
  }
  .tb-status {
    background: #e6f4ff;
  }
  span {
    color: var(--text-color);
    text-align: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 0.875em;
    line-height: 16px;
  }
  .v-data-table-header {
    background-color: var(--primary-button-color);
    span {
      color: var(--primary-button-text-color);
    }
  }
  .free-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    h1 {
      color: #df514c;
    }
    p {
      font-size: 1em;
      line-height: 18px;
      margin-top: 10px;
      text-align: center;
    }
    button {
      padding: 10px 20px;
      color: #fff;
      border-radius: 10px;
      background: #0d4593;
      margin-top: 10px;
    }
    i {
      margin-right: 5px;
    }
  }
  .order-table-filter {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      margin-right: 10px;
    }
  }
  .text-status-green {
    color: var(--green-text);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 14px;
  }
  .text-status-red {
    color: var(--red-text);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 14px;
  }
  .text--black {
    color: black;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 14px;
    text-align: justify;
  }
  td {
    font-size: 0.875em;
    font-weight: 500;
  }
}
.tabs-component-tabs {
  margin-right: auto;
  margin-bottom: 5px;
  width: 60%;
  display: inline-flex;
}
.tabs-component-tab {
  color: #999;
  font-size: 0.875em;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}
tabs-component {
  margin: 4em 0;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  border-bottom: solid 1px #2196f3;
  font-family: "Roboto";
  font-style: normal;
  line-height: 22px;
  display: flex;
  align-items: center;
}
a:active,
a:hover {
  color: #2196f3 !important;
}
a[aria-selected="true"] {
  color: #2196f3 !important;
}
.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

.tabs-component-tab-a {
  align-items: center;
  display: flex;
  padding: 0.5em 1em;
  text-decoration: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 22px;
  color: gray;
}
a {
  color: gray !important;
  text-decoration: none;
}
.tabs-component-panels {
  padding: 1em 0;
}
.modify-btn,
.cancel-btn {
  color: #fff;
  border-radius: 4px;
  background-color: var(--green-text);
  box-shadow: 1px 0px 2px 0px #000;
  width: 58px;
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-16);
  line-height: 19px;
  text-align: center;
}
.cancel-btn {
  margin: 0 12px;
  background-color: #626561;
}
.order-book-header {
  padding: 0 20px;
}
// For devices greater than 700px in length
@media (min-width: 700px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: center;
    margin-bottom: -1px;
    width: 50%;
  }
  .tabs-component-panels {
    padding: 1em 2em;
  }
  .tabs-component-tab {
    margin-right: 0.5em;
    transform: translateY(2px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    z-index: 2;
    transform: translateY(0);
  }
  ul.tabs-component-tabs li {
    margin: auto;
  }
}
// For devices upto 700px
@media screen and (max-width: 699px) {
  // .tabs-component-tab-a {
  //   font-size: 0.75em;
  // }
  #orders > div > div > div.v-data-table > div > table tr > th > span,
  #orders > div > div > div.v-data-table > div > table tr > th,
  #orders > div > div > div.v-data-table > div > table tr > td,
  .pending-order-book span,
  #pending-orders > div > div > div > div > div > table > tbody > tr > td {
    // font-size: 0.625em;
    font-weight: 400;
    height: 35px;
  }
  #container > div.order-book-header > div.header-bottom > div {
    padding: 0px 0px;
  }
  #container > div.tabs-component > ul {
    padding-left: 0px;
  }
  .orders-book {
    .orders-book-table {
      padding: 0px;
    }
  }
}
</style>
<style scoped lang="scss">
.default-style {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  color: var(--text-color);
  background-color: transparent;
}
.header-top.blue--text > p {
  margin-left: 15px;
}
.search-resize{
  width: max-content;
  margin: auto auto;
}
.page-title {
  font-size: 1.7em;
  line-height: 28px;
  font-weight: 600;
}
@media screen and (max-width: 699px) {
  .page-heading {
    font-size: 1.25rem !important;
    line-height: 12px !important;
  }
  .search-resize {
    width: 100%;
  }
  .item-resize {
    scale: 0.7;
  }
  .header-top.blue--text > p {
    display: none;
  }

  .pending-order-book {
    padding: 0px 0px;
    .order-book-table {
      width: 100%;
      overflow: hidden;
    }
    span {
      font-weight: 600;
      // font-size: 0.5625em;
      line-height: 16px;
    }
    .v-data-table-header {
      background-color: #2196f3;
      color: white;
    }
    .free-table {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 10px;
      h1 {
        color: #df514c;
      }
      p {
        // font-size: 1em;
        line-height: 18px;
        margin-top: 10px;
        text-align: center;
      }
      button {
        padding: 10px 20px;
        color: #fff;
        border-radius: 10px;
        background: #0d4593;
        margin-top: 10px;
      }
      img {
        max-width: 50%;
        max-height: 50%;
      }
    }
    .order-table-filter {
      display: flex;
      align-items: center;
      p {
        margin: 0;
        margin-right: 10px;
      }
    }
    .text-status-green {
      color: var(--green-text);
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875em;
      line-height: 14px;
    }
    .text-status-red {
      color: var(--red-text);
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875em;
      line-height: 14px;
    }
    .text--black {
      color: black;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875em;
      line-height: 14px;
      text-align: justify;
    }
  }
  .order-book {
    padding: 0px 0px;
    // .order-book-table-hover{
    //   font-family: 'Roboto';
    //   font-style: normal;
    //   font-weight: 500;
    //   font-size: 12px;
    //   line-height: 14px;
    //   border-collapse: collapse;
    //   background: white;
    //   width: inherit;
    // }
    td,
    .order-book-table-hover th {
      border: 1px solid #ddd;
      padding: 10px;
      text-align: center;
    }
    .tb-status {
      background: #e6f4ff;
    }
    span {
      // font-size: 0.5625em !important;
      line-height: 16px;
    }
    .v-data-table-header {
      background-color: #2196f3;
      color: white;
    }
    .free-table {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 10px;
      h1 {
        color: #df514c;
      }
      p {
        // font-size: 1em;
        line-height: 18px;
        margin-top: 10px;
        text-align: center;
      }
      button {
        padding: 10px 20px;
        color: #fff;
        border-radius: 10px;
        background: #0d4593;
        margin-top: 10px;
      }
      i {
        margin-right: 5px;
      }
    }
    .order-table-filter {
      display: flex;
      align-items: center;
      p {
        margin: 0;
        margin-right: 10px;
      }
    }
  }
}
</style>
