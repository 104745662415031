<template>
  <!-- test -->
  <div class="ml-2 graphs-container">
    <v-row class="white-card mb-2 graphs-container-header" no-gutters>
      <v-col cols="2">
        <v-select
          style="font-size: var(--font-18)"
          class="interval-dropdown"
          outlined
          v-model="selectedInterval"
          :items="timeIntervalsList"
          item-text="label"
          item-value="value"
          label="Default"
          single-line
          return-object
          @change="handleSelectedInterval()"
        ></v-select>
      </v-col>
      <v-col cols="10" class="d-flex align-center justify-end">
        <span class="d-flex align-center">
          <span
            style="cursor: pointer"
            @click="
              showUnderlyingChart = !showUnderlyingChart;
              loadChartData(multiStrikeChartData);
            "
          >
            <span class="ml-1"
              ><v-icon class="eye-icon" v-if="showUnderlyingChart"
                >mdi-eye</v-icon
              >
              <v-icon class="eye-icon" v-else>mdi-eye-off</v-icon>
            </span>
            <span class="ins-name-label ml-1">
              {{ selectedInstrument.name }}
            </span>
          </span>
          <span class="mis-nrml-label ml-2">
            <span
              @click="
                showCandleUnderlying = false;
                loadChartData(multiStrikeChartData);
              "
              :class="
                !showCandleUnderlying ? 'selected-span' : 'unselected-span'
              "
            >
              <img
                style="height: 15px"
                :src="
                  darkMode
                    ? showCandleUnderlying
                      ? require('@/assets/MultiStrike/DarkMode/line.svg')
                      : require('@/assets/MultiStrike/DarkMode/line-selected.svg')
                    : showCandleUnderlying
                    ? require('@/assets/MultiStrike/LightMode/line.svg')
                    : require('@/assets/MultiStrike/LightMode/line-selected.svg')
                "
              />
            </span>
            <span
              @click="
                showCandleUnderlying = true;
                loadChartData(multiStrikeChartData);
              "
              :class="
                showCandleUnderlying ? 'selected-span' : 'unselected-span'
              "
            >
              <img
                style="height: 15px"
                :src="
                  darkMode
                    ? !showCandleUnderlying
                      ? require('@/assets/MultiStrike/DarkMode/candle.svg')
                      : require('@/assets/MultiStrike/DarkMode/candle-selected.svg')
                    : require('@/assets/MultiStrike/LightMode/candle.svg')
                "
              />
            </span>
          </span>
        </span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <div class="white-card" style="width: 100%; position: relative">
        <div class="strategy-label">Multi Strike Chart</div>
        <div
          class="text-center"
          style="position: absolute; top: 45%; width: 100%"
          v-if="multiStrikeChartData.length == 0 && !showUnderlyingChart"
        >
          No data available
        </div>
        <div style="height: 400px; width: 100%">
          <div v-if="isLoading" style="height: 400px; width: 100%">
            <SkeletonLoader />
          </div>

          <div id="chartContainer" class="candlestick-chart"></div>
        </div>
      </div>
    </v-row>

    <v-row
      class="white-card mt-3"
      no-gutters
      v-if="deltaChart && deltaChart.series.length"
    >
      <div class="strategy-label">Delta Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="deltaChart"
        ref="chart"
      >
      </highcharts>
    </v-row>
    <v-row
      class="white-card mt-3"
      no-gutters
      v-if="gammaChart && gammaChart.series.length"
    >
      <div class="strategy-label">Gamma Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="gammaChart"
        ref="chart"
      >
      </highcharts>
    </v-row>
    <v-row
      class="white-card mt-3"
      no-gutters
      v-if="thetaChart && thetaChart.series.length"
    >
      <div class="strategy-label">Theta Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="thetaChart"
        ref="chart"
      >
      </highcharts>
    </v-row>
    <v-row
      class="white-card mt-3"
      no-gutters
      v-if="vegaChart && vegaChart.series.length"
    >
      <div class="strategy-label">Vega Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="vegaChart"
        ref="chart"
      >
      </highcharts>
    </v-row>
    <v-row
      class="white-card mt-3"
      no-gutters
      v-if="oiChart && oiChart.series.length"
    >
      <div class="strategy-label">OI Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="oiChart"
        ref="chart"
      >
      </highcharts>
    </v-row>
  </div>
</template>
<script>
import { EventBus } from "../../main.js";
import { Chart } from "highcharts-vue";
import "highcharts/modules/stock"; // Import the stock module
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import { createChart, PriceScaleMode } from "lightweight-charts";
HighchartsMore(Highcharts);
import SkeletonLoader from "../modals/SkeletonLoader.vue";

export default {
  name: "StrikeCharts",
  props: {
    selectedInstrument: null,
  },
  components: {
    highcharts: Chart,
    SkeletonLoader,
  },
  created() {
    EventBus.$on("multiStrikeChartData", (value) => {
      this.loadChartData(value);
    });
    EventBus.$on("instrumentChartData", (value) => {
      this.isLoading = false;
      this.instrumentChartData = value.chartData;
      this.candleChartData = value.candleInstrument;
      this.chartData = this.createChart();
      this.deltaChart = this.createOtherCharts();
      this.gammaChart = this.createOtherCharts();
      this.thetaChart = this.createOtherCharts();
      this.vegaChart = this.createOtherCharts();
      this.oiChart = this.createOtherCharts();
    });
  },
  computed: {
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  data() {
    return {
      timeIntervalsList: [
        { label: "1 min", value: "1" },
        { label: "3 min", value: "3" },
        { label: "5 min", value: "5" },
        { label: "15 min", value: "15" },
        { label: "30 min", value: "30" },
        { label: "1 hr", value: "60" },
        // { label: "24 hr", value: "d" },
      ],
      selectedInterval: { label: "1 min", value: "1" },
      multiStrikeChartData: [],
      chartData: null,
      instrumentChartData: [],
      deltaChart: null,
      gammaChart: null,
      thetaChart: null,
      vegaChart: null,
      oiChart: null,
      candleStick: null,
      candleChartData: [],
      showCandleUnderlying: false,
      showUnderlyingChart: true,
      isLoading: true,
    };
  },
  methods: {
    loadChartData(value) {
      this.destroyCharts();
      if (this.showCandleUnderlying) {
        this.createUnderlyingChartData(this.candleChartData);
      } else {
        this.createUnderlyingChartData(this.instrumentChartData);
      }
      this.multiStrikeChartData = value;
      this.deltaChart.series = [];
      this.gammaChart.series = [];
      this.thetaChart.series = [];
      this.vegaChart.series = [];
      this.oiChart.series = [];
      this.chartData.series = this.chartData.series.slice(0, 1);
      let index = 0;
      for (let chart of this.multiStrikeChartData) {
        this.chartData.series.push({
          name: chart.name,
          yAxis: 0,
          visible: chart.visible,
          color: "var(--color-" + chart.colorNumber + ")",
          data: chart.data,
        });
        this.deltaChart.series.push({
          name: chart.name,
          yAxis: 0,
          visible: chart.visible,
          color: "var(--color-" + chart.colorNumber + ")",
          data: chart.delta,
        });
        this.gammaChart.series.push({
          name: chart.name,
          yAxis: 0,
          visible: chart.visible,
          color: "var(--color-" + chart.colorNumber + ")",
          data: chart.gamma,
        });
        this.thetaChart.series.push({
          name: chart.name,
          yAxis: 0,
          visible: chart.visible,
          color: "var(--color-" + chart.colorNumber + ")",
          data: chart.theta,
        });
        this.vegaChart.series.push({
          name: chart.name,
          yAxis: 0,
          visible: chart.visible,
          color: "var(--color-" + chart.colorNumber + ")",
          data: chart.vega,
        });

        //to create chart with light weight charts
        if (this.multiStrikeChartData[index].visible) {
          setTimeout(() => {
            const lineData = chart.data.map((item) => ({
              time: item[0] / 1000, // Assuming the timestamp is the first item in each sub-array
              value: item[1], // Assuming the value is the second item in each sub-array
            }));
            const chartColor = getComputedStyle(
              document.querySelector(":root")
            ).getPropertyValue("--color-" + chart.colorNumber);
            const lineSeries = this.candleStick.addLineSeries({
              lineWidth: 2,
              color: chartColor,
              priceScaleId: "rightPriceScale",
              PriceScaleOptions: "rightPriceScale",
            });
            lineSeries.setData(lineData);
            this.candleStick.timeScale().fitContent();
            this.candleStick.applyOptions({
              leftPriceScale: {
                mode: PriceScaleMode.Normal,
                visible: true,
                autoScale: true,
              },
            });
          }, 0);
        }
        index = index + 1;
      }
    },
    createChart() {
      return {
        chart: {
          backgroundColor: "transparent",
        },
        title: {
          text: "",
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: [
          {
            title: {
              text: "",
            },
          },

          {
            title: {
              text: "",
            },
            opposite: true, // This places the axis on the right side
          },
        ],
        series: [
          {
            // type:"candlestick",
            name: this.selectedInstrument.name,
            data: this.instrumentChartData,
            yAxis: 1,
            dashStyle: "dash",
            color: "var(--primary-color)",
            visible: true,
          },
        ],
        credits: {
          enabled: false,
        },
      };
    },
    createOtherCharts() {
      return {
        lang: {
          noData: "Loading...",
        },
        chart: {
          backgroundColor: "transparent",
        },
        title: {
          text: "",
        },
        xAxis: {
          type: "datetime",

          labels: {
            style: {
              color: "var(--text-color)",
            },
          },
        },
        yAxis: [
          {
            title: {
              text: "",
            },
            labels: {
              style: {
                color: "var(--text-color)",
              },
            },
          },
          {
            title: {
              text: "",
              labels: {
                style: {
                  color: "var(--text-color)",
                },
              },
            },
            opposite: true, // This places the axis on the right side
          },
        ],
        series: [],
        credits: {
          enabled: false,
        },
      };
    },
    handleSelectedInterval() {
      EventBus.$emit("selectedInterval", this.selectedInterval.value);
    },
    createUnderlyingChartData(value) {
      const mappedData = value.map((item) => {
        const mappedItem = {
          // time: new Date(item.date).toISOString().split("T")[0], // Convert "date" to "time"
          time: item.date / 1000,
          open: item.open,
          high: item.high,
          low: item.low,
          close: item.close,
        };
        return mappedItem;
      });
      this.$nextTick(() => {
        const container = document.getElementById("chartContainer");
        if (!container) {
          console.error('Element with id "chartContainer" not found.');
          return;
        }
        this.candleStick = createChart(container, {
          timeScale: {
            timeVisible: true,
            secondsVisible: false, // You can adjust this based on your data
          },
          layout: {
            leftPriceScaleId: "leftPriceScale",
            rightPriceScaleId: "rightPriceScale",
            background: { type: "solid", color: "transparent" },
            textColor: this.darkMode ? "#c4c4c4" : "#2A2A2A",
          },
        });
        if (this.showUnderlyingChart) {
          if (this.showCandleUnderlying) {
            const candlestickSeries = this.candleStick.addCandlestickSeries();
            const candlestickData = mappedData;
            candlestickSeries.setData(candlestickData);
            this.candleStick.timeScale().fitContent();
          } else {
            const lineData = this.instrumentChartData.map((item) => ({
              time: item[0] / 1000, // Assuming the timestamp is the first item in each sub-array
              value: item[1], // Assuming the value is the second item in each sub-array
            }));
            const chartColor = getComputedStyle(
              document.querySelector(":root")
            ).getPropertyValue("--primary-color");
            const lineSeries = this.candleStick.addLineSeries({
              lineWidth: 2,
              color: chartColor,
              lineStyle: 2,
            });
            lineSeries.setData(lineData);
            this.candleStick.timeScale().fitContent();
          }
        }
      });
    },
    destroyCharts() {
      if (this.candleStick !== null) {
        this.candleStick.remove();
        this.candleStick = null;
      }
    },
  },
  mounted() {},
  destroyed() {
    EventBus.$off("multiStrikeChartData");
  EventBus.$off("instrumentChartData");
    if (this.candleStick !== null) {
      this.candleStick.remove();
      this.candleStick = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.ins-name-icon {
  width: 25px;
}

.ins-name-label {
  font-size: var(--font-18);
}
.eye-icon.v-icon.v-icon {
  font-size: max(var(--font-18), 16px);
}

.strategy-label {
  font-size: var(--font-24);
  font-family: Gilroy-SemiBold;
  color: var(--text-color);
}
.candlestick-chart {
  width: 100%;
  height: 100%;
}

.mis-nrml-label {
  padding: 4px;
  // font-size: 1rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  display: flex;

  span {
    padding: 5px 10px;
    cursor: pointer;
    width: 45px;
    text-align: center;
    white-space: nowrap;
    display: flex;
  }

  .selected-span {
    background-color: var(--primary-button-color);
    color: var(--primary-button-text-color);
    border-radius: 4px;
  }

  .unselected-span {
    background-color: transparent;
    color: var(--text-color);
  }
}

.graphs-container {
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;

  .graphs-container-header {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}
@media only screen and (max-width: 600px) {
  .strategy-label {
    font-size: var(--font-20) !important;
  }
  .ins-name-label {
    font-size: var(--font-16) !important;
  }
}
</style>
<style lang="scss">
.interval-dropdown {
  width: 80%;
  min-width: 100px;
}
.interval-dropdown.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 25px;
}
.interval-dropdown.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
.interval-dropdown.v-text-field--enclosed .v-input__append-inner {
  margin-top: 4px;
}
@media only screen and (max-width: 600px) {
  .interval-dropdown {
    font-size: var(--font-14) !important;
  }
}
</style>
