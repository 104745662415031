<template>
  <div class="mb-wrapper">
    <div>
      <PlaceOrderModal
        v-if="showPlaceOrderDialog"
        :visible="showPlaceOrderDialog"
        @close="showPlaceOrderDialog = false"
        :strategyCreator="strategyCreatorList"
        :selectedInstrument="selectedInstrument"
        :order_location="order_location"
      />
    </div>
    <div style="display: flex; justify-content: space-between" class="px-2">
      <div class="margin-benefit-label">Margin Benefit/Hedge Strategies</div>
      <button style="float: right" @click="isVisible = !isVisible">
        <i
          style="font-size: var(--font-24)"
          v-if="!isVisible"
          class="fa-solid fa-angle-down"
        ></i>
        <i
          style="font-size: var(--font-24)"
          v-else
          class="fa-solid fa-angle-up"
        ></i>
      </button>
    </div>
    <div v-if="!isVisible" style="color: #b3b3b3" class="mx-2 my-1">
      An opportunity to leverage assets
    </div>
    <div style="max-width: 100%; position: relative" v-if="isVisible">
      <!-- LOADING -->
      <div v-if="isLoadingStrategies" class="text-center">Loading...</div>

      <!-- ERROR -->
      <div v-else-if="error && !isLoadingStrategies" class="text-center">
        <h3>{{ error.tagline }}</h3>
        <div v-if="error.message">
          <p>Type: {{ error.type }}</p>
          <p>Message: {{ error.message }}</p>
        </div>
      </div>

      <!-- CARDS -->
      <div v-else class="margin-benefit-wrapper mt-3 mx-sm-2">
        <div
          class="mb-inner-wrapper d-flex flex-column flex-sm-row"
          id="scrollableMarginBenefitDiv"
        >
          <!-- CARDS Loop -->
          <div
            class="mb-cards"
            v-for="(strategy, index) in recomendedStrats"
            :key="index"
          >
            <div
              class="white-card my-2 mx-auto mx-sm-2"
              style="max-width: 500px"
            >
              <p
                class="text-capitalize"
                :style="{
                  color:
                    strategy.title == 'Recommended'
                      ? 'var(--primary-button-color)'
                      : 'var(--text-color)',
                }"
              >
                {{ strategy.title }}
              </p>

              <!-- INNER TABLE -->
              <v-simple-table>
                <template v-slot:default>
                  <thead class="strategy-header">
                    <tr>
                      <th class="text-left">Instrument</th>
                      <th class="text-center">Qty</th>
                      <th class="text-center">Price</th>
                    </tr>
                  </thead>
                  <tbody style="font-size: var(--font-14)">
                    <tr>
                      <td class="text-left">
                        {{ strategy.cards[0].formatted_ins_name }}
                      </td>
                      <td
                        class="text-center"
                        :class="
                          strategy.cards[0].quantity < 0
                            ? 'red-text'
                            : 'green-text'
                        "
                      >
                        <span v-if="strategy.cards[0].quantity > 0">+</span
                        >
                        <span v-if="selectedInstrument.exchange=='MCX' || selectedInstrument.exchange=='CDS'">{{ strategy.cards[0].quantity / selectedInstrument.lot_size}}
                        </span>
                        <span v-else>
                          {{ strategy.cards[0].quantity }}
                        </span>
                      </td>
                      <td class="text-center green-text">
                        <span v-if="selectedInstrument.name !== 'USDINR'">
                          {{
                            strategy.cards[0].ltp
                              | currencyWithDecimal
                          }}
                        </span>
                        <span v-else>
                          {{ strategy.cards[0].ltp | float(4) }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        {{ strategy.cards[1].formatted_ins_name }}
                      </td>
                      <td
                        class="text-center"
                        :class="
                          strategy.cards[1].quantity < 0
                            ? 'red-text'
                            : 'green-text'
                        "
                      >
                        <span v-if="strategy.cards[1].quantity > 0">+</span
                        >
                        <span v-if="selectedInstrument.exchange=='MCX' || selectedInstrument.exchange=='CDS'">{{ strategy.cards[1].quantity / selectedInstrument.lot_size}}
                        </span>
                        <span v-else>
                          {{ strategy.cards[1].quantity }}
                        </span>
                      </td>
                      <td class="text-center green-text">
                        <span v-if="selectedInstrument.name !== 'USDINR'">
                          {{
                            strategy.cards[1].ltp.toFixed(2)
                              | currencyWithDecimal
                          }}
                        </span>
                        <span v-else>
                          {{ strategy.cards[1].ltp.toFixed(2) | float(4) }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <!-- LOWER CARD SECTION -->
              <div class="d-flex mt-4">
                <span class="mr-1" style="font-size: var(--font-16)">{{
                  vWidth > 900 ? "Max Profit: " : "M.P.: "
                }}</span>
                <span
                  style="margin-right: 1rem"
                  class="green-text"
                  v-if="
                    possibleStrToFloat(strategy.pl_data.profit) !== 'Unlimited'
                  "
                >
                  {{
                    parseFloat(strategy.pl_data.profit).toFixed(0)
                      | integerFormat
                  }}
                </span>
                <span style="margin-right: 1rem" class="green-text" v-else>
                  {{ possibleStrToFloat(strategy.pl_data.profit) }}</span
                >
                <span class="mr-1" style="font-size: var(--font-16)">{{
                  vWidth > 900 ? "Max Loss: " : "M.L.: "
                }}</span>
                <span
                  class="red-text"
                  v-if="
                    possibleStrToFloat(strategy.pl_data.loss) !== 'Unlimited'
                  "
                >
                  {{
                    parseFloat(strategy.pl_data.loss).toFixed(0) | integerFormat
                  }}</span
                >
                <span class="red-text" v-else
                  >{{ possibleStrToFloat(strategy.pl_data.loss) }}
                </span>
              </div>
              <v-row class="justify-space-between mt-3" no-gutters>
                <v-col class="col-7 mt-2">
                  <span
                    style="
                      background-color: var(--chip-background);
                      padding: 5px;
                    "
                  >
                    <span
                      style="font-size: var(--font-14)"
                      v-if="strategy.margin_data.margin == 'NA'"
                      >Margin: NA</span
                    >
                    <span style="font-size: 0.9em" v-else
                      >Margin:
                      {{
                        strategy.margin_data.margin.toFixed(0) | integerFormat
                      }}</span
                    >
                    <span
                      v-if="strategy.margin_data.mb == 'NA'"
                      class="green-text"
                    >
                      (MB: NA)
                    </span>
                    <span
                      style="font-size: var(--font-14)"
                      v-else
                      class="green-text"
                    >
                      <span>
                        (MB: {{ strategy.margin_data.mb | currency }})
                      </span>
                    </span>
                  </span>
                </v-col>
                <v-col class="col-5 text-right d-flex justify-end">
                  <div class="mt-2 mr-3">
                    <img
                      :src="
                        darkMode
                          ? require('@/assets/LightMode/graph.png')
                          : require('@/assets/LightMode/graph.png')
                      "
                      width="30px"
                      style="cursor: pointer"
                      @click="setCustomStrategyData(strategy)"
                    />
                  </div>
                  <button
                    class="primary-button"
                    @click.stop="showPlaceOrder(index)"
                  >
                    Place Order
                  </button>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isLoadingStrategies && !$vuetify.breakpoint.xs">
        <v-icon
          v-if="moveRight == 'right'"
          @click="scrollToEnd('left')"
          class="left-arrow"
          >mdi-chevron-left</v-icon
        >
        <v-icon
          v-if="moveRight == 'left'"
          @click="scrollToEnd('right')"
          class="right-arrow"
          >mdi-chevron-right</v-icon
        >
      </div>
    </div>
  </div>
</template>
<script>
import { getStrategyCardsURL } from "../getService.js";
import axios from "axios";
import PlaceOrderModal from "../modals/PlaceOrderModal.vue";
import UserDataMixin from "@/mixins/getUserDataMixin";

export default {
  components: {
    PlaceOrderModal,
  },
  mixins:[UserDataMixin],
  props: {
    ins: null,
    selectedTab: { type: String, default: "Bullish" },
    selectedExpiry: { type: String, default: "" },
    selectedInstrument: null,
  },
  data() {
    return {
      showPlaceOrderDialog: false,
      isVisible: false,
      recomendedStrats: [],
      isLoadingStrategies: false,
      error: null,
      strategyCreatorList: [],
      // Temp vars further converted to props or inject
    
      moveRight: "left",
      order_location: "margin_benefit",
    };
  },
  watch: {
    isVisible() {
      this.getStrategies();
    },
    selectedExpiry() {
      this.getStrategies();
    },
    ins() {
      this.getStrategies();
    },
    selectedTab() {
      this.getStrategies();
    },
  },
  mounted() {
    
    // this.getStrategies();
  },
  computed: {
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    vWidth() {
      return this.$vuetify.breakpoint.width;
    },
  },
  methods: {
    /**
     * @function TO GET AND UPDATE THE STRATEGIES CARDS
     * Cards depends on INSTRUMENT, EXPIRY and TREND-TAB
     */
    async getStrategies() {
      this.isLoadingStrategies = true;

      const payload = {
        path: "get_cards",
        user_id: this.userId,
        access_token: this.accessToken,
        ws_session: this.wsSession,
        broker: "aliceblue",
        tab_name: this.selectedTab.toLowerCase(),
        exchange: this.ins.exchange,
        expiry: this.selectedExpiry,
        name: this.ins.name,
        code:
          this.ins.exchange == "NSE"
            ? this.ins.underlying_code
            : this.ins.fut_code,
      };
      if (this.isVisible) {
        axios
          .post(getStrategyCardsURL, payload, {
            headers: {
              "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3",
            },
          })
          .then((res) => {
            this.isLoadingStrategies = false;
            // Condition to check for errors
            if (res.data.errorMessage) {
              throw res;
            }
            this.recomendedStrats = res.data.card_combinations;
            this.recomendedStrats[0].title = "Recommended";
            this.recomendedStrats[1].title = "Low Margin";
            this.recomendedStrats[2].title = "Low Hedged Cost";
            this.error = null;
          })
          .catch((err) => {
            // this.error=err.data.errorMessage;
            this.error = {
              tagline: "Oops! An error Occured!",
              message: err.data.errorMessage ?? "",
              type: err.data.errorType ?? "",
            };
            // console.log(err)
          });
      }
    },

    getClassRedGreen(val, pivot = 0) {
      if (val < pivot) return "red";
      else if (val == pivot) return "blue";
      else return "green";
    },
    scrollToEnd(moveTo) {
      let scrollDirection = moveTo == "right" ? "last-child" : "first-child";
      this.moveRight = moveTo;
      document
        .querySelector(`#scrollableMarginBenefitDiv > div:${scrollDirection}`)
        .scrollIntoView({
          block: "end",
          inline: "nearest",
          behavior: "smooth",
        });
    },
    possibleStrToFloat(val) {
      let ans = parseFloat(val);
      return ans ? ans.toFixed(2) : val;
    },
    setCustomStrategyData(strategy) {
      this.$emit("changePrebuiltStrategyDataBenefit", strategy);
      this.$parent.$refs.targetComponent.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    },
    showPlaceOrder(index) {
      this.strategyCreatorList = this.recomendedStrats[index].cards;
      for(let item of this.strategyCreatorList){
        item.quantity =  item.exchange != "MCX" && item.exchange != "CDS"?item.quantity: item.quantity/this.selectedInstrument.lot_size
      }
      this.showPlaceOrderDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global.scss";

.margin-benefit-label {
  font-size: var(--font-24);
  line-height: 28px;
  font-family: "Gilroy-Semibold";
}

.v-data-table {
  color: inherit;

  th,
  td {
    height: 36px !important;
    border-bottom: none !important;
    padding: 0 2px !important;
  }

  th {
    font-weight: 400;
    font-size: var(--font-14) !important;
    background: var(--chip-background);
    color: var(--text-color) !important;
  }

  .v-data-table__wrapper {
    overflow-x: hidden !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */
  }
}

.margin-benefit-wrapper {
  position: relative;
  overflow-x: hidden;

  .mb-inner-wrapper {
    position: relative;
    transition: all 1s;

    .left {
      left: 0;
    }
  }
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  font-size: var(--font-32);
  box-shadow: 0px 0px 10px rgb(152 152 152 / 48%);
  border-radius: 50%;
  color: var(--text-color);
  background-color: var(--cards-background-color);
  cursor: pointer;
}

.left-arrow {
  transform: translate(-20%, -50%);
  left: -19px;
}

.right-arrow {
  transform: translate(20%, -50%);
  right: -19px;
}
.mb-cards{
  width: 52%;
}
.mb-cards>div{
  min-width:430px
}
@media only screen and (max-width:600px){
  .mb-cards{
    width: 100%;
  }
  .mb-cards>div{
    min-width:auto;
  }
}
</style>
