<template>
    <v-card 
        elevation="4"
        class="order-btns"
        rounded="lg"
        :style="{top:clickPointGraphData.chartY+'px',left: clickPointGraphData.chartX+'px'}"
    >
    <div>
        <div style="font-size: 0.875rem;">{{ clickPointGraphData.strike }} - {{ clickPointGraphData.segment=='ce'?'Call':'Put' }}</div>
        <div v-if="clickPointGraphData.seriesData" style="white-space: nowrap; font-size: 0.6rem;">
            {{clickPointGraphData.seriesData.name}} - {{ clickPointGraphData.seriesData.val | float(2)}}
        </div>

    </div>
    <div class=" mt-2 d-flex justify-space-around align-center">
        <button 
            class="buy-ins-btn" 
            @click="$emit(
                'add-from-charts',
                clickPointGraphData.strike,
                clickPointGraphData.segment,
                'b'
            )"
        >B</button>
        <button 
            class="sell-ins-btn" 
            @click="$emit(
                'add-from-charts',
                clickPointGraphData.strike,
                clickPointGraphData.segment,
                's'
            )"
        >S</button>
    </div>
    </v-card >
</template>
<script>
export default({
    props:{
        clickPointGraphData:Object
    },
    emits:['add-from-charts'],
})
</script>
<style lang="scss" scoped>
.order-btns{
    padding: 0.5rem;
    
    .buy-ins-btn,.sell-ins-btn{
        width: 25px;
        height: 25px;
        text-align: center;
        vertical-align: middle;
        background-color: white;
        border-radius: 5px;
        position: relative;
    }
    .buy-ins-btn{
        color: var(--primary-color);
        background-color: var(--buy-background);
        margin-left: 2px;
        margin-right: 6px;
    }
    .sell-ins-btn{
        color: var(--red-text);
        background-color: var(--put-background);
        margin-left: 6px;
        margin-right: 2px;
    }
    .buy-ins-btn:hover{
        border: 1px solid var(--primary-color);
    }
    .sell-ins-btn:hover{
        border: 1px solid var(--red-text);
    }
}
</style>