import VueRouter from 'vue-router';
import {landingPageURL} from "@/utils/aliceBlueUrls"

import LoginSuccess from '../components/LoginSuccess';
import Layout from '../components/Layout';
import OptionChain from '../components/option-chain/OptionChain';

import OrderBook from "../components/orderBook.vue";
import HomeView from "../views/HomeView.vue";
import Dashboard from "../components/Dashboard/Dashboard.vue";
import Feedback from "../components/Feedback.vue";
import Position from "../components/Position/Position.vue";
import VerifiedPnL from "../components/Position/VerifiedPnL.vue";
import OptionScanner from "../components/OptionScanner.vue";
import OpenInterestFilter from "@/components/OpenInterestFilter";
import PageNotFound from "@/components/PageNotFound";
import Settings from "../components/Settings.vue";
import ResearchCalls from "../components/ResearchCalls/ResearchCalls.vue";
import MultiStrikeCharts from "../components/MultiStrikeCharts/MultiStrikeCharts.vue";
const router = new VueRouter({
  mode: "history",
  routes: [
    { 
      path: "/", 
      component: HomeView, 
      name: "Home", 
      meta: { guest: true } ,
      beforeEnter(_,__,next){
        const isLoggedIn=isUserLoggedIn();
        const dev = localStorage.getItem('dev');
        if(dev)return next();
        if(isLoggedIn) return next('/dashboard');
        else window.open(landingPageURL,'_self');
      },
    },
    {
      path: "/dashboard",
      component: Dashboard,
      name: "Dashboard",
      meta: { requiresAuth: true, layout: Layout },
    },
    {
      path: "/position",
      component: Position,
      name: "Position",
      meta: { requiresAuth: true, layout: Layout },
    },
    {
      path: "/watchlist",
      component: Position,
      name: "Watchlist",
      meta: { requiresAuth: true, layout: Layout },
    },
    {
      path: "/verifiedPnL/:portfolio_key",
      component: VerifiedPnL,
      name: "VerifiedPnL",
      meta: { requiresAuth: false, layout: Layout },
    },
    {
      path: "/option-scanner",
      component: OptionScanner,
      name: "OptionScanner",
      meta: { requiresAuth: true, layout: Layout },
    },
    {
      path: "/orderbook",
      component: OrderBook,
      name: "Orderbook",
      meta: { requiresAuth: true, layout: Layout },
    },
    {
      path: "/feedback",
      component: Feedback,
      name: "Feedback",
      meta: { requiresAuth: false, layout: Layout },
    },
    {
      path: "/layout",
      component: Layout,
      name: "Layout",
      meta: { requiresAuth: false },
    },
    {
      path: "/loginsuccess",
      component: LoginSuccess,
      name: "LoginSuccess",
      meta: { requiresAuth: false, layout: Layout },
    },
    {
      path: "/option-chain",
      component: OptionChain,
      name: "OptionChain",
      meta: { requiresAuth: true, layout: Layout },
    },
    {
      path: "/open-interest-table",
      component: OpenInterestFilter,
      name: "OpenInterestFilter",
      meta: { requiresAuth: true, layout: Layout },
    },
    {
      path: "/strike-charts",
      component: MultiStrikeCharts,
      name: "MultiStrikeCharts",
      meta: { requiresAuth: false, layout: Layout },
    },
    {
      path: "/settings",
      component: Settings,
      name: "Settings",
      meta: { requiresAuth: true, layout: Layout },
    },
    {
      path:"/research-calls",
      component: ResearchCalls,
      name: "ResearchCalls",
      meta: { requiresAuth: true, layout: Layout },
    },
    // ADD PATH ABOVE THIS LINE
    {
      path: "/:pathMatch(.*)",
      redirect: { name: "PageNotFound" },
    },
    {
      path: "/pageNotFound",
      component: PageNotFound,
      name: "PageNotFound",
      meta: { requiresAuth: false, layout: Layout },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

/*  Router Is instantiated in before the store and even before the app is created.
    Hence need to use local storage instead of store to check login status
    rest of the login, autologin, logout and token expiry login is handled by store in auth module
*/
router.beforeEach((to, from, next) => {
  const loggedIn = isUserLoggedIn();
  if( to.meta.requiresAuth && !loggedIn ) {
    console.log("User is not logged in")
    return next('/')
  }
  else{
    return next();
  }
});
const isUserLoggedIn=()=>{
  const user =JSON.parse(localStorage.getItem('userObj'));
  return user?true:false;
}

export default router;
