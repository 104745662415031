<template>
  <div class="white-card watchlist-wrapper default-style px-2 px-sm-3 ma-0">
    <PlaceOrderModal
      v-if="showPlaceOrderDialog && orderData"
      :visible="showPlaceOrderDialog"
      @close="
        () => {
          showPlaceOrderDialog = false;
          orderData = null;
        }
      "
      :strategyCreator="orderData.orderIns"
      :selectedInstrument="orderData.selectedIns"
      @clear-items="cart = []"
      :order_location="order_location"
      :isHoldingsSection="isHoldingsSection"
    />
    <div class="d-flex mt-2 px-2">
      <span class="section-heading" @click="print('watchlist')">
        Watchlist
      </span>
      <v-spacer />
      <!-- <span><v-icon @click="$emit('refresh-websocket')">mdi-refresh</v-icon></span> -->
    </div>
    <!-- Nifty And Banknifty -->
    <div class="d-flex justify-space-between my-3 px-2 header-live-ltps">
      <!-- Nifty Price -->
      <div>
        <span>{{ NIFTY.name }}</span>
        <br v-if="xsScreen || mdScreen" />
        <span
          class="mx-1"
          v-if="liveLtp(NIFTY.code)"
          style="white-space: nowrap"
        >
          <span
            :class="liveChange(NIFTY.code) >= 0 ? 'green-text' : 'red-text'"
          >
            {{ liveLtp(NIFTY.code) | float(2) }}
          </span>
          <span
            class="sub-text-change"
            :class="liveChange(NIFTY.code) >= 0 ? 'green-text' : 'red-text'"
          >
            (<span v-if="liveChange(NIFTY.code) >= 0">+</span
            >{{ liveChange(NIFTY.code) | float(2) }}%)
          </span>
        </span>
      </div>
      <v-spacer></v-spacer>

      <!-- Banknifty Price -->
      <div :class="{ 'text-right': xsScreen || mdScreen }">
        <span>{{ BANKNIFTY.name }}</span>
        <br v-if="xsScreen || mdScreen" />
        <span
          class="mx-1"
          v-if="liveLtp(BANKNIFTY.code)"
          style="white-space: nowrap"
        >
          <span
            :class="
              liveChange(BANKNIFTY.code) >= 0 ? 'green-text' : 'red-text'
            "
          >
            {{ liveLtp(BANKNIFTY.code) | float(2) }}
          </span>
          <span
            class="sub-text-change"
            :class="
              liveChange(BANKNIFTY.code) >= 0 ? 'green-text' : 'red-text'
            "
          >
            (<span v-if="liveChange(BANKNIFTY.code) >= 0">+</span
            >{{ liveChange(BANKNIFTY.code) | float(2) }}%)
          </span>
        </span>
      </div>
    </div>

    <!-- Search Bar -->
    <div style="cursor: pointer" class="my-2 px-2">
      <InstrumentSearchModal
        @add-instrument="handleInstrumentClick"
        @menu-open="showSearchMenu = true"
        @menu-close="showSearchMenu = false"
        :watchlistLength="watchlist.length"
        :showSearchMenu="showSearchMenu"
      />
    </div>

    <!-- Watchlist -->
    <div class="instruments-watchlist px-2">
      <div
        v-if="watchlist.length == 0 || loadingWatchlist"
        style="width: 100%"
        class="row no-gutters my-5 justify-center"
      >
        <v-img
          class="col-12 empty-watchlist-img"
          :max-width="xsScreen ? 130 : 150"
          alt="Empty Watchlist"
          :src="
            darkMode
              ? require('@/assets/Positions/watchlist-empty-d.svg')
              : require('@/assets/Positions/watchlist-empty.svg')
          "
        ></v-img>
        <div class="col-12 d-flex justify-center my-3">
          <button
            class="primary-button"
            style="color=var(--primary-buttontext--color);"
            @click="showSearchMenu = true"
          >
            Add Instrument
          </button>
        </div>
      </div>
      <!-- Instrument Data -->
      <div
        v-for="(ins, index) in watchlist"
        :key="ins.trading_symbol"
        class="ins-row-container"
      >
        <!-- <v-hover v-slot="{ hover }"> -->
          <div style="position: relative">
            <!-- Instrument data row -->
            <div
              class="d-flex justify-space-between instrument-row"
              @click="
                showInsBtns =
                  showInsBtns == ins.trading_symbol ? null : ins.trading_symbol
              "
            >
              <!-- Name -->
              <span
                class="d-sm-flex d-md-block d-lg-flex align-center name"
                @click="
                  print({
                    insSub: insSub(ins.token),
                  })
                "
              >
                <div
                  :class="
                    liveChange(ins.token) >= 0 ? 'green-text' : 'red-text'
                  "
                >
                  {{ ins.formatted_ins_name }}
                </div>
                <v-chip
                  x-small
                  label
                  class="ml-sm-1 my-sm-2"
                  style="background-color: var(--dialog-chips); color: inherit"
                >
                  {{ ins.exchange }}
                </v-chip>
              </span>

              <!-- LTP -->
              <span
                class="price-distribution"
                :class="{
                  'd-sm-flex align-center justify-space-between': !mdScreen,
                }"
              >
                <div
                  class="order-3 mx-1 text-right"
                  :class="
                    liveChange(ins.token) >= 0 ? 'green-text' : 'red-text'
                  "
                >
                  <span v-if="ins.name !='USDINR'">
                    {{ liveLtp(ins.token) | float(2) }}
                  </span>
                  <span v-else>
                    {{ liveLtp(ins.token) | float(4) }}
                  </span>
                </div>

                <span class="mx-1 order-1">
                  <span v-if="insSub(ins.token)">
                    <!-- <span v-if="insSub(ins.token).priceChange > 0">+</span> -->
                    {{ insSub(ins.token).priceChange }}
                  </span>
                  <span v-else>-</span>
                </span>

                <span class="mx-1 order-2">
                  <span v-if="liveChange(ins.token) > 0">+</span>
                  <span> {{ liveChange(ins.token) | float(2) }}% </span>
                  <span>
                    <v-icon
                      :color="
                        liveChange(ins.token) >= 0
                          ? 'var(--green-text)'
                          : 'var(--red-text)'
                      "
                    >
                      {{
                        liveChange(ins.token) >= 0
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      }}
                    </v-icon>
                  </span>
                </span>
              </span>
            </div>

            <!-- HOVER OPTIONS -->
            <div
              class="instrument-row-buttons"
              :class="{'is-visible': (showInsBtns == ins.trading_symbol) && xsScreen}"
            >
              <!-- Buy Button -->
              <v-tooltip bottom :disabled="xsScreen">
                <template v-slot:activator="{ on, attrs }">
                  <button
                    v-bind="attrs"
                    v-on="on"
                    class="buy-btn"
                    @click="placeOrder(ins, 'b')"
                  >
                    B
                  </button>
                </template>
                <span>Buy</span>
              </v-tooltip>

              <!-- Sell Button -->
              <v-tooltip bottom :disabled="xsScreen">
                <template v-slot:activator="{ on, attrs }">
                  <button
                    v-bind="attrs"
                    v-on="on"
                    class="sell-btn"
                    @click="placeOrder(ins, 's')"
                  >
                    S
                  </button>
                </template>
                <span>Sell</span>
              </v-tooltip>

              <!-- Show/Hide Market Depth Button -->
              <v-tooltip bottom :disabled="xsScreen">
                <template v-slot:activator="{ on, attrs }">
                  <button
                    v-bind="attrs"
                    v-on="on"
                    @click="showMarketDepth(index)"
                  >
                    <v-icon small color="--text-color">
                      {{
                        marketDepthArr[index]
                          ? "mdi-close"
                          : "mdi-format-align-center"
                      }}
                    </v-icon>
                  </button>
                </template>
                <span>
                  <span v-if="marketDepthArr[index]">Close</span>
                  Market Depth
                </span>
              </v-tooltip>

              <!-- Option Chain Button -->
              <v-tooltip bottom :disabled="xsScreen">
                <template v-slot:activator="{ on, attrs }">
                  <a
                    @click="redirectToInstrumentURL(ins, 'option-chain')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <button>
                      <v-icon small color="--text-color"
                        >mdi-link-variant</v-icon
                      >
                    </button>
                  </a>
                </template>
                <span>Option Chain</span>
              </v-tooltip>

              <!-- Dashboard Button -->
              <v-tooltip bottom :disabled="xsScreen">
                <template v-slot:activator="{ on, attrs }">
                  <a
                    @click="redirectToInstrumentURL(ins, 'dashboard')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <button>
                      <v-icon small color="--text-color"
                        >mdi-view-dashboard-outline</v-icon
                      >
                    </button>
                  </a>
                </template>
                <span>Dashboard</span>
              </v-tooltip>

              <!-- Delete Button -->
              <v-tooltip bottom :disabled="xsScreen">
                <template v-slot:activator="{ on, attrs }">
                  <button
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteFromWatchlist(ins.trading_symbol)"
                  >
                    <v-icon small color="--text-color">mdi-delete</v-icon>
                  </button>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </div>
        <!-- </v-hover> -->

        <!-- Additional Data-->
        <div v-if="marketDepthArr[index]" class="market-depth-table-container">
          <!-- Market Depth -->
          <v-simple-table style="border-bottom: 1px solid #e0e0e0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="left-depth text-left">BID</th>
                  <th class="left-depth text-center">ORDERS</th>
                  <th class="left-depth text-right">QTY.</th>
                  <th class="right-depth text-left">OFFER</th>
                  <th class="right-depth text-center">ORDERS</th>
                  <th class="right-depth text-right">QTY.</th>
                </tr>
              </thead>
              <tbody>
                <!-- Market Depth rows -->
                <tr v-for="i in 5" :key="'depth' + i">
                  <td class="left-depth text-left">
                    <span v-if="insSub(ins.token)">{{ compactInsSub(ins.token).buyBid[i - 1] | float(2) }}</span>
                    <span v-else>-</span>
                  </td>
                  <td class="left-depth text-center">
                    <span v-if="insSub(ins.token)">{{ compactInsSub(ins.token).buyOrder[i - 1] }}</span>
                    <span v-else>-</span>
                  </td>
                  <td class="left-depth text-right">
                    <span v-if="insSub(ins.token)">{{ compactInsSub(ins.token).buyQuanity[i - 1] }}</span>
                    <span v-else>-</span>
                  </td>
                  <td class="right-depth text-left">
                    <span v-if="insSub(ins.token)">{{ compactInsSub(ins.token).sellOffer[i - 1] | float(2) }}</span>
                    <span v-else>-</span>
                  </td>
                  <td class="right-depth text-center">
                    <span v-if="insSub(ins.token)">{{ compactInsSub(ins.token).sellOrder[i - 1] }}</span>
                    <span v-else>-</span>
                  </td>
                  <td class="right-depth text-right">
                    <span v-if="insSub(ins.token)">{{ compactInsSub(ins.token).sellQuantity[i - 1] }}</span>
                    <span v-else>-</span>
                  </td>
                </tr>
                <tr class="total-row">
                  <td class="left-depth text-left" colspan="2">Total</td>
                  <td class="left-depth text-right">
                    <span v-if="insSub(ins.token)">{{
                      insSub(ins.token).totalBuyQty
                    }}</span>
                    <span v-else>0</span>
                  </td>
                  <td class="right-depth text-left" colspan="2">Total</td>
                  <td class="right-depth text-right">
                    <span v-if="insSub(ins.token)">{{
                      insSub(ins.token).totalSellQty
                    }}</span>
                    <span v-else>0</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- Stock Data -->
          <div class="stock-data row no-gutters">
            <div class="col-6">
              <span>Open</span>
              <span v-if="ins.name !='USDINR'">
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).open | float(2)
                }}</span>
                <span v-else>-</span>
              </span>
              <span v-else>
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).open | float(4)
                }}</span>
                <span v-else>-</span>
              </span>
            </div>
            <div class="col-6">
              <span>High</span>
              <span v-if="ins.name !='USDINR'">
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).high | float(2)
                }}</span>
                <span v-else>-</span>
              </span>
              <span v-else>
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).high | float(4)
                }}</span>
                <span v-else>-</span>
              </span>
            </div>
            <div class="col-6">
              <span>Low</span>
              <span v-if="ins.name !='USDINR'">
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).low | float(2)
                }}</span>
                <span v-else>-</span>
              </span>
              <span v-else>
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).low | float(4)
                }}</span>
                <span v-else>-</span>
              </span>
            </div>
            <div class="col-6">
              <span>Prev. close</span>
              <span v-if="ins.name !='USDINR'">
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).close | float(2)
                }}</span>
                <span v-else>-</span>
              </span>
              <span v-else>
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).close | float(4)
                }}</span>
                <span v-else>-</span>
              </span>
            </div>
            <div class="col-6">
              <span>Volume</span>
              <span>
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).volume
                }}</span>
                <span v-else>-</span>
              </span>
            </div>
            <div class="col-6">
              <span>Avg. Price</span>
              <span v-if="ins.name !='USDINR'">
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).avgPrice | float(2)
                }}</span>
                <span v-else>-</span>
              </span>
              <span v-else>
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).avgPrice | float(4)
                }}</span>
                <span v-else>-</span>
              </span>
            </div>
            <div class="col-6">
              <span>LTQ</span>
              <span>
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).ltq
                }}</span>
                <span v-else>-</span>
              </span>
            </div>
            <div class="col-6">
              <span>LTT</span>
              <span>
                <span v-if="insSub(ins.token)">{{
                  insSub(ins.token).ltt
                }}</span>
                <span v-else>-</span>
              </span>
            </div>
            <div class="col-6" v-if="lowerCircuit(ins.token)">
              <span>Lower Circuit</span>
              <span>{{
                lowerCircuit(ins.token) | float(ins.name =='USDINR'?4:2)
              }}</span>
            </div>
            <div class="col-6" v-if="upperCircuit(ins.token)">
              <span>Upper Circuit</span>
              <span>{{
                upperCircuit(ins.token) | float(ins.name =='USDINR'?4:2)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pages -->
    <div class="watchlist-footer">
      <div class="pages row no-gutters">
        <div
          :class="{ selected: currentPage == 1 }"
          class="col"
          @click="currentPage = 1"
        >
          1
        </div>
        <div
          :class="{ selected: currentPage == 2 }"
          class="col"
          @click="currentPage = 2"
        >
          2
        </div>
        <div
          :class="{ selected: currentPage == 3 }"
          class="col"
          @click="currentPage = 3"
        >
          3
        </div>
        <div
          :class="{ selected: currentPage == 4 }"
          class="col"
          @click="currentPage = 4"
        >
          4
        </div>
        <div
          :class="{ selected: currentPage == 5 }"
          class="col"
          @click="currentPage = 5"
        >
          5
        </div>
        <div
          :class="{ selected: currentPage == 6 }"
          class="col"
          @click="currentPage = 6"
        >
          6
        </div>
        <div
          :class="{ selected: currentPage == 7 }"
          class="col"
          @click="currentPage = 7"
        >
          7
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {
  getPositionWatchlist,
} from "../getService";

import UserDataMixin from "@/mixins/getUserDataMixin";
import WebsocketMethodsMixin from "@/mixins/websocketMethodsMixin";
import SubscribeInstrumentsMixin from "../../mixins/SubscribedInstrumentMixin";

import PlaceOrderModal from "../modals/PlaceOrderModal.vue";
import InstrumentSearchModal from "./modals/InstrumentSearchModal.vue";
export default {
  components: {
    PlaceOrderModal,
    InstrumentSearchModal,
  },
  mixins: [SubscribeInstrumentsMixin, UserDataMixin, WebsocketMethodsMixin],
  emits: ["refresh-websocket"],
  data: () => {
    return {
      // Static Vars
      NIFTY: { code: 26000, name: "NIFTY 50", exchange: "NSE" },
      BANKNIFTY: { code: 26009, name: "BANKNIFTY", exchange: "NSE" },

      // Flag Vars
      showPlaceOrderDialog: false,
      showSearchMenu: false,
      showInsBtns: null,
      loadingWatchlist: false,

      // Watchlist Vars
      watchlist: [],
      watchlistObjects: null,
      currentPage: null,
      marketDepthArr: null,

      // Order vars
      orderData: null,

      order_location: "positions",
      isHoldingsSection: false,
    };
  },
  // Actions before route change
  beforeRouteLeave(to, from, next) {
    this.unsubscribeWatchlistInstruments();
    next();
  },
  mounted() {
    this.$store.dispatch('setCachedData');
    this.getWatchlist();
  },
  computed: {
    xsScreen() {
      return this.$vuetify.breakpoint.xs;
    },
    mdScreen() {
      return this.$vuetify.breakpoint.md;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  watch: {
    currentPage(newVal, oldVal) {
      if (oldVal != null)
        this.watchlist = this.watchlistObjects[String(newVal)].instruments;
    },
    watchlist(newVal,oldVal) {
      this.marketDepthArr = Array(newVal.length).fill(false);
      if(oldVal)
        this.unsubscribeWatchlistInstruments(oldVal);
      this.subscribeWatchlistInstruments();
    },
  },
  methods: {
    print(a) {
      console.log(a);
    },
    /**
     *   @function fetching_Watchlist
     *   loads the all the watchlist data
     */
    getWatchlist() {
      this.loadingWatchlist = true;
      const payload = {
        user_id: this.userId,
        operation: "get",
      };
      axios
        .post(getPositionWatchlist, payload)
        .then((res) => {
          if (res.data.errorMessage) throw res.data;
          this.watchlistObjects = res.data.watchlist;
          if (this.currentPage == null) this.currentPage = 1;

          this.watchlist =
            this.watchlistObjects[String(this.currentPage)].instruments;
          this.loadingWatchlist = false;
        })
        .catch((err) => {
          console.log("error occured while fetching watchlist");
          console.log(err);
          this.loadingWatchlist = false;
        });
    },
    subscribeWatchlistInstruments() {
      let insList=[
        {...this.NIFTY, loc:this.$route.name},
        {...this.BANKNIFTY ,loc:this.$route.name}
      ];
      insList=[
        ...insList,
        ...this.watchlist.map((el)=>{return {code:el.token,exchange:el.exchange,loc:this.$route.name}})
      ]
      this.subscribeToInstruments(insList);//function form websocketMethodsMixin
    },
    unsubscribeWatchlistInstruments(watchlist=this.watchlist) {
      const insList=watchlist.map((el)=>{return {code:el.token,exchange:el.exchange}})
      this.unsubscribeToInstruments(insList);//function form websocketMethodsMixin
    },
    /**
     *   @function Adding_instrument_To_watchlist
     */
    addToWatchlist(trading_symbol, exchange) {
      const payload = {
        user_id: this.userId,
        operation: "add",
        trading_symbol: trading_symbol,
        exchange: exchange,
        page: this.currentPage,
      };
      axios
        .post(getPositionWatchlist, payload)
        .then((res) => {
          if (res.data.errorMessage) throw res.data;
          this.showSearchMenu = false;
          this.getWatchlist();
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: "Instrument added to watchlist",
          });
        })
        .catch((err) => {
          const errorText=err.errorMessage;
          this.$notify({
            group: "success",
            type: "error",
            title: "Error",
            text: errorText?errorText:"Failed to Add Instrument",
          });
          console.log(
            "error occured while Adding Instrument: " + trading_symbol
          );
          console.log(err);
        });
    },
    /**
     *   @function Deleting_instrument_From_watchlist
     *   requests to delete an instrument then refreshed the list (calls getWatchlist())
     */
    deleteFromWatchlist(trading_symbol) {
      const payload = {
        user_id: this.userId,
        operation: "delete",
        trading_symbol: trading_symbol,
        page: this.currentPage,
      };
      axios
        .post(getPositionWatchlist, payload)
        .then((res) => {
          if (res.data.errorMessage) throw res.data;
          this.getWatchlist();
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: "Instrument deleted from list",
          });
        })
        .catch((err) => {
          console.log(
            "error occured while deleting Instrument: " + trading_symbol
          );
          console.log(err);
        });
    },
    placeOrder(ins, orderType) {
      this.isHoldingsSection =
        ins.exchange == "BSE" || ins.exchange == "NSE" ? true : false;
      const lot_size = ins.lot_size * (orderType == "b" ? 1 : -1);
      const ltp = this.liveLtp(ins.token);
      const selectedIns = {
        selectedIns: {
          exchange: ins.exchange,
          trading_symbol: ins.trading_symbol,
          ltp: ltp,
          name: ins.name,
          strike: ins.strike_price,
          underlying_code: ins.token,
          expiry: ins.expiry,
          lot_size: ins.lot_size,
          formatted_ins_name: ins.formatted_ins_name,
        },
        orderIns: [
          {
            exchange: ins.exchange,
            trading_symbol: ins.trading_symbol,
            ltp: ltp,
            name: ins.name,
            strike: ins.strike_price,
            segment: ins.segment_type == "CE" ? "c" : "p",
            code: ins.token,
            expiry: ins.expiry,
            quantity: ins.exchange != "MCX" && ins.exchange != "CDS"?lot_size: orderType=='b'?(lot_size/lot_size):-(lot_size/lot_size),
            op_pr: ltp,
            formatted_ins_name: ins.formatted_ins_name,
          },
        ],
      };
      this.orderData = selectedIns;
      this.showPlaceOrderDialog = true;
    },
    redirectToInstrumentURL(ins, page) {
      let url = window.location.origin + `/${page}?trading_symbol=${ins.name}`;
      if (ins.expiry) url += `&expiry=${ins.expiry}`;
      window.open(url, "_blank");
    },
    /**
     * @function to_make_market_depth_of_instruments_visible
     */
    showMarketDepth(index) {
      const val = this.marketDepthArr[index];
      this.$set(this.marketDepthArr, index, !val);
    },
    handleInstrumentClick(ins) {
      const exc = ins.exchange;
      const trading_symbol = ins.trading_symbol;
      this.addToWatchlist(trading_symbol, exc);
    },
  },
};
</script>
<style lang="scss" scoped>
.default-style {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  color: var(--text-color);
}
.empty-watchlist-img {
  min-width: 100px;
  width: 60%;
  max-width: 200px;
}

.watchlist-wrapper {
  position: relative;
  height: 100%;
  font-size: 0.9rem;

  // Visible heading
  .section-heading {
    font-size: 1.5em;
    line-height: 28px;
  }

  // Top
  .header-live-ltps {
    font-size: 0.93em;

    .sub-text-change {
      font-size: 0.85em;
    }
  }

  // Middle
  .instruments-watchlist {
    overflow: hidden;
    max-height: 78%;

    // min-height: 77%;
    .ins-row-container {
      border-bottom: 1px solid #e0e0e0;
      .instrument-row-buttons{
        display: none;
      }
    }
    .ins-row-container:hover{
      background: #d9d9d933;
      .instrument-row-buttons{
        display: block;
      }
    }

    .instrument-row {
      padding-top: 10px;
      padding-bottom: 10px;

      .price-distribution {
        white-space: nowrap;
      }
    }

    .instrument-row-buttons {
      padding: 5px;
      position: absolute;
      top: 15%;
      right: 0;
      button {
        margin: 0 4px;
        width: 27px;
        height: 27px;
        font-size: 0.8em;
        text-align: center;
        background-color: var(--cards-background-color);
        color: var(--text-color);
        border: 1px solid var(--text-color);
        border-radius: 5px;
      }

      button.buy-btn {
        background-color: #4d92e3;
        color: white;
        border: none;
      }

      button.sell-btn {
        background-color: #ff5a54;
        color: white;
        border: none;
      }
    }
  }

  .instruments-watchlist::-webkit-scrollbar {
    width: 5px;
  }

  .instruments-watchlist::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
  }

  .instruments-watchlist::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  // Footer
  .watchlist-footer {
    position: sticky;
    top: 100%;
    margin-top: 10px;
    border-radius: 8px;
    background: #d9d9d926;

    .pages {
      > div {
        text-align: center;
        cursor: pointer;
        padding: 10px 0;
        margin: 0 0.5em;
      }

      > div:hover {
        background: #d9d9d933;
      }

      > div.selected {
        border-bottom: 2px solid var(--primary-button-color);
        color: var(--primary-button-color) !important;
      }
    }
  }

  .instruments-watchlist:hover {
    overflow-y: auto;
  }
}

// Table market depth
.market-depth-table-container {
  table {
    th {
      background-color: var(--option-header-bg);
      color: var(--text-color) !important;
      white-space: nowrap;
      font-weight: 500;
    }

    td,
    th {
      font-size: 1em !important;
      height: 2.2em !important;
      border-bottom: none !important;
      padding: 0 5px !important;
    }

    td.left-depth {
      color: var(--primary-button-color);
    }

    td.right-depth {
      color: var(--red-text);
    }
  }

  .stock-data {
    margin-top: 8px;
    color: #575757;

    > div {
      display: flex;
      justify-content: space-between;
      padding: 0 5px;
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1850px) {
  .watchlist-wrapper {
    font-size: 0.9rem !important;

    .section-heading {
      font-size: 1.44em !important;
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .watchlist-wrapper {
    font-size: 1rem !important;

    .section-heading {
      font-size: 2.3em !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .watchlist-wrapper {
    .instruments-watchlist {
      overflow-y: auto !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .section-heading {
    font-size: 1.375em !important;
    line-height: 22px !important;
  }

  .header-live-ltps,
  .instruments-watchlist {
    font-size: 0.85em !important;
  }

  .watchlist-wrapper {
    .instruments-watchlist {
      max-height: none !important;
      min-height: 80vh !important;

      .instrument-row:hover {
        background: none !important;
      }

      .price-distribution {
        min-width: auto !important;
      }
    }
    .ins-row-container:hover{
      .instrument-row-buttons{
        display: none;
      }
      .instrument-row-buttons.is-visible{
        display: block;
      }
    }
  }
}
</style>
