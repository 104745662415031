var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"option-chain-wrapper"},[_c('div',{staticClass:"white-card pa-3 px-5 my-0 mx-auto",style:(_vm.$vuetify.breakpoint.xs ? { 'min-height': '100vh' } : {})},[_c('div',[_vm._m(0),_c('div',{staticClass:"row no-gutters justify-space-between my-2"},[_c('div',[_c('v-menu',{attrs:{"offset-y":"","max-height":"300px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"download-btn strategy-btn",attrs:{"flat":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v("Strategy "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-checkbox',{staticClass:"select-all-checkbox",attrs:{"input-value":_vm.filteredCubeIds.length == _vm.cubeList.length,"color":"var(--primary-button-color)","label":"Select All","hide-details":""},on:{"click":function($event){return _vm.toggleSelectAll()}}})],1)],1),_vm._l((_vm.cubeList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-checkbox',{staticClass:"filter-checkbox",attrs:{"input-value":_vm.filteredCubeIds.includes(item.id),"color":"var(--primary-button-color)","label":item.name,"hide-details":""},on:{"click":function($event){return _vm.toggleSelection(item)}}})],1)],1)})],2)],1)],1),_c('div',[_c('v-btn',{staticClass:"download-btn",attrs:{"flat":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadResearchCalls()}}},[_c('v-icon',[_vm._v("mdi-arrow-collapse-down")]),_vm._v(" Download")],1)],1)])]),(_vm.isLoading)?_c('div',{staticStyle:{"height":"60vh","width":"100%"}},[_c('SkeletonLoader')],1):_c('div',{staticClass:"tables-section mt-3 mt-sm-5"},[(_vm.pageCubeData.length == 0)?_c('div',{staticClass:"d-flex justify-center flex-column no-positions align-center text-center"},[_c('img',{staticStyle:{"width":"40%"},attrs:{"src":require("@/assets/Positions/no-positions.svg"),"alt":"NA"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" No Research Calls Available. Kindly change the strategy! ")])]):_c('v-simple-table',{attrs:{"page":_vm.page,"items-per-page":_vm.itemsPerPage,"height":_vm.loadedAsPage
            ? _vm.$vuetify.breakpoint.xs
              ? '80vh'
              : '65vh'
            : _vm.$vuetify.breakpoint.xs
            ? '82vh'
            : '59vh'},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"fixed-table-header"},[_c('tr',{staticClass:"table-header"},[_c('th',{staticClass:"text-left"},[_vm._v("Strategy")]),_c('th',{staticClass:"text-left"},[_vm._v("Instrument")]),_c('th',{staticClass:"text-center"},[_vm._v(" Signal "),_c('v-tooltip',{attrs:{"content-class":"header-tooltips","top":"","color":"var(--cards-background-color)","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"info-icon"},'span',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Entry long- Buy, Exit Long- Sell (only if the entry long is taken), Entry Short- Sell, Exit Short- Buy (Only if entry short is taken)")])])],1),_c('th',{staticClass:"text-center"},[_vm._v("Quantity")]),_c('th',{staticClass:"text-center"},[_vm._v("Price")]),_c('th',{staticClass:"text-center"},[_vm._v("Ltp")]),_c('th',{staticClass:"text-center"},[_vm._v("Signal Time")]),_c('th',{staticClass:"text-center"},[_vm._v(" Validity "),_c('v-tooltip',{attrs:{"content-class":"header-tooltips","top":"","color":"var(--cards-background-color)","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"info-icon"},'span',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant-circle-outline")])],1)]}}])},[_c('span',[_vm._v("We recommend the signal to be executed before this time")])])],1),_c('th',{staticClass:"text-center"},[_vm._v("Execution")])])]),_c('tbody',_vm._l((_vm.pageCubeData),function(cube,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(cube.name))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(cube.exchange =='NSE'?cube.symbol: cube.formatted_ins_name))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(cube.signal !== "" ? cube.signal : "-")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(cube.quantity))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(cube.price))]),_c('td',{staticClass:"text-center"},[(_vm.insSub(cube.token))?_c('span',[_c('span',{class:_vm.insSub(cube.token).ltp > 0 ? 'green-text' : 'red-text'},[_vm._v(" "+_vm._s(_vm.insSub(cube.token).ltp.toFixed(2))+" ")])]):_c('span',[_c('span',{class:cube.price > 0 ? 'green-text' : 'red-text'},[_vm._v(" "+_vm._s(cube.price.toFixed(2))+" ")])])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatDate")(cube.signal_date)))]),(cube.validity_date !== null)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(cube.validity_date))+" ")]):_c('td',{staticClass:"text-center"},[_vm._v("-")]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"primary-button",class:cube.place_order_flag
                      ? 'primary-button'
                      : 'disabled-button',on:{"click":function($event){$event.stopPropagation();return _vm.showPlaceOrderForExitPositions(cube)}}},[_vm._v(" Place Order ")])])])}),0)]},proxy:true}])}),_c('div',{staticClass:"text-center"},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.pageCount},on:{"input":_vm.handleInput},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1),(_vm.showPlaceOrderDialog)?_c('PlaceOrderModal',{attrs:{"visible":_vm.showPlaceOrderDialog,"strategyCreator":_vm.strategyCreatorList,"selectedInstrument":_vm.selectedInstrument,"isPositionGraph":true},on:{"close":function($event){_vm.showPlaceOrderDialog = false}}}):_vm._e()],1),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row no-gutters align-left my-1 my-sm-3"},[_c('div',{staticClass:"page-heading mb-2 mb-sm-0 col-12"},[_c('span',[_vm._v("Research Calls")])]),_c('div',{staticClass:"col-12 page-content"},[_vm._v(" Inuvest builds quant models and provide research calls. These calls are generated automatically by the models built by us. Visit "),_c('a',{staticStyle:{"color":"var(--primary-color) !important"},attrs:{"target":"_blank","href":"https://inuvest.tech"}},[_vm._v(" inuvest.tech")]),_vm._v(" to know more. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-content"},[_vm._v(" Inuvest is a SEBi registered Reasearch Analyst (INH000012403). All the call provided here are based on it's models built using quantitative techniques. Trading in stocks or futures and options involves risk of loss. Any reference to past or potential performance is not, and should not be construed as a recommendation or a guarantee of any specific outcome or profit. Any action taken, the outcome of such action shall be the sole responsibility of the user. Inuvest nor any third-party would be responsible for any loss arising due to the actions taken by the user using this platform. By using the platform you agree to our "),_c('a',{staticStyle:{"color":"var(--sub-text-color)"},attrs:{"href":"https://www.inuvest.tech/terms-of-service"}},[_vm._v("Terms and Conditions")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://www.inuvest.tech/disclaimers"}},[_vm._v("Risk Disclosure Agreement.")])])}]

export { render, staticRenderFns }