<template>
  <div class="skeleton-loader">
    <div class="skeleton-box"></div>
  </div>
</template>
<script>
export default {
  name: "SkeletonLoader",
};
</script>
<style lang="scss">
.skeleton-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    border-radius: 12px;
    margin: 12px;
    width:95%;
    height:100%;
  }
  
  .skeleton-box {
    background-color: var(--cards-background-color);
    border-radius: 12px;
    border: 1px solid var(--border-color);
    padding: 12px 24px;
    width: 100%;
    height: 100%;
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% {
      opacity: 0.4;
    }
    
    50% {
      opacity: 0.8;
    }
    
    100% {
      opacity: 0.4;
    }
  }
</style>
