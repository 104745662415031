import { getScripQuoteDetails, getPrebuiltStrategyUrl } from "../getService";
import axios from "axios";
export let newConnection = "";
export let messageSocket = "";
import ReconnectingWebSocket from "reconnecting-websocket";
import moment from "moment";
import store from "../../store";
export const unsubscribeLtp = (payload) => {
  newConnection.send(
    JSON.stringify({
      k: `NFO|${payload}#NFO`,
      t: "ud",
      m: "compact_marketdata",
    })
  );
};
export const unsubscribeData = () => {
  for (let ins of this.$store.state.compactMarketData) {
    this.unsubscribeLtp(String(ins.code));
  }
  this.$store.state.compactMarketData = [];
};

export const startWebSocket = () => {
  let userId = localStorage.getItem("user_id");
  let wsSessionToken = localStorage.getItem("ws_session");
  newConnection = new ReconnectingWebSocket(
    "wss://ws1.aliceblueonline.com/NorenWS/"
  );
  newConnection.send(
    JSON.stringify({
      susertoken: wsSessionToken,
      t: "c",
      actid: userId + "_API",
      uid: userId + "_API",
      source: "API",
    })
  );
  newConnection.onmessage = messageSocket;
  newConnection.onerror = function () {
    console.log("websocket falied");
    newConnection.close();
  };
  newConnection.onclose = function () {
    userId = localStorage.getItem("user_id");
    wsSessionToken = localStorage.getItem("ws_session");
    let currentDate = new Date();
    if (currentDate.getHours("hi") < 15) {
      newConnection = new ReconnectingWebSocket(
        "wss://ws1.aliceblueonline.com/NorenWS/"
      );
      newConnection.send(
        JSON.stringify({
          susertoken: wsSessionToken,
          t: "c",
          actid: userId + "_API",
          uid: userId + "_API",
          source: "API",
        })
      );
      newConnection.onmessage = messageSocket;
    } else {
      newConnection.close();
    }
  };
};
export const subscribeNseLtp = (payload) => {
  newConnection.send(
    JSON.stringify({
      k: `NSE|${payload}#NSE`,
      t: "d",
      m: "compact_marketdata",
    })
  );
  apiLtp(payload, "NSE");
};

export const apiLtp = (code, exchange) => {
  let accessToken = localStorage.getItem("accessTokenAnt");
  let user_id = localStorage.getItem("user_id");
  let ltp;
  let header = { Authorization: `Bearer ${user_id} ${accessToken}` };
  let load = { exch: exchange, symbol: String(code) };
  axios.post(getScripQuoteDetails, load, { headers: header }).then((res) => {
    ltp = res.data.LTP;
    store.state.compactMarketData.push({
      code: code,
      details: {
        "Last Traded Price": ltp,
        "Open Price": 0,
        oi: 0,
        "Trade Volume": 0,
        Change: res.data.PerChange,
        buyOrder: [0, 0, 0, 0, 0],
        buyBid: [0, 0, 0, 0, 0],
        buyQuanity: [0, 0, 0, 0, 0],
        sellOrder: [0, 0, 0, 0, 0],
        sellOffer: [0, 0, 0, 0, 0],
        sellQuantity: [0, 0, 0, 0, 0],
      },
    });
  });
};

export const getPrebuiltStrategiesApi = (payload) => {
  axios
    .post(getPrebuiltStrategyUrl, payload, {
      headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
    })
    .then((res) => {
      return res.data;
    });
};

export const getExpiredDays = (dateValue) => {
  let discharge = moment(moment(dateValue).format("DD/MM/YYYY"), "DD/MM/YYYY");
  let admission = moment(moment(new Date()).format("DD/MM/YYYY"), "DD/MM/YYYY");
  return discharge.diff(admission, "days");
};

export const calculateTargetDays = (instrument) => {
  let dates = [];
  for (let ins of instrument) {
    dates.push(ins.expiry);
  }
  let minDate = new Date(dates[0]);
  for (let i = 1; i < dates.length; i++) {
    const date = new Date(dates[i]);
    if (date < minDate) {
      minDate = date.toDateString();
    }
  }
  const today = new Date();
  const targetDate = new Date(minDate);
  const timeDiff = Math.abs(targetDate.getTime() - today.getTime());
  let targetDays=0
  if(today.getDate().toString()+today.getMonth().toString()== targetDate.getDate().toString()+targetDate.getMonth().toString()){
    targetDays = 0
  }else{
    targetDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  }
  return targetDays;
};

export const getTargetDaysList = (expiryDatesList) => {
  let targetDaysList = [];
  for (let date of expiryDatesList) {
    const today = new Date();
    const targetDate = new Date(date);
    const timeDiff = Math.abs(targetDate.getTime() - today.getTime());
    let targetDays=0
    if(today.getDate().toString()+today.getMonth().toString()== targetDate.getDate().toString()+targetDate.getMonth().toString()){
      targetDays = 0
    }else{
      targetDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    }
    targetDaysList.push(targetDays);
  }
  return targetDaysList;
};

export const getFilteredExpiry = (dates) => {
  let expiryDatesList = [];
  let formattedDates = [];
  for (let date of dates) {
    const formattedDate = moment(date).format("YYYYMMDD");
    formattedDates.push(formattedDate);
  }
  let sortedDates = formattedDates.sort((a, b) => a - b);
  for (let date of sortedDates) {
    const formattedSortedDate = moment(date).format("DD MMM YYYY");
    expiryDatesList.push(formattedSortedDate);
  }
  return expiryDatesList;
};

export const getSelectedExpiry = (dates) => {
  if(dates.length == 1){
    return dates[0];
  }else{
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.toLocaleString("en-US", { month: "short" });
    const monthDates = [];
    const nextMonth = moment()
      .add(1, "month")
      .startOf("month")
      .format("MMMM")
      .substring(0, 3);
  
    for (let date of dates) {
      let dmy = date.split(" ");
      if (dmy[2] == currentYear && dmy[1] == currentMonth) {
        monthDates.push(date);
      }
    }
    if (monthDates.length !== 0) {
      return monthDates[monthDates.length - 1];
    } else {
      for (let date of dates) {
        let dmy = date.split(" ");
        if (dmy[2] == currentYear && dmy[1] == nextMonth) {
          monthDates.push(date);
        }
      }
      return monthDates[monthDates.length - 1];
    }
  }
};

// Reformats date to 'dd-mm-yyy'
export const reformatDate=(dateStr) => {
  let d = new Date(dateStr);
  const month = (d.getMonth()<9?"0":"")+(d.getMonth()+1);
  const year=d.getFullYear();
  const date=(d.getDate()<=9?"0":"")+d.getDate();
  const ans=[year,month,date].join('-');
  return ans;
}
