import Vue from 'vue';
export default{
  state(){
    return{
      // Subscribed instruments
      subscribedInstrumentsData: {},
      compactMarketDataMap: {},
      compactMarketData: [],
    }
  },
  getters:{
    getSubscribedInstrumentData: (state) => (code) => {
      const mapKey = "depth_" + code;
      return state.subscribedInstrumentsData[mapKey] ?? null;
    },
    compactMarketDataScripts: (state) => (id) => {
      console.log(id)
      const mapKey = "compact_" + id;
      const index = state.compactMarketDataMap[mapKey];
      return index ? state.compactMarketData[index] : "";
    },
  },
  mutations:{
    removeFromSubscribedInstrumentsData(state, code) {
      const key = "depth_" + code;
      delete state.subscribedInstrumentsData[key];
    },
    removeFromCompactMarketData(state, code) {
      const key = "compact_" + code;
      const index = state.compactMarketDataMap[key];
      if (index) {
        state.compactMarketData.splice(index, 1);
        delete state.compactMarketDataMap[key];
      }
    },
    /**
     * @function set/update-subscribed-instrument-data
     * @param payload data from websocket
     * same as "setcompactData"
     */
    setSubscribedInstrumentsData(state, payload) {
      if (!payload.tk)
        return;
      const getPriceChange = (change, ltp) => {
        change = parseFloat(change ?? 0)
        ltp = parseFloat(ltp ?? 0)
        return ((ltp / (100 + change)) * change).toFixed(2);
      };
      const key = "depth_" + payload.tk;
      const oldObj = state.subscribedInstrumentsData[key] ?? null;
      if (!oldObj) {
        const ltp=payload.lp?+payload.lp:payload.c?+payload.c:0;
        let value = {
          "code": payload.tk,
          "details": {
            "Last Traded Price": ltp,
            "ltp": ltp, //duplicate value key
            "Trade Volume": payload.v != undefined ? +payload.v : 0,
            "volume": payload.v != undefined ? +payload.v : 0, //duplicate value key
            "oi": payload.oi != undefined ? +payload.oi : 0,
            "totalBuyQty": payload.tbq != undefined ? +payload.tbq : 0,
            "totalSellQty": payload.tsq != undefined ? +payload.tsq : 0,
            "Change": payload.pc != undefined ? +payload.pc : 0,
            "Open Price": payload.o != undefined ? +payload.o : 0,
            "open": payload.o != undefined ? +payload.o : 0, //duplicate value key
            "high": payload.h != undefined ? +payload.h : 0,
            "low": payload.l != undefined ? +payload.l : 0,
            "close": payload.c != undefined ? +payload.c : 0,
            "avgPrice": payload.ap != undefined ? +payload.ap : 0,
            "upperCircuit": payload.uc != undefined ? +payload.uc : 0,
            "lowerCircuit": payload.lc != undefined ? +payload.lc : 0,
            "ltq": payload.ltq != undefined ? +payload.ltq : 0,
            "ltt": payload.ltt != undefined ? payload.ltt : 0,
            "bid": payload.bp1 != undefined ? +payload.bp1 : 0,
            "ask": payload.sp1 != undefined ? +payload.sp1 : 0,
            "priceChange": getPriceChange(payload.pc, payload.lp),
          }
        }
        Vue.set(state.subscribedInstrumentsData, key, value)
      }
      else if (payload.e && oldObj) {

        if (payload.bp1) {
          state.subscribedInstrumentsData[key]['details']['bid'] = (+payload.bp1).toFixed(2);
        }

        if (payload.sp1) {
          state.subscribedInstrumentsData[key]['details']['ask'] = (+payload.sp1).toFixed(2);
        }

        if (payload.lp) {
          Vue.set(state.subscribedInstrumentsData[key]['details'], 'Last Traded Price', (+payload.lp));
          Vue.set(state.subscribedInstrumentsData[key]['details'], 'ltp', (+payload.lp));
        }
        if (payload.v) {
          state.subscribedInstrumentsData[key]['details']['Trade Volume'] = +payload.v;
          state.subscribedInstrumentsData[key]['details']['volume'] = +payload.v;
        }
        if (payload.pc)
          state.subscribedInstrumentsData[key]['details']['Change'] = +payload.pc;
        if (payload.oi)
          state.subscribedInstrumentsData[key]['details']['oi'] = +payload.oi;
        if (payload.tbq)
          state.subscribedInstrumentsData[key]['details']['totalBuyQty'] = +payload.tbq;
        if (payload.tsq)
          state.subscribedInstrumentsData[key]['details']['totalSellQty'] = +payload.tsq;

        if (payload.o) {
          state.subscribedInstrumentsData[key]['details']['Open Price'] = (+payload.o).toFixed(2);
          state.subscribedInstrumentsData[key]['details']['open'] = (+payload.o).toFixed(2);
        }
        if (payload.c)
          state.subscribedInstrumentsData[key]['details']['close'] = (+payload.c).toFixed(2);
        if (payload.h)
          state.subscribedInstrumentsData[key]['details']['high'] = (+payload.h).toFixed(2);
        if (payload.l)
          state.subscribedInstrumentsData[key]['details']['low'] = (+payload.l).toFixed(2);
        if (payload.ap)
          state.subscribedInstrumentsData[key]['details']['avgPrice'] = (+payload.ap).toFixed(2);

        if (payload.uc)
          state.subscribedInstrumentsData[key]['details']['upperCircuit'] = (+payload.uc).toFixed(2);
        if (payload.lc)
          state.subscribedInstrumentsData[key]['details']['lowerCircuit'] = (+payload.lc).toFixed(2);
        if (payload.ltq)
          state.subscribedInstrumentsData[key]['details']['ltq'] = +payload.ltq;
        if (payload.ltt)
          state.subscribedInstrumentsData[key]['details']['ltt'] = payload.ltt;

        if (payload.pc || payload.lp) {
          state.subscribedInstrumentsData[key]['details']['priceChange'] = getPriceChange(payload.pc, payload.lp)
        }
      }
    },
    setcompactData(state, payload) {
      if (!payload.tk)
        return;
      const mapKey = "compact_" + payload.tk;
      const index = state.compactMarketDataMap[mapKey];
      let objIndex = index ? index : -1;
      if (objIndex == -1) {
        const ltp=payload.lp?+payload.lp:payload.c?+payload.c:0;
        state.compactMarketData.push({
          "code": payload.tk,
          "details": {
            "Last Traded Price": ltp,
            "Open Price": Number(payload.o),
            "Low Price": Number(payload.l),
            "close": Number(payload.c),
            "oi": payload.oi == undefined ? 0 : Number(payload.oi),
            "Trade Volume": payload.v == undefined ? 0 : Number(payload.v),
            "Change": Number(payload.pc) == undefined ? 0 : Number(payload.pc),
            "totalBuyQty": payload.tbq == undefined ? 0 : Number(payload.tbq),
            "totalSellQty": payload.tsq == undefined ? 0 : Number(payload.tsq),
            "buyOrder": [
              payload.bo1 == undefined ? 0 : Number(payload.bo1),
              payload.bo2 == undefined ? 0 : Number(payload.bo2),
              payload.bo3 == undefined ? 0 : Number(payload.bo3),
              payload.bo4 == undefined ? 0 : Number(payload.bo4),
              payload.bo5 == undefined ? 0 : Number(payload.bo5)
            ],
            "buyBid": [
              payload.bp1 == undefined ? 0 : Number(payload.bp1),
              payload.bp2 == undefined ? 0 : Number(payload.bp2),
              payload.bp3 == undefined ? 0 : Number(payload.bp3),
              payload.bp4 == undefined ? 0 : Number(payload.bp4),
              payload.bp5 == undefined ? 0 : Number(payload.bp5)
            ],
            "buyQuanity": [
              payload.bq1 == undefined ? 0 : Number(payload.bq1),
              payload.bq2 == undefined ? 0 : Number(payload.bq2),
              payload.bq3 == undefined ? 0 : Number(payload.bq3),
              payload.bq4 == undefined ? 0 : Number(payload.bq4),
              payload.bq5 == undefined ? 0 : Number(payload.bq5)
            ],
            "sellOrder": [
              payload.so1 == undefined ? 0 : Number(payload.so1),
              payload.so2 == undefined ? 0 : Number(payload.so2),
              payload.so3 == undefined ? 0 : Number(payload.so3),
              payload.so4 == undefined ? 0 : Number(payload.so4),
              payload.so5 == undefined ? 0 : Number(payload.so5)
            ],
            "sellOffer": [
              payload.sp1 == undefined ? 0 : Number(payload.sp1),
              payload.sp2 == undefined ? 0 : Number(payload.sp2),
              payload.sp3 == undefined ? 0 : Number(payload.sp3),
              payload.sp4 == undefined ? 0 : Number(payload.sp4),
              payload.sp5 == undefined ? 0 : Number(payload.sp5)
            ],
            "sellQuantity": [
              payload.sq1 == undefined ? 0 : Number(payload.sq1),
              payload.sq2 == undefined ? 0 : Number(payload.sq2),
              payload.sq3 == undefined ? 0 : Number(payload.sq3),
              payload.sq4 == undefined ? 0 : Number(payload.sq4),
              payload.sq5 == undefined ? 0 : Number(payload.sq5)
            ]
          }
        })
        const newIndex = state.compactMarketData.length - 1;
        Vue.set(state.compactMarketDataMap, mapKey, newIndex);
      }
      else {
        if (payload.e && state.compactMarketData[objIndex]) {
          if (payload.bq1)
            Vue.set(state.compactMarketData[objIndex]['details']['buyQuanity'], 0, Number(payload.bq1))
          if (payload.bq2)
            Vue.set(state.compactMarketData[objIndex]['details']['buyQuanity'], 1, Number(payload.bq2))
          if (payload.bq3)
            Vue.set(state.compactMarketData[objIndex]['details']['buyQuanity'], 2, Number(payload.bq3))
          if (payload.bq4)
            Vue.set(state.compactMarketData[objIndex]['details']['buyQuanity'], 3, Number(payload.bq4))
          if (payload.bq5)
            Vue.set(state.compactMarketData[objIndex]['details']['buyQuanity'], 4, Number(payload.bq5))

          if (payload.bp1)
            Vue.set(state.compactMarketData[objIndex]['details']['buyBid'], 0, Number(payload.bp1))
          if (payload.bp2)
            Vue.set(state.compactMarketData[objIndex]['details']['buyBid'], 1, Number(payload.bp2))
          if (payload.bp3)
            Vue.set(state.compactMarketData[objIndex]['details']['buyBid'], 2, Number(payload.bp3))
          if (payload.bp4)
            Vue.set(state.compactMarketData[objIndex]['details']['buyBid'], 3, Number(payload.bp4))
          if (payload.bp5)
            Vue.set(state.compactMarketData[objIndex]['details']['buyBid'], 4, Number(payload.bp5))

          if (payload.bo1)
            Vue.set(state.compactMarketData[objIndex]['details']['buyOrder'], 0, Number(payload.bo1))
          if (payload.bo2)
            Vue.set(state.compactMarketData[objIndex]['details']['buyOrder'], 1, Number(payload.bo2))
          if (payload.bo3)
            Vue.set(state.compactMarketData[objIndex]['details']['buyOrder'], 2, Number(payload.bo3))
          if (payload.bo4)
            Vue.set(state.compactMarketData[objIndex]['details']['buyOrder'], 3, Number(payload.bo4))
          if (payload.bo5)
            Vue.set(state.compactMarketData[objIndex]['details']['buyOrder'], 4, Number(payload.bo5))

          if (payload.so1)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOrder'], 0, Number(payload.so1))
          if (payload.so2)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOrder'], 1, Number(payload.so2))
          if (payload.so3)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOrder'], 2, Number(payload.so3))
          if (payload.so4)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOrder'], 3, Number(payload.so4))
          if (payload.so5)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOrder'], 4, Number(payload.so5))


          if (payload.sp1)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOffer'], 0, Number(payload.sp1))
          if (payload.sp2)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOffer'], 1, Number(payload.sp2))
          if (payload.sp3)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOffer'], 2, Number(payload.sp3))
          if (payload.sp4)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOffer'], 3, Number(payload.sp4))
          if (payload.sp5)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOffer'], 4, Number(payload.sp5))


          if (payload.sq1)
            Vue.set(state.compactMarketData[objIndex]['details']['sellQuantity'], 0, Number(payload.sq1))
          if (payload.sq2)
            Vue.set(state.compactMarketData[objIndex]['details']['sellQuantity'], 1, Number(payload.sq2))
          if (payload.sq3)
            Vue.set(state.compactMarketData[objIndex]['details']['sellQuantity'], 2, Number(payload.sq3))
          if (payload.sq4)
            Vue.set(state.compactMarketData[objIndex]['details']['sellQuantity'], 3, Number(payload.sq4))
          if (payload.sq5)
            Vue.set(state.compactMarketData[objIndex]['details']['sellQuantity'], 4, Number(payload.sq5))

          if (payload.lp) {
            state.compactMarketData[objIndex]['details']['Last Traded Price'] = Number(payload.lp)
          }
          if (payload.pc) {
            state.compactMarketData[objIndex]['details']['Change'] = Number(payload.pc)
          }
          if (payload.tbq) {
            state.compactMarketData[objIndex]['details']['totalBuyQty'] = Number(payload.tbq)
            state.compactMarketData[objIndex]['details']['totalBuyQty'] = Number(payload.tbq)
          }
          if (payload.tsq) {
            state.compactMarketData[objIndex]['details']['totalSellQty'] = Number(payload.tsq)
          }
          if (payload.t == 'dk') {
            state.compactMarketData[objIndex]['details']['oi'] = Number(payload.oi)
          }
          if (payload.t == 'dk') {
            state.compactMarketData[objIndex]['details']['Trade Volume'] = Number(payload.v)
          }
        }
      }
    },
  },
}