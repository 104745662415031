<template lang="html">
  <div class="default-position-wrapper">
    <div>
      <v-data-table
        v-if="!$vuetify.breakpoint.xs"
        fixed-header
        class="default-positions-data-table"
        :class="{'calculated-height':isDefaultPositions}"
        ref="defPosTable"
        style="margin-top: 0.5rem;"
        :items="filteredPositions"
        :hide-default-footer="true"
        :items-per-page="-1"
      >
        <template v-slot:header>
          <thead>
            <tr>
              <th>
                <v-checkbox
                  :disabled="totalOpenPosition == 0"
                  class="mobile-checkbox"
                  :input-value="selectAllValue"
                  @click="toggleSelectAll"
                  color="var(--primary-button-color)"
                  hide-details
                ></v-checkbox>
              </th>
              <th>
                <div class="d-flex align-center justify-space-between">
                  Instrument
                  <v-tooltip bottom v-if="isDefaultPositions">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="ml-1 d-flex" stacked>
                        <v-badge
                          :content="filteredInstrumentList.length"
                          color="primary"
                          class="filter-badge"
                          v-if="filteredInstrumentList.length != 0"
                        >
                          <v-icon
                            size="x-large"
                            @click="showInstrumentFilter = !showInstrumentFilter"
                            style="
                              font-size: 1.4rem;
                              cursor: pointer;
                              color: var(--primary-color);
                            "
                          >
                            mdi-filter
                          </v-icon>
                        </v-badge>
                        <v-icon
                          v-else
                          @click="showInstrumentFilter = !showInstrumentFilter"
                          style="font-size: 1.4rem; cursor: pointer"
                          >mdi-filter-outline</v-icon
                        >
                      </span>
                    </template>
                    <span> Filter Instrument</span>
                  </v-tooltip>
                  <div class="instrument-filter" v-if="showInstrumentFilter">
                    <FilterInstrument
                      :menuVisible="showInstrumentFilter"
                      :instrumentList="instrumentList"
                      @findfilteredInstrumentList="getFilteredInstrumentData"
                      :filteredInstrumentList="filteredInstrumentList"
                      @close="showInstrumentFilter = false"
                      @clickedReset="showAllPositions"
                    />
                  </div>
                </div>
              </th>
              <th class="text-center" v-if="isDefaultPositions">Type</th>
              <th class="text-center" v-else>LIX</th>
              <th class="text-center">Qty</th>
              <th class="text-center" v-if="isDefaultPositions">Buy Avg</th>
              <th class="text-center" v-if="isDefaultPositions">Sell Avg</th>
              <th class="text-center" v-if="!isDefaultPositions">Buy/Sell</th>
              <th class="text-center">LTP</th>
              <th class="text-center" v-if="isDefaultPositions">Day's P&L</th>
              <th class="text-center" v-if="isDefaultPositions">Net P&L</th>
              <th class="text-center" v-if="!isDefaultPositions">P/L</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="data">
          <tr
            :key="data.item.id"
            :class="data.item.net_quantity == 0 ? 'disable-row' : ''"
            @mouseenter="showButtons(data.item)"
            @mouseleave="hideButtons(data.item)"
            style="position: relative;"
          >
            <!-- CHECKBOX -->
            <td>
              <v-checkbox
                class="mobile-checkbox"
                :input-value="
                  data.selected || selectedPositions.includes(data.item)
                "
                @click="toggleSelection(data.item)"
                color="var(--primary-button-color)"
                :disabled="data.item.net_quantity == 0"
                hide-details
              ></v-checkbox>
            </td>

            <!-- INSTRUMENT NAME -->
            <td
              style="white-space: nowrap ;position: inherit;"
              class="d-flex align-center"
              @click="showMobileExitAddDialog(data.item)"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-if="data.item.isNa"
                    class="alert-icons mr-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img src="@/assets/Positions/not-available.png" alt="NA" class="na-position">
                  </span>
                </template>
                <span>Analytics for this Instrument is not Available</span>
              </v-tooltip>
              <span @click="print(data.item)">{{ data.item.formatted_ins_name }}</span>
              <span v-if="data.item.net_quantity != 0" class="mx-1">
                <span v-if="data.item.lix_value < 5">
                  <img
                    class="mx-1 low-liquidity-icon"
                    :src="
                      !darkMode
                        ? require('@/assets/Positions/low-liquidity.svg')
                        : require('@/assets/Positions/low-liquidity-dark.svg')
                    "
                    style="width: 16px; height: 16px"
                  />
                </span>
              </span>
              <span
                v-if="!data.item.in_trend && data.item.net_quantity != 0"
                class="alert-icons"
              >
                <v-icon class="mr-1">mdi-close-circle</v-icon>
              </span>
              <span
                v-if="data.item.delivery_risk && data.item.net_quantity != 0"
                class="alert-icons"
              >
                <v-icon class="mr-1">mdi-clock-fast</v-icon>
              </span>
            </td>

            <!-- INSTRYMENT PURCHASE TYPE -->
            <td
              v-if="isDefaultPositions"
              @click="showMobileExitAddDialog(data.item)"
              class="text-center"
            >
              <span
                v-if="data.item.net_quantity != 0"
                class="product-type"
                :class="data.item.prod_type == 'NRML' ? 'nrml' : 'mis'"
                >{{ data.item.prod_type }}</span
              >
              <span
                v-else
                class="product-type"
                :class="
                  data.item.prod_type == 'NRML' ? 'nrml-disable' : 'mis-disable'
                "
                >{{ data.item.prod_type }}</span
              >
            </td>

            <!-- LIX -->
            <td
              class="text-center"
              v-else
              @click="showMobileExitAddDialog(data.item)"
            >
              <span>{{ data.item.lix_value }}</span>
            </td>

            <!-- QUANTITY -->
            <td class="text-center" @click="showMobileExitAddDialog(data.item)">
              <span v-if="data.item.net_quantity != 0">
                <span v-if="data.item.net_quantity > 0" class="green-text"
                  >+</span
                >
                <span
                  :class="
                    data.item.net_quantity >= 0 ? 'green-text' : 'red-text'
                  "
                >
                  <span
                    v-if="
                      data.item.exchange == 'MCX' || data.item.exchange == 'CDS'
                    "
                  >
                    {{ data.item.net_quantity / data.item.lot_size }}
                  </span>
                  <span v-else>
                    {{ data.item.net_quantity }}
                  </span>
                </span>
              </span>
              <span v-else>
                <span
                  v-if="data.item.net_quantity > 0"
                  class="disable-green-text"
                  >+</span
                >
                <span
                  :class="
                    data.item.net_quantity >= 0
                      ? 'disable-green-text'
                      : 'disable-red-text'
                  "
                >
                  <span
                    v-if="
                      data.item.exchange == 'MCX' || data.item.exchange == 'CDS'
                    "
                  >
                    {{ data.item.net_quantity / data.item.lot_size }}
                  </span>
                  <span v-else>
                    {{ data.item.net_quantity }}
                  </span></span
                >
              </span>
            </td>

            <!-- BUY AVG -->
            <td
              class="text-center"
              v-if="isDefaultPositions"
              @click="showMobileExitAddDialog(data.item)"
            >
              <span v-if="data.item.symbol != 'USDINR'">
                {{ data.item.buy_amount.toFixed(2) }}
              </span>
              <span v-else>
                {{ data.item.buy_amount | float(4) }}
              </span>
            </td>

            <!-- SELL AVG -->
            <td
              class="text-center"
              v-if="isDefaultPositions"
              @click="showMobileExitAddDialog(data.item)"
            >
              <span v-if="data.item.symbol != 'USDINR'">
                {{ data.item.sell_amount.toFixed(2) }}
              </span>
              <span v-else>
                {{ data.item.sell_amount | float(4) }}
              </span>
            </td>


            <td
              class="text-center"
              v-if="!isDefaultPositions"
              @click="showMobileExitAddDialog(data.item)"
            >
              <span v-if="data.item.net_quantity > 0">
                <span v-if="data.item.symbol != 'USDINR'">
                  {{ data.item.buy_amount.toFixed(2) }}
                </span>
                <span v-else>
                  {{ data.item.buy_amount | float(4) }}
                </span>
              </span>
              <span v-else>
                <span v-if="data.item.symbol != 'USDINR'">
                  {{ data.item.sell_amount.toFixed(2) }}
                </span>
                <span v-else>
                  {{ data.item.sell_amount | float(4) }}
                </span>
              </span>
            </td>

            <!-- LTP -->
            <td class="text-center ltp-col" @click="showMobileExitAddDialog(data.item)">
              <span v-if="insSub(data.item.instrument_token.toString())">
                <span v-if="data.item.symbol != 'USDINR'">
                  {{
                    insSub(data.item.instrument_token.toString()).ltp.toFixed(2)
                  }}
                </span>
                <span v-else>
                  {{
                    insSub(data.item.instrument_token.toString()).ltp | float(4)
                  }}
                </span>
              </span>
            </td>

            <!-- DAYS P&L -->
            <td
              class="text-center days-pnl"
              v-if="isDefaultPositions"
              @click="showMobileExitAddDialog(data.item)"
            >
              <span>
                <span
                  v-if="getDaysAndNetPL('days', data.item) > 0"
                  class="green-text"
                  >+</span
                >
                <span
                  :class="
                    getDaysAndNetPL('days', data.item) >= 0
                      ? 'green-text'
                      : 'red-text'
                  "
                  >{{
                    getDaysAndNetPL("days", data.item) | currencyWithDecimal
                  }}
                </span>
              </span>
            </td>

            <!-- NET P&L -->
            <td
              class="text-center net-pnl"
              v-if="isDefaultPositions"
              @click="showMobileExitAddDialog(data.item)"
            >
              <span>
                <span
                  v-if="getDaysAndNetPL('net', data.item) > 0"
                  class="green-text"
                  >+</span
                >
                <span
                  :class="
                    getDaysAndNetPL('net', data.item) >= 0
                      ? 'green-text'
                      : 'red-text'
                  "
                  >{{
                    getDaysAndNetPL("net", data.item) | currencyWithDecimal
                  }}</span
                >
              </span>
            </td>
            <td
              class="text-center"
              v-if="!isDefaultPositions"
              @click="showMobileExitAddDialog(data.item)"
            >
              <span v-if="getCardProfitLoss(data.item) >= 0" class="green-text"
                >+</span
              >
              <span
                :class="
                  getCardProfitLoss(data.item) >= 0 ? 'green-text' : 'red-text'
                "
              >
                {{ getCardProfitLoss(data.item) | currencyWithDecimal }}
              </span>
            </td>

            <!-- POP OVER BUTTONS: ADD,EXIT AND GRAPH -->
            <td
              v-if="
                data.item.net_quantity !== 0 &&
                data.item.hover &&
                !$vuetify.breakpoint.sm &&
                !$vuetify.breakpoint.xs
              "
              class="add-exit-buttons"
            >
              <div v-if="data.item.hover">
                <button
                  class="primary-button"
                  style="white-space: nowrap"
                  :style="
                    data.item.net_quantity > 0
                      ? 'background-color:var(--primary-color)'
                      : 'background-color: var(--red-text)'
                  "
                  @click="showPlaceOrderDialogPopUp(data.item, 'add', null)"
                >
                  Add
                </button>
                <button
                  class="primary-button"
                  style="white-space: nowrap"
                  :style="
                    data.item.net_quantity < 0
                      ? 'background-color:var(--primary-color)'
                      : 'background-color: var(--red-text)'
                  "
                  @click="showPlaceOrderDialogPopUp(data.item, 'exit', null)"
                >
                  Exit
                </button>
                <button
                  v-if="!data.item.isNa"
                  class="outlined-button"
                  style="white-space: nowrap"
                  @click="showGraphModal(data.item)"
                >
                  <img
                    :src="
                      !darkMode
                        ? require('@/assets/LightMode/graph.png')
                        : require('@/assets/DarkMode/graph.png')
                    "
                    alt="show chart"
                    style="cursor: pointer; height: 13px; width: 30px"
                  />
                </button>
              </div>
            </td>
            <td
              v-if="
                data.item.net_quantity == 0 &&
                data.item.hover &&
                !$vuetify.breakpoint.sm &&
                !$vuetify.breakpoint.xs
              "
              class="add-exit-buttons"
            >
              <div v-if="data.item.hover">
                <button
                  class="primary-button"
                  style="
                    white-space: nowrap;
                    background-color: var(--primary-color);
                  "
                  @click="showPlaceOrderDialogPopUp(data.item, null, 'buy')"
                >
                  Buy
                </button>
                <button
                  class="primary-button"
                  style="white-space: nowrap; background-color: var(--red-text)"
                  @click="showPlaceOrderDialogPopUp(data.item, null, 'sell')"
                >
                  Sell
                </button>
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:foot>
          <tfoot>
          <tr>
            <td :colspan="isDefaultPositions?6:5"></td>
            <td class="text-center">Total:</td>
            <td class="text-center" v-if="isDefaultPositions">
              <span v-if="getTotalDaysAndNetPL('days') > 0" class="green-text">
                +</span
              ><span
                :class="
                  getTotalDaysAndNetPL('days') > 0 ? 'green-text' : 'red-text'
                "
                >{{ getTotalDaysAndNetPL("days") | currencyWithDecimal }}
              </span>
            </td>
            <td class="text-center">
              <span v-if="getTotalDaysAndNetPL('net') > 0" class="green-text">
                +</span
              ><span
                :class="
                  getTotalDaysAndNetPL('net') > 0 ? 'green-text' : 'red-text'
                "
                >{{ getTotalDaysAndNetPL("net") | currencyWithoutFormat }}
              </span>
            </td>
          </tr></tfoot>
        </template>
        <template v-slot:no-data>
          <div
            class="custom-empty-wrapper"
            style="color: var(--text-color); position: absolute"
          >
            No positions available
          </div>
        </template>
      </v-data-table>
      <!-- Mobile responsive design -->
      <div v-else>
        <!-- for default positions -->
        <div v-if="isDefaultPositions">
          <div style="position: relative" class="d-flex">
            <span>
              <v-checkbox
                :disabled="totalOpenPosition == 0"
                :input-value="selectAllValue"
                @click="toggleSelectAll"
                color="var(--primary-button-color)"
                label="Select All"
                hide-details
                class="mobile-checkbox"
              ></v-checkbox>
            </span>
            <span class="ml-3">
              <span class="ml-1 mt-2">
                <v-badge
                  :content="filteredInstrumentList.length"
                  color="primary"
                  class="filter-badge"
                  v-if="filteredInstrumentList.length != 0"
                >
                  <v-icon
                    @click="showInstrumentFilter = !showInstrumentFilter"
                    style="
                      font-size: 0.8rem;
                      cursor: pointer;
                      color: var(--primary-color);
                    "
                  >
                    mdi-filter
                  </v-icon>
                </v-badge>
                <v-icon
                  v-else
                  @click="showInstrumentFilter = !showInstrumentFilter"
                  style="font-size: 0.8rem; cursor: pointer"
                  >mdi-filter-outline</v-icon
                >
              </span>
              <div class="instrument-filter" v-if="showInstrumentFilter">
                <FilterInstrument
                  :menuVisible="showInstrumentFilter"
                  :instrumentList="instrumentList"
                  @findfilteredInstrumentList="getFilteredInstrumentData"
                  :filteredInstrumentList="filteredInstrumentList"
                  @close="showInstrumentFilter = false"
                  @clickedReset="showAllPositions"
                />
              </div>
            </span>
          </div>
          <div
            class="mobile-instrument-default-rows pt-4"
            v-for="(item, index) of filteredPositions"
            :key="index"
            :class="item.net_quantity == 0 ? 'disable-row' : ''"
          >
            <v-row no-gutters class="pb-3 justify-space-between">
              <v-col cols="8" class="d-flex align-center">
                <v-checkbox
                  class="mobile-checkbox"
                  :input-value="selectedPositions.includes(item)"
                  @click="toggleSelection(item)"
                  color="var(--primary-button-color)"
                  :disabled="item.net_quantity == 0"
                  hide-details
                ></v-checkbox>
                <span class="pl-4" @click="showMobileExitAddDialog(item)">
                  {{ item.formatted_ins_name }}
                  <span v-if="item.net_quantity != 0" class="mx-1 mt-1">
                    <span v-if="item.lix_value < 5">
                      <img
                        class="mx-1 low-liquidity-icon"
                        :src="
                          !darkMode
                            ? require('@/assets/Positions/low-liquidity.svg')
                            : require('@/assets/Positions/low-liquidity-dark.svg')
                        "
                        style="width: 12px; height: 12px"
                      />
                    </span>
                  </span>
                  <span
                    v-if="!item.in_trend && item.net_quantity != 0"
                    class="alert-icons"
                  >
                    <v-icon class="mr-1">mdi-close-circle</v-icon>
                  </span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-if="data.item.isNa"
                        class="alert-icons mr-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <img src="@/assets/Positions/not-available.png" alt="NA" class="na-position">
                      </span>
                    </template>
                    <span>Analytics for this Instrument is not Available</span>
                  </v-tooltip>
                  <span
                    v-if="item.delivery_risk && item.net_quantity != 0"
                    class="alert-icons"
                  >
                    <v-icon class="mr-1">mdi-clock-fast</v-icon>
                  </span>
                </span>
              </v-col>
              <v-col
                @click="showMobileExitAddDialog(item)"
                cols="4"
                class="text-right pr-2"
              >
                <span>Net P&L: </span>
                <span>
                  <span
                    v-if="getDaysAndNetPL('net', item) > 0"
                    class="green-text"
                    >+</span
                  >
                  <span
                    :class="
                      getDaysAndNetPL('net', item) >= 0
                        ? 'green-text'
                        : 'red-text'
                    "
                    >{{ getDaysAndNetPL("net", item) | currencyWithDecimal }}
                  </span>
                </span>
              </v-col>
            </v-row>
            <v-row
              @click="showMobileExitAddDialog(item)"
              no-gutters
              class="pb-3"
            >
              <v-col cols="4" class="text-left pl-10">
                <span
                  v-if="item.net_quantity != 0"
                  :class="
                    item.prod_type == 'NRML' ? 'nrml-mobile' : 'mis-mobile'
                  "
                >
                  {{ item.prod_type }}
                </span>
                <span
                  v-else
                  :class="
                    item.prod_type == 'NRML'
                      ? 'nrml-mobile-disable'
                      : 'mis-mobile-disable'
                  "
                >
                  {{ item.prod_type }}
                </span>
              </v-col>
              <v-col cols="4" class="text-center">
                <span> Qty: </span>
                <span v-if="item.net_quantity != 0">
                  <span v-if="item.net_quantity > 0" class="green-text">+</span>
                  <span
                    :class="item.net_quantity >= 0 ? 'green-text' : 'red-text'"
                  >
                    <span
                      v-if="item.exchange == 'MCX' || item.exchange == 'CDS'"
                    >
                      {{ item.net_quantity / item.lot_size }}
                    </span>
                    <span v-else>
                      {{ item.net_quantity }}
                    </span></span
                  >
                </span>
                <span v-else>
                  <span v-if="item.net_quantity > 0" class="disable-green-text"
                    >+</span
                  >
                  <span
                    :class="
                      item.net_quantity >= 0
                        ? 'disable-green-text'
                        : 'disable-red-text'
                    "
                  >
                    <span
                      v-if="item.exchange == 'MCX' || item.exchange == 'CDS'"
                    >
                      {{ item.net_quantity / item.lot_size }}
                    </span>
                    <span v-else>
                      {{ item.net_quantity }}
                    </span></span
                  >
                </span>
              </v-col>
              <v-col cols="4" class="text-right pr-2">
                <span>Day's P&L: </span>
                <span>
                  <span
                    v-if="getDaysAndNetPL('days', item) > 0"
                    class="green-text"
                    >+</span
                  >
                  <span
                    :class="
                      getDaysAndNetPL('days', item) >= 0
                        ? 'green-text'
                        : 'red-text'
                    "
                    >{{ getDaysAndNetPL("days", item) | currencyWithDecimal }}
                  </span>
                </span>
              </v-col>
            </v-row>
            <v-row
              @click="showMobileExitAddDialog(item)"
              no-gutters
              class="pb-3"
            >
              <v-col cols="4" class="text-left pl-10">
                <span> Buy Avg: </span>
                <span>
                  <span v-if="item.symbol != 'USDINR'">
                    {{ item.buy_amount.toFixed(2) }}
                  </span>
                  <span v-else>
                    {{ item.buy_amount | float(4) }}
                  </span>
                </span>
              </v-col>
              <v-col cols="4" class="text-center">
                <span>Sell Avg: </span>
                <span>
                  <span v-if="item.symbol != 'USDINR'">
                    {{ item.sell_amount.toFixed(2) }}
                  </span>
                  <span v-else>
                    {{ item.sell_amount | float(4) }}
                  </span>
                </span>
              </v-col>
              <v-col cols="4" class="text-right pr-2">
                <span> LTP: </span>
                <span v-if="insSub(item.instrument_token.toString())">
                  {{ insSub(item.instrument_token.toString()).ltp }}
                </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
          <div
            class="mobile-instrument-default-rows total-pl-mobile pt-2"
            v-if="isDefaultPositions"
          >
            <v-row no-gutters>
              <v-col cols="4">Total</v-col>
              <v-col cols="4">
                <span>Days P&:L</span>
                <span class="green-text">
                  {{ getTotalDaysAndNetPL("days") > 0 ? "+" : "" }}
                </span>
                <span
                  :class="
                    getTotalDaysAndNetPL('days') >= 0
                      ? 'green-text'
                      : 'red-text'
                  "
                >
                  {{ getTotalDaysAndNetPL("days") }}
                </span>
              </v-col>
              <v-col cols="4" class="text-right">
                <span>Net P&L:</span>
                <span class="green-text">
                  {{ getTotalDaysAndNetPL("net") > 0 ? "+" : "" }}
                </span>
                <span
                  :class="
                    getTotalDaysAndNetPL('net') >= 0 ? 'green-text' : 'red-text'
                  "
                >
                  {{ getTotalDaysAndNetPL("net") }}
                </span>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- for symbol and hedge positions -->
        <div v-else>
          <v-row no-gutters class="mobile-instrument-header">
            <v-col cols="1">
              <v-checkbox
                :input-value="selectAllValue"
                @click="toggleSelectAll"
                :disabled="totalOpenPosition == 0"
                color="var(--primary-button-color)"
                hide-details
                class="mobile-checkbox"
                style="align-items: center"
              ></v-checkbox>
            </v-col>
            <v-col cols="11">
              <v-row no-gutters>
                <v-col cols="7" class="pl-5"> Instrument </v-col>
                <v-col cols="2"> LIX </v-col>
                <v-col cols="3" class="text-right"> Qty </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="7" class="pl-5"> LTP </v-col>
                <v-col cols="2"> Buy/Sell </v-col>
                <v-col cols="3" class="text-right"> P/L </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="mobile-instrument-rows"
            v-for="(item, index) of defaultPositionsList"
            :key="index"
            :class="item.net_quantity == 0 ? 'disable-row' : ''"
          >
            <v-col cols="1">
              <v-checkbox
                class="mobile-checkbox"
                :input-value="selectedPositions.includes(item)"
                @click="toggleSelection(item)"
                color="var(--primary-button-color)"
                :disabled="item.net_quantity == 0"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="11" @click="showMobileExitAddDialog(item)">
              <v-row no-gutters>
                <v-col cols="7" class="pl-5">
                  {{ item.formatted_ins_name }}
                  <span v-if="item.net_quantity != 0" class="mx-1">
                    <span v-if="item.lix_value < 5">
                      <img
                        class="mx-1 low-liquidity-icon"
                        :src="
                          !darkMode
                            ? require('@/assets/Positions/low-liquidity.svg')
                            : require('@/assets/Positions/low-liquidity-dark.svg')
                        "
                        style="width: 12px; height: 12px"
                      />
                    </span>
                  </span>
                  <span
                    v-if="!item.in_trend && item.net_quantity != 0"
                    class="alert-icons"
                  >
                    <v-icon class="mr-1">mdi-close-circle</v-icon>
                  </span>
                  <span
                    v-if="item.delivery_risk && item.net_quantity != 0"
                    class="alert-icons"
                  >
                    <v-icon class="mr-1">mdi-clock-fast</v-icon>
                  </span>
                </v-col>
                <v-col cols="2">
                  <span>{{ item.lix_value }}</span>
                </v-col>
                <v-col cols="3" class="text-right">
                  <span v-if="item.net_quantity != 0">
                    <span v-if="item.net_quantity > 0" class="green-text"
                      >+</span
                    >
                    <span
                      :class="
                        item.net_quantity >= 0 ? 'green-text' : 'red-text'
                      "
                    >
                      <span
                        v-if="item.exchange == 'MCX' || item.exchange == 'CDS'"
                      >
                        {{ item.net_quantity / item.lot_size }}
                      </span>
                      <span v-else>
                        {{ item.net_quantity }}
                      </span></span
                    >
                  </span>
                  <span v-else>
                    <span
                      v-if="item.net_quantity > 0"
                      class="disable-green-text"
                      >+</span
                    >
                    <span
                      :class="
                        item.net_quantity >= 0
                          ? 'disable-green-text'
                          : 'disable-red-text'
                      "
                    >
                      <span
                        v-if="item.exchange == 'MCX' || item.exchange == 'CDS'"
                      >
                        {{ item.net_quantity / item.lot_size }}
                      </span>
                      <span v-else>
                        {{ item.net_quantity }}
                      </span></span
                    >
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="7" class="pl-5">
                  <span v-if="insSub(item.instrument_token)">
                    <span v-if="item.symbol != 'USDINR'">
                      {{ insSub(item.instrument_token).ltp }}
                    </span>
                    <span v-else>
                      {{
                        insSub(item.instrument_token).ltp | float(4)
                      }}
                    </span>
                  </span>
                </v-col>
                <v-col cols="2">
                  <span v-if="item.net_quantity > 0">
                    <span v-if="item.symbol != 'USDINR'">
                      {{ item.buy_amount.toFixed(2) }}
                    </span>
                    <span v-else>
                      {{ item.buy_amount | float(4) }}
                    </span>
                  </span>
                  <span v-else>
                    <span v-if="item.symbol != 'USDINR'">
                      {{ item.sell_amount.toFixed(2) }}
                    </span>
                    <span v-else>
                      {{ item.sell_amount | float(4) }}
                    </span>
                  </span>
                </v-col>
                <v-col cols="3" class="text-right">
                  <span v-if="getCardProfitLoss(item) >= 0" class="green-text"
                    >+</span
                  >

                  <span
                    :class="
                      getCardProfitLoss(item) >= 0 ? 'green-text' : 'red-text'
                    "
                  >
                    {{ getCardProfitLoss(item) }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>

      <div>
        <button
          class="primary-button"
          style="
            white-space: nowrap;
            background-color: var(--red-text);
            position: absolute;
            bottom: 1rem;
          "
          v-if="selectedPositions.length != 0 && isDefaultPositions"
          @click="showPlaceOrderForExitPositions()"
        >
          Exit({{ selectedPositions.length }})
        </button>
      </div>
    </div>

    <ExitPositionsModal
      v-if="isExitPositionsClicked"
      :isExitPositionsVisible="isExitPositionsClicked"
      @close="isExitPositionsClicked = false"
      :selectedPositions="selectedPositions"
    />
    <AddExitButtonsDialog
      v-if="showAddExitDialog"
      :isBuySellButtonsDialog="isBuySellButtonsDialog"
      :visible="showAddExitDialog"
      @close="showAddExitDialog = false"
      :clickedPosition="clickedPosition"
      :positionAnalyticsData="positionAnalyticsData"
    />
    <PlaceOrderModal
      v-if="showPlaceOrderDialog"
      :visible="showPlaceOrderDialog"
      @close="
        showPlaceOrderDialog = false;
        refreshPositions();
      "
      :strategyCreator="strategyCreatorList"
      :selectedInstrument="selectedInstrument"
      :isPositionGraph="true"
      :isExitPositionsModal="isExitPositionsModal"
    ></PlaceOrderModal>
    <GraphAndDaysModal
      v-if="payOffChartVisible"
      :visible="payOffChartVisible"
      :selectedInstrument="selectedInstrument"
      :strategyData="payOffChartData"
      :isPositionGraph="true"
      :isDefaultPositions="true"
      @close="payOffChartVisible = false"
      :positionAnalyticsData="payOffChart"
    ></GraphAndDaysModal>
  </div>
</template>
<script>
import axios from "axios";
import PlaceOrderModal from "../modals/PlaceOrderModal.vue";
import {
  getPlaceOrderData,
  getPayOffChartData,
  getProfitLossForPosition,
} from "./common/positionServices";
import { getLixData } from "../getService";
import AddExitButtonsDialog from "./modals/AddExitButtonsDialog.vue";
import GraphAndDaysModal from "../modals/GraphAndDaysModal.vue";
import FilterInstrument from "./modals/FilterInstrument.vue";
import ExitPositionsModal from "./modals/ExitPositionsModal.vue";
import { EventBus } from "../../main";
export default {
  name: "DefaultPositions",
  components: {
    PlaceOrderModal,
    AddExitButtonsDialog,
    GraphAndDaysModal,
    FilterInstrument,
    ExitPositionsModal,
  },
  props: {
    defaultPositionsList: [],
    isDefaultPositions: Boolean,
    positionAnalyticsData: null,
    refreshDefaultPositions: Boolean,
  },
  watch: {
    refreshDefaultPositions() {
      if (this.refreshDefaultPositions) {
        this.sortDefaultPositions(this.defaultPositionsList);
      }
    },
  },
  computed: {
    insSub() {
      return (el) => {
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  data() {
    return {
      instrumentList: [],
      selectedPositions: [],
      filteredPositions: [],
      selectAllValue: false,
      isMobile: false,
      totalOpenPosition: 0,
      isExitPositionsClicked: false,
      showAddExitDialog: false,
      clickedPosition: null,
      showPlaceOrderDialog: false,
      strategyCreatorList: [],
      selectedInstrument: null,
      isBuySellButtonsDialog: false,
      payOffChartVisible: false,
      payOffChartData: null,
      positionAgainstTrends: 0,
      positionAgainstDeliveryRisk: 0,
      positionLowLiquidity: 0,
      showInstrumentFilter: false,
      filteredInstrumentList: [],
      payOffChart: null,
      isExitPositionsModal: false,
    };
  },
  methods: {
    print(val){console.log(val)},
    showButtons(item) {
      item.hover = true;
    },
    hideButtons(item) {
      item.hover = false;
    },
    createInstrumentList() {
      for (let ins of this.defaultPositionsList) {
        if (!this.instrumentList.includes(ins.symbol)) {
          this.instrumentList.push(ins.symbol);
        }
      }
    },
    sortDefaultPositions(positionsList) {
      this.filteredPositions = [];
      let index = 0;
      this.totalOpenPosition = 0;
      this.positionAgainstTrends = 0;
      this.positionAgainstDeliveryRisk = 0;
      this.positionLowLiquidity = 0;
      for (let item of positionsList) {
        if (item.net_quantity !== 0) this.totalOpenPosition += 1;
        if (this.isDefaultPositions) {
          if (!item.in_trend) this.positionAgainstTrends += 1;
          if (item.delivery_risk) this.positionAgainstDeliveryRisk += 1;
          this.$emit("totalOpenPositions", this.totalOpenPosition);
          this.$emit("positionAgainstTrends", this.positionAgainstTrends);
          this.$emit(
            "positionAgainstDeliveryRisk",
            this.positionAgainstDeliveryRisk
          );
          if (item.net_quantity !== 0) {
            this.getLixValueCount(item, index);
          }
          index = index + 1;
          this.$set(item, "hover", false);
        }
        this.filteredPositions.push(item);
        // console.log(this.filteredPositions)
      }
      this.filteredPositions.sort((a, b) => {
        if (a.net_quantity === 0 && b.net_quantity !== 0) {
          return 1;
        } else if (a.net_quantity !== 0 && b.net_quantity === 0) {
          return -1;
        }
      });

      // this.filteredPositions = this.defaultPositionsLi st;
      if (!this.isDefaultPositions) {
        // this.selectedPositions = this.defaultPositionsList;
        for (let item of this.defaultPositionsList) {
          this.selectedPositions.push(item);
        }
        this.selectAllValue = true;
      }
    },
    toggleSelection(item) {
      const index = this.selectedPositions.indexOf(item);
      if (index > -1) {
        this.selectedPositions.splice(index, 1);
      } else {
        this.selectedPositions.push(item);
      }
      this.selectAllValue =
        this.totalOpenPosition == this.selectedPositions.length ? true : false;
      if (!this.isDefaultPositions) {
        this.$emit("selectedPositions", this.selectedPositions);
      }
    },
    toggleSelectAll() {
      this.selectAllValue = !this.selectAllValue;
      if (this.selectAllValue) {
        if (!this.isDefaultPositions) {
          for (let item of this.defaultPositionsList) {
            if (
              item.net_quantity !== 0 &&
              !this.selectedPositions.includes(item)
            )
              this.selectedPositions.push(item);
          }
        } else {
          for (let item of this.filteredPositions) {
            if (
              item.net_quantity !== 0 &&
              !this.selectedPositions.includes(item)
            )
              this.selectedPositions.push(item);
          }
        }
      } else {
        this.selectedPositions = [];
      }
      if (!this.isDefaultPositions) {
        this.$emit("selectedPositions", this.selectedPositions);
      }
    },
    showMobileExitAddDialog(item) {
      if (window.innerWidth <= 960) {
        this.showAddExitDialog = true;
        this.clickedPosition = item;
        if (item.net_quantity == 0) {
          this.isBuySellButtonsDialog = true;
        } else {
          this.isBuySellButtonsDialog = false;
        }
      }
    },
    showExitDialog(item) {
      this.isExitPositionsClicked = true;
      this.selectedPositions = [];
      this.selectedPositions.push(item);
    },
    showPlaceOrderDialogPopUp(item, type, buySellType = null) {
      console.log(item)
      this.showPlaceOrderDialog = true;
      let net_quantity =
        item.exchange != "MCX" && item.exchange != "CDS"
          ? type != null && type == "add"
            ? item.net_quantity > 0
              ? item.lot_size
              : -item.lot_size
            : -item.net_quantity
          : type != null && type == "add"
          ? item.net_quantity > 0
            ? item.net_quantity / item.lot_size
            : item.net_quantity / item.lot_size
          : -item.net_quantity / item.lot_size;
      this.strategyCreatorList = getPlaceOrderData(
        item,
        net_quantity,
        "creator",
        buySellType
      );
      this.selectedInstrument = getPlaceOrderData(
        item,
        net_quantity,
        "instrument",
        buySellType
      );
    },

    /** @function showGraphModal method is used to show graph modal */
    showGraphModal(item) {
      this.payOffChartVisible = true;
      item.ltp = item.net_quantity > 0 ? item.buy_amount : -item.sell_amount;
      this.payOffChart = item;
      this.selectedInstrument = getPlaceOrderData(item, "instrument", null);
      let spot_price =
        this.selectedInstrument.exchange == "NSE" ||
        this.selectedInstrument.exchange == "NFO"
          ? this.insSub(this.selectedInstrument.underlying_code)
            ? this.insSub(this.selectedInstrument.underlying_code).ltp
            : 0
          : this.insSub(this.selectedInstrument.fut_code)
          ? this.insSub(this.selectedInstrument.fut_code).ltp
          : 0;
      if (
        this.selectedInstrument.exchange == "NSE" ||
        this.selectedInstrument.exchange == "NFO"
      ) {
        this.selectedInstrument.exchange = "NSE";
        this.selectedInstrument.ltp = spot_price;
      } else {
        this.selectedInstrument.fut_ltp = spot_price;
      }
      this.payOffChartData = getPayOffChartData(
        item,
        this.selectedInstrument,
        spot_price
      );
    },

    /** @function getLixValueCount method is used to get lix value count */
    getLixValueCount(item, index) {
      let payload = {
        user_id: localStorage.getItem("user_id"),
        access_token: localStorage.getItem("accessTokenAnt"),
        ws_token: localStorage.getItem("ws_session"),
        param_list: [item.param_list],
      };
      axios.post(getLixData, payload).then((res) => {
        if (res.data.param_list.at(0)?.lix_value < 5) {
          if (item.net_quantity !== 0) {
            this.positionLowLiquidity += 1;
            this.$emit("positionLowLiquidity", this.positionLowLiquidity);
          }
          this.$set(
            this.defaultPositionsList[index],
            "lix_value",
            res.data.param_list[0].lix_value
          );
          return res.data.param_list[0].lix_value;
        }
      });
    },

    getCardProfitLoss(position) {
      let cardTotalProfit = 0;
      let ltp = this.insSub(position.instrument_token)
        ? parseFloat(this.insSub(position.instrument_token).ltp).toFixed(2)
        : 0;
      let profitLoss = getProfitLossForPosition(position, ltp);
      cardTotalProfit = cardTotalProfit + parseFloat(profitLoss);
      return cardTotalProfit;
    },
    showAllPositions() {
      // for (let item of this.defaultPositionsList) {
      //   this.filteredPositions.push(item);
      // }
      this.sortDefaultPositions(this.defaultPositionsList);
    },
    getFilteredInstrumentData(value) {
      this.filteredInstrumentList = value;
      let filteredData = this.defaultPositionsList.reduce((result, obj1) => {
        const obj2 = this.filteredInstrumentList.find(
          (obj2) => obj2 === obj1.symbol
        );
        if (obj2) {
          result.push(obj1);
        }
        return result;
      }, []);
      this.sortDefaultPositions(filteredData);
    },

    getDaysAndNetPL(key, position) {
      let daysPL = 0;
      let netPL = 0;
      const token = position.instrument_token.toString();
      let closePrice = this.insSub(token)
        ? parseFloat(this.insSub(token).close)
        : 0;
      let ltp = this.insSub(token)
        ? parseFloat(this.insSub(token).ltp).toFixed(2)
        : 0;
      if (position.net_quantity !== 0) {
        let amount =
          position.Type != "DAYNET"
            ? position.net_quantity > 0
              ? position.buy_amount
              : position.sell_amount
            : position.cf_quantity > 0
            ? position.actual_average_buy_price
            : position.actual_average_sell_price;
        let netPLAmount =
          position.net_quantity > 0
            ? position.buy_amount
            : position.sell_amount;
        netPL = (
          parseFloat((ltp - netPLAmount) * position.net_quantity) +
          position.realized_mtm
        ).toFixed(2);
        daysPL =
          position.Type == "DAY1"
            ? netPL
            : position.Type == "DAYNET"
            ? (netPL - (closePrice - amount) * position.cf_quantity).toFixed(2)
            : (netPL - (closePrice - amount) * position.net_quantity).toFixed(
                2
              );
      } else {
        let amount =
          position.cf_quantity > 0 ? position.buy_amount : position.sell_amount;
        netPL = position.net_amount_mtm;
        daysPL =
          position.Type == "DAY1"
            ? netPL
            : position.Type == "DAYNET"
            ? (
                position.daynet_realised -
                (closePrice - amount) * position.cf_quantity
              ).toFixed(2)
            : (
                position.realized_mtm -
                (closePrice - amount) * position.cf_quantity
              ).toFixed(2);
      }
      return key == "days" ? daysPL : netPL;
    },

    getTotalDaysAndNetPL(key) {
      let totalDaysPL = 0;
      let totalNetPL = 0;
      for (let position of this.filteredPositions) {
        totalDaysPL =
          totalDaysPL + parseFloat(this.getDaysAndNetPL("days", position));
        totalNetPL =
          parseFloat(totalNetPL) +
          parseFloat(this.getDaysAndNetPL("net", position));
      }
      return key == "days" ? totalDaysPL.toFixed(2) : totalNetPL.toFixed(2);
    },
    refreshPositions() {
      this.selectedPositions = [];
      this.selectAllValue = false;
      this.isExitPositionsModal = false;
      EventBus.$emit("refreshPositionsScreen");
    },

    showPlaceOrderForExitPositions() {
      this.showPlaceOrderDialog = true;
      this.isExitPositionsModal = true;
      this.strategyCreatorList = [];
      for (let item of this.selectedPositions) {
        this.strategyCreatorList.push({
          trading_symbol: item.trading_symbol,
          formatted_ins_name: item.formatted_ins_name,
          strike_price: item.strike_price,
          strike: item.strike_price,
          segment:
            item.param_list.segment == "PE"
              ? "p"
              : item.param_list.segment == "CE"
              ? "c"
              : "fut",
          name: item.symbol,
          exchange: item.exchange == "NSE" ? "NFO" : item.exchange,
          ltp: item.ltp,
          expiry: item.expiry_date,
          quantity: -item.net_quantity,
          code: item.code,
          prod_type: item.prod_type,
        });
      }
    },
  },
  mounted() {
    this.sortDefaultPositions(this.defaultPositionsList);
    this.createInstrumentList();
  },
};
</script>
<style lang="scss" scoped>
.default-position-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.default-position-wrapper:hover {
  overflow-y: auto !important;
}

.default-positions-data-table.v-data-table {
  width: 100%;
  tr {
    position: relative;
  }
  .add-exit-buttons {
    position: absolute;
    top: 0;
    left: 18%;
    padding: 0 16px;
    button {
      width: 68px;
      height: 30px;
      margin-right: 1rem;
      padding: 4px 16px;
      margin-top: 8px;
    }
  }
  td,
  th > span {
    font-size: 1em !important;
    color: var(--text-color);
  }
  td.days-pnl,td.net-pnl{
    width: 11%;
  }
  td.ltp-col{
    width: 9%;
  }

  table > tr > th {
    font-size: 1em !important;
    color: var(--text-color);
    background-color: var(--chip-background) !important;
    font-family: "Gilroy-medium";
    text-align: left;
    padding: 0 1em;
    font-weight: normal;
    white-space: nowrap;
    height: 40px !important;
  }
  tfoot{
    position: sticky;
    bottom:0;
    td{
      background-color:var(--cards-background-color);
      z-index: 2;
    }
  }
}

.v-input--selection-controls,
.v-application--is-ltr .v-input--selection-controls__input {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.instrument-filter {
  position: absolute;
  top: 1.5rem;
  left: 3rem;
  z-index: 1;
}

.product-type {
  padding: 4px 8px;
  border-radius: 4px;
}

.nrml {
  color: var(--option-strike-text);
  background-color: var(--option-strike-bg);
}

.nrml-disable {
  color: var(--disable-nrml-text);
  background-color: var(--option-strike-bg);
}
.mis {
  color: var(--mis-label-text);
  background-color: var(--mis-label-bg);
}

.mis-disable {
  color: var(--disable-mis-text);
  background-color: var(--mis-label-bg);
}
.mobile-checkbox.v-icon {
  font-size: 1em !important;
}
.alert-icons {
  .v-icon.v-icon {
    font-size: 1em;
    color: var(--red-text);
  }
  .na-position{
    max-height: 1.5em;
  }
}

.disabled-icon {
  .v-icon.v-icon {
    color: var(--disable-red-text) !important;
  }
}
.disable-row {
  background-color: var(--option-even-row) !important;
  td {
    border: none !important;
    color: var(--disabled-text) !important;
  }
}
.default-position-wrapper::-webkit-scrollbar,
.default-position-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px; /* set the width of the scrollbar */
}
.default-position-wrapper::-webkit-scrollbar-thumb,
.default-position-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc; /* set the color of the scrollbar thumb */
  border-radius: 5px; /* set the border radius of the scrollbar thumb */
}
.default-position-wrapper,
.default-position-wrapper {
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* set the color of the scrollbar track */
  }
}
// @media only screen and (max-width: 1500px) {
//   .default-position-wrapper {
//   }
// }
// @media only screen and (max-width: 1250px) {
//   .default-position-wrapper {
//   }
// }
@media only screen and (max-width: 600px) {
  .default-positions-data-table.v-data-table {
    td,
    th > span {
      font-size: 0.75em !important;
      height: 34px;
    }

    table > tr > th {
      font-size: 0.75em !important;
      height: 30px !important;
    }
  }

  .mobile-instrument-default-rows {
    font-size: 0.75em;
    color: var(--text-color);

    .nrml-mobile {
      color: var(--option-strike-text);
    }

    .nrml-mobile-disable {
      color: var(--disable-nrml-text);
    }

    .mis-mobile {
      color: var(--mis-label-text);
    }
    .mis-mobile-disable {
      color: var(--disable-mis-text);
    }
  }

  .total-pl-mobile {
    position: fixed;
    bottom: 3rem;
    width: 100%;
    left: 0;
    padding: 1rem;
    background-color: var(--cards-background-color);
    border: 1px solid var(--border-color);
    border-top: none;
    box-shadow: 0px 0px 8px rgba(132, 132, 132, 0.25);
  }
  .mobile-instrument-header {
    background-color: var(--chip-background);
    padding: 4px 20px 4px 8px;
    color: var(--sub-text-color);
    font-size: 0.75em;
  }

  .mobile-instrument-rows {
    padding: 4px 20px 4px 8px;
    color: var(--text-color);
    font-size: 0.75em;
  }
  .alert-icons {
    .v-icon.v-icon {
      font-size: 0.75em;
      color: var(--red-text);
    }
    .na-position{
      max-height: 1.25em;
    }
  }
}

@media only screen and (max-width: 400px) {
  .mobile-instrument-rows {
    font-size: 0.625em !important;
  }
  // .mobile-instrument-header {
  //   font-size: 0.625em !important;
  // }
}
</style>

<style lang="scss">
.filter-badge .v-badge__badge {
  font-size: 14px !important;
  height: 15px;
  min-width: 15px;
  padding: 3px 4px;
}
.calculated-height>div.v-data-table__wrapper{
  min-height: 300px;
  max-height: 700px;
  height:calc(100vh - 315px);
}
.funds-visible .calculated-height>div.v-data-table__wrapper{
  height:calc(100vh - 386px);
}
.add-exit-overlay.v-dialog {
  width: 100vw;
  position: absolute !important;
  bottom: 0px;
  margin: 0;
}
.add-exit-overlay.v-dialog > .v-card > .v-card__text {
  padding-top: 24px;
}
.mobile-checkbox .v-icon {
  font-size: 1.2em !important;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0 !important;
}

@media only screen and (max-width: 600px) {
  .mobile-checkbox.v-input--selection-controls .v-input__slot > .v-label {
    font-size: 0.875em !important;
    margin-left: 1rem !important;
  }

  .mobile-checkbox .v-icon {
    font-size: 1em !important;
  }
  .mobile-checkbox.v-application--is-ltr .v-input--selection-controls__input {
    font-size: 1em !important;
    margin-right: 1rem !important;
  }
  .filter-badge .v-badge__badge {
    font-size: 10px !important;
    height: 10px;
    min-width: 10px;
    padding: 1px 4px;
  }
}
</style>
