<template>
  <div class="container1 row1 flex-between" :id="`commonComp${_id}`">
    <div class="left-content">
        <h2 class="heading">{{info.title}}</h2>
        <img :src="require(`@/assets/${info.img}`)" alt="Position analytics image" class="thumbnail mobile-only">
        <ul>
            <li v-for="(el, index) in info.points" :key="index"><div class="dot"></div> {{el}}</li>
        </ul>
    </div>
    <img :src="require(`@/assets/${info.img}`)" alt="Position analytics image" class="thumbnail desktop-only">
  </div>
</template>

<script>
export default {
    props:['info','_id'],
    mounted(){
        if(this.info.alignment === 'right'){
            document.getElementById(`commonComp${this._id}`).style.flexDirection = 'row-reverse';
        }
    }
}
</script>

<style lang="scss" scoped>
.container1{
    margin-bottom: 88px;
    @media only screen and (max-width:980px) {
        flex-direction: column-reverse;
        margin-bottom: 63px;
    }
    .left-content{
        width: 564px;
         @media only screen and (max-width:980px) {
            width: 100%;
        }
        .heading{
            font-weight: 600;
            font-size: 40px;
            margin-bottom: 32px;
            @media only screen and (max-width:980px) {
                margin-bottom: 14px;
                font-size: 24px;
            }
            @media only screen and (max-width:580px) {
                font-size: 18px;
            }
        }
        ul{
            list-style: none;
            li{
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 20px;
                color: #888888;
                margin-bottom: 21px;
                @media only screen and (max-width:580px) {
                   font-size: 8px;
                   margin-bottom: 10px;
                }
                .dot{
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #888888;
                    margin-right: 20px;
                    @media only screen and (max-width:580px) {
                        width: 5px;
                        height: 5px;
                    }
                }

            }
        }
    }
    .thumbnail{
        width: 600px;
        border: 1px solid #E6E6E6;
        border-radius: 5px;
         @media only screen and (max-width:980px) {
            margin-left: 50%;
            transform: translateX(-50%);
            width: 100%;
            margin-bottom: 24px;
        }
    }
}

</style>