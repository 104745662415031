<template>
  <div class="modal-backdrop">
    <div class="modal">
      <v-card-title class="white--text modal__header">
        <v-row>
          <v-col class="modal-title">Cancel Order</v-col>
          <v-col cols="3"></v-col>
        </v-row>
      </v-card-title>

      <div class="modal__body">
        <div class="text-center mt-10" style="font-size: var(--font-16)">
          Are you sure you want to cancel the order for
          <b>{{ data[0].formatted_ins_name }}</b
          >?
        </div>
        <div class="modal-bottom mt-8">
          <div class="d-flex align-center modal-control">
            <v-progress-circular
              v-if="isLoading"
              :size="50"
              color="success"
              indeterminate
            ></v-progress-circular>
          </div>
          <div class="d-flex align-center modal-control">
            <v-btn class="modal__body_btn mr-3" text @click="close()">
              Cancel
            </v-btn>
            <v-btn
              :disabled="isDisabled"
              class="modal__body_btn modal__body_btn-confirm"
              @click="confirmOrder()"
            >
              Confirm
            </v-btn>
          </div>
        </div>
      </div>
      <footer class="modal__footer"></footer>
    </div>
    <SuccessModal v-show="isSuccessVisible" @close="closeModal">
      <template v-slot:header>
        <img width="300px" src="@/assets/cancelSuccess.png" />
      </template>

      <template v-slot:body>
        <div class="success-message">Your order has been cancelled.</div>
      </template>

      <template v-slot:footer>
        <button type="button" class="btn-blue" @click="closeModal">
          Check Order book
        </button>
      </template>
    </SuccessModal>
  </div>
</template>

<script>
import SuccessModal from "@/components/SuccessModal";
import axios from "axios";

export default {
  name: "CancelOrderModal",
  components: {
    SuccessModal,
  },
  props: {
    data: { type: Array },
  },
  mounted() {
    let instrument_list = this.data;
    if (instrument_list.length > 0) {
      this.product = instrument_list[0]["product"];
    } else {
      this.product = "NRML";
    }
    instrument_list.forEach((element, index) => {
      if (
        element["trading_symbol"] == "NIFTY" ||
        element["trading_symbol"] == "BANKNIFTY"
      ) {
        this.$set(element, "type", "MARKET");
      } else {
        this.$set(element, "type", "LIMIT");
      }
      this.$set(this.items, index, element);

      this.oms_order_id = element["Nstordno"];
      this.trading_symbol = element["Trsym"];
      this.exchange = element["Exchange"];
    });
    this.items.sort(this.compare_qty);
    // this.data = this.items
  },
  data() {
    return {
      listOfOrders: [],
      isSuccessVisible: false,
      typeOptions: [
        { text: "MKT", value: "MARKET" },
        { text: "LIMIT", value: "LIMIT" },
      ],
      items: [],
      isLoading: false,
      isDisabled: false,
      headers: [
        {
          text: "Trading Symbol",
          value: "trading_symbol",
        },
        {
          text: "quantity",
          value: "quantity",
        },
        { text: "trade", value: "trade" },
        { text: "type", value: "type" },
        { text: "ltp", value: "ltp" },
      ],
      marginRequired: 0,
      availableMargin: 0,
      product: "NRML",
      targetPrice: false,
      stoploss: false,
      oms_order_id: null,
    };
  },
  methods: {
    closeModal() {
      // this.$router.push({name: 'Position'})
      this.$emit("close");
      this.isDisabled = false;
      this.isSuccessVisible = false;
      this.isModalVisible = false;
    },
    async confirmOrder() {
      this.isDisabled = true;
      this.listOfOrders = [];
      let access_token = localStorage.getItem("accessTokenAnt");
      let user_id = localStorage.getItem("user_id");
      let cancelUrl =
        "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/placeOrder/cancelOrder";
      let header = { Authorization: "Bearer " + user_id + " " + access_token };
      let payload = {
        exch: this.exchange,
        nestOrderNumber: `${this.oms_order_id}`,
        trading_symbol: this.trading_symbol,
      };
      console.log(payload);
      debugger;
      // let scriptPayload = []
      axios.post(cancelUrl, payload, { headers: header }).then((res) => {
        if (res.data.stat == "Ok") {
          this.isSuccessVisible = true;
        } else {
          this.isSuccessVisible = false;
        }
      });
      this.items = [];
    },
    close() {
      this.isDisabled = false;
      this.items = [];
      this.$emit("close");
      this.isModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";
::v-deep .modal {
  &__header {
    background: #df514c;
    padding: 13px 40px 0px 40px !important;
    &_title {
      font-size: 20px;
      font-weight: 400;
    }
    .v-label {
      color: #fff !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
    .v-icon {
      color: #fff !important;
    }
  }
  &__body {
    padding: 13px 40px 24px 40px !important;
    &_btn {
      font-size: 14px;
      color: #979797;
      font-weight: 400;
      &-confirm {
        background-color: #0d4593 !important;
        padding: 0 24px !important;
        color: #fff;
      }
    }
    &_marginChip {
      font-size: 14px;
      background-color: #d1edff !important;
    }
    &_input {
      height: 24px;
      width: 92px;
      border: 1px solid #c5c5c5;
      border-radius: 4px;
      font-size: 12px;
      padding: 2px 4px;
    }
    &_input:focus {
      outline: none;
    }
    &_input::placeholder {
      color: #c5c5c5;
    }
    .v-input__slot {
      min-height: 24px !important;
      height: 24px;
      width: 110px;
      border: 1px solid #c5c5c5 !important;
      fieldset {
        border: none !important;
      }
    }
    .v-select__slot {
      .v-label {
        font-size: 12px;
        top: 2px;
      }
      .v-input__append-inner {
        margin-top: 0 !important;
        .v-icon {
          font-size: 18px;
        }
      }
      .v-select__selection {
        font-size: 12px;
      }
    }
    .v-data-table {
      box-shadow: none !important;
    }
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:not(:last-child)
      > td:not(.v-data-table__mobile-row),
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:not(:last-child)
      > th:not(.v-data-table__mobile-row) {
      border-bottom: none !important;
    }
    .text-start {
      padding: 5px 5px 5px 5px !important;
    }
  }
  &__footer {
    font-size: 12px;
    padding: 12px 40px 27px 40px !important;
    &_marginAmount {
      margin: 0 7px;
      color: #4aaf39;
    }
    &_marginError {
      color: #df514c;
    }
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  background: #df514c;
  border-radius: 8px 8px 0px 0px;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}
.btn-blue {
  width: 167.04px;
  height: 30px;
  background: #0d4593;
  border-radius: 4px;
  color: white;
  display: block;
  align-self: center;
}
.type-radio {
  opacity: 0.5;
}
.success-message {
  font-size: 20px;
  text-align: center;
}
</style>
