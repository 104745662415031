var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-wrapper"},[_c('div',[(_vm.showPlaceOrderDialog)?_c('PlaceOrderModal',{attrs:{"visible":_vm.showPlaceOrderDialog,"strategyCreator":_vm.strategyCreatorList,"selectedInstrument":_vm.selectedInstrument,"order_location":_vm.order_location},on:{"close":function($event){_vm.showPlaceOrderDialog = false}}}):_vm._e()],1),_c('div',{staticClass:"px-2",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"margin-benefit-label"},[_vm._v("Margin Benefit/Hedge Strategies")]),_c('button',{staticStyle:{"float":"right"},on:{"click":function($event){_vm.isVisible = !_vm.isVisible}}},[(!_vm.isVisible)?_c('i',{staticClass:"fa-solid fa-angle-down",staticStyle:{"font-size":"var(--font-24)"}}):_c('i',{staticClass:"fa-solid fa-angle-up",staticStyle:{"font-size":"var(--font-24)"}})])]),(!_vm.isVisible)?_c('div',{staticClass:"mx-2 my-1",staticStyle:{"color":"#b3b3b3"}},[_vm._v(" An opportunity to leverage assets ")]):_vm._e(),(_vm.isVisible)?_c('div',{staticStyle:{"max-width":"100%","position":"relative"}},[(_vm.isLoadingStrategies)?_c('div',{staticClass:"text-center"},[_vm._v("Loading...")]):(_vm.error && !_vm.isLoadingStrategies)?_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(_vm._s(_vm.error.tagline))]),(_vm.error.message)?_c('div',[_c('p',[_vm._v("Type: "+_vm._s(_vm.error.type))]),_c('p',[_vm._v("Message: "+_vm._s(_vm.error.message))])]):_vm._e()]):_c('div',{staticClass:"margin-benefit-wrapper mt-3 mx-sm-2"},[_c('div',{staticClass:"mb-inner-wrapper d-flex flex-column flex-sm-row",attrs:{"id":"scrollableMarginBenefitDiv"}},_vm._l((_vm.recomendedStrats),function(strategy,index){return _c('div',{key:index,staticClass:"mb-cards"},[_c('div',{staticClass:"white-card my-2 mx-auto mx-sm-2",staticStyle:{"max-width":"500px"}},[_c('p',{staticClass:"text-capitalize",style:({
                color:
                  strategy.title == 'Recommended'
                    ? 'var(--primary-button-color)'
                    : 'var(--text-color)',
              })},[_vm._v(" "+_vm._s(strategy.title)+" ")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"strategy-header"},[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Instrument")]),_c('th',{staticClass:"text-center"},[_vm._v("Qty")]),_c('th',{staticClass:"text-center"},[_vm._v("Price")])])]),_c('tbody',{staticStyle:{"font-size":"var(--font-14)"}},[_c('tr',[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(strategy.cards[0].formatted_ins_name)+" ")]),_c('td',{staticClass:"text-center",class:strategy.cards[0].quantity < 0
                          ? 'red-text'
                          : 'green-text'},[(strategy.cards[0].quantity > 0)?_c('span',[_vm._v("+")]):_vm._e(),(_vm.selectedInstrument.exchange=='MCX' || _vm.selectedInstrument.exchange=='CDS')?_c('span',[_vm._v(_vm._s(strategy.cards[0].quantity / _vm.selectedInstrument.lot_size)+" ")]):_c('span',[_vm._v(" "+_vm._s(strategy.cards[0].quantity)+" ")])]),_c('td',{staticClass:"text-center green-text"},[(_vm.selectedInstrument.name !== 'USDINR')?_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyWithDecimal")(strategy.cards[0].ltp))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("float")(strategy.cards[0].ltp,4))+" ")])])]),_c('tr',[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(strategy.cards[1].formatted_ins_name)+" ")]),_c('td',{staticClass:"text-center",class:strategy.cards[1].quantity < 0
                          ? 'red-text'
                          : 'green-text'},[(strategy.cards[1].quantity > 0)?_c('span',[_vm._v("+")]):_vm._e(),(_vm.selectedInstrument.exchange=='MCX' || _vm.selectedInstrument.exchange=='CDS')?_c('span',[_vm._v(_vm._s(strategy.cards[1].quantity / _vm.selectedInstrument.lot_size)+" ")]):_c('span',[_vm._v(" "+_vm._s(strategy.cards[1].quantity)+" ")])]),_c('td',{staticClass:"text-center green-text"},[(_vm.selectedInstrument.name !== 'USDINR')?_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyWithDecimal")(strategy.cards[1].ltp.toFixed(2)))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("float")(strategy.cards[1].ltp.toFixed(2),4))+" ")])])])])]},proxy:true}],null,true)}),_c('div',{staticClass:"d-flex mt-4"},[_c('span',{staticClass:"mr-1",staticStyle:{"font-size":"var(--font-16)"}},[_vm._v(_vm._s(_vm.vWidth > 900 ? "Max Profit: " : "M.P.: "))]),(
                  _vm.possibleStrToFloat(strategy.pl_data.profit) !== 'Unlimited'
                )?_c('span',{staticClass:"green-text",staticStyle:{"margin-right":"1rem"}},[_vm._v(" "+_vm._s(_vm._f("integerFormat")(parseFloat(strategy.pl_data.profit).toFixed(0)))+" ")]):_c('span',{staticClass:"green-text",staticStyle:{"margin-right":"1rem"}},[_vm._v(" "+_vm._s(_vm.possibleStrToFloat(strategy.pl_data.profit)))]),_c('span',{staticClass:"mr-1",staticStyle:{"font-size":"var(--font-16)"}},[_vm._v(_vm._s(_vm.vWidth > 900 ? "Max Loss: " : "M.L.: "))]),(
                  _vm.possibleStrToFloat(strategy.pl_data.loss) !== 'Unlimited'
                )?_c('span',{staticClass:"red-text"},[_vm._v(" "+_vm._s(_vm._f("integerFormat")(parseFloat(strategy.pl_data.loss).toFixed(0))))]):_c('span',{staticClass:"red-text"},[_vm._v(_vm._s(_vm.possibleStrToFloat(strategy.pl_data.loss))+" ")])]),_c('v-row',{staticClass:"justify-space-between mt-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-7 mt-2"},[_c('span',{staticStyle:{"background-color":"var(--chip-background)","padding":"5px"}},[(strategy.margin_data.margin == 'NA')?_c('span',{staticStyle:{"font-size":"var(--font-14)"}},[_vm._v("Margin: NA")]):_c('span',{staticStyle:{"font-size":"0.9em"}},[_vm._v("Margin: "+_vm._s(_vm._f("integerFormat")(strategy.margin_data.margin.toFixed(0))))]),(strategy.margin_data.mb == 'NA')?_c('span',{staticClass:"green-text"},[_vm._v(" (MB: NA) ")]):_c('span',{staticClass:"green-text",staticStyle:{"font-size":"var(--font-14)"}},[_c('span',[_vm._v(" (MB: "+_vm._s(_vm._f("currency")(strategy.margin_data.mb))+") ")])])])]),_c('v-col',{staticClass:"col-5 text-right d-flex justify-end"},[_c('div',{staticClass:"mt-2 mr-3"},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.darkMode
                        ? require('@/assets/LightMode/graph.png')
                        : require('@/assets/LightMode/graph.png'),"width":"30px"},on:{"click":function($event){return _vm.setCustomStrategyData(strategy)}}})]),_c('button',{staticClass:"primary-button",on:{"click":function($event){$event.stopPropagation();return _vm.showPlaceOrder(index)}}},[_vm._v(" Place Order ")])])],1)],1)])}),0)]),(!_vm.isLoadingStrategies && !_vm.$vuetify.breakpoint.xs)?_c('div',[(_vm.moveRight == 'right')?_c('v-icon',{staticClass:"left-arrow",on:{"click":function($event){return _vm.scrollToEnd('left')}}},[_vm._v("mdi-chevron-left")]):_vm._e(),(_vm.moveRight == 'left')?_c('v-icon',{staticClass:"right-arrow",on:{"click":function($event){return _vm.scrollToEnd('right')}}},[_vm._v("mdi-chevron-right")]):_vm._e()],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }