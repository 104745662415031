<template>
  <div class="option-chain-wrapper">
    <div
      class="white-card pa-3 px-5 my-0 mx-auto"
      :style="$vuetify.breakpoint.xs ? { 'min-height': '100vh' } : {}"
    >
      <!-- Top Section with filters -->
      <div>
        <div class="row no-gutters align-left my-1 my-sm-3">
          <div class="page-heading mb-2 mb-sm-0 col-12">
            <span>Research Calls</span>
          </div>
          <div class="col-12 page-content">
            Inuvest builds quant models and provide research calls. These calls
            are generated automatically by the models built by us. Visit
            <a
              style="color: var(--primary-color) !important"
              target="_blank"
              href="https://inuvest.tech"
            >
              inuvest.tech</a
            >
            to know more.
          </div>
        </div>
        <div class="row no-gutters justify-space-between my-2">
          <div>
            <v-menu offset-y max-height="300px" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  flat
                  outlined
                  class="download-btn strategy-btn"
                  >Strategy <v-icon>mdi-chevron-down</v-icon></v-btn
                >
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <v-checkbox
                      class="select-all-checkbox"
                      :input-value="filteredCubeIds.length == cubeList.length"
                      @click="toggleSelectAll()"
                      color="var(--primary-button-color)"
                      label="Select All"
                      hide-details
                    ></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-for="(item, index) in cubeList" :key="index">
                  <v-list-item-title>
                    <v-checkbox
                      class="filter-checkbox"
                      :input-value="filteredCubeIds.includes(item.id)"
                      @click="toggleSelection(item)"
                      color="var(--primary-button-color)"
                      :label="item.name"
                      hide-details
                    ></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <!-- Download Research Calls -->
          <div>
            <v-btn
              flat
              outlined
              class="download-btn"
              @click.stop="downloadResearchCalls()"
              ><v-icon>mdi-arrow-collapse-down</v-icon> Download</v-btn
            >
          </div>
        </div>
      </div>
      <div v-if="isLoading" style="height: 60vh; width: 100%">
        <SkeletonLoader />
      </div>

      <!-- Middle Table Section -->
      <div v-else class="tables-section mt-3 mt-sm-5">
        <!-- OPTION CHAIN TABLE -->
        <div
          v-if="pageCubeData.length == 0"
          class="d-flex justify-center flex-column no-positions align-center text-center"
        >
          <img
            src="@/assets/Positions/no-positions.svg"
            alt="NA"
            style="width: 40%"
          />
          <div class="mt-1">
            No Research Calls Available. Kindly change the strategy!
          </div>
        </div>

        <v-simple-table
          v-else
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :height="
            loadedAsPage
              ? $vuetify.breakpoint.xs
                ? '80vh'
                : '65vh'
              : $vuetify.breakpoint.xs
              ? '82vh'
              : '59vh'
          "
        >
          <template v-slot:default>
            <thead class="fixed-table-header">
              <tr class="table-header">
                <!-- Call Oi Header Tool-tip -->
                <th class="text-left">Strategy</th>
                <th class="text-left">Instrument</th>
                <th class="text-center">
                  Signal
                  <v-tooltip
                    content-class="header-tooltips"
                    top
                    color="var(--cards-background-color)"
                    max-width="300px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="info-icon">
                        <v-icon>mdi-information-variant-circle-outline</v-icon>
                      </span>
                    </template>
                    <span
                      >Entry long- Buy, Exit Long- Sell (only if the entry long
                      is taken), Entry Short- Sell, Exit Short- Buy (Only if
                      entry short is taken)</span
                    >
                  </v-tooltip>
                </th>
                <th class="text-center">Quantity</th>
                <th class="text-center">Price</th>
                <th class="text-center">Ltp</th>
                <th class="text-center">Signal Time</th>
                <th class="text-center">
                  Validity
                  <v-tooltip
                    content-class="header-tooltips"
                    top
                    color="var(--cards-background-color)"
                    max-width="300px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="info-icon">
                        <v-icon>mdi-information-variant-circle-outline</v-icon>
                      </span>
                    </template>
                    <span
                      >We recommend the signal to be executed before this
                      time</span
                    >
                  </v-tooltip>
                </th>
                <th class="text-center">Execution</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cube, index) in pageCubeData" :key="index">
                <td class="text-left">{{ cube.name }}</td>
                <td class="text-left">{{cube.exchange =='NSE'?cube.symbol: cube.formatted_ins_name }}</td>
                <td class="text-center">
                  {{ cube.signal !== "" ? cube.signal : "-" }}
                </td>
                <td class="text-center">{{ cube.quantity }}</td>
                <td class="text-center">{{ cube.price }}</td>
                <td class="text-center">
                  <span v-if="insSub(cube.token)">
                    <span
                      :class="
                        insSub(cube.token).ltp > 0 ? 'green-text' : 'red-text'
                      "
                    >
                      {{ insSub(cube.token).ltp.toFixed(2) }}
                    </span>
                  </span>
                  <span v-else>
                    <span :class="cube.price > 0 ? 'green-text' : 'red-text'">
                      {{ cube.price.toFixed(2) }}
                    </span>
                  </span>
                </td>
                <td class="text-center">{{ cube.signal_date | formatDate }}</td>
                <td class="text-center" v-if="cube.validity_date !== null">
                  {{ cube.validity_date | formatDate }}
                </td>
                <td class="text-center" v-else>-</td>
                <td class="text-center">
                  <button
                    class="primary-button"
                    :class="
                      cube.place_order_flag
                        ? 'primary-button'
                        : 'disabled-button'
                    "
                    @click.stop="showPlaceOrderForExitPositions(cube)"
                  >
                    Place Order
                  </button>
                </td>
              </tr>
            </tbody>
            <!-- <tfoot class="total-data">
              <tr>
                <td colspan="8">
                </td>
              </tr>
             
            </tfoot> -->
          </template>
        </v-simple-table>
        <div class="text-center">
          <v-container>
            <v-row justify="center">
              <v-col cols="12">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="pageCount"
                  @input="handleInput"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>

      <PlaceOrderModal
        v-if="showPlaceOrderDialog"
        :visible="showPlaceOrderDialog"
        @close="showPlaceOrderDialog = false"
        :strategyCreator="strategyCreatorList"
        :selectedInstrument="selectedInstrument"
        :isPositionGraph="true"
      ></PlaceOrderModal>
    </div>
    <div class="footer-content">
      Inuvest is a SEBi registered Reasearch Analyst (INH000012403). All the
      call provided here are based on it's models built using quantitative
      techniques. Trading in stocks or futures and options involves risk of
      loss. Any reference to past or potential performance is not, and should
      not be construed as a recommendation or a guarantee of any specific
      outcome or profit. Any action taken, the outcome of such action shall be
      the sole responsibility of the user. Inuvest nor any third-party would be
      responsible for any loss arising due to the actions taken by the user
      using this platform. By using the platform you agree to our
      <a
        style="color: var(--sub-text-color)"
        href="https://www.inuvest.tech/terms-of-service"
        >Terms and Conditions</a
      >
      and
      <a href="https://www.inuvest.tech/disclaimers"
        >Risk Disclosure Agreement.</a
      >
    </div>
  </div>
</template>
<script>
const XLSX = require("xlsx");
import { getCubesListUrl, getSingleCubeDataUrl } from "../getService";
import axios from "axios";
import PlaceOrderModal from "../modals/PlaceOrderModal.vue";
import SkeletonLoader from "../modals/SkeletonLoader.vue";
import UserDataMixin from "@/mixins/getUserDataMixin";
import WebsocketMethodsMixin from "@/mixins/websocketMethodsMixin";

export default {
  name: "ResearchCalls",
  mixins: [UserDataMixin, WebsocketMethodsMixin],
  components: {
    PlaceOrderModal,
    SkeletonLoader,
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        const date = new Date(value);
        const options = {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        return new Intl.DateTimeFormat("en-US", options).format(date);
      }
    },
  },
  computed: {
    insSub() {
      return (el) => {
        console.log(el);
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  data() {
    return {
      singleCubeData: [],
      pageCubeData: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 30,
      loadedAsPage: true,
      startIndex: 0,
      endIndex: 0,
      cubeList: [],
      filteredCubeIds: [],
      showPlaceOrderDialog: false,
      strategyCreatorList: [],
      selectedInstrument: null,
      isLoading: true,
    };
  },
  methods: {
    toggleSelection(item) {
      const index = this.filteredCubeIds.indexOf(item.id);
      this.isLoading = true;
      if (index > -1) {
        this.filteredCubeIds.splice(index, 1);
      } else {
        this.filteredCubeIds.push(item.id);
      }
      this.getSingleCubeData(this.filteredCubeIds);
    },

    toggleSelectAll() {
      this.isChecked = !this.isChecked;
      this.isLoading = true;
      if (this.isChecked) {
        this.filteredCubeIds = [];
      } else {
        this.filteredCubeIds = this.cubeList.map((item) => item.id);
      }
      this.getSingleCubeData(this.filteredCubeIds);
    },
    getsStartIndex() {
      return (this.page - 1) * this.itemsPerPage;
    },
    // Calculate the end index of the current page
    getEndIndex() {
      return this.startIndex + this.itemsPerPage;
    },
    // Get the data for the current page
    getCurrentPageData() {
      return this.singleCubeData.slice(this.startIndex, this.endIndex);
    },
    handleInput(val) {
      this.startIndex = this.getsStartIndex();
      this.endIndex = this.getEndIndex();
      this.pageCubeData = this.getCurrentPageData();
    },

    getCubesList() {
      axios.post(getCubesListUrl, {}).then((res) => {
        console.log(res);
        this.cubeList = res.data.data_list;
        // this.getSingleCubeData([this.cubeList[0].id]);
        this.getSingleCubeData(res.data.all_list);
        this.filteredCubeIds = res.data.all_list;
      });
    },
    getSingleCubeData(ids) {
      let payload = {
        id_list: ids,
      };
      axios.post(getSingleCubeDataUrl, payload).then((res) => {
        this.isLoading = false;
        console.log(res.data.data.length);
        this.singleCubeData = res.data.data;
        this.pageCount = Math.ceil(
          this.singleCubeData.length / this.itemsPerPage
        );
        this.startIndex = this.getsStartIndex();
        this.endIndex = this.getEndIndex();
        this.pageCubeData = this.getCurrentPageData();
        this.subscribeToLtpValues(this.pageCubeData);
      });
    },
    /**
     * @function subscribeToLtpValues to subscribe to all the LTPs
     */
    subscribeToLtpValues(positions) {
      const insList = [];
      for (let item of positions) {
        if (item.exchange == "NSE" || item.exchange == "NFO") {
          insList.push({ code: item.token, exchange: "NSE" });
          insList.push({ code: item.token, exchange: "NFO" });
        } else {
          insList.push({ code: item.token, exchange: item.exchange });
        }
      }
      insList.forEach((el) => (el["loc"] = this.$route.name));
      this.subscribeToInstruments(insList); //function form websocketMethodsMixin
    },
    showPlaceOrderForExitPositions(item) {
      console.log(item);
      this.showPlaceOrderDialog = true;
      this.strategyCreatorList = [];
      this.strategyCreatorList.push({
        trading_symbol: item.trading_symbol,
        formatted_ins_name: item.formatted_ins_name,
        strike_price: item.strike,
        strike: item.strike,
        segment: item.segment == "p" ? "p" : item.segment == "c" ? "c" : "fut",
        name: item.symbol,
        exchange: item.exchange == "NSE" ? "NFO" : item.exchange,
        ltp: item.price,
        expiry: item.expiry,
        quantity: item.quantity,
        code: item.token,
      });

      this.selectedInstrument = {
        name: item.symbol,
        exchange: item.exchange == "NSE" ? "NFO" : item.exchange,
        lot_size: item.lot_size,
        tick_size: item.tick_size,
        underlying_code: item.underlying_code,
        fut_code: item.fut_code,
      };
    },

    downloadResearchCalls() {
      const researchRows = [];
      this.singleCubeData.forEach((el, index) => {
        const ltp = this.insSub(el.token);
        researchRows.push({
          Strategy: el.name,
          Instrument: el.formatted_ins_name,
          Signal: el.signal,
          Quantity: el.quantity,
          Price: ltp,
          "Signal Time": el.signal_date,
          Validity: el.validity_date,
        });
      });

      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(researchRows);
      XLSX.utils.book_append_sheet(workBook, workSheet, "Research Calls");
      const excelBuffer = XLSX.write(workBook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
      const blobUrl = URL.createObjectURL(blob);

      let a = document.createElement("A");
      a.setAttribute("href", blobUrl);
      a.setAttribute("download", "Research-Calls" + ".xlsx");
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  },
  mounted() {
    this.getCubesList();
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global.scss";

.option-chain-wrapper {
  max-width: 2500px;
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  // padding: 0 1rem;
  color: var(--text-color);
  background-color: var(--cards-background-color);
}

.page-heading {
  font-size: var(--font-24);
  line-height: 28px;
  font-weight: 600;
}

.page-content {
  font-size: var(--font-20);
  color: var(--sub-text-color-2);
  margin-top: 8px;
}

.filter-checkbox {
  font-size: var(--font-16);
  color: var(--sub-text-color) !important;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 10px !important;
}
.footer-content {
  margin: 30px 0;
  font-size: var(--font-14);
  color: var(--sub-text-color);
}

.disabled-button {
  pointer-events: none;
  background-color: var(--plus-minus-background) !important;
  color: var(--sub-text-color) !important;
}
.tables-section {
  .table-header {
    background-color: var(--option-header-bg);

    th {
      background-color: var(--selected-row);
      color: var(--text-color) !important;
      font-size: var(--font-18);
      font-weight: 400;
    }

    th.tool-tip {
      cursor: pointer;
    }
  }

  thead.fixed-table-header {
    position: sticky;
    top: 0;
    z-index: 6;
  }

  tfoot.total-data {
    position: sticky;
    bottom: 0;
    z-index: 6;

    td {
      background-color: var(--cards-background-color) !important;
    }

    td.total-tag {
      font-size: 1.1em;
      letter-spacing: 0.1em;
    }
  }

  td,
  th {
    color: var(--text-color);
    padding: 8px 16px !important;
  }

  th {
    height: 46px !important;
    border-bottom: none !important;
    white-space: nowrap;
  }

  td {
    // background-color: white;
    border-bottom: none !important;
    height: 40px !important;
    color: var(--text-color) !important;
    font-size: var(--font-18) !important;
    font-weight: 400;
    text-wrap: nowrap;
  }

  tr:hover {
    td.call,
    td.put {
      background-color: var(--callput-row-hover-bg) !important;
    }
  }
}

.download-btn {
  border: 1px solid var(--border-color);
  color: var(--text-color);
  font-size: var(--font-16);
  border-radius: 8px;
  padding: 14px 12px;
  text-transform: capitalize;
}

.download-btn .v-icon {
  font-size: var(--font-18);
  margin-right: 10px;
}

.strategy-btn .v-icon {
  margin-right: 0;
  margin-left: 30px;
}

.no-positions {
  transform: translate(0, 45%);
}

@media screen and (min-width: 961px) and (max-width: 1850px) {
  .option-chain-wrapper {
    font-size: 1rem;
  }

  .page-heading {
    font-size: 1.6rem !important;
  }
}

@media screen and (max-width: 600px) {
  .page-heading {
    font-size: 1.25rem !important;
    line-height: 22px !important;
  }

  .page-content {
    font-size: var(--font-14);
    margin-top: 0;
  }

  .download-btn {
    font-size: var(--font-14);
  }

  .download-btn .v-icon {
    font-size: var(--font-16);
    margin-right: 10px;
  }

  .table-header {
    background-color: var(--option-header-bg);

    th {
      font-size: var(--font-14) !important;
      font-weight: 400;
    }

    th.tool-tip {
      cursor: pointer;
    }
  }

  .tables-section {
    td {
      font-size: var(--font-14) !important;
    }

    th {
      height: 38px !important;
    }
  }

  .footer-content {
    font-size: var(--font-14) !important;
    padding: 0 16px;
  }
}
</style>
