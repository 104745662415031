<template>
  <div class="white-card compare-strategy-wrapper" style="margin-top: 0.5rem">
    <v-row no-gutters class="compare-strategy-header">
      <v-col class="compare-strategy-label" cols="12" sm="4"
        >Compare Strategies</v-col
      >
      <v-col
        :style="$vuetify.breakpoint.xs ? 'text-align:left' : 'text-align:right'"
        class="d-flex flex-row-reverse justify-end flex-sm-row"
      >
        <button
          class="clear-all ml-2 mr-sm-2 primary-button"
          cols="12"
          sm="8"
          v-if="compareStrategyList.length > 0"
          @click="deleteAllRows()"
        >
          Clear All
        </button>
        <button
          class="compare-strategy-btn primary-button"
          color="var(--primary-button-color)"
          @click="showPrebuiltStrategyDialog()"
        >
          + Add Strategy
        </button>
      </v-col>
    </v-row>
    <div v-if="compareStrategyList.length != 0">
      <!-- Target Price Slider -->
      <v-row class="compare-row my-2" no-gutters>
        <v-col class="target-days-section" lg="7" xs="12">
          <div
            v-if="$vuetify.breakpoint.xs"
            class="d-flex justify-space-between my-2"
          >
            <span class="target-iv-label">
              Target Price
              <span class="target-iv-sublabel"> (-50% to 50%) </span>
            </span>
            <span>
              <input
                style="width: 90px; margin-left: 1rem"
                class="target-iv-input"
                :max="targetPrice + parseFloat(0.5 * targetPrice)"
                v-model="inputTargetPrice"
                type="text"
                placeholder="Enter Price"
                @input="clearTimeout($event)"
              />
            </span>
          </div>

          <v-slider
            v-model="targetPricePercent"
            hide-details
            color="var(--primary-button-color)"
            always-dirty
            :min="-50"
            step="0.1"
            :max="50"
            track-color="#e0e0e0"
            prepend-icon="mid-minus"
            @change="getTargetPrice()"
          >
            <!-- Slider prepend -->
            <template v-slot:prepend>
              <span class="target-iv-label" v-if="!$vuetify.breakpoint.xs">
                Target Price
                <span class="target-iv-sublabel"> (-50% to 50%) </span>
              </span>
              <v-icon
                color="var(--text-color)"
                @click="
                  targetPricePercent = targetPricePercent - 0.1;
                  getTargetPrice();
                "
                :disabled="targetPricePercent == -50 ? true : false"
              >
                mdi-minus
              </v-icon>
            </template>

            <!-- Slider Append -->
            <template v-slot:append>
              <v-icon
                color="var(--text-color)"
                class="ml-3 ml-sm-0"
                @click="
                  targetPricePercent = targetPricePercent + 0.1;
                  getTargetPrice();
                "
                :disabled="targetPricePercent == 50 ? true : false"
              >
                mdi-plus
              </v-icon>
              <span class="d-flex">
                <span style="position: relative">
                  <input
                    type="text"
                    class="target-iv-input"
                    style="width: 70px"
                    v-model="inputPricePercent"
                    @input="inputTargetPricePercent($event)"
                  />
                  <span
                    style="
                      position: absolute;
                      top: 3px;
                      right: 6px;
                      color: var(--text-color);
                    "
                    >%</span
                  >
                </span>
                <span v-if="!$vuetify.breakpoint.xs">
                  <input
                    style="width: 90px; margin-left: 1rem"
                    class="target-iv-input"
                    :max="targetPrice + parseFloat(0.5 * targetPrice)"
                    v-model="inputTargetPrice"
                    type="text"
                    placeholder="Enter Price"
                    @input="clearTimeout($event)"
                  />
                </span>
              </span>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row class="compare-row my-2 my-sm-2" no-gutters>
        <!-- Target Days Slider -->
        <v-col class="target-days-section" cols="12" lg="6" md="6" xs="12">
          <span class="target-iv-label" v-if="$vuetify.breakpoint.xs">
            Target Days
            <span class="target-iv-sublabel"> (0 to {{ targetDays }}) </span>
          </span>
          <v-slider
            v-model="selectedTargetDays"
            hide-details
            color="var(--primary-button-color)"
            always-dirty
            min="0"
            step="1"
            :max="targetDays"
            @change="getCustomStrategyApi('targetDays')"
            track-color="#e0e0e0"
            prepend-icon="mid-minus"
          >
            <!-- Slider prepend -->
            <template v-slot:prepend>
              <span class="target-iv-label" v-if="!$vuetify.breakpoint.xs">
                Target Days
                <span class="target-iv-sublabel">
                  (0 to {{ targetDays }})
                </span>
              </span>
              <v-icon
                color="var(--text-color) !important"
                @click="
                  selectedTargetDays = selectedTargetDays - 1;
                  getCustomStrategyApi('targetDays');
                "
                :disabled="selectedTargetDays == 0 ? true : false"
              >
                mdi-minus
              </v-icon>
            </template>

            <!-- Slider Append -->
            <template v-slot:append>
              <v-icon
                color="var(--text-color)"
                class="ml-3 ml-sm-0"
                @click="
                  selectedTargetDays = selectedTargetDays + 1;
                  getCustomStrategyApi('targetDays');
                "
                :disabled="selectedTargetDays == targetDays ? true : false"
              >
                mdi-plus
              </v-icon>
              <input
                class="target-iv-input"
                v-model="selectedTargetDays"
                :max="targetDays"
                @input="inputTargetDays($event)"
              />
            </template>
          </v-slider>
        </v-col>

        <!-- IV Percent Slider -->
        <v-col class="target-days-section" cols="12" lg="6" md="6" xs="12">
          <span class="target-iv-label" v-if="$vuetify.breakpoint.xs">
            IV Percent
            <span class="target-iv-sublabel"> (-50 to 50) </span>
          </span>
          <v-slider
            color="var(--primary-button-color)"
            always-dirty
            min="-50"
            step="1"
            max="50"
            hide-details
            v-model="perChange"
            track-color="#e0e0e0"
            prepend-icon="mid-minus"
            @change="changeIvPercent()"
          >
            <!-- Slider prepend -->
            <template v-slot:prepend>
              <span
                class="target-iv-label ml-md-2"
                v-if="!$vuetify.breakpoint.xs"
              >
                IV% Change
                <span class="target-iv-sublabel"> (-50 to 50) </span>
              </span>
              <v-icon
                color="var(--text-color)"
                @click="
                  perChange = perChange - 1;
                  changeIvPercent();
                "
                :disabled="perChange == -50 ? true : false"
              >
                mdi-minus
              </v-icon>
            </template>

            <!-- Slider Append -->
            <template v-slot:append>
              <v-icon
                :ripple="false"
                color="var(--text-color)"
                @click="
                  perChange = perChange + 1;
                  changeIvPercent();
                "
                class="ml-3 ml-sm-0"
                :disabled="perChange == 50 ? true : false"
              >
                mdi-plus
              </v-icon>
              <span style="position: relative">
                <input
                  class="target-iv-input"
                  v-model="ivInput"
                  :max="50"
                  @input="inputIvPercent($event)"
                />
                <span
                  style="
                    position: absolute;
                    top: 3px;
                    right: 6px;
                    color: var(--text-color);
                  "
                  >%</span
                >
              </span>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-data-table
        v-if="compareStrategyList.length != 0"
        class="compare-strategy-table"
        ref="dataTable"
        style="margin-top: 1.3rem"
        v-model="expanded"
        :items="compareStrategyList"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :hide-default-footer="true"
      >
        <template v-slot:header>
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header.text"
                :class="{ active: sortBy === header.value }"
              >
                <span style="margin-right: 0.8rem">{{ header.text }}</span>
                <span v-if="header.sortable != false" class="asc-desc-icons">
                  <v-icon style="top: -3px" @click="sort(header.value, false)"
                    >mdi-triangle-small-up</v-icon
                  >
                  <v-icon style="bottom: -3px" @click="sort(header.value, true)"
                    >mdi-triangle-small-down</v-icon
                  >
                </span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, index }">
          <tr>
            <td>
              <span>
                {{ compareStrategyList.indexOf(item) + 1 }}
              </span>
            </td>
            <td style="display: flex; align-items: center">
              <span v-if="item.name != 'Current Strategy'">{{
                item.name
              }}</span>
              <span
                @click="
                  showPlaceOrderDialog = true;
                  strategyCreatorList = item.payload.param_list;
                "
              >
                <v-icon
                  color="var(--text-color)"
                  class="ml-2"
                  style="opacity: 0.8; cursor: pointer"
                  >mdi-cart-arrow-down</v-icon
                >
              </span>
              <span class="d-flex"
                ><img
                  class="cart-graph-image"
                  @click="showGraphModal(item)"
                  :src="
                    !darkMode
                      ? require('@/assets/LightMode/graph.png')
                      : require('@/assets/DarkMode/graph.png')
                  "
                  alt=""
              /></span>
              <span @click="toggleExpand(item)" class="expand-collapse-icon">
                <img
                  v-if="!item.expand"
                  src="../../assets/comp-icons/expand.png"
                />
                <img
                  v-if="item.expand"
                  src="../../assets/comp-icons/collapse.png"
                />
              </span>
            </td>
            <td>
              <span v-if="item.targetedPL > 0" class="green-text">+</span>
              <span :class="item.targetedPL < 0 ? 'red-text' : 'green-text'">{{
                item.targetedPL.toFixed(2)
              }}</span>
              <span
                style="font-size: var(--font-12)"
                :class="item.targetedPL < 0 ? 'red-text' : 'green-text'"
              >
                (
                <span v-if="item.targetedPL > 0" class="green-text">+</span>
                <span>{{
                  item.netMargin != 0
                    ? (item.targetedPL / item.netMargin).toFixed(2)
                    : 0
                }}</span>
                <span>%)</span></span
              >
            </td>
            <td>
              <span v-if="item.expectedProfit == 'Unlimited'">Unlimited</span>
              <span v-else>{{ item.expectedProfit | integerFormat }}</span>
            </td>
            <td>
              <span v-if="item.expectedLoss == 'Unlimited'">Unlimited</span>
              <span v-else>{{ item.expectedLoss | integerFormat }}</span>
            </td>
            <td>{{ item.netMargin | integerFormat }}</td>
            <td>
              {{ item.return }} <span v-if="item.return != 'Unlimited'">%</span>
            </td>
            <td>{{ item.risk_reward }}</td>
            <td>{{ item.probability }}%</td>
            <td>
              <v-icon
                small
                slot="prepend"
                color="var(--text-color)"
                @click="deleteCompareStrategyRow(index)"
              >
                mdi-trash-can
              </v-icon>
            </td>
          </tr>
          <tr>
            <td v-if="item.expand"></td>
            <td :colspan="headers.length - 1" v-if="item.expand">
              <v-simple-table class="instrument-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Qty</th>
                      <th>LTP</th>
                      <th>IV</th>
                      <th>Delta</th>
                      <th>Theta</th>
                      <th>Gamma</th>
                      <th>Vega</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(greek, idx) in item.greeks" :key="idx">
                      <td>{{ greek.formatted_ins_name }}</td>
                      <td
                        v-if="item.strategyData.instrument[idx].quantity < 0"
                        style="color: var(--red-text) !important"
                      >
                        {{ item.strategyData.instrument[idx].quantity }}
                      </td>
                      <td v-else style="color: #31bc00 !important">
                        +{{ item.strategyData.instrument[idx].quantity }}
                      </td>

                      <td>
                        {{ item.strategyData.instrument[idx].ltp }}
                      </td>
                      <td>{{ greek.iv }}</td>
                      <td>{{ greek.delta }}</td>
                      <td>{{ greek.theta }}</td>
                      <td>{{ greek.gamma }}</td>
                      <td>{{ greek.vega }}</td>
                    </tr>
                    <tr>
                      <td style="font-family: 'Gilroy-SemiBold'">Total</td>
                      <td></td>
                      <td></td>
                      <td>{{ item.total.iv }}</td>
                      <td>{{ item.total.delta }}</td>
                      <td>{{ item.total.theta }}</td>
                      <td>{{ item.total.gamma }}</td>
                      <td>{{ item.total.vega }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
        </template>
      </v-data-table>
      <PlaceOrderModal
        v-if="showPlaceOrderDialog"
        :visible="showPlaceOrderDialog"
        @close="showPlaceOrderDialog = false"
        :strategyCreator="strategyCreatorList"
        :selectedInstrument="selectedInstrument"
        :selectedDirection="selectedTab"
      ></PlaceOrderModal>
      <GraphAndDaysModal
        v-if="showGraph"
        :visible="showGraph"
        :strategyData="strategyData"
        :selectedInstrument="selectedInstrument"
        @close="showGraph = false"
        :targetDays="targetDays"
      />
    </div>
    <div v-else class="text-center">
      <img
        class="compare-blank"
        :src="
          !darkMode
            ? require('@/assets/LightMode/compare-blank.svg')
            : require('@/assets/DarkMode/compare-blank.svg')
        "
      />
    </div>

    <PrebuiltStrategy
      v-if="isPrebuiltStrategy"
      :isPrebuiltStrategyDialog="true"
      :dialogOpen="isPrebuiltStrategy"
      @close="isPrebuiltStrategy = false"
      :selectedTab="selectedTab"
      :selectedExpiry="selectedExpiry"
      :prebuiltStrategyData="prebuiltStrategyData"
      :selectedInstrument="selectedInstrument"
    />
  </div>
</template>
<script>
import { EventBus } from "../../main.js";
import { calculateTargetDays } from "./dashboardService";
import { getCustomStrategyUrl } from "../getService";
import PlaceOrderModal from "../modals/PlaceOrderModal.vue";
import GraphAndDaysModal from "../modals/GraphAndDaysModal.vue";
import PrebuiltStrategy from "./PrebuiltStrategy.vue";
import axios from "axios";
export default {
  name: "CompareStrategy",
  components: {
    PlaceOrderModal,
    GraphAndDaysModal,
    PrebuiltStrategy,
  },
  created() {
    EventBus.$on("addToCompareStrategiesFromSaved", (value) => {
      this.fillCompareStrategyData(value, "saved");
    });
    EventBus.$on("addToCompareStrategiesFromCreator", (value) => {
      this.fillCompareStrategyData(value, "creator");
    });
  },
  props: {
    selectedExpiry: String,
    selectedTab: String,
    prebuiltStrategyData: [],
    selectedInstrument: null,
  },
  data: () => ({
    showPlaceOrderDialog: false,
    showGraph: false,
    expanded: [],
    singleExpand: false,
    compareStrategyList: [],
    strategyCreatorList: [],
    targetDays: 0,
    perChange: 0,
    targetIvId: null,
    selectedTargetDays: 0,
    targetDaysTimerId: null,
    targetPriceId: null,
    greeksTotal: null,
    riskRewardRatio: "",
    plData: null,
    probability: 0,
    strategyData: null,
    count: 0,
    targetPrice: 0,
    creatorCount: 0,
    timerId: null,
    plTargeted: [],
    headers: [
      {
        text: "#",
        value: "serialNumber",
        sortable: false,
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Targeted Profit/Loss",
        value: "targetedPL",
      },
      {
        text: "Expiry Profit",
        value: "expectedProfit",
      },
      {
        text: "Expiry Loss",
        value: "expectedLoss",
      },
      {
        text: "Net Margin",
        value: "netMargin",
      },
      {
        text: "Return",
        value: "return",
      },
      {
        text: "RR",
        value: "risk_reward",
      },
      {
        text: "PoP",
        value: "probability",
      },
      {
        text: "",
        value: "delete",
        sortable: false,
      },
    ],
    // selectedInstrument: null,
    targetPricePercent: 0,
    inputPricePercent: 0,
    // selectedTab: "",
    sortBy: "",
    ivInput: 0,
    sortDesc: false,
    breakEvenPrice: "",
    inputTargetPrice: 0,
    isMobile: false,
    isPrebuiltStrategy: false,
  }),
  methods: {
    scrollToStrategyCreator() {
      this.$parent.$refs.targetComponent.scrollIntoView();
    },
    sort(value, isDesc) {
      if (this.sortBy === value) {
        this.sortDesc = isDesc;
      } else {
        this.sortBy = value;
        this.sortDesc = isDesc;
      }
    },
    toggleExpand(item) {
      item.expand = !item.expand;
    },
    deleteCompareStrategyRow(index) {
      if (
        this.compareStrategyList[index].name.split(" ")[0] +
          " " +
          this.compareStrategyList[index].name.split(" ")[1] ==
        "Current Strategy"
      ) {
        this.creatorCount = this.creatorCount - 1;
      }
      this.compareStrategyList.splice(index, 1);
    },
    deleteAllRows() {
      this.compareStrategyList.splice(0);
    },
    fillCompareStrategyData(strategy, change) {
      this.selectedInstrument = strategy.selectedInstrument;
      this.selectedTab = strategy.selectedTab;
      this.targetDays = calculateTargetDays(strategy.payload.param_list);
      this.plTargeted = strategy.data.data.pl_data.x.map((num) =>
        parseFloat(num.toFixed(2))
      );
      let plIndex = this.findIndexWithMinDiff(
        this.plTargeted,
        strategy.payload.spot_price.toFixed(2)
      );
      if (plIndex == this.plTargeted.length) {
        plIndex -= 1;
      }
      this.inputTargetPrice = this.plTargeted[plIndex];
      this.inputPricePercent = 0;
      this.targetPricePercent = 0;
      if (change == "creator") {
        this.creatorCount = this.creatorCount + 1;
      }
      this.compareStrategyList.push({
        name:
          change != "creator"
            ? strategy.name
            : !this.isPrebuiltStrategy
            ? "Current Strategy " + this.creatorCount.toString()
            : strategy.name,
        targetedPL: strategy.data.data.pl_targeted[plIndex],
        expectedProfit:
          strategy.data.data.pl_data.profit == "Unlimited"
            ? "Unlimited"
            : parseInt(strategy.data.data.pl_data.profit),
        expectedLoss:
          strategy.data.data.pl_data.loss == "Unlimited"
            ? "Unlimited"
            : parseInt(strategy.data.data.pl_data.loss),
        netMargin: parseInt(strategy.data.data.margin_data.margin),
        probability: strategy.data.pop,
        risk_reward: this.getRiskRewardRatio(
          strategy.data.data.pl_data.profit,
          strategy.data.data.pl_data.loss
        ),
        return: this.getReturn(
          strategy.data.data.pl_data.profit,
          strategy.data.data.margin_data.margin
        ),
        greeks: strategy.data.greeks,
        total: strategy.data.total,
        expand: false,
        payload: strategy.payload,
        strategyData: strategy.data,
      });
      this.$set(this.compareStrategyList, this.compareStrategyList);
      this.targetPrice = parseFloat(
        this.compareStrategyList[0].payload.spot_price
      );
      // this.inputTargetPrice = this.targetPrice;
      // this.getTargetPricePercent();
      setTimeout(() => {
        this.handleResize();
      }, 100);
    },
    findIndexWithMinDiff(arr, value) {
      let left = 0;
      let right = arr.length - 1;
      let mid;

      while (left <= right) {
        mid = Math.floor((left + right) / 2);

        if (Math.abs(arr[mid]) === value) {
          return mid;
        } else if (Math.abs(arr[mid]) < value) {
          left = mid + 1;
        } else {
          right = mid - 1;
        }
      }

      // at this point, the value isn't in the array, so find the closest index
      if (arr[mid] < value) {
        return mid + 1;
      } else if (arr[mid] > value && mid > 0) {
        const diff1 = value - arr[mid - 1];
        const diff2 = arr[mid] - value;
        return diff1 < diff2 ? mid - 1 : mid;
      } else {
        return mid;
      }
    },
    getTargetPricePercent() {
      this.targetPricePercent =
        ((this.inputTargetPrice - this.targetPrice) / this.targetPrice) * 100;

      this.inputPricePercent = this.targetPricePercent.toFixed(1);
    },
    getReturn(maxProfit, margin) {
      if (maxProfit == "Unlimited") {
        return "Unlimited";
      } else {
        return ((maxProfit / margin) * 100).toFixed(1);
      }
    },
    getRiskRewardRatio(profit, loss) {
      if (
        profit === "Undefined" ||
        profit === "Unlimited" ||
        loss === "Undefined" ||
        loss === "Unlimited"
      ) {
        return "Unlimited";
      } else {
        let risk = parseFloat(profit / Math.abs(loss)).toFixed(1);
        if (parseFloat(risk) >= 1) {
          return risk.toString() + " : 1";
        } else {
          return risk.toString() + " : 1";
        }
      }
    },
    inputTargetDays(event) {
      if (this.targetDaysTimerId) {
        clearTimeout(this.targetDaysTimerId);
      }
      if (this.selectedTargetDays == "") {
        return;
      } else {
        this.selectedTargetDays = event.target.value;
        this.targetDaysTimerId = setTimeout(() => {
          this.getCustomStrategyApi("targetDays");
        }, 500);
      }
    },
    changeIvPercent() {
      this.ivInput = this.perChange;
      this.getCustomStrategyApi("ivPercent");
    },
    inputIvPercent(event) {
      if (this.targetIvId) {
        clearTimeout(this.targetIvId);
      }
      let value = event.target.value.toString().replace(/[^-?\d]/g, "");
      this.ivInput = value;
      this.targetIvId = setTimeout(() => {
        if (value < -50) {
          this.ivInput = -50;
        } else if (value > 50) {
          this.ivInput = 50;
        } else if (value == "" || value == "-") {
          this.ivInput = 0;
        }
        this.perChange = parseInt(this.ivInput);
        this.changeIvPercent();
      }, 1000);
    },
    inputTargetPricePercent(event) {
      let value = event.target.value.toString().replace(/[^-?\d.]/g, "");
      this.inputPricePercent = value;
      if (this.targetPriceId) {
        clearTimeout(this.targetPriceId);
      }
      this.targetPriceId = setTimeout(() => {
        this.inputPricePercent = parseFloat(this.inputPricePercent).toFixed(1);
        if (value < -50) {
          this.inputPricePercent = -50;
        } else if (value > 50) {
          this.inputPricePercent = 50;
        } else if (value == "" || value == "-") {
          this.inputPricePercent = 0;
        }
        this.targetPricePercent = this.inputPricePercent;
        this.inputTargetPrice = (
          this.targetPrice +
          (parseFloat(this.targetPricePercent) * this.targetPrice) / 100
        ).toFixed(1);
        if (
          this.inputTargetPrice > this.plTargeted[this.plTargeted.length - 1]
        ) {
          this.inputTargetPrice = this.plTargeted[this.plTargeted.length - 1];
        } else if (this.inputTargetPrice < this.plTargeted[0]) {
          this.inputTargetPrice = this.plTargeted[0];
        }
        this.getCustomStrategyApi("targetPrice");
      }, 1000);
    },
    getTargetPrice() {
      this.inputPricePercent = this.targetPricePercent;
      console.log(typeof this.targetPrice, this.targetPrice);
      console.log(typeof this.targetPricePercent, this.targetPricePercent);
      this.inputTargetPrice = (
        this.targetPrice +
        (parseFloat(this.targetPricePercent) * this.targetPrice) / 100
      ).toFixed(1);
      setTimeout(() => {
        this.getCustomStrategyApi("targetPrice");
      }, 1000);
    },
    clearTimeout(event) {
      let value = event.target.value.toString().replace(/[^\d.]/g, "");
      if (value.indexOf(".") !== value.lastIndexOf(".")) {
        value = value.substring(0, value.length - 1);
      }
      this.inputTargetPrice = value;
      let maxPrice = this.targetPrice + parseFloat(0.5 * this.targetPrice);
      let minPrice = this.targetPrice + parseFloat(-0.5 * this.targetPrice);
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        if (this.inputTargetPrice > maxPrice) {
          this.inputTargetPrice = maxPrice.toFixed(2);
        } else if (this.inputTargetPrice < minPrice) {
          this.inputTargetPrice = minPrice.toFixed(2);
        }
        if (this.inputTargetPrice == "") {
          this.inputTargetPrice = this.targetPrice;
        }
        this.getTargetPricePercent();
        this.getCustomStrategyApi("targetPrice");
      }, 1500);
    },
    getCustomStrategyApi(change) {
      for (let i = 0; i < this.compareStrategyList.length; i++) {
        if (change == "targetDays") {
          this.compareStrategyList[i].payload.target_days =
            this.selectedTargetDays;
        } else if (change == "ivPercent") {
          this.compareStrategyList[i].payload.iv_percent = this.perChange;
        } else if (change == "targetPrice") {
          this.compareStrategyList[i].payload.spot_price =
            this.inputTargetPrice;
        }
        axios
          .post(getCustomStrategyUrl, this.compareStrategyList[i].payload, {
            headers: {
              "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3",
            },
          })
          .then((res) => {
            let plTargeted = res.data.data.pl_data.x.map((num) =>
              parseFloat(num.toFixed(2))
            );
            let plIndex = this.findIndexWithMinDiff(
              plTargeted,
              parseFloat(this.inputTargetPrice).toFixed(2)
            );
            if (plIndex == plTargeted.length) {
              plIndex -= 1;
            }
            this.inputTargetPrice = plTargeted[plIndex];
            this.$set(
              this.compareStrategyList[i],
              "targetedPL",
              res.data.data.pl_targeted[plIndex]
            );
            if (res.data.data.pl_data.profit == "Unlimited") {
              this.$set(
                this.compareStrategyList[i],
                "expectedProfit",
                "Unlimited"
              );
            } else {
              this.$set(
                this.compareStrategyList[i],
                "expectedProfit",
                parseInt(res.data.data.pl_data.profit)
              );
            }
            if (res.data.data.pl_data.loss == "Unlimited") {
              this.$set(
                this.compareStrategyList[i],
                "expectedLoss",
                "Unlimited"
              );
            } else {
              this.$set(
                this.compareStrategyList[i],
                "expectedLoss",
                parseInt(res.data.data.pl_data.loss)
              );
            }
            this.$set(
              this.compareStrategyList[i],
              "netMargin",
              parseInt(res.data.data.margin_data.margin)
            );
            this.$set(this.compareStrategyList[i], "pop", res.data.pop);
            this.$set(
              this.compareStrategyList[i],
              "risk_reward",
              this.getRiskRewardRatio(
                res.data.data.pl_data.profit,
                res.data.data.pl_data.loss
              )
            );
            this.$set(this.compareStrategyList[i], "greeks", res.data.greeks);
            this.$set(this.compareStrategyList[i], "total", res.data.total);
            this.$set(this.compareStrategyList[i], "strategyData", res.data);
          });
      }
    },
    showGraphModal(strategy) {
      this.showGraph = true;
      this.strategyData = {
        strategyData: strategy.strategyData,
        payload: strategy.payload,
      };
    },
    handleResize() {
      if (this.compareStrategyList.length !== 0) {
        if (window.innerWidth <= 600) {
          this.isMobile = true;
          document
            .getElementsByClassName("compare-strategy-table")[0]
            .classList.remove("v-data-table--mobile");
        } else if (window.innerWidth > 600) {
          this.isMobile = false;
          document
            .getElementsByClassName("compare-strategy-table")[0]
            .classList.remove("v-data-table--mobile");
        }
      }
    },

    showPrebuiltStrategyDialog() {
      this.isPrebuiltStrategy = true;
    },
    // beforeDestroy() {
    //   window.removeEventListener("resize", this.handleResize);
    // },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global.scss";
.compare-strategy-wrapper {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;

  .compare-blank {
    width: 25vh;
    height: 200px;
    margin: 2rem;
  }
  .compare-strategy-label {
    font-family: "Gilroy-semibold";
    font-size: var(--font-24);
  }
  .compare-row {
    margin-top: -1.5rem;
    align-items: baseline;
    .target-input {
      border: 1px solid var(--border-color);
      padding: 8px;
      margin: 2px 5px;
      border-radius: 4px;
      border: 1px solid var(--cards-background-color);
      color: var(--text-color);
      max-width: 145px;
      width: 90%;
    }

    .target-input:focus {
      outline: 1px solid var(--border-color);
    }
  }
  .compare-strategy-header {
    .clear-all {
      background-color: var(--app-background-color);
      color: var(--text-color);
      border-radius: 4px;
    }
    .clear-all,
    .primary-button {
      padding: 6px 12px;
      white-space: nowrap;
    }
  }
  .target-days-section {
    color: var(--text-color);
    margin: 0.5rem 0;

    .target-iv-label {
      width: 250px;
      padding-top: 4px;
      color: var(--text-color);
      font-size: var(--font-16);
      .target-iv-sublabel {
        color: #575757;
        font-size: var(--font-14);
        margin-left: 8px;
      }
    }
  }

  .expand-collapse-icon {
    display: flex;
    img {
      margin-left: 10px;
      width: 15px;
      height: 25px;
      cursor: pointer;
    }
  }

  .asc-desc-icons {
    position: relative;
    margin-left: 4px;
    .v-icon {
      position: absolute;
      width: 10px;
      height: 10px;
    }

    .v-icon.v-icon {
      color: var(--text-color) !important;
    }
  }
  .cart-graph-image {
    // width: 25px;
    height: 20px;
    margin-left: 10px;
    cursor: pointer;
  }

  thead {
    background-color: var(--chip-background);
    height: 39px;
  }
  th,
  td {
    font-family: "Gilroy-Medium";
    font-size: var(--font-14) !important;
    color: var(--text-color) !important;
    height: 39px !important;
    font-weight: 400;
  }
  td {
    height: 50px !important;
  }

  .instrument-table {
    thead th {
      background-color: var(--chip-background) !important;
    }
    th {
      font-family: "Gilroy-SemiBold";
      border: none !important;
      color: var(--text-color);
    }
    td {
      color: #575757 !important;
      height: 40px !important;
      border: none !important;
    }
    tbody tr:last-child td {
      border-top: 1px solid #0000001f !important;
      border-bottom: 1px solid #0000001f !important;
    }
  }
}
.compare-strategy-wrapper {
  font-size: var(--font-16);
}

@media screen and(max-width:960px) {
  .compare-strategy-wrapper .target-days-section .target-iv-label {
    font-size: var(--font-14) !important;
  }

  .compare-strategy-wrapper {
    th,
    td {
      font-size: var(--font-12) !important;
    }
  }
}
@media screen and(max-width:600px) {
  .compare-strategy-wrapper .target-days-section .target-iv-label {
    font-size: 0.875em !important;
  }
}
</style>
<style lang="scss">
.compare-strategy-wrapper {
  table {
    min-width: 1080px !important;
  }
}
</style>
