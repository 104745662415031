export default{
  computed:{
    liveLtp() {
      return (el) => {
        const ins = this.insSub(el);
        return ins ? ins.ltp : null;
      };
    },
    liveChange() {
      return (el) => {
        const ins = this.insSub(el);
        return ins ? ins.Change : null;
      };
    },
    liveOI() {
      return (el) => {
        const ins = this.insSub(el);
        return ins ? ins.oi : null;
      };
    },
    liveVolume() {
      return (el) => {
        const ins = this.insSub(el);
        return ins ? ins.volume : null;
      };
    },
    liveBuyBid() {
      return (el) => {
        const ins = this.insSub(el);
        return ins ? ins.bid : null;
      };
    },
    liveSellBid() {
      return (el) => {
        const ins = this.insSub(el);
        return ins ? ins.ask : null;
      };
    },
    lowerCircuit() {
      return (el) => {
        const ins = this.insSub(el);
        return ins ? ins.lowerCircuit : null;
      };
    },
    upperCircuit() {
      return (el) => {
        const ins = this.insSub(el);
        return ins ? ins.upperCircuit : null;
      };
    },
    insSub() {
      return (el) => {
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    compactInsSub(){
      return (el) => {
        let data = this.compactMarketDataScripts(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
  }
}