import { mapGetters } from 'vuex';
export default {
  // ...
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      isAnonymous: "isAnonymous",
      userId: "getUserId",
      wsSession: "getWsSession",
      accessToken: "getAccessToken",
      broker: "getBroker",
      getUser:"getUser",
    })
  }
  // eqvilant to :
  // computed: {
  //   userId() {
  //     return this.$store.getters.getUserId;
  //   }
  // }
}