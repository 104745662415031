export default {
  currency(value) {
    if (value >= 10000) {
      return `${(value / 100000).toFixed(2)}L`;
    } else {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        currencyDisplay: "symbol",
      })
        .format(value)
        .replace("₹", "")
        .trim();
    }
  },
  currencyWithDecimal(value) {
    if (value >= 100000) {
      return `${(value / 100000).toFixed(2)}L`;
    } else {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        currencyDisplay: "symbol",
      })
        .format(value)
        .replace("₹", "")
        .trim();
    }
  },
  currencyWithoutFormat(value) {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      currencyDisplay: "symbol",
    })
      .format(value)
      .replace("₹", "")
      .trim();
  },
  integerFormat(value) {
    if(!value) value=0;
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      currencyDisplay: "symbol",
    })
      .format(value)
      .replace("₹", "")
      .replace(/\.[0-9]+/, "")
      .trim();
  },
  float(value, decimals) {
    const val = parseFloat(value);
    if (val || val == 0) return val.toFixed(decimals);
    else return value;
  },
};
