<template>
  <div>
    <div style="position: relative" class="verified-pl-wrapper">
      <div v-if="error">
      {{ error.message }}
      </div>
      <div class="data-container-row row no-gutters default-style justify-space-between">
        <!-- P/L section -->
        <div class="col-12 col-md-9 pr-0 pr-md-3">

          <div style="height: 80vh; width: 100%" v-if="loading">
            <SkeletonLoader/>
          </div>
          <v-card v-else rounded='lg' class="py-3 px-3 pnl-data-section">
            <!-- Header -->
            <div class="verifiedpl-heading px-0 px-sm-1 mb-2 mb-sm-5 row no-gutters justify-space-between">
              <div class="col-12 col-md-auto">
                <div>
                  <v-icon small color="var(--primary-button-color)" class="mr-2">mdi-check-circle</v-icon>
                  <span @click="print(portfolioData)">P&L verified by 1LY Options</span>
                </div>
                <div class="sub-heading" v-if="createdOn">
                  on 
                  {{ createdOn.toDateString() }}, 
                  {{ createdOn.toLocaleTimeString('en-US',{hour12:true,hour:'numeric',minute:'numeric'}) }}
                </div>
              </div>
              <div class="col header-info">
                <div class="row no-gutters">
                  <div class="col px-3">
                    <div class="text-center sub-heading">Realized P&L</div>
                    <div class="text-center" :class="realisedPL>0?'green-text':'red-text'">
                      {{(realisedPL>0?'+':'')+realisedPL | integerFormat}}
                    </div>
                  </div>
                  <div class="col px-3">
                    <div class="text-center sub-heading">Unrealized P&L</div>
                    <div class="text-center" :class="unrealisedPL>0?'green-text':'red-text'">
                      {{(unrealisedPL>0?'+':'')+unrealisedPL | integerFormat}}
                    </div>
                  </div>
                  <div class="col px-3">
                    <div class="text-center sub-heading">Total P&L</div>
                    <div class="text-center" :class="totalPL>0?'green-text':'red-text'">
                      {{(totalPL>0?'+':'')+totalPL | integerFormat}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="instruments-data styled-scroll">

              <!-- Visibile in mobile -->
              <div v-if="xsScreen" class="mobile-view-positions">
                <div 
                  v-for="pos in filteredPositions" 
                  :key="pos.trading_symbol+pos.prod_type" 
                  class="pt-3"
                  :class="{'closed-instrument':pos.net_qty==0 && !allClosed}"
                >
                  <div class="row no-gutters justify-space-between">
                    <div class="col">
                      {{ pos.formatted_ins_name }}
                    </div>
                    <div class="col col-4">
                      <span>Net P&L: </span>
                      <span :class="pos.netPL>0?'green-text':'red-text'">
                        {{ pos.netPL | currencyWithDecimal  }}
                      </span>
                    </div>
                  </div>
                  <div class="row no-gutters">
                    <div class="col">
                      <span 
                        class="product-type"
                        :class="pos.prod_type == 'NRML' ? 'nrml' : 'mis'"
                      >
                        {{ pos.prod_type }}
                    </span>
                    </div>
                    <div class="col">
                      <span> Qty: </span>
                      <span :class="pos.net_qty>0?'green-text':'red-text'">
                        {{ pos.net_qty }}</span>
                    </div>
                    <div class="col">
                      <span>Days P&L: </span>
                      <span :class="pos.daysPL>0?'green-text':'red-text'">
                        {{ pos.daysPL | currencyWithDecimal  }}
                      </span>
                    </div>
                  </div>
                  <div class="row no-gutters">
                    <div class="col">
                      <span> LTP: </span>
                      <span>{{ pos.ltp | float(2) }}</span>
                    </div>
                    <div class="col">
                      <span>Buy Avg: </span>
                      <span>{{ pos.avg_buy_price | float(2) }}</span>
                    </div>
                    <div class="col">
                      <span>Sell Avg: </span>
                      <span>{{ pos.avg_sell_price | float(2) }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Visibile in Desktop -->
              <v-simple-table
                v-else
                class="share-positions-table"
                fixed-header
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <v-hover v-slot="{ hover }">
                          <div @click="filterStat=filterStat==null?true:filterStat==true?false:null">
                            <span>Instrument</span>
                            <v-icon 
                              class="mx-2"
                              v-if="hover || filterStat!=null" 
                              color="var(--text-color)"
                              :style="{opacity: filterStat==null?'0.5':'0.8'}"
                            >
                              {{filterStat?'mdi-arrow-down-thin':filterStat==null?'mdi-sort-variant':'mdi-arrow-up-thin'}}
                            </v-icon>
                          </div>
                        </v-hover>
                      </th>
                      <th class="text-center">Type</th>
                      <th class="text-center">Qty</th>
                      <th class="text-center">Buy Avg</th>
                      <th class="text-center">Sell Avg</th>
                      <th class="text-center">LTP</th>
                      <th class="text-center">Day's P&L</th>
                      <th class="text-center">Net P&L</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr 
                      v-for="(pos,index) in filteredPositions" 
                      :key="index+'-instruments'" 
                      :class="{'closed-instrument':pos.net_qty==0 && !allClosed}"
                    >
                      <td class="text-left">
                        {{ pos.formatted_ins_name }}
                      </td>
                      <td class="text-center">
                        <span class="product-type" :class="pos.prod_type == 'NRML' ? 'nrml' : 'mis'">
                          {{ pos.prod_type }}
                        </span>
                      </td>
                      <td class="text-center" :class="pos.net_qty>0?'green-text':'red-text'">
                        {{ pos.net_qty }}
                      </td>
                      <td class="text-center">
                        {{ pos.avg_buy_price | float(2) }}
                      </td>
                      <td class="text-center">
                        {{ pos.avg_sell_price | float(2) }}
                      </td>
                      <td class="text-center">
                        {{ pos.ltp | float(2) }}
                      </td>
                      <td class="text-center" :class="pos.daysPL>0?'green-text':'red-text'">
                        {{ pos.daysPL | currencyWithDecimal}}
                      </td>
                      <td class="text-center" :class="pos.netPL>0?'green-text':'red-text'">
                        {{ pos.netPL | currencyWithDecimal }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

          </v-card>
        </div>

        <!-- portfolio-data Section -->
        <div class="col-12 col-md-3 pl-0 pl-md-3 mt-3 mt-md-0">
          <v-card rounded='lg' class="portfolio-data-section pa-3 pt-0">
            <!-- Form Data -->
            <div class=" form-section">
              <div class="d-flex align-center">
                <div class="pt-5 pr-2">Broker:</div>
                <img :src="require('@/assets/aliceblue-logo.png')" alt="IMG" class="broker-image">
              </div>
              <div class="mt-4">
                1LY Options ID
                <div class="data"><span v-if="portfolioData">{{portfolioData.user_id}}</span></div>
              </div>
            </div>

            <div>
              <button 
                class="primary-button d-flex align-center justify-center history-btn"
                @click="showHistory=!showHistory"
              >
                History
                <v-icon color="var(--primary-button-text-color)">
                  {{showHistory?'mdi-chevron-double-up':'mdi-chevron-double-down'}}
                </v-icon>
              </button>
            </div>
            <!-- portfolio actions -->
            <div class="portfolio-actions">
              <div>
                <v-dialog
                  v-model="showDeleteModal"
                  max-width="250"
                >
                  <v-card>
                    <v-card-title class="text-h5">
                      Delete!
                    </v-card-title>

                    <v-card-text style="color: var(--text-color);">
                      Are you sure you want to delete this?
                    </v-card-text>

                    <v-card-actions>

                      <v-btn
                        style="color: var(--text-color) !important;background-color:var(--chip-background) !important;"
                        @click="()=>{showDeleteModal=false;deletePortfolioKey=null;}"
                      >
                        Cancel
                      </v-btn>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="var(--red-text)"
                        style="color: var(--primary-button-text-color) !important;"
                        @click="deletePortfolio()" 
                        depressed>
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <div class="mt-4 styled-scroll history" v-if="showHistory">
                <div 
                  v-for="(item,i) in history" 
                  :key="i+'history'" 
                  :style="item.portfolio_key!==portfolioKey
                    ?{cursor: 'pointer'} 
                    :{backgroundColor:'var(--chip-background)',cursor:'default'}"
                >
                <v-hover v-slot="{ hover }">
                  <div style="position: relative;">
                    <div class="history-log d-flex" @click="navigateToVerifiedPL(item)">
                      <div>
                        <div>
                          {{ item.created_on.toDateString() }}, 
                        </div>
                        <div class="sub-heading">
                          {{ item.created_on.toLocaleTimeString('en-US',{hour12:true,hour:'numeric',minute:'numeric'}) }}
                        </div>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <div class="text-right" :class="item.net_unrealized+item.net_realized>0?'green-text':'red-text'">
                          {{ (item.net_unrealized+item.net_realized>0?'+':'')}}{{ item.net_unrealized+item.net_realized | integerFormat}}
                        </div>
                        <div class="text-right sub-heading">Total P&L</div>
                      </div>
                    </div>
                    <!-- Hover buttons div -->
                    <div v-if="(hover && item.portfolio_key!=portfolioKey)" 
                    class="portfolio-hover-section">
                      <!-- Delete portfolio Button -->
                      <v-tooltip bottom v-if="editAccess">
                        <template v-slot:activator="{ on, attrs }">
                          <button
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteClicked(item.portfolio_key)"
                          >
                            <v-icon color="var(--red-text)">
                              mdi-trash-can
                            </v-icon>
                          </button>
                        </template>
                        <span>
                          Delete
                        </span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-hover>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { endpointVerifiedPL } from "../getService";
import SkeletonLoader from "../modals/SkeletonLoader.vue";
import UserDataMixin from "@/mixins/getUserDataMixin";
import axios from "axios";
export default{
  components:{
    SkeletonLoader
  },
  mixins: [UserDataMixin],
  data(){
    return{
      // flags
      loading:false,
      error:null,
      showHistory:true,
      editAccess:false,
      deletingPortfolio:false,
      deletePortfolioKey:null,
      showDeleteModal:false,
      allClosed:false,

      portfolioKey:null,
      history:[],
      positions:[],
      portfolioData:null,

      // table filter state
      filterStat:null,
    }
  },
  computed: {
    filteredPositions() {
      let list = this.positions;
      if (this.filterStat !== null) {
        list.sort(function (a, b) { return a.formatted_ins_name.localeCompare(b.formatted_ins_name) });
        if (this.filterStat == false) list.reverse();
      }
      else {
        const nifty = list.filter(el => el.symbol === 'NIFTY');
        const banknifty = list.filter(el => el.symbol === 'BANKNIFTY');
        const finnifty = list.filter(el => el.symbol === 'FINNIFTY');
        list = list.filter(el => el.symbol !== 'NIFTY' && el.symbol !== 'BANKNIFTY'  && el.symbol !== 'FINNIFTY');
        list = nifty.concat(banknifty, finnifty, list);
      }
      const closedPos=list.filter(el=>el.net_qty==0);
      list=list.filter(el=>el.net_qty!=0);
      list=list.concat(closedPos);
      return list;
    },
    unrealisedPL(){
      let total = 0;
      if(this.portfolioData)
        total=parseFloat(this.portfolioData.net_unrealized);
      // this.positions.forEach(el=>{
      //   if(el.net_qty!=0)
      //     total+=parseFloat(el.netPL);
      // })
      return total.toFixed(2);
    },
    realisedPL(){
      let total = 0;
      if(this.portfolioData)
        total=parseFloat(this.portfolioData.net_realized);
      // this.positions.forEach(el=>{
      //   if(el.net_qty==0)
      //     total+=parseFloat(el.netPL);
      // })
      return total.toFixed(2);
    },
    createdOn(){
      if(this.portfolioData)
        return new Date(this.portfolioData.created_on);
      return null;
    },
    totalPL(){
      let total = 0;
      // this.positions.forEach(el=>{
      //   total+=parseFloat(el.netPL);
      // })
      total=parseFloat(this.realisedPL)+parseFloat(this.unrealisedPL);

      return total.toFixed(2);
    },
    darkMode(){
      return this.$store.getters.getDarkMode;
    },
    xsScreen(){
      return this.$vuetify.breakpoint.xs;
    },
    windowWidth(){
      return this.$vuetify.breakpoint.width;
    }
  },
  mounted(){
    this.getVerifiedPnLPortfolio();
  },
  watch:{
    $route(newRoute){
      this.getVerifiedPnLPortfolio(newRoute)
    }
  },
  methods:{
    print(data){
      console.log(data);
    },
    getVerifiedPnLPortfolio(route=this.$route){
      if(!this.deletingPortfolio) this.loading=true;
      const portfolioKey=route.params.portfolio_key;
      if(!portfolioKey){
        this.error={message:'No Verified PnL Portfolio Key detected'};
        return;
      }
      this.portfolioKey=portfolioKey;
      const payload={
        portfolio_key:portfolioKey,
        operation:'get',
        user_id:this.userId,
      }
      axios.post(endpointVerifiedPL,payload)
      .then(res=>{
        if(res.data.errorMessage) throw(res);
        this.positions=res.data.positions;
        this.portfolioData=res.data.portfolio_data;
        this.editAccess=res.data.edit_access;
        
        this.allClosed=this.positions.every((el)=>{
          return el.net_qty==0;
        })
        // User verified PL History section
        let user_portfolios=res.data.user_portfolios;
        user_portfolios.forEach(el=>{
          el.created_on=new Date(el.created_on);
        })
        this.history=user_portfolios;
        
        this.loading=false;
      })
      .catch(err=>{
        console.log(err);
        this.error={message:'No Verified PnL Portfolio with this key found'};
        this.loading=false;
      })
    },
    deleteClicked(pKey){
      this.deletePortfolioKey=pKey;
      this.showDeleteModal=true;
    },
    deletePortfolio(pKey=this.deletePortfolioKey){
      this.deletingPortfolio=true;
      this.showDeleteModal=false;
      const payload={
        portfolio_key:pKey,
        operation:'delete',
        user_id:this.userId,
        accessTokenAnt:this.accessToken,
      }
      axios.post(endpointVerifiedPL,payload)
      .then(res=>{
        if(res.data.errorMessage) throw(res);
        this.$notify({
          group: "success",
          type: "success",
          title: "Success",
          text: `portfolio deleted successfully`,
        });
        this.getVerifiedPnLPortfolio();
        this.deletePortfolioKey=null;
        this.deletingPortfolio=false;
      })
      .catch(err=>{
        console.log(err);
        this.$notify({
            group: "success",
            type: "error",
            title: "Error",
            text: "Error deleting portfolio",
          });
        this.deletePortfolioKey=null;
        this.deletingPortfolio=false;
      })
    },
    navigateToVerifiedPL(item){
      // let url=window.location.origin + `/verifiedpnl/${item.portfolio_key}`;
      // window.open(url, '_blank');

      const portfolio_key=item.portfolio_key
      this.$router.push({
        name:'VerifiedPnL',params: { portfolio_key: portfolio_key },
        query: { portfolio_key: portfolio_key }
      });
    },
  },
}
</script>
<style scoped lang="scss">
@import "../../assets/css/global.scss";
.default-style {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  color: var(--text-color);
}
.sub-heading{
  color: #909090;
  font-size: 0.7em;
}

.product-type{
  padding: 5px;
  border-radius:5px;
}
.nrml {
  color: var(--option-strike-text);
  background-color: var(--option-strike-bg);
}
.mis {
  color: var(--mis-label-text);
  background-color: var(--mis-label-bg);
}
.closed-instrument{
  opacity: 0.6;
  background-color:var(--option-even-row);
}
.verified-pl-wrapper{
  max-width: 1850px;
  margin: auto;
  .verifiedpl-heading {
    font-size: 1.5em;
    line-height: 28px;
    margin-top: 5px;
    .header-info{
      font-size: 0.85em;
      white-space: nowrap;
    }
  }
  .pnl-data-section{
    position: relative;
    min-height: 800px;
    .instruments-data{
      overflow: hidden;
    }
  }

  .pnl-data-section:hover{
    .instruments-data{
      overflow: auto;
    }
  }
  .portfolio-data-section{
    position: relative;
    height: 100%;
    .profile-section{
      font-size: 1.1em;
    }
    .form-section{
      .broker-image{
        // filter:drop-shadow(3px 6px 5px #ccc);
        width: 40%;
        max-width: 100px;
      }
      .data{
        background-color:var(--option-header-bg);
        padding: 8px 20px;
        border-radius:5px;
        width: 100%;
        margin-top: 10px;
      }
    }
    .history-btn{
      width: 100%;
      padding: 8px 16px;
      text-align: center;
      border-radius: 5px;
      margin-top: 10px;
    }
    .portfolio-actions{
      .history{
        overflow: hidden;
        max-height: min(66.5vh,640px);
      }
      .history:hover{
        overflow: auto;
      }
      
      .history-log{
        cursor: pointer;
        border-bottom: 1px solid #90909045;
        >div{
          padding: 5px 0;
        }
        .sub-heading{
          font-size: 0.8em;
        }

      }

      .portfolio-hover-section{
        padding-right: 5em;
        position: absolute;
        top: 12.5%;
        right: 0;
        font-size: 0.7em;

        button {
          all:unset;
          margin: 0 .3em;
          padding: 0.25em;
          text-align: center;
          background-color: var(--cards-background-color);
          color: var(--text-color);
          border: 1px solid var(--text-color);
          border-radius: 5px;
          *{
            font-size: 2em !important;
          }
        }
      }
    }
  }
}
.mobile-view-positions{
  .row{
    padding: 10px 0;
  }
  .col{
    // padding: 0 10px;
    white-space: nowrap;
    // display: flex;
    
  }
  // .row:first-child,.row:last-child{
  //   padding-left: 5px;
  // }
  .row:last-child{
    border-bottom: 1px solid #90909045;
  }
}
// Table section
.share-positions-table{
  table{
    th {
      background-color: var(--option-header-bg);
      white-space: nowrap;
      font-weight: 500;
      font-size: 1em !important;
    }

    tr{
      color: var(--text-color) ;
    }
    td,
    th {
      font-size: 1em !important;
      height: 3em !important;
      border-bottom: 1px solid #90909045 !important;
      padding: 0 10px !important;
    }
    tr:last-child td {
      border-bottom: none !important;
    }
    td:first-child{
      white-space: nowrap;
    }
    tr.closed-instrument{
      opacity: 0.6;
      // color: var(--disabled-text);
      td{
        background-color:var(--option-even-row);
      }
    }
  }

}
@media screen and (max-width: 600px) {
  .verified-pl-wrapper{
    font-size: 0.85em;
    >.row{
      min-height: 100vh;
    }

    .pnl-data-section{
      min-height: 500px;
      max-height:none;
      .instruments-data{
        overflow: auto;
      }
    }
    .history{
      max-height: none;
    }
  }
}
</style>
<style lang="scss">
.verified-pl-wrapper{
  .share-positions-table{
    .v-data-table__wrapper{
      max-height: 750px !important;
    }
  }
}
</style>