<template>
  <div>
    <GraphAndDaysModal
      v-if="payOffChartVisible"
      :visible="payOffChartVisible"
      :selectedInstrument="selectedInstrument"
      :strategyData="payOffChartData"
      @close="payOffChartVisible = false"
    ></GraphAndDaysModal>
    <v-dialog :fullscreen="$vuetify.breakpoint.xs" v-model="show" width="auto">
      <template>
        <v-card class="pb-5" style="position: relative">
          <!-- Heading -->
          <v-toolbar
            color="var(--dialog-header)"
            dark
            class="place-order-toolbar-title"
          >
            <v-toolbar-title @click="print(strategyCreator)"> Order Confirmation </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click.stop="cancelBtnClick()">
              <v-icon style="color: var(--dialog-header-text)"
                >mdi-close</v-icon
              >
            </v-btn>
          </v-toolbar>
          <v-card-text class="px-3 px-sm-5">
            <!-- Upper Section -->            <div
              class="place-order-content my-5 flex"
              :class="!isExitPositionsModal ? '' : 'justify-end'"
            >
              <div class="mis-nrml-label" v-if="!isExitPositionsModal">
                <span
                  @click="selectedMisLabel = 'MIS'"
                  :class="
                    selectedMisLabel == 'MIS'
                      ? 'selected-span'
                      : 'unselected-span'
                  "
                  >MIS
                </span>
                <span
                  @click="selectedMisLabel = 'NRML'"
                  :class="
                    selectedMisLabel == 'NRML'
                      ? 'selected-span'
                      : 'unselected-span'
                  "
                  >NRML
                </span>
              </div>

              <div class="stoploss-checkbox">
                <v-checkbox
                  v-model="selectedLabel"
                  hide-details
                  value="stoploss"
                  color="var(--text-color)"
                  @click="columnLength = columnLength + 1"
                >
                  <template v-slot:label>
                    <span class="checkbox-label">Stoploss Price</span>
                  </template>
                </v-checkbox>
                <v-checkbox
                  hide-details
                  v-model="selectedLabel"
                  value="targetPrice"
                  color="var(--text-color)"
                  @click="columnLength = columnLength + 1"
                >
                  <template v-slot:label>
                    <span class="checkbox-label">Target Price</span>
                  </template>
                </v-checkbox>
              </div>
            </div>

            <!-- Middle Section -->
            <div class="place-order-table" v-if="!$vuetify.breakpoint.xs">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr class="placeorder-table-header">
                      <th
                        class="text-left"
                        style="padding-left: 5px !important"
                      >
                        Instrument
                      </th>
                      <th
                        class="text-center"
                        style="min-width: 80px"
                        v-if="!isHoldingsSection"
                      >
                        LIX
                        <v-tooltip
                          content-class="header-tooltips"
                          style="font-size: 16px"
                          top
                          color="var(--cards-background-color)"
                          max-width="300px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="info-icon">
                              <v-icon
                                >mdi-information-variant-circle-outline</v-icon
                              >
                            </span>
                          </template>
                          <span
                            >Liquidity Index (LIX) indicates the liquidity of
                            the given instrument ranging from 0 (low Liquidity)
                            to 10 (High Liquidity) at the given point.</span
                          >
                        </v-tooltip>
                      </th>
                      <th class="text-center">MD</th>
                      <th class="text-center" style="width: 5rem">B/S</th>
                      <th class="text-center" style="width: 7rem">Qty</th>
                      <th class="text-center" style="width: 10rem">Order</th>
                      <th class="text-center">Limit</th>
                      <th
                        class="text-center"
                        v-if="selectedLabel.includes('stoploss')"
                      >
                        Stoploss Price
                      </th>
                      <th
                        class="text-center"
                        v-if="selectedLabel.includes('targetPrice')"
                      >
                        Target Price
                        <v-tooltip
                          content-class="header-tooltips"
                          style="font-size: 16px"
                          top
                          color="var(--cards-background-color)"
                          max-width="300px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="info-icon">
                              <v-icon
                                >mdi-information-variant-circle-outline</v-icon
                              >
                            </span>
                          </template>
                          <span
                            >It will place a limit order as bracket orders are
                            blocked by exchange on options contract. Kindly
                            monitor your orders accordingly.</span
                          >
                        </v-tooltip>
                      </th>
                      <th
                        class="text-center"
                        v-if="
                          sortedPlaceOrderData[0] &&
                          sortedPlaceOrderData[0].status
                        "
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(ins, index) in sortedPlaceOrderData"
                    :key="index"
                  >
                    <tr class="place-order-rows parent-table">
                      <td class="text-left" style="padding-left: 8px">
                        {{ ins.formatted_ins_name }}
                      </td>
                      <td class="text-center" v-if="!isHoldingsSection">
                        <span
                          v-if="ins.lixValueList"
                          :class="getLixValueColor(ins.lixValueList)"
                          >{{ ins.lixValueList }}</span
                        >
                      </td>
                      <td
                        class="text-center"
                        style="cursor: pointer"
                        @click="getOrdersTable(ins.code, index)"
                      >
                        <v-icon color="#9e9e9e">mdi-format-align-center</v-icon>
                      </td>
                      <td class="text-center" style="cursor: pointer">
                        <v-chip
                          v-if="ins.quantity < 0"
                          label
                          large
                          color="var(--put-background)"
                          text-color="var(--red-text)"
                          font-size="1rem"
                          @click="
                            ins.quantity = -ins.quantity;
                            getSortedData();
                            calculateCharges();
                          "
                        >
                          S
                        </v-chip>

                        <v-chip
                          v-else
                          label
                          large
                          color="var(--buy-background)"
                          text-color="var(--primary-color)"
                          font-size="1rem"
                          @click="
                            ins.quantity = -ins.quantity;
                            getSortedData();
                            calculateCharges();
                          "
                        >
                          B
                        </v-chip>
                      </td>
                      <td class="text-center order-qty-field">
                        <div class="plus-minus-field">
                          <span
                            class="minus"
                            @click="
                              incDecInstrumentValues(
                                'quantity',
                                ins.quantity - selectedInstrument.lot_size,
                                index,
                                false
                              )
                            "
                            >-</span
                          >
                          <input
                            type="text"
                            :value="ins.quantity"
                            @input="
                              handleQuantityInput($event, 'quantity', index)
                            "
                          />
                          <span
                            class="plus"
                            @click="
                              incDecInstrumentValues(
                                'quantity',
                                ins.quantity + selectedInstrument.lot_size,
                                index,
                                true
                              )
                            "
                            >+</span
                          >
                        </div>
                      </td>
                      <td class="text-center order-qty-field">
                        <div class="mkt-limit-label">
                          <span
                            @click="
                              changeInstrumentValues('prctyp', 'MKT', index)
                            "
                            :class="
                              ins.prctyp == 'MKT'
                                ? 'selected-mkt'
                                : 'unselected-mkt'
                            "
                            >MKT</span
                          >
                          <span
                            @click="
                              changeInstrumentValues('prctyp', 'LIMIT', index)
                            "
                            :class="
                              ins.prctyp == 'LIMIT'
                                ? 'selected-mkt'
                                : 'unselected-mkt'
                            "
                            >LIMIT</span
                          >
                        </div>
                      </td>
                      <td class="text-center">
                        <input
                          class="limit-input"
                          type="number"
                          v-model="ins.ltp"
                          :disabled="ins.prctyp == 'MKT' ? true : false"
                        />
                      </td>
                      <td
                        class="text-center"
                        v-if="selectedLabel.includes('stoploss')"
                      >
                        <input
                          class="limit-input"
                          placeholder="Stoploss Price"
                          v-model="ins.stopLoss"
                          @input="setIntegerValue($event, 'stopLoss', index)"
                        />
                      </td>
                      <td
                        class="text-center"
                        v-if="selectedLabel.includes('targetPrice')"
                      >
                        <input
                          v-model="ins.targetPrice"
                          class="limit-input"
                          placeholder="Target Price"
                          @input="setIntegerValue($event, 'targetPrice', index)"
                        />
                      </td>
                      <td
                        class="text-center"
                        v-if="ins.status != ''"
                        style="cursor: pointer; position: relative"
                      >
                        <v-tooltip top color="#ff6665" dark>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              :class="
                                ins.status == 'rejected'
                                  ? 'red-text'
                                  : ins.status == 'sent'
                                  ? 'text'
                                  : 'green-text'
                              "
                              >{{
                                ins.status.charAt(0).toUpperCase() +
                                ins.status.substr(1).toLowerCase()
                              }}</span
                            >
                          </template>
                          <span> {{ ins.statusMessage }}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td
                        :colspan="columnLength"
                        v-if="sortedPlaceOrderData[index].showMdTable"
                        style="padding: 0 !important"
                        :style="
                          sortedPlaceOrderData[index].showMdTable
                            ? { backgroundColor: 'transparent' }
                            : {}
                        "
                      >
                        <v-simple-table
                          style="margin-top: 1rem"
                          class="market-table"
                        >
                          <template>
                            <thead
                              class="placeorder-table-header"
                              style="font-size: 1rem"
                            >
                              <tr>
                                <th class="text-center">BID</th>
                                <th class="text-center">Orders</th>
                                <th class="text-center">Qty</th>
                                <th class="text-center">Offer</th>
                                <th class="text-center">Orders</th>
                                <th class="text-center">Qty</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(order, index) in ins.orderTable.buyBid"
                                :key="index"
                              >
                                <td class="text-center green-text">
                                  {{ order }}
                                </td>
                                <td class="text-center green-text">
                                  {{ ins.orderTable.buyOrder[index] }}
                                </td>
                                <td class="text-center green-text">
                                  {{ ins.orderTable.buyQuanity[index] }}
                                </td>
                                <td class="text-center red-text">
                                  {{ ins.orderTable.sellOffer[index] }}
                                </td>
                                <td class="text-center red-text">
                                  {{ ins.orderTable.sellOrder[index] }}
                                </td>
                                <td class="text-center red-text">
                                  {{ ins.orderTable.sellQuantity[index] }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center green-text">Total</td>
                                <td class="text-center green-text"></td>
                                <td class="text-center green-text">
                                  {{ ins.orderTable.totalBuyQty }}
                                </td>
                                <td class="text-center red-text">Total</td>
                                <td class="text-center red-text"></td>
                                <td class="text-center red-text">
                                  {{ ins.orderTable.totalSellQty }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <!-- mobile responsiveness -->
             <div v-else>
              <v-row no-gutters class="mobile-instrument-header px-1">
                <!-- Headers -->
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="4"> Instrument </v-col>
                    <v-col cols="2"> LIX
                      <v-tooltip
                      content-class="header-tooltips"
                      style="font-size: 1rem"
                      top
                      color="var(--cards-background-color)"
                      max-width="300px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="info-icon">
                          <v-icon
                            >mdi-information-variant-circle-outline</v-icon
                          >
                        </span>
                      </template>
                      <span
                        >Liquidity Index (LIX) indicates the liquidity of
                        the given instrument ranging from 0 (low Liquidity)
                        to 10 (High Liquidity) at the given point.</span
                      >
                    </v-tooltip> </v-col>
                    <v-col cols="1"> MD </v-col>
                    <v-col cols="2"> B/S </v-col>
                    <v-col cols="3"> Qty </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="4"> Order </v-col>
                    <v-col cols="3"> Limit </v-col>
                    <v-col cols="2" v-if="selectedLabel.includes('stoploss')">
                      Stoploss
                    </v-col>
                    <v-col
                      cols="3"
                      v-if="selectedLabel.includes('targetPrice')"
                    >
                      Target Price
                    </v-col>
                    <!-- <v-col
                      cols="12"
                      class="text-center"
                      v-if="
                        sortedPlaceOrderData[0] &&
                        sortedPlaceOrderData[0].status
                      "
                    >
                      Status</v-col
                    > -->
                  </v-row>
                </v-col>
              </v-row>
              <!-- Orders -->
              <v-row
                no-gutters
                class="mobile-instrument-rows mb-2"
                style="border-bottom: 1px solid #e0e0e0;"
                v-for="(ins, index) of sortedPlaceOrderData"
                :key="index"
              >
                <v-col cols="12" class="mobile">
                  <v-row no-gutters>
                    <v-col cols="4">
                      {{ ins.formatted_ins_name }}
                    </v-col>
                    <v-col cols="2">
                      <span
                        class="mx-2"
                        v-if="ins.lixValueList"
                        :class="getLixValueColor(ins.lixValueList)"
                        >{{ ins.lixValueList }}</span
                      >
                    </v-col>
                    <v-col cols="1" @click="getOrdersTable(ins.code, index)">
                      <v-icon color="#9e9e9e" style="font-size:1rem">mdi-format-align-center</v-icon>
                    </v-col>
                    <v-col cols="2">
                      <v-chip
                        v-if="ins.quantity < 0"
                        label
                        color="var(--put-background)"
                        text-color="var(--red-text)"
                        font-size="var(--font-12) !important"
                        small
                        @click="
                          ins.quantity = -ins.quantity;
                          getSortedData();
                          calculateCharges();
                        "
                      >
                        S
                      </v-chip>

                      <v-chip
                        v-else
                        label
                        color="var(--buy-background)"
                        text-color="var(--primary-color)"
                        font-size="var(--font-12) !important"
                        small
                        @click="
                          ins.quantity = -ins.quantity;
                          getSortedData();
                          calculateCharges();
                        "
                      >
                        B
                      </v-chip>
                    </v-col>
                    <v-col cols="3" class="text-right order-qty-field">
                      <div class="plus-minus-field mobile">
                        <span
                          class="minus mobile-field"
                          @click="
                            incDecInstrumentValues(
                              'quantity',
                              ins.quantity - selectedInstrument.lot_size,
                              index,
                              false
                            )
                          "
                          >-</span
                        >
                        <input
                          type="text"
                          :value="ins.quantity"
                          class="mobile-text mobile-field"
                          @input="
                            handleQuantityInput($event, 'quantity', index)
                          "
                        />
                        <span
                          class="plus mobile-field"
                          @click="
                            incDecInstrumentValues(
                              'quantity',
                              ins.quantity + selectedInstrument.lot_size,
                              index,
                              true
                            )
                          "
                          >+</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="mt-2 mb-1">
                    <v-col cols="4" class="order-qty-field d-flex align-center">
                      <div class="mkt-limit-label mobile">
                        <span
                          @click="
                            changeInstrumentValues('prctyp', 'MKT', index)
                          "
                          :class="
                            ins.prctyp == 'MKT'
                              ? 'selected-mkt'
                              : 'unselected-mkt'
                          "
                          >MKT</span
                        >
                        <span
                          @click="
                            changeInstrumentValues('prctyp', 'LIMIT', index)
                          "
                          :class="
                            ins.prctyp == 'LIMIT'
                              ? 'selected-mkt'
                              : 'unselected-mkt'
                          "
                          >LIMIT</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <input
                        class="limit-input"
                        type="number"
                        v-model="ins.ltp"
                        :disabled="ins.prctyp == 'MKT' ? true : false"
                      />
                    </v-col>
                    <v-col cols="2" v-if="selectedLabel.includes('stoploss')">
                      <input
                        class="limit-input text-center"
                        placeholder="Stoploss"
                        v-model="ins.stopLoss"
                        @input="setIntegerValue($event, 'stopLoss', index)"
                      />
                    </v-col>
                    <v-col cols="3" v-if="selectedLabel.includes('targetPrice')">
                      <input
                        v-model="ins.targetPrice"
                        class="limit-input text-center"
                        placeholder="Target"
                        @input="setIntegerValue($event, 'targetPrice', index)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="ins.status != ''"
                      class="d-flex mt-2 align-center justify-center"
                      style="cursor: pointer; position: relative"
                    >
                      <v-tooltip top color="#ff6665" dark>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            style="font-size: 1.3em;"
                            :class="
                              ins.status == 'rejected'
                                ? 'red-text'
                                : ins.status == 'sent'
                                ? 'text'
                                : 'green-text'
                            "
                            >{{
                              ins.status.charAt(0).toUpperCase() +
                              ins.status.substr(1).toLowerCase()
                            }}</div
                          >
                        </template>
                        <span> {{ ins.statusMessage }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <!-- Market Depth -->
                  <v-row v-if="sortedPlaceOrderData[index].showMdTable"
                  :style="
                    sortedPlaceOrderData[index].showMdTable
                      ? { backgroundColor: 'transparent' }
                      : {}
                  ">
                  <v-simple-table
                  style="width:100%"
                  class="market-table mt-0 mb-2"
                >
                  <template>
                    <thead
                      class="placeorder-table-header"
                      style="font-size: 1rem"
                    >
                      <tr>
                        <th class="text-center">BID</th>
                        <th class="text-center">Orders</th>
                        <th class="text-center">Qty</th>
                        <th class="text-center">Offer</th>
                        <th class="text-center">Orders</th>
                        <th class="text-center">Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(order, index) in ins.orderTable.buyBid"
                        :key="index"
                      >
                        <td class="text-center green-text">
                          {{ order }}{{index}}
                        </td>
                        <td class="text-center green-text">
                          {{ ins.orderTable.buyOrder[index] }}
                        </td>
                        <td class="text-center green-text">
                          {{ ins.orderTable.buyQuanity[index] }}
                        </td>
                        <td class="text-center red-text">
                          {{ ins.orderTable.sellOffer[index] }}
                        </td>
                        <td class="text-center red-text">
                          {{ ins.orderTable.sellOrder[index] }}
                        </td>
                        <td class="text-center red-text">
                          {{ ins.orderTable.sellQuantity[index] }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center green-text">Total</td>
                        <td class="text-center green-text"></td>
                        <td class="text-center green-text">
                          {{ ins.orderTable.totalBuyQty }}
                        </td>
                        <td class="text-center red-text">Total</td>
                        <td class="text-center red-text"></td>
                        <td class="text-center red-text">
                          {{ ins.orderTable.totalSellQty }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                  </v-row>
                </v-col>
              </v-row>
            </div> 
          </v-card-text>

          <!-- Bottom Section -->
          <v-card-actions
            class="footer-section px-3 px-sm-5"
            :style="
              $vuetify.breakpoint.xs ? { position: 'sticky', top: '100%' } : {}
            "
          >
            <div
              class="row ma-0 no-gutters"
              :class="!isExitPositionsModal ? '' : 'justify-end'"
            >
              <div
                class="footer-labels row justify-space-between justify-sm-start ma-0 align-center"
                v-if="!isExitPositionsModal"
              >
                <span
                  class="label order-2 order-sm-1"
                  style="
                    border-radius: 4px;
                    background-color: var(--light-plotband-color);
                  "
                >
                  Req Margin:
                  {{ parseFloat(requiredMargin).toFixed(2) | integerFormat }}
                </span>
                <span class="label order-3 order-sm-2">
                  Available Margin:
                  <span class="green-text">{{
                    parseFloat(availableMargin).toFixed(2) | currency
                  }}</span>
                </span>
                <span class="label col-12 col-sm-auto order-1 order-sm-3">
                  <BrokerCharges
                    :insChargesArray="insChargesList"
                    @refresh-charges="calculateCharges"
                  />
                </span>
              </div>
              <div
                class="row col-12 col-md-auto align-center justify-end justify-sm-end ma-0"
              >
                <!-- Insufficient Balance -->
                <div
                  class="px-0 red-text d-sm-none col-12 text-sm-center text-md-left"
                  v-if="requiredMargin > availableMargin"
                  style="font-size: 0.7rem"
                >
                  Insufficient fund to place orders, Please
                  <a
                    target="_blank"
                    href="https://ant.aliceblueonline.com/funds"
                    class="click_btn"
                    >click here</a
                  >
                  to add funds.
                </div>
                <button
                  v-if="showGraphIcon"
                  class="d-flex align-center mx-2"
                  @click="openPayOffChart()"
                >
                  <img
                    :src="
                      !darkMode
                        ? require('@/assets/LightMode/graph.png')
                        : require('@/assets/DarkMode/graph.png')
                    "
                    alt="show chart"
                    width="35px"
                    style="cursor: pointer"
                  />
                </button>

                <v-btn
                  color="var(--text-color)"
                  class="mr-2"
                  outlined
                  dark
                  @click.stop="cancelBtnClick()"
                >
                  {{ isOnOptionChain ? "Clear" : "Cancel" }}
                </v-btn>

                <v-btn
                  color="var(--primary-button-color)"
                  dark
                  @click="placeOrderApi()"
                >
                  Confirm
                </v-btn>
              </div>
            </div>
          </v-card-actions>

          <!-- Insufficient Balance -->
          <div
            class="red-text px-3 px-sm-5 text-sm-center text-md-left d-none d-sm-block"
            v-if="requiredMargin > availableMargin"
          >
            Insufficient fund to place orders, Please
            <a
              target="_blank"
              href="https://ant.aliceblueonline.com/funds"
              class="click_btn"
              >click here</a
            >
            to add funds.
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import { EventBus } from "@/main";
import {
  getLixData,
  getMarginDataUrl,
  saveOrderResponse,
  getBrokrage,
} from "../getService";
import {
  placeOrderUrl,
  getOrderHistoryUrl,
  modifyOrderUrl
} from "@/utils/aliceBlueUrls";
import axios from "axios";
import BrokerCharges from "./BrokerCharges.vue";
import GraphAndDaysModal from "./GraphAndDaysModal.vue";
import { reformatDate } from "../Dashboard/dashboardService";
import UserDataMixin from "@/mixins/getUserDataMixin";

export default {
  name: "PlaceOrderModal",
  mixins:[UserDataMixin],
  props: {
    visible: Boolean,
    strategyCreator: [],
    selectedInstrument: null,
    isPositionGraph: Boolean,
    order_location: String,
    isExitPositionsModal: {
      type: Boolean,
      default: false,
    },
    isHoldingsSection: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BrokerCharges,
    GraphAndDaysModal,
  },
  data: () => ({
    userSettings: JSON.parse(localStorage.getItem("user_settings")),
    selectedLabel: [],
    columnLength: 7,
    selectedMisLabel: "NRML",
    selectedMktLabel: "LIMIT",
    placeOrderData: [],
    availableMargin: 0,
    requiredMargin: 0,
    orderTableDataList: [],
    lixValueList: [],
    orderList: [],
    count: 0,
    sortedPlaceOrderData: [],
    orderDataLength: 0,
    timerId: null,
    // Broker Charges Vars
    insChargesList: [],

    // PayOffChart vars
    orderPlaced:false,
    payOffChartVisible: false,
    payOffChartData: {},
  }),
  computed: {
    showGraphIcon(){
      const isNa=this.strategyCreator.some(el=>el.isNa)
      return (
          this.isOnOptionChain || 
          this.isPositionGraph || 
          this.isOnOptionScanner
        ) 
        && !this.isExitPositionsModal
        && !isNa
    },
    isOnOptionChain() {
      return this.$route.name == "OptionChain";
    },
    isOnOptionScanner() {
      return this.$route.name == "OptionScanner";
    },
    currentRoute() {
      return window.location.href;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
    insSub() {
      return (el) => {
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
    liveLtp() {
      return (el) => {
        let ltp = this.compactMarketDataScripts(el);
        if (
          ltp &&
          ltp["details"] &&
          (ltp["details"]["Last Traded Price"] == 0 ||
            ltp["details"]["Last Traded Price"])
        )
          return ltp["details"]["Last Traded Price"];
        else return 0;
      };
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    print(val){console.log(val)},
    loadPlaceOrderData() {
      this.placeOrderData = JSON.parse(JSON.stringify(this.strategyCreator));
      let prctyp;
      console.log(this.placeOrderData)
      if (!this.isExitPositionsModal) {
        // prctyp =
        //   this.selectedInstrument.name === "NIFTY" ||
        //   this.selectedInstrument.name === "BANKNIFTY" ||
        //   this.selectedInstrument.name === "FINNIFTY"
        //     ? "MKT"
        //     : "LIMIT";
        prctyp = this.userSettings.orderconfirmation_ordertype;
        this.availableMargin = parseInt(localStorage.getItem("balance"));
      }
      // if (this.userSettings.orderconfirmation_producttype == "LIMIT") {
      //   prctyp = "LIMIT";
      // } else {
      //   prctyp = "MKT";
      // }
      // prctyp = this.userSettings.orderconfirmation_producttype;
      const insList=[];
      for (let i = 0; i < this.strategyCreator.length; i++) {
        if (!this.isExitPositionsModal) {
          this.$set(this.placeOrderData[i], "pCode", this.selectedMisLabel);
          this.$set(this.placeOrderData[i], "prctyp", prctyp);
        } else {
          let selectedMisLabel =
            this.strategyCreator[i].name === "NIFTY" ||
            this.strategyCreator[i].name === "BANKNIFTY" ||
            this.strategyCreator[i].name === "FINNIFTY"
              ? "MKT"
              : "LIMIT";
          this.$set(
            this.placeOrderData[i],
            "pCode",
            this.strategyCreator[i].prod_type
          );
          this.$set(this.placeOrderData[i], "prctyp", selectedMisLabel);
        }
        this.$set(this.placeOrderData[i], "stopLoss", "");
        this.$set(this.placeOrderData[i], "targetPrice", "");
        this.$set(this.placeOrderData[i], "price", this.strategyCreator[i].ltp);
        this.$set(this.placeOrderData[i], "status", "");
        this.$set(this.placeOrderData[i], "showMdTable", false);
        if(this.order_location == 'order_book'){
          this.$set(this.placeOrderData[i], "nestOrderNumber", this.strategyCreator[i].nestOrderNumber);
        }
        // Temporary fix
        if (window.location.href.toLowerCase().includes("dashboard")) {
          insList.push({
            code: this.strategyCreator[i].code,
            exchange: this.strategyCreator[i].exchange,
            loc:this.$route.name
          });
        }
        if (!this.isHoldingsSection) {
          this.getLixValue(this.placeOrderData[i], i);
        }
      }
      this.$store.dispatch("subscribeToInstruments",insList);
      this.getSortedData();
      if (!this.isExitPositionsModal) {
        this.calculateCharges();
      }
    },
    calculateCharges() {
      this.insChargesList = [];
      this.placeOrderData.forEach((ins) => {
        this.getInstrumentCharges(ins);
      });
    },
    getInstrumentCharges(ins) {
      const user_id = this.userId;
      const access_token = this.accessToken;

      // ORDER TYPE
      const type = this.selectedMisLabel == "MIS" ? "INTRADAY" : "DELIVERY";

      // INSTRUMENT EXCHANGE
      const exchange =
        this.selectedInstrument.exchange == "NSE"
          ? "NFO"
          : this.selectedInstrument.exchange;

      // INSTRUMENT TYPE
      let instrumentType;
      if (exchange == "MCX") {
        instrumentType =
          ins?.segment.toUpperCase() == "FUT" ? "FUTCOM" : "OPTFUT";
      } else if (exchange == "CDS") {
        instrumentType =
          ins?.segment.toUpperCase() == "FUT" ? "FUTCUR" : "OPTCUR";
      } else {
        const segmentPrefix =
          ins.segment.toUpperCase() == "FUT" ? "FUT" : "OPT";
        const segmentSuffix = ins.trading_symbol.includes("NIFTY")
          ? "IDX"
          : "STK";
        instrumentType = segmentPrefix + segmentSuffix;
      }
      const payload = {
        user: user_id,
        transactionType: parseInt(ins.quantity) > 0 ? "buy" : "sell",
        type,
        lotSize: String(this.selectedInstrument.lot_size),
        plan: "T-pro",
        token: ins.code,
        qty: String(Math.abs(ins.quantity)),
        price: parseFloat(ins.op_pr || ins.ltp).toFixed(2),
        instrumentType,
        segment: exchange,
      };
      const header = {
        Authorization: "Bearer " + user_id + " " + access_token,
      };
      axios
        .post(getBrokrage, payload, { headers: header })
        .then((res) => {
          if (res.status == 0) {
            throw res;
          }
          let chargesData = res.data.result;
          const keys = [
            "brokerage",
            "stampCharges",
            "transactionCharge",
            "cmCharges",
            "sebi",
            "stt",
            "gst",
          ];
          let chargesSum = 0;

          keys.forEach((key) => {
            chargesSum += parseFloat(chargesData[key]);
          });
          chargesData["total"] = chargesSum;
          chargesData["name"] = ins.name;
          chargesData["quantity"] = ins.quantity;
          chargesData["trading_symbol"] = ins.trading_symbol;
          this.insChargesList.push(chargesData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * @function PayOffChart translates data in format needed for GraphModal
     * On option chain
     */
    openPayOffChart() {
      this.payOffChartData = {};
      const param_list = this.placeOrderData
        .filter((el) => el.segment.toLowerCase() != "fut")
        .map((el) => {
          return {
            code: el.code,
            exchange: el.exchange,
            expiry: reformatDate(el.expiry),
            ltp: el.ltp,
            name: this.selectedInstrument.name,
            // op_pr: el.op_pr,
            segment: el.segment,
            quantity: el.quantity,
            strike: +el.strike,
            trading_symbol: el.trading_symbol,
            formatted_ins_name: el.formatted_ins_name,
          };
        });
      // Target Days calculation
      const today = new Date();
      const targetDate = new Date(param_list[0].expiry);
      const timeDiff = Math.abs(targetDate.getTime() - today.getTime());
      let days_to_expire = 0;
      if (
        today.getDate() + today.getMonth() ==
        targetDate.getDate() + targetDate.getMonth()
      ) {
        days_to_expire = 0;
      } else {
        days_to_expire = Math.ceil(timeDiff / (1000 * 3600 * 24));
      }
      let spot_price =
        this.selectedInstrument.exchange == "NSE" ||
        this.selectedInstrument.exchange == "NFO"
          ? this.insSub(this.selectedInstrument.underlying_code)
            ? this.insSub(this.selectedInstrument.underlying_code).ltp
            : 0
          : this.insSub(this.selectedInstrument.fut_code)
          ? this.insSub(this.selectedInstrument.fut_code).ltp
          : 0;
      const payloadForChart = {
        user_id:this.userId,
        access_token:this.accessToken,
        ws_session: this.wsSession,
        param_list: param_list,
        path: "custom_strategy",
        broker: "aliceblue",
        name: this.selectedInstrument.name,
        lot_size: this.selectedInstrument.lot_size,
        exchange:
          this.selectedInstrument.exchange == "NSE"
            ? "NFO"
            : this.selectedInstrument.exchange,
        days_to_expire: days_to_expire,
        spot_price:spot_price,
        target_days: 0,
        iv_percent: 0,
      };
      this.payOffChartData = { payload: payloadForChart };
      this.payOffChartVisible = true;
    },

    getLixValue(list, index) {
      let param_list = {
        code: list.code,
        exchange: !this.isExitPositionsModal
          ? this.selectedInstrument.exchange
          : list.exchange,
        expiry: list.expiry,
        name: !this.isExitPositionsModal
          ? this.selectedInstrument.name
          : list.name,
        op_pr: list.ltp.toString(),
        quantity: parseInt(Math.abs(list.quantity)),
        segment:
          list.segment == "c" ? "CE" : list.segment == "p" ? "PE" : "FUT",
        strike: parseFloat(list.strike),
      };
      let payload = {
        user_id:this.userId,
        access_token: this.accessToken,
        ws_token:this.wsSession,
        param_list: [param_list],
      };
      axios.post(getLixData, payload).then((res) => {
        this.orderDataLength = this.orderDataLength + 1;
        if (res.data.param_list) {
          this.lixValueList.push(res.data.param_list[0].lix_value);
        } else {
          this.lixValueList.push(0);
        }
        this.$set(
          this.placeOrderData[index],
          "lixValueList",
          res.data.param_list[0].lix_value
        );
        if (this.orderDataLength == this.placeOrderData.length) {
          this.getSortedData();
        }
      });
    },
    getSortedData() {
      let buy_call = [];
      let buy_put = [];
      let sell_call = [];
      let sell_put = [];
      let fut = [];
      console.log(this.placeOrderData)
      for (let load of this.placeOrderData) {
        if (load.quantity > 0 && load.segment != "fut") {
          if (load.segment == "c") buy_call.push(load);
          else buy_put.push(load);
        } else if (load.quantity < 0 && load.segment != "fut") {
          if (load.segment == "c") sell_call.push(load);
          else sell_put.push(load);
        } else {
          fut.push(load);
        }
      }
      this.sortedPlaceOrderData = buy_call
        .concat(buy_put)
        .concat(sell_call)
        .concat(sell_put)
        .concat(fut);
      if (!this.isExitPositionsModal) {
        this.getMarginData();
      }
    },
    getMarginData() {
      let payload = {
        path: "margin",
        broker: "aliceblue",
        user_id: this.userId,
        access_token: this.accessToken,
        exchange: this.selectedInstrument.exchange,
        lot_size: this.selectedInstrument.lot_size,
        param_list: this.sortedPlaceOrderData,
      };
      axios
        .post(getMarginDataUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.requiredMargin = res.data.margin;
        });
    },
    getOrdersTable(code, index) {
    console.log(code, this.compactMarketDataScripts(code))
      this.$set(
        this.sortedPlaceOrderData[index],
        "orderTable",
        this.compactMarketDataScripts(code)["details"]
      );
      this.$set(
        this.sortedPlaceOrderData[index],
        "showMdTable",
        !this.sortedPlaceOrderData[index].showMdTable
      );
    },
    placeOrderApi() {
      const user_id =this.userId;
      const access_token = this.accessToken;
      const header = { Authorization: "Bearer " + user_id + " " + access_token };
      const pcode=this.isHoldingsSection && this.selectedMisLabel == "NRML" ? "CNC" : this.selectedMisLabel;
      let buyPayload = [];
      let sellPayload = [];
      for (let order of this.sortedPlaceOrderData) {
        if (order.quantity > 0 && order.segment != "fut") {
          buyPayload.push({
            complexty: "regular",
            exch: order.exchange,
            symbol_id: order.code,
            price: order.prctyp === "LIMIT" ? order.ltp.toString() : "",
            transtype: order.quantity < 0 ? "SELL" : "BUY",
            prctyp: order.prctyp == "MKT" ? "MKT" : "L",
            pCode: pcode,
            qty: Math.abs(order.quantity).toString(),
            ret: "DAY",
            trading_symbol: order.trading_symbol,
            orderTag:"1lyoptions",
            nestOrderNumber: order.nestOrderNumber,
            filledQuantity: order.filledQuantity
          });
        } else {
          sellPayload.push({
            complexty: "regular",
            exch: order.exchange,
            symbol_id: order.code,
            price: order.prctyp === "LIMIT" ? order.ltp.toString() : "",
            transtype: order.quantity < 0 ? "SELL" : "BUY",
            prctyp: order.prctyp == "MKT" ? "MKT" : "L",
            pCode: pcode,
            qty: Math.abs(order.quantity).toString(),
            ret: "DAY",
            trading_symbol: order.trading_symbol,
            orderTag:"1lyoptions",
            nestOrderNumber: order.nestOrderNumber,
            filledQuantity: order.filledQuantity
          });
        }
      }
      this.count = 0;
      axios
        .post(this.order_location=='order_book'?modifyOrderUrl:placeOrderUrl,this.order_location=='order_book'?buyPayload[0]: buyPayload, {
          headers: header,
        })
        .then((res) => {
          // this.show = false;
          // this.count = 0;
          this.columnLength += 1;
          for (let i = 0; i < buyPayload.length; i++) {
            this.sortedPlaceOrderData[i].status = "sent";
            this.getOrderHistoryAPI(res.data[i].NOrdNo, header, i);
          }
        });
          setTimeout(() => {
        axios
          .post(this.order_location=='order_book'?modifyOrderUrl:placeOrderUrl,this.order_location=='order_book'?sellPayload[0]:  sellPayload, {
            headers: header,
          })
          .then((res) => {
            // this.show = false;
            // this.count = 0;
            this.columnLength += 1;
            for (let i = 0; i < sellPayload.length; i++) {
              this.sortedPlaceOrderData[buyPayload.length + i].status = "sent";
              this.getOrderHistoryAPI(
                res.data[i].NOrdNo,
                header,
                buyPayload.length + i
              );
            }
          });
      }, 400);
    },
    getOrderHistoryAPI(order_no, header, index) {
      let payload = { nestOrderNumber: order_no };
      axios.post(getOrderHistoryUrl, payload, { headers: header }).then((res) => {
        this.sortedPlaceOrderData[index].status = res.data[0].Status;
        this.sortedPlaceOrderData[index].statusMessage =
          res.data[0].rejectionreason;
        // let updatedResponse = {
        //   ...res.data[0], // create a copy of the response object
        //   order_location: this.order_location, // add a new key-value pair to the object
        // };
        this.orderList.push({
          response: res.data[0],
          trading_symbol: res.data[0].Trsym,
        });
        if (res.data[0].Status != "rejected") {
          if (this.selectedLabel.includes("stoploss")) {
            this.executeOrderStoploss(this.sortedPlaceOrderData[index]);
          }
          if (this.selectedLabel.includes("targetPrice")) {
            this.executeOrderTargetPrice(this.sortedPlaceOrderData[index]);
          }
        }
        this.count = this.count + 1;
        if (this.count == this.sortedPlaceOrderData.length) {
          this.getSavedOrderAPI();
        }
      });
    },
    executeOrderStoploss(order) {
      let payload = {
        complexty: "regular",
        exch: order.exchange,
        symbol_id: order.code,
        trigPrice: order.stopLoss,
        price:
          order.stopLoss != ""
            ? order.segment == "fut"
              ? order.quantity > 0
                ? (
                    parseFloat(order.stopLoss) +
                    parseFloat(this.selectedInstrument.tick_size)
                  ).toString()
                : (
                    order.stopLoss - this.selectedInstrument.tick_size
                  ).toString()
              : order.quantity < 0
              ? (
                  parseFloat(order.stopLoss) +
                  parseFloat(this.selectedInstrument.tick_size)
                ).toString()
              : (order.stopLoss - this.selectedInstrument.tick_size).toString()
            : "",
        transtype: order.quantity < 0 ? "BUY" : "SELL",
        prctyp: "SL",
        pCode: this.selectedMisLabel,
        qty: Math.abs(order.quantity).toString(),
        ret: "DAY",
        trading_symbol: order.trading_symbol,
      };
      this.executeOrder(payload);
    },
    executeOrderTargetPrice(order) {
      let payload = {
        complexty: "regular",
        exch: order.exchange,
        symbol_id: order.code,
        price: order.targetPrice,
        transtype: order.quantity < 0 ? "BUY" : "SELL",
        prctyp: "L",
        pCode: this.selectedMisLabel,
        qty: Math.abs(order.quantity).toString(),
        ret: "DAY",
        trading_symbol: order.trading_symbol,
      };
      this.executeOrder(payload);
    },
    executeOrder(payload) {
      let user_id =this.userId;
      let access_token =this.accessToken;
      let header = { Authorization: "Bearer " + user_id + " " + access_token };
      payload={
        ...payload,
        orderTag:"1lyoptions",
      }
      axios
        .post(this.order_location=='order_book'?modifyOrderUrl:placeOrderUrl, this.order_location=='order_book'?payload: [payload], {
          headers: header,
        })
        .then((res) => {
          // this.show = false;
          console.log(res);
        });
    },
    getSavedOrderAPI() {
      let payload = {
        order_list: this.orderList,
        user_id:this.userId,
        order_location: this.order_location,
      };
      axios.post(saveOrderResponse, payload).then((res) => {
        if (res.data.status != 200) {
          // this.orderPlaced = true;
          this.$notify({
            group: "success",
            type: "error",
            title: "Error",
            text: "Order is rejected",
          });
        } else {
          EventBus.$emit("refreshPositionsScreen");
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: "Order has been sent successfully!",
          });
        }
      });
    },
    incDecInstrumentValues(change, value, index, isIncremented) {
      if (value == 0 && isIncremented) {
        this.$set(
          this.sortedPlaceOrderData[index],
          change,
          this.selectedInstrument.lot_size
        );
      } else if (value == 0 && !isIncremented) {
        this.$set(
          this.sortedPlaceOrderData[index],
          change,
          -this.selectedInstrument.lot_size
        );
      } else {
        this.$set(this.sortedPlaceOrderData[index], change, value);
      }
      this.getSortedData();
      this.calculateCharges();
    },
    changeInstrumentValues(change, value, index) {
      this.$set(this.sortedPlaceOrderData[index], change, value);
    },
    handleQuantityInput(event, change, index) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        if (
          event.target.value == "" ||
          isNaN(event.target.value) ||
          event.target.value == "0"
        ) {
          this.sortedPlaceOrderData[index].quantity =
            this.selectedInstrument.lot_size;
        } else {
          this.sortedPlaceOrderData[index].quantity = this.sortedPlaceOrderData[
            index
          ].quantity
            .toString()
            .replace(/[^\d]/g, "");
          let qty = Math.ceil(
            parseInt(event.target.value) / this.selectedInstrument.lot_size
          );
          if (qty == 0) {
            this.sortedPlaceOrderData[index][change] =
              this.selectedInstrument.lot_size;
          } else {
            this.sortedPlaceOrderData[index][change] =
              qty * this.selectedInstrument.lot_size;
          }
        }
        this.getSortedData();
      }, 1500);
    },
    setIntegerValue(event, change, index) {
      let value = event.target.value.toString().replace(/[^\d.]/g, "");
      if (value.indexOf(".") !== value.lastIndexOf(".")) {
        value = value.substring(0, value.length - 1);
      }
      this.$set(this.sortedPlaceOrderData[index], change, value);
    },
    getLixValueColor(val) {
      if (val >= 0 && val < 1) {
        return "zero";
      }
      if (val >= 1 && val < 2) {
        return "one";
      }
      if (val >= 2 && val < 3) {
        return "two";
      }
      if (val >= 3 && val < 4) {
        return "three";
      }
      if (val >= 4 && val < 5) {
        return "four";
      }
      if (val >= 5 && val < 6) {
        return "five";
      }
      if (val >= 6 && val < 7) {
        return "six";
      }
      if (val >= 7 && val < 8) {
        return "seven";
      }
      if (val >= 8 && val < 9) {
        return "eight";
      }
      if (val >= 9) {
        return "nine";
      }
    },
    cancelBtnClick() {
      this.$emit("clear-items");
      this.show = false;
      // if(this.orderPlaced){
      //   EventBus.$emit("refreshPositionsScreen");
      //   this.orderPlaced = false;
      // }
    },
    applyUserSettings() {
      if(!this.isPositionGraph){
        this.selectedMisLabel = this.userSettings.orderconfirmation_producttype;
      }else if(!this.isExitPositionsModal){
        this.selectedMisLabel = this.userSettings.orderconfirmation_producttype;
      }
      else{
        this.selectedMisLabel = this.strategyCreator[0].prod_type;
      }
    },
  },
  mounted() {
    this.loadPlaceOrderData();
    this.applyUserSettings();
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global.scss";

.v-dialog {
  font-family: "Gilroy-Medium" !important;
}
.header-tooltips {
  font-size: 0.8rem !important;
  text-align: center;
  color: var(--text-color);
  background-color: var(--cards-background-color);
  border: 1px solid var(--line-color) !important;
  border-radius: 4px;
}
.info-icon .v-icon.v-icon {
  color: var(--text-color) !important;
  font-size: 1rem !important;
  cursor: pointer;
}
thead th {
  color: var(--text-color) !important;
}

.place-order-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .mis-nrml-label {
    padding: 4px;
    // font-size: 1rem;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    display: flex;

    span {
      padding: 3px 12px;
      cursor: pointer;
      width: 58px;
      text-align: center;
      white-space: nowrap;
    }

    .selected-span {
      background-color: var(--primary-button-color);
      color: var(--primary-button-text-color);
      border-radius: 4px;
    }

    .unselected-span {
      background-color: transparent;
      color: var(--text-color);
    }
  }

  .stoploss-checkbox {
    display: flex;
    align-items: center;
    font-size: 1.125rem;

    .checkbox-label {
      font-size: 0.875rem;
      white-space: nowrap;
    }
  }
}

.mobile-instrument-header {
  background-color: var(--chip-background);
  padding: 4px 20px 4px 8px;
  color: var(--sub-text-color);
  font-size: var(--font-14);
}

.mobile-instrument-rows {
  padding: 4px 20px 4px 8px;
  color: var(--text-color);
  .mobile{
    font-size: var(--font-12) !important;

    .limit-input{
      padding:3px !important;
    }

  }
}
.placeorder-table-header {
  background: var(--chip-background);
  margin-top: 1rem;
  font-size: 1rem;

  th {
    color: var(--text-color);
    font-size: 0.875rem !important;
    border-bottom: none !important;
    font-weight: 400;
    height: 36px !important;
    // padding: 1rem !important;
  }
}

.parent-table td {
  color: var(--text-color) !important;
  border-bottom: none !important;
}

.plus-minus-field {
  max-width: 75px;
  display: flex;

  span {
    cursor: pointer;
  }

  .minus,
  .plus {
    cursor: pointer;
    width: 25px;
    height: 36px;
    background: var(--border-color);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding-top: 9px;
  }

  .minus {
    border-radius: 4px 0 0 4px;
  }

  .plus {
    border-radius: 0 4px 4px 0;
  }

  input {
    width: 100%;
    height: 36px;
    min-width: 44px;
    font-size: 1.1rem;
    text-align: center;
    border: 1px solid var(--plus-minus-background);
    background-color: transparent;
    outline: none;
  }

  .mobile-field{
    height: 27px !important;
  }
}

.v-chip.v-size--large {
  height: 36px !important;
  font-size: 1.3rem !important;
}

.mkt-limit-label {
  padding: 4px;
  font-size: 1rem;
  border-radius: 4px;
  background-color: var(--chip-background);
  height: 36px;
  display: flex;
  align-items: center;

  span {
    padding: 3px 12px;
    cursor: pointer;
  }

  .selected-mkt {
    background-color: var(--dialog-background);
    color: var(--primary-button-color);
    border-radius: 4px;
  }

  .unselected-mkt {
    color: var(--text-color);
  }
}
.mkt-limit-label.mobile{
  padding: 0;
  height: 24px;
}

.order-qty-field {
  position: relative;

  div {
    position: absolute;
    /* top: 8px; */
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mobile {
    left: 0 !important;
    transform: translate(0, 0) !important;

    span{
      padding: 3px 6px !important;
    }
  }
  .mobile-text{
    font-size: var(--font-12) !important;

  }
}

.limit-input {
  border: 1px solid var(--border-color);
  padding: 8px;
  margin: 2px 0;
  border-radius: 4px;
  color: var(--text-color);
  max-width: 145px;
  width: 90%;
}

.limit-input:focus {
  outline: 1px solid var(--border-color);
}

input:disabled {
  color: var(--text-color);
  opacity: 0.5;
}

.footer-labels {
  color: var(--text-color);

  .label {
    padding: 8px 5px;
  }
}

.zero {
  color: #8b0000 !important;
  background-color: transparent !important;
}

.one {
  color: #ce5b4a !important;
  background-color: transparent !important;
}

.two {
  color: #be6648 !important;
  background-color: transparent !important;
}

.three {
  color: #ad7046 !important;
  background-color: transparent !important;
}

.four {
  color: #9d7b44 !important;
  background-color: transparent !important;
}

.five {
  color: #8c8541 !important;
  background-color: transparent !important;
}

.six {
  color: #7c903f !important;
  background-color: transparent !important;
}

.seven {
  color: #6b9a3d !important;
  background-color: transparent !important;
}

.eight {
  color: #5ba53b !important;
  background-color: transparent !important;
}

.nine {
  color: #4aaf39 !important;
  background-color: transparent !important;
}

.ten {
  color: #54af39 !important;
  background-color: transparent !important;
}

.click_btn {
  color: #2196f3 !important;
  text-decoration: underline;
}

.market-table th,
td {
  height: 29px !important;
  padding: 0.2rem !important;
}

@media screen and (max-width: 600px) {
  .footer-section {
    .v-btn {
      height: 36px !important;
      width: 36%;
    }

    .footer-labels .label {
      padding: 8px 0;
    }
  }
}
</style>
<style>
.place-order-table .v-data-table__wrapper > table {
  min-width: 750px;
}

.order-container {
  position: relative;
  padding: 0;
}

/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
					animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}
</style>

<style lang="scss">
// FORCED CSS vuetify components
.place-order-toolbar-title,
.place-order-toolbar-title .v-toolbar__content {
  height: auto !important;
}
</style>
