<template>
  <div class="settings-wrapper">
    <!-- mobile view -->
    <v-tabs v-if="screenWidth < 600">
      <v-tab v-for="(tab, index) in tabs" :key="index" class="tabs-box-mobile">
        <v-icon color="var(--text-color)">{{ tab.icon }}</v-icon>
      </v-tab>
      <v-divider></v-divider>
      <!-- Tabs Data -->
      <!-- General -->
      <v-tab-item class="scrollable-tab-item">
        <p class="mobile-heading">General</p>
        <v-card flat>
          <p>Modes</p>
          <v-radio-group v-model="general_modes" row>
            <v-radio
              label="Light Mode"
              value="light_mode"
              color="var(--switch-color)"
              @click="onSwitched(false)"
            ></v-radio>
            <v-radio
              label="Dark Mode"
              value="dark_mode"
              color="var(--switch-color)"
              @click="onSwitched(true)"
            ></v-radio>
          </v-radio-group>
          <br />
          <p>Font Size</p>
          <v-radio-group v-model="general_fontSize" row>
            <v-radio
              label="S"
              value="s"
              color="var(--switch-color)"
              @click="setFontS()"
            ></v-radio>
            <v-radio
              label="M"
              value="m"
              color="var(--switch-color)"
              @click="setFontM()"
            ></v-radio>
            <v-radio
              label="L"
              value="l"
              color="var(--switch-color)"
              @click="setFontL()"
            ></v-radio>
          </v-radio-group>
          <br />
          <p>Landing Page</p>
          <div class="radio-group">
            <v-radio-group v-model="general_landingPage" row>
              <v-radio
                label="Dashboard"
                value="Dashboard"
                color="var(--switch-color)"
                style="margin-bottom: 5px"
              ></v-radio>

              <v-radio
                label="Position"
                value="Position"
                color="var(--switch-color)"
                style="margin-bottom: 5px"
              ></v-radio>

              <v-radio
                label="Option Chain"
                value="OptionChain"
                color="var(--switch-color)"
                style="margin-bottom: 5px"
              ></v-radio>

              <v-radio
                label="Straddle Premium"
                value="OptionScanner"
                color="var(--switch-color)"
                style="margin-bottom: 5px"
              ></v-radio>

              <v-radio
                label="Open Interest"
                value="OpenInterestFilter"
                color="var(--switch-color)"
                style="margin-bottom: 5px"
              ></v-radio>

              <v-radio
                label="Order Book"
                value="Orderbook"
                color="var(--switch-color)"
                style="margin-bottom: 5px"
              ></v-radio>
            </v-radio-group>
          </div>
        </v-card>
        <v-card-actions class="fixed-bottom">
          <v-spacer></v-spacer>
          <v-btn class="apply-btn" @click="apply(), openPopup()">Apply</v-btn>
          <v-btn class="reset-btn" @click="reset()">Reset</v-btn>
        </v-card-actions>
      </v-tab-item>
      <!-- Dashboard -->
      <v-tab-item class="scrollable-tab-item">
        <p class="mobile-heading">Dashboard</p>
        <v-card flat>
          <p>Preferred Symbol</p>
          <v-autocomplete
            v-model="dashboard_symbol"
            :items="symbol_list"
            item-text="name"
            auto-select-first
            outlined
            class="select-box"
            dense
          ></v-autocomplete>
          <br />
          <p>Preferred Strategy</p>
          <v-autocomplete
            v-model="dashboard_strategy"
            :items="dashboard_strategy_list"
            item-text="name"
            item-value="value"
            auto-select-first
            outlined
            class="select-box"
            dense
          >
            <template v-slot:item="{ item }">
              <template v-if="item.isHeading">
                <div class="heading">{{ item.name }}</div>
              </template>
              <template v-else>
                <div>
                  {{ item.name }}:
                  <span :class="getColorClass(item.label)">{{
                    item.label
                  }}</span>
                </div>
              </template>
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="!item.isHeading">
                {{ item.name }}
              </span>
            </template>
          </v-autocomplete>
          <br />
          <p>Preferred Expiry</p>
          <v-select
            v-model="dashboard_expiry"
            :items="['Current Week', 'Current Month']"
            outlined
            class="select-box"
            dense
          ></v-select>
        </v-card>
        <br />
        <v-card-actions class="fixed-bottom">
          <v-spacer></v-spacer>
          <v-btn class="apply-btn" @click="apply(), openPopup()">Apply</v-btn>
          <v-btn class="reset-btn" @click="reset()">Reset</v-btn>
        </v-card-actions>
      </v-tab-item>
      <!-- option chain -->
      <v-tab-item class="scrollable-tab-item">
        <!-- <div class="scrollable-content"> -->
        <p class="mobile-heading">Option Chain</p>
        <v-card flat>
          <v-card-text>
            <p>Preferred Symbol</p>
            <v-autocomplete
              v-model="optionChain_symbol"
              :items="symbol_list"
              item-text="name"
              auto-select-first
              outlined
              class="select-box"
              dense
            ></v-autocomplete>
            <br />
            <p>Preferred Expiry</p>
            <v-select
              v-model="optionChain_expiry"
              :items="['Current Week', 'Current Month']"
              outlined
              class="select-box"
              dense
            ></v-select>
            <br />
            <p>Preferred Option Chain Tabs</p>
            <v-radio-group v-model="optionChain_settings" row>
              <v-radio
                label="Greeks"
                value="greeks"
                color="var(--switch-color)"
              ></v-radio>
              <v-radio
                label="LTP"
                value="ltp"
                color="var(--switch-color)"
              ></v-radio>
            </v-radio-group>
            <v-row>
              <v-col cols="12" md="6" class="d-flex align-center">
                <v-subheader class="pl-2 pr-2 slider-text">
                  No. of Strikes
                </v-subheader>
                <v-slider
                color="var(--primary-button-color)"
                always-dirty
                :thumb-size="24"
                hide-details
                v-model="optionChain_slider"
                track-color="#e0e0e0"
                  thumb-label="always"
                ></v-slider>
              </v-col>
            </v-row>
            <p>Greeks:</p>
            <v-sheet class="pa-5 toggle-btn">
              <v-row style="justify-content: space-between">
                <span>Gamma</span>
                <v-switch
                  v-model="optionChain_greeksGamma"
                  inset
                  class="small-switch"
                  color="var(--switch-color)"
                ></v-switch>
              </v-row>
              <v-row style="justify-content: space-between">
                <span>Theta</span>
                <v-switch
                  v-model="optionChain_greeksTheta"
                  inset
                  class="small-switch"
                  color="var(--switch-color)"
                ></v-switch>
              </v-row>
              <v-row style="justify-content: space-between">
                <span>Vega</span>
                <v-switch
                  v-model="optionChain_greeksVega"
                  inset
                  class="small-switch"
                  color="var(--switch-color)"
                ></v-switch>
              </v-row>
              <v-row style="justify-content: space-between">
                <span>Delta</span>
                <v-switch
                  v-model="optionChain_greeksDelta"
                  inset
                  class="small-switch"
                  color="var(--switch-color)"
                ></v-switch>
              </v-row>
            </v-sheet>
            <v-divider></v-divider>
            <br />
            <p>LTP:</p>
            <v-sheet class="pa-5 toggle-btn">
              <v-row style="justify-content: space-between">
                <span>OI Change %</span>
                <v-switch
                  v-model="optionChain_ltpOIchange"
                  inset
                  class="small-switch"
                  color="var(--switch-color)"
                ></v-switch>
              </v-row>
              <v-row style="justify-content: space-between">
                <span>Bid Offer</span>
                <v-switch
                  v-model="optionChain_ltpBID"
                  inset
                  class="small-switch"
                  color="var(--switch-color)"
                ></v-switch>
              </v-row>
              <v-row style="justify-content: space-between">
                <span>Volume</span>
                <v-switch
                  v-model="optionChain_ltpVolume"
                  inset
                  class="small-switch"
                  color="var(--switch-color)"
                ></v-switch>
              </v-row>
              <v-row style="justify-content: space-between">
                <span>LIX</span>
                <v-switch
                  v-model="optionChain_ltpLIX"
                  inset
                  class="small-switch"
                  color="var(--switch-color)"
                ></v-switch>
              </v-row>
              <v-row style="justify-content: space-between">
                <span>OI</span>
                <v-switch
                  v-model="optionChain_ltpOI"
                  inset
                  class="small-switch"
                  color="var(--switch-color)"
                ></v-switch>
              </v-row>
              <v-row style="justify-content: space-between">
                <span>OI Buildup</span>
                <v-switch
                  v-model="optionChain_ltpOIbuild"
                  inset
                  class="small-switch"
                  color="var(--switch-color)"
                ></v-switch>
              </v-row>
            </v-sheet>
          </v-card-text>
        </v-card>
        <!-- </div> -->
        <v-card-actions
          class="fixed-bottom"
          style="position: sticky !important"
        >
          <v-spacer></v-spacer>
          <v-btn class="apply-btn" @click="apply(), openPopup()">Apply</v-btn>
          <v-btn class="reset-btn" @click="reset()">Reset</v-btn>
        </v-card-actions>
      </v-tab-item>
      <!-- Place Order -->
      <v-tab-item class="scrollable-tab-item">
        <p class="mobile-heading">Place Order</p>
        <v-card flat>
          <v-card-text>
            <p>Order Confirmation</p>
            <div class="table-toggler">
              <v-btn-toggle
                mandatory
                dense
                v-model="placeOrder_confirmation"
                active-class="active-tab"
              >
                <v-btn small value="MIS">MIS</v-btn>
                <v-btn small value="NRML">NRML</v-btn>
              </v-btn-toggle>
            </div>
            <br />
            <p>Order</p>
            <div class="table-toggler">
              <v-btn-toggle
                mandatory
                dense
                v-model="placeOrder_order"
                active-class="active-tab"
              >
                <v-btn small value="MKT">MKT</v-btn>
                <v-btn small value="LIMIT">LIMIT</v-btn>
              </v-btn-toggle>
            </div>
          </v-card-text>
        </v-card>
        <br />
        <v-card-actions class="fixed-bottom">
          <v-spacer></v-spacer>
          <v-btn class="apply-btn" @click="apply(), openPopup()">Apply</v-btn>
          <v-btn class="reset-btn" @click="reset()">Reset</v-btn>
        </v-card-actions>
      </v-tab-item>
        <!-- Verified P&L-->
        <v-tab-item class="scrollable-tab-item">
          <p class="mobile-heading">Verified P&L</p>
          <v-card flat>
            <v-card-text>
            <p>Username</p>
            <div class="table-toggler">
              <v-btn-toggle
                mandatory
                dense
                v-model="username_visibility"
                active-class="active-tab"
              >
                <v-btn small value="masked">Masked</v-btn>
                <v-btn small value="unmasked">Unmasked</v-btn>
              </v-btn-toggle>
            </div>
            </v-card-text>
          </v-card>
          <br />
          <v-card-actions class="fixed-bottom">
            <v-spacer></v-spacer>
            <v-btn class="apply-btn" @click="apply(), openPopup()">Apply</v-btn>
            <v-btn class="reset-btn" @click="reset()">Reset</v-btn>
          </v-card-actions>
        </v-tab-item>
    </v-tabs>

    <!-- desktop view -->
    <v-tabs vertical v-else>
      <p class="heading-text" style="padding-top: 16px; padding-bottom: 8px">
        Settings
      </p>
      <!-- Select Tabs -->
      <v-tab
        class="tabs-text tabs-box"
        v-for="(tab, index) in tabs"
        :key="index"
        style="margin-bottom: 15px"
      >
        <v-icon left color="var(--text-color)">{{ tab.icon }}</v-icon>
        {{ tab.label }}
      </v-tab>
      <v-card-actions style="position: absolute;
      bottom: 0;
      width: 92%;">
        <v-spacer></v-spacer>
        <v-btn class="apply-btn" @click="apply(), openPopup()">Apply</v-btn>
        <v-btn class="reset-btn" @click="reset()">Reset</v-btn>
      </v-card-actions>
      <!-- Tabs Data -->
      <!-- General -->
      <v-tab-item style="margin-left: 10px" class="scrollable-tab-item">
        <v-card flat>
          <p>Modes</p>
          <v-radio-group v-model="general_modes" row>
            <v-radio
              label="Light Mode"
              value="light_mode"
              color="var(--switch-color)"
              @click="onSwitched(false)"
            ></v-radio>
            <v-radio
              label="Dark Mode"
              value="dark_mode"
              color="var(--switch-color)"
              @click="onSwitched(true)"
            ></v-radio>
          </v-radio-group>
          <br />
          <p>Font Size</p>
          <v-radio-group v-model="general_fontSize" row>
            <v-radio
              label="S"
              value="s"
              color="var(--switch-color)"
              @click="setFontS()"
            ></v-radio>
            <v-radio
              label="M"
              value="m"
              color="var(--switch-color)"
              @click="setFontM()"
            ></v-radio>
            <v-radio
              label="L"
              value="l"
              color="var(--switch-color)"
              @click="setFontL()"
            ></v-radio>
          </v-radio-group>
          <br />
          <p>Landing Page</p>
          <div class="radio-group">
            <v-radio-group v-model="general_landingPage" row>
              <table>
                <tr>
                  <td>
                    <v-radio
                      label="Dashboard"
                      value="Dashboard"
                      color="var(--switch-color)"
                    ></v-radio>
                  </td>
                  <td>
                    <v-radio
                      label="Option Chain"
                      value="OptionChain"
                      color="var(--switch-color)"
                    ></v-radio>
                  </td>
                  <td>
                    <v-radio
                      label="Open Interest"
                      value="OpenInterestFilter"
                      color="var(--switch-color)"
                    ></v-radio>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <v-radio label="Position" value="Position"></v-radio>
                  </td>
                  <td>
                    <v-radio
                      label="Straddle Premium"
                      value="OptionScanner"
                      color="var(--switch-color)"
                    ></v-radio>
                  </td>
                  <td>
                    <v-radio
                      label="Order Book"
                      value="Orderbook"
                      color="var(--switch-color)"
                    ></v-radio>
                  </td>
                </tr>
              </table>
            </v-radio-group>
          </div>
        </v-card>
      </v-tab-item>
      <!-- Dashboard -->
      <v-tab-item style="margin-left: 10px" class="scrollable-tab-item">
        <v-card flat>
          <p>Preferred Symbol</p>
          <v-autocomplete
            v-model="dashboard_symbol"
            :items="symbol_list"
            item-text="name"
            auto-select-first
            outlined
            class="select-box"
            dense
          ></v-autocomplete>
          <br />
          <p>Preferred Strategy</p>
          <v-autocomplete
            v-model="dashboard_strategy"
            :items="dashboard_strategy_list"
            item-text="name"
            item-value="value"
            auto-select-first
            outlined
            class="select-box"
            dense
          >
            <template v-slot:item="{ item }">
              <template v-if="item.isHeading">
                <div class="heading">{{ item.name }}</div>
              </template>
              <template v-else>
                <div>
                  {{ item.name }}:
                  <span :class="getColorClass(item.label)">{{
                    item.label
                  }}</span>
                </div>
              </template>
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="!item.isHeading">
                {{ item.name }}
              </span>
            </template>
          </v-autocomplete>
          <br />
          <p>Preferred Expiry</p>
          <v-select
            v-model="dashboard_expiry"
            :items="['Current Week', 'Current Month']"
            outlined
            class="select-box"
            dense
          ></v-select>
        </v-card>
      </v-tab-item>
      <!-- option chain -->
      <v-tab-item style="margin-left: 10px" class="scrollable-tab-item">
        <v-card flat>
          <v-card-text>
            <p>Preferred Symbol</p>
            <v-autocomplete
              v-model="optionChain_symbol"
              :items="symbol_list"
              item-text="name"
              auto-select-first
              outlined
              class="select-box"
              dense
            ></v-autocomplete>
            <br />
            <p>Preferred Expiry</p>
            <v-select
              v-model="optionChain_expiry"
              :items="['Current Week', 'Current Month']"
              outlined
              class="select-box"
              dense
            ></v-select>
            <br />
            <p>Preferred Option Chain Tabs</p>
            <v-radio-group v-model="optionChain_settings" row>
              <v-radio
                label="Greeks"
                value="greeks"
                color="var(--switch-color)"
              ></v-radio>
              <v-radio
                label="LTP"
                value="ltp"
                color="var(--switch-color)"
              ></v-radio>
            </v-radio-group>
            <v-row>
              <v-col cols="12" md="6" class="d-flex align-center">
                <v-subheader class="pl-2 pr-2 slider-text">
                  No. of Strikes
                </v-subheader>
                <v-slider
                  min="10"
                  max="90"
                  color="var(--primary-button-color)"
                  always-dirty
                  :thumb-size="24"
                  hide-details
                  v-model="optionChain_slider"
                  track-color="#e0e0e0"
                    thumb-label="always"
                ></v-slider>
              </v-col>
            </v-row>
            <p>Greeks:</p>
            <v-sheet class="pa-5" style="margin-left: -30px">
              <table class="table">
                <tr>
                  <td>
                    <v-switch
                      v-model="optionChain_greeksGamma"
                      inset
                      :label="'Gamma'"
                      class="small-switch"
                      color="var(--switch-color)"
                    ></v-switch>
                  </td>
                  <td></td>
                  <!-- Empty column -->
                  <td>
                    <v-switch
                      v-model="optionChain_greeksTheta"
                      inset
                      :label="'Theta'"
                      class="small-switch"
                      color="var(--switch-color)"
                    ></v-switch>
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td>
                    <v-switch
                      v-model="optionChain_greeksVega"
                      inset
                      :label="'Vega'"
                      class="small-switch"
                      color="var(--switch-color)"
                    ></v-switch>
                  </td>
                  <td></td>
                  <!-- Empty column -->
                  <td>
                    <v-switch
                      v-model="optionChain_greeksDelta"
                      inset
                      :label="'Delta'"
                      class="small-switch"
                      color="var(--switch-color)"
                    ></v-switch>
                  </td>
                </tr>
              </table>
            </v-sheet>
            <v-divider></v-divider>
            <br />
            <p>LTP:</p>
            <v-sheet class="pa-5" style="margin-left: -30px">
              <table class="table">
                <tr>
                  <td>
                    <v-switch
                      v-model="optionChain_ltpVolume"
                      inset
                      :label="'Volume'"
                      class="small-switch"
                      color="var(--switch-color)"
                    ></v-switch>
                  </td>
                  <td></td>
                  <!-- Empty column -->
                  <td>
                    <v-switch
                      v-model="optionChain_ltpBID"
                      inset
                      :label="'Bid Offer'"
                      class="small-switch"
                      color="var(--switch-color)"
                    ></v-switch>
                  </td>
                  <td></td>
                  <!-- Empty column -->
                  <td>
                    <v-switch
                      v-model="optionChain_ltpOIchange"
                      inset
                      :label="'OI Change %'"
                      class="small-switch"
                      color="var(--switch-color)"
                      v-if="false"
                    ></v-switch>
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td>
                    <v-switch
                      v-model="optionChain_ltpLIX"
                      inset
                      :label="'LIX'"
                      class="small-switch"
                      color="var(--switch-color)"
                    ></v-switch>
                  </td>
                  <td></td>
                  <!-- Empty column -->
                  <td>
                    <v-switch
                      v-model="optionChain_ltpOI"
                      inset
                      :label="'OI'"
                      class="small-switch"
                      color="var(--switch-color)"
                    ></v-switch>
                  </td>
                  <td></td>
                  <!-- Empty column -->
                  <td>
                    <v-switch
                      v-model="optionChain_ltpOIbuild"
                      inset
                      :label="'OI Buildup'"
                      class="small-switch"
                      color="var(--switch-color)"
                      v-if="false"
                    ></v-switch>
                  </td>
                </tr>
              </table>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- Place Order -->
      <v-tab-item style="margin-left: 10px" class="scrollable-tab-item">
        <v-card flat>
          <v-card-text>
            <p>Order Confirmation</p>
            <div class="table-toggler">
              <v-btn-toggle
                mandatory
                dense
                v-model="placeOrder_confirmation"
                active-class="active-tab"
              >
                <v-btn small value="MIS">MIS</v-btn>
                <v-btn small value="NRML">NRML</v-btn>
              </v-btn-toggle>
            </div>
            <br />
            <p>Order</p>
            <div class="table-toggler">
              <v-btn-toggle
                mandatory
                dense
                v-model="placeOrder_order"
                active-class="active-tab"
              >
                <v-btn small value="MKT">MKT</v-btn>
                <v-btn small value="LIMIT">LIMIT</v-btn>
              </v-btn-toggle>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
       <!-- Verified P&L-->
       <v-tab-item style="margin-left: 10px" class="scrollable-tab-item">
        <v-card flat>
          <v-card-text>
            <p>Username</p>
            <div class="table-toggler">
              <v-btn-toggle
                mandatory
                dense
                v-model="username_visibility"
                active-class="active-tab"
              >
                <v-btn medium value="masked">Masked</v-btn>
                <v-btn medium value="unmasked">Unmasked</v-btn>
              </v-btn-toggle>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <!-- Apply Settings popup -->
    <v-dialog v-model="showPopup">
      <v-card class="apply-popup">
        <img
          :src="require('@/assets/LightMode/checked.svg')"
          alt=""
          style="margin-left: 92px"
        />
        <v-card-title style="justify-content: center; margin-top: -13px"
          >Settings Applied!</v-card-title
        >
        <v-card-text style="text-align: center; color: var(--text-color)">
          Your changes have been successfully applied. Enjoy your personalized
          experience with 1LY Options.
        </v-card-text>
        <v-btn
          color="var(--cards-background-color) !important"
          text
          @click="closePopup()"
          class="popup-btn"
          >OK</v-btn
        >
      </v-card>
    </v-dialog>

    <!-- Discard changes popup -->
    <v-dialog v-model="discardChanges">
      <v-card class="apply-popup">
        <img
          :src="require('@/assets/LightMode/warning.svg')"
          alt=""
          style="margin-left: 92px"
        />
        <v-card-title style="justify-content: center; margin-top: -13px"
          >Warning!</v-card-title
        >
        <v-card-text style="text-align: center; color: var(--text-color)">
          You have unsaved changes. Are you sure you want to leave this page
          without saving?
        </v-card-text>
        <v-btn
          class="reset-btn"
          @click="closeDiscardPopup()"
          style="width: 45% !important"
          >Discard</v-btn
        >
        <span class="mr-4"></span>
        <v-btn
          class="apply-btn"
          @click="saveChanges()"
          style="width: 45% !important"
          >Save</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { EventBus } from "../main";
import {
  getTrendsAndSymbolsUrl,
  getStrategyStructure,
  getUserSettingsUrl,
  updateUserSettingsUrl,
} from "./getService.js";
import {
  BULLISH_STRATEGY,
  BEARISH_STRATEGY,
  NEUTRAL_STRATEGY,
} from "./Dashboard/dashboardConstants.js";
import UserDataMixin from "@/mixins/getUserDataMixin";

export default {
  mounted() {
    this.getTrendsAndSymbols();
    this.getSavedStrategies();
    this.getUserSettings();
    this.updateScreenWidth();
    this.onScreenResize();
  },
  watch: {
    darkMode: function (val) {
      document.documentElement.setAttribute(
        "data-theme",
        val ? "dark" : "light"
      );
    },
  },
  mixins:[UserDataMixin],
  data() {
    return {
      screenWidth: 0,
      symbol_list: [],
      showPopup: false,
      discardChanges: false,
      hideTabs: false,
      tabs: [
        { label: "General", icon: "mdi-cog-outline" },
        { label: "Dashboard", icon: "mdi-account-circle-outline" },
        { label: "Option Chain", icon: "mdi-link-variant" },
        { label: "Place Order", icon: "mdi-cart-outline" },
        {label:"Verified P&L", icon:"mdi-check-decagram-outline"}
      ],
      //General Variables
      general_modes: "light_mode",
      general_fontSize: "m",
      general_landingPage: "Dashboard",

      //Dashboard Variables
      dashboard_symbol: "NIFTY",
      dashboard_strategy: "buy_call",
      dashboard_expiry: "Current Week",
      dashboard_strategy_list: [
        {
          name: "Pre-Built",
          isHeading: true,
        },
        ...BULLISH_STRATEGY.map((strategy) => ({
          name: strategy.label,
          value: strategy.value,
          label: "Bullish",
          isHeading: false,
        })),
        ...BEARISH_STRATEGY.map((strategy) => ({
          name: strategy.label,
          value: strategy.value,
          label: "Bearish",
          isHeading: false,
        })),
        ...NEUTRAL_STRATEGY.map((strategy) => ({
          name: strategy.label,
          value: strategy.value,
          label: "Neutral",
          isHeading: false,
        })),
        {
          name: "Saved",
          isHeading: true,
        },
      ],

      //Option Chain Variables
      optionChain_symbol: "NIFTY",
      optionChain_expiry: "Current Week",
      optionChain_settings: "ltp",
      optionChain_slider: "50",
      optionChain_greeksGamma: true,
      optionChain_greeksTheta: true,
      optionChain_greeksVega: true,
      optionChain_greeksDelta: true,
      optionChain_ltpOIchange: true,
      optionChain_ltpBID: true,
      optionChain_ltpVolume: true,
      optionChain_ltpLIX: true,
      optionChain_ltpOI: true,
      optionChain_ltpOIbuild: true,

      //Place Order Variables
      placeOrder_confirmation: "MIS",
      placeOrder_order: "MKT",

      //Local Storage Variables
     
      instrumentList: [],
      username_visibility:"masked",
    };
  },
  name: "Settings",
  methods: {
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    openPopup() {
      this.showPopup = true;
    },
    openDiscardPopup() {
      this.discardChanges = true;
    },
    saveChanges() {
      this.apply();
      this.closeDiscardPopup();
    },
    closePopup() {
      this.showPopup = false;
    },
    closeDiscardPopup() {
      this.discardChanges = false;
      this.reset();
    },
    onSwitched(switched) {
      this.$store.commit("setDarkMode", switched);
    },

    // @function to get saved user settings
    getUserSettings() {
      let payload = {
        user_id: this.userId,
        path: "get_user_settings",
        access_token: this.accessToken,
      };
      axios
        .post(getUserSettingsUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          let settings = res.data;
          localStorage.setItem("user_settings", JSON.stringify(settings));
          // this.$store.commit("setUserSettings", userSettings);
          //General Variables
          this.general_modes = settings.modes;
          this.general_fontSize = settings.font_size;
          this.general_landingPage = settings.landing_page;

          //Dashboard Variables
          this.dashboard_symbol = settings.dashboard_symbol;
          this.dashboard_strategy = settings.dashboard_strategy=="Buy Call"?'buy_call':settings.dashboard_strategy;
          this.dashboard_expiry = settings.dashboard_expiry;

          //Option Chain Variables
          this.optionChain_symbol = settings.optionchain_symbol;
          this.optionChain_expiry = settings.optionchain_expiry;
          this.optionChain_settings = settings.optionchain_default_tab;
          this.optionChain_slider = settings.optionchain_default_strikes;
          this.optionChain_greeksGamma = settings.optionchain_gamma;
          this.optionChain_greeksTheta = settings.optionchain_theta;
          this.optionChain_greeksVega = settings.optionchain_vega;
          this.optionChain_greeksDelta = settings.optionchain_delta;
          this.optionChain_ltpOIchange = settings.optionchain_oichange;
          this.optionChain_ltpBID = settings.optionchain_bid;
          this.optionChain_ltpVolume = settings.optionchain_volume;
          this.optionChain_ltpLIX = settings.optionchain_lix;
          this.optionChain_ltpOI = settings.optionchain_oi;
          this.optionChain_ltpOIbuild = settings.optionchain_oibuildup;

          //Place Order Variables
          this.placeOrder_confirmation = settings.orderconfirmation_producttype;
          this.placeOrder_order = settings.orderconfirmation_ordertype;
          this.username_visibility = settings.mask_username?'masked':'unmasked';
          if (this.general_modes == "dark_mode") {
            this.onSwitched(true);
          } else {
            this.onSwitched(false);
          }
          if (this.general_fontSize == "s") {
            this.setFontS();
          } else if (this.general_fontSize == "l") {
            this.setFontL();
          } else {
            this.setFontM();
          }
        });
    },
    setFontS() {
      let newSize = "1vw";
      if (this.screenWidth < 601) {
        newSize = "2.55vw";
      } else if (this.screenWidth < 961) {
        newSize = "1.5vw";
      } else {
        newSize = "0.85vw";
      }
      console.log(newSize);
      document.documentElement.style.fontSize = newSize;
    },
    setFontM() {
      let newSize = "1vw";
      if (this.screenWidth < 601) {
        newSize = "3vw";
      } else if (this.screenWidth < 961) {
        newSize = "1.7vw";
      } else {
        newSize = "0.9vw";
      }
      document.documentElement.style.fontSize = newSize;
    },
    setFontL() {
      let newSize = "1vw";
      if (this.screenWidth < 601) {
        newSize = "3.3vw";
      } else if (this.screenWidth < 961) {
        newSize = "1.8vw";
      } else {
        newSize = "1vw";
      }
      document.documentElement.style.fontSize = newSize;
    },
    // @function to get list of symbols
    getTrendsAndSymbols() {
      let payload = {
        path: "dashboard_symbols",
        broker: "aliceblue",
        user_id: this.userId,
        access_token: this.accessToken,
      };
      axios
        .post(getTrendsAndSymbolsUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          let newInsList = [];
          for (let ins of res.data) {
            if (
              ins.name === "NIFTY" ||
              ins.name === "FINNIFTY" ||
              ins.name === "BANKNIFTY"
            ) {
              newInsList.push(ins);
            } else {
              this.instrumentList.push(ins);
            }
          }
          this.instrumentList = newInsList.concat(this.instrumentList);
          this.symbol_list = this.instrumentList;
        });
    },
    // @function to get list of saved strategies
    getSavedStrategies() {
      this.savedStrategies = [];
      let payload = {
        user_id:this.userId,
      };
      axios.post(getStrategyStructure, payload).then((res) => {
        this.savedStrategies = res.data;

        // Append savedStrategies to dashboard_strategy_list
        this.dashboard_strategy_list = this.dashboard_strategy_list.concat(
          this.savedStrategies.map((strategy) => ({
            name: strategy.name,
            label: strategy.direction,
          }))
        );
      });
    },
    // this applies dynamic colors to the selected trend
    getColorClass(label) {
      if (label === "Bullish") {
        return "label-color1";
      } else if (label === "Bearish") {
        return "label-color2";
      } else {
        return "label-color3";
      }
    },
    // This created a JSON object for the api
    apply() {
      const payload = {
        user_id: this.userId,
        path: "update_user_settings",
        access_token: this.accessToken,
        modes: this.general_modes,
        font_size: this.general_fontSize,
        landing_page: this.general_landingPage,
        dashboard_symbol: this.dashboard_symbol,
        dashboard_strategy: this.dashboard_strategy,
        dashboard_expiry: this.dashboard_expiry,
        optionchain_symbol: this.optionChain_symbol,
        optionchain_expiry: this.optionChain_expiry,
        optionchain_default_tab: this.optionChain_settings,
        optionchain_default_strikes: this.optionChain_slider,
        optionchain_gamma: this.optionChain_greeksGamma,
        optionchain_theta: this.optionChain_greeksTheta,
        optionchain_delta: this.optionChain_greeksDelta,
        optionchain_vega: this.optionChain_greeksVega,
        optionchain_oichange: this.optionChain_ltpOIchange,
        optionchain_bid: this.optionChain_ltpBID,
        optionchain_volume: this.optionChain_ltpVolume,
        optionchain_lix: this.optionChain_ltpLIX,
        optionchain_oi: this.optionChain_ltpOI,
        optionchain_oibuildup: this.optionChain_ltpOIbuild,
        orderconfirmation_producttype: this.placeOrder_confirmation,
        orderconfirmation_ordertype: this.placeOrder_order,
        public_verified: true,
        mask_username: this.username_visibility=="masked"?true:false,
      };
      localStorage.setItem("user_settings", JSON.stringify(payload));
      // this.$store.commit("setUserSettings", payload);
      EventBus.$emit('user_settings', payload)
      axios
        .post(updateUserSettingsUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          console.log(res.data, "Settings Updated");
          this.reset();
        });
    },
    // this function returns the settings to default
    reset() {
      this.getUserSettings();
    },
    // this function checks if any settings have been modified
    settingsModified() {
      const userSettings = JSON.parse(localStorage.getItem("user_settings"));
      if (this.general_modes != userSettings.modes) {
        return true;
      }
      if (this.general_fontSize != userSettings.font_size) {
        return true;
      }
      if (this.general_landingPage != userSettings.landing_page) {
        return true;
      }
      if (this.dashboard_symbol != userSettings.dashboard_symbol) {
        return true;
      }
      if (this.dashboard_strategy != userSettings.dashboard_strategy) {
        return true;
      }
      if (this.dashboard_expiry != userSettings.dashboard_expiry) {
        return true;
      }
      if (this.optionChain_symbol != userSettings.optionchain_symbol) {
        return true;
      }
      if (this.optionChain_expiry != userSettings.optionchain_expiry) {
        return true;
      }
      if (this.optionChain_settings != userSettings.optionchain_default_tab) {
        return true;
      }
      if (this.optionChain_slider != userSettings.optionchain_default_strikes) {
        return true;
      }
      if (this.optionChain_greeksGamma != userSettings.optionchain_gamma) {
        return true;
      }
      if (this.optionChain_greeksTheta != userSettings.optionchain_theta) {
        return true;
      }
      if (this.optionChain_greeksDelta != userSettings.optionchain_delta) {
        return true;
      }
      if (this.optionChain_greeksVega != userSettings.optionchain_vega) {
        return true;
      }
      if (this.optionChain_ltpOIchange != userSettings.optionchain_oichange) {
        return true;
      }
      if (this.optionChain_ltpBID != userSettings.optionchain_bid) {
        return true;
      }
      if (this.optionChain_ltpVolume != userSettings.optionchain_volume) {
        return true;
      }
      if (this.optionChain_ltpLIX != userSettings.optionchain_lix) {
        return true;
      }
      if (this.optionChain_ltpOI != userSettings.optionchain_oi) {
        return true;
      }
      if (this.optionChain_ltpOIbuild != userSettings.optionchain_oibuildup) {
        return true;
      }
      if (
        this.placeOrder_confirmation !=
        userSettings.orderconfirmation_producttype
      ) {
        return true;
      }
      if (this.placeOrder_order != userSettings.orderconfirmation_ordertype) {
        return true;
      }
      // if (
      //   this.username_visibility !=
      //   userSettings.orderconfirmation_producttype
      // ) {
      //   return true;
     
      return false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.settingsModified()) {
      // Show the popup
      this.discardChanges = true;

      // Prevent navigation
      next(false);
    } else {
      // Continue with navigation
      this.reset();
      next();
    }
  },
};
</script>
<style lang="scss">
@import "../assets/css/global.scss";
.settings-wrapper {
  .v-label {
    font-family: "Gilroy-Medium";
    font-size: 1.25em;
    line-height: 26px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }
  .v-slider--horizontal {
    min-height: 32px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 25px;
  }
  .v-card__actions {
    margin-left: -2px;
    align-items: center;
    display: flex;
    padding: 5px;
    margin-right: 13px;
    background: transparent !important;
  }
  .v-tabs-items {
    background: var(--cards-background-color) !important;
  }
  .small-switch .v-input--selection-controls__input {
    transform: scale(0.7);
  }

  .v-slide-group__content {
    display: flex;
    flex: 1 0 auto;
    position: relative;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    white-space: nowrap;
    background: var(--cards-background-color);
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
  }

  .v-sheet {
    background: var(--cards-background-color) !important;
  }

  .v-tabs-slider {
    color: var(--switch-color);
  }

  .v-tab.v-tab {
    color: var(--switch-color);
    min-width:auto !important;
  }

  .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 0px;
  }

  .v-input__slot {
    white-space: nowrap;
  }

  .theme--light.v-tabs > .v-tabs-bar {
    background: var(--cards-background-color);
  }
}
@media screen and (min-width: 961px) and (max-width: 1850px) {
  .settings-wrapper {
    .v-label {
      font-size: 1.3rem;
    }
  }
}
@media screen and (min-width: 601px) and (max-width: 960px) {
  .settings-wrapper {
    .v-label {
      font-size: 1.3rem;
    }
    .v-card__actions {
      align-items: center;
      display: flex;
      padding: 8px;
      margin-right: 10px;
    }
  }
}
@media screen and (max-width: 600px) {
  .settings-wrapper {
    .v-label {
      font-size: 1rem;
    }
    .v-slide-group {
      display: block;
    }
  }
}
</style>
<style lang="scss">
@import "../assets/css/global.scss";
.apply-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  padding: 28px 28px;
  width: 300px;

  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.32);
  border-radius: 12px;
}

.popup-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  background: var(--primary-button-color);
  border-radius: 6px;
  // margin-top: 20px;
  width: 100%;
  color: var(--cards-background-color) !important;
}
.settings-wrapper {
  margin: 0px;
  border: 1.46049px solid var(--border-color);
  border-radius: 20px;
  background: var(--cards-background-color);
  padding: 24px;
  max-width: 1850px;
  width: 100%;
  height: 90vh;
  // min-height: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: none;
  order: 2;
  flex-grow: 0;
}
.heading-text {
  font-family: "Gilroy-SemiBold";
  font-size: 2em !important;
  line-height: 38px;
  display: flex;
  align-items: center;

  /* Text */

  color: var(--text-color);
}
.table-toggler {
  button {
    background: var(--cards-background-color) !important;
    color: var(--text-color) !important;
  }
  button.active-tab {
    background: var(--primary-button-color) !important;
    color: var(--cards-background-color) !important;
  }
}
.tabs-text {
  text-transform: capitalize !important;
  height: 24px;
  font-family: "Gilroy-Medium";
  font-size: 1.25em;
  line-height: 26px;
  display: flex;
  color: var(--text-color);
}
.settings-wrapper p {
  font-family: "Gilroy-SemiBold";
  font-size: 1.5em;
  line-height: 28px;

  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text */

  color: var(--text-color);
}
.radio-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  white-space: nowrap;
}
.select-box {
  /* Filter */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 16px;
  gap: 64px;
  width: 306px;
  background: var(--cards-background-color);
  border-radius: 8px;
}
.slider-text {
  font-family: "Gilroy-Medium";
  font-size: 1.125em;
  color: var(--text-color) !important;
}

.table td {
  padding: 0 10px; /* Adjust the value as per your desired spacing */
}
.heading {
  font-size: 1em;
  color: var(--text-color);
  text-decoration: bold;
}
.tabs-box {
  width: 300px;
  height: 52px;
  margin-right: 10px;
  background: var(--tab-background);
  border-radius: 8px;
  justify-content: flex-start;
}
.apply-btn {
  background: var(--primary-button-color) !important;
  font-family: "Gilroy-SemiBold";
  font-size: var(--font-16);
  color: var(--cards-background-color) !important;
  width: 50%;
}
.reset-btn {
  background: var(--cards-background-color) !important;
  border: 1px solid #888888;
  font-family: "Gilroy-Medium";
  font-size: var(--font-16);
  color: var(--reset-btn) !important;
  width: 50%;
  box-shadow:none
}
.tabs-box.v-tab.v-tab--active{
  background-color: var(--cards-background-color);
  border: 2px solid var(--primary-button-color);
  border-radius:8px;

  .v-icon.v-icon{
    color: var(--primary-button-color) !important;
  }
}
.v-tabs-slider-wrapper{
  display:none !important;
}
.scrollable-tab-item {
  height: 75vh; /* Set a fixed height for the scrollable area */
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--cards-background-color);
}

.label-color1 {
  color: var(--green-text);
}

.label-color2 {
  color: var(--red-text);
}

.label-color3 {
  color: var(--blue-text);
}

@media screen and (min-width: 961px) and (max-width: 1850px) {
  .settings-wrapper {
    font-size: 1rem;
    p {
      font-size: 1.5rem;
    }
  }
  .slider-text {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 601px) and (max-width: 960px) {
  // .settings-wrapper {
  //   font-size: 2rem;
  // }
  .tabs-text {
    font-size: 1.25rem;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .tabs-box-mobile {
    width: 20%;
    height: 40px;
    margin: 0px;
  }
  .mobile-heading {
    font-family: "Gilroy-SemiBold";
    font-size: 1.25rem !important;
    line-height: 23px;
    display: flex;
    align-items: center;

    /* Text */

    color: #2A2A2A;
  }
  .settings-wrapper {
    p {
      font-size: 1.125rem;
    }
  }
  .toggle-btn {
    span {
      font-family: "Gilroy-Medium";
      font-size: 1rem;
      line-height: 19px;
      color: var(--text-color);
    }
  }
  .fixed-bottom {
    position: absolute;
    bottom: 0;
    background-color: white;
    width: 97%;
    z-index: 1; /* Ensure the buttons are above the scrollable content */
  }
  .select-box {
    width: 100%;
  }
}
</style>
```
