<template>
  <div>
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
      <!-- <portal-target name="modal"/> -->
    </component>
    <v-snackbar
      top right
      :timeout="snackbarMsgTimeout"
      content-class="app-wide-snackbar"
      :value="showSnackbar && snackbarMsg"
      :tile="false"
      :color="snackbarColor"
      outlined
      transition="dialog-bottom-transition"
      text
    >
    <span style="font-family: Roboto, sans-serif; font-weight: 600;font-size: 14px;">{{snackbarMsg}}</span>
      
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" class="mx-3" @click="showSnackbar = false" style="color:inherit !important">
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <notifications
      group="success"
      width="auto"
      :duration="3000"
      position="top right"
    >
      <template slot="body" slot-scope="props">
        <div
          class="custom-template"
          :class="
            props.item.type == 'success' ? 'success-template' : 'error-template'
          "
        >
          <div class="custom-template-icon">
            <img
              v-if="props.item.type == 'success'"
              src="./assets/icons/success.png"
              alt=""
            />
            <img
              v-if="props.item.type == 'error'"
              src="./assets/icons/error.png"
              alt=""
            />
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">
              {{ props.item.title }}
            </div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div class="custom-template-close" @click="props.close">
            <v-icon>mdi-close</v-icon>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>  
import Vue from "vue";
import filters from "@/filters";
Object.keys(filters).forEach(name=>{
  Vue.filter(name,filters[name])
})

export default {
  name: "App",
  data: () => ({
    showSnackbar: true,
  }),
  beforeCreate(){
    this.$store.dispatch("autoLogin");
  },
  mounted() {
    if (navigator.onLine) {
      return;
    } else {
      // The browser is currently offline
      this.$notify({
        group: "success",
        type: "error",
        title: "Error",
        text: "Oops! Looks like you are not connected to the internet",
      });
    }
  },
  computed:{
    snackbarMsg(){
      return this.$store.getters.getSnackbarMsg;
    },
    snackbarColor(){
      return this.$store.getters.getSnackbarMsgColor;
    },
    snackbarMsgTimeout(){
      const timeout = this.$store.getters.getSnackbarMsgTimeout;
      return timeout?timeout-500:-1;
    },
    
  },
  watch:{
    snackbarMsg: {
      handler: function(newVal) {
        if(newVal){
          this.showSnackbar=true;
        }
      },
      deep: true
    }
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url("./../public/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Semibold";
  src: local("Gilroy-Semibold"),
    url("./../public/fonts/Gilroy/Gilroy-Semibold.ttf") format("truetype");
}
.mdi-menu-down {
  color: red;
}
.row1 {
  width: 100%;
  max-width: 1356px;
  margin-left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: 1380px) {
    padding: 0 24px;
  }
  .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field #input-21 {
    display: none !important;
  }
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-only {
  display: none;
  @media only screen and (max-width: 980px) {
    display: block;
  }
}
.desktop-only {
  display: none;
  @media only screen and (min-width: 980px) {
    display: block;
  }
}

.second-nav-dashboard {
  width: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: -31px;
  margin-bottom: 21px;
  .second-nav-dashboard-left {
    width: 500px;
    display: flex;
    align-items: center;
    .v-text-field__details {
      display: none !important;
    }
    .input-middle-text {
      min-width: 120px;
    }
  }
  .second-nav-dashboard-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
  }
}

//CUSTOM TEMPLATE
.custom-template {
  font-family: "Gilroy-Semibold";
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 13px;
  margin: 1rem 0 0 2rem;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-width: 25rem;
  border-radius: 8px;
  padding: 18px;
  &,
  & > div {
    box-sizing: border-box;
  }

  .custom-template-icon {
    flex: 0 1 auto;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .custom-template-close {
    flex: 0 1 auto;
    font-size: 16px;
    cursor: pointer;
  }

  .custom-template-content {
    flex: 1 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;

    .custom-template-title {
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;
    }
    .custom-template-text {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.success-template {
  background: #ecffed;
  color: #72e979;
  border: 1px solid #72e979;
  i {
    color: #43c84b !important;
  }

  .custom-template-content {
    color: #43c84b;
  }
}
.error-template {
  background: #ffeaea;
  color: #ff6665;
  border: 1px solid #ff6665;
  i,
  .custom-template-content {
    color: #ee4d4c !important;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
  .container--fluid {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 950px) {
  .second-nav-dashboard {
    display: block;
    .second-nav-dashboard-left {
      width: 100%;
    }
    .second-nav-dashboard-right {
      padding-top: 10px;
      padding-left: 0px;
    }
  }
}
@media screen and (max-width: 450px) {
  .tabs .v-slide-group__prev {
    background-color: #ecf8fe;
  }
  .tabs .v-slide-group__next {
    background-color: #ecf8fe;
  }
  .second-nav-dashboard {
    display: block;
    .second-nav-dashboard-left {
      width: 100%;
      display: block;
      .input-middle-text {
        margin: 10px 0px;
      }
    }
    .second-nav-dashboard-right {
      padding-top: 20px;
      padding-left: 0px;
      display: block;
    }
  }
  .card-top-row {
    display: flex;
    flex-wrap: nowrap !important;
    justify-content: space-between !important;
  }
  .card-top-title {
    max-width: 160px !important;
  }
  .text-right {
    text-align: right;
    flex: 1;
    h5 {
      // text-overflow: ellipsis;
      white-space: nowrap !important;
      // overflow: hidden;
      width: 100%; // some width
    }
  }
}

.check-boxes-draw {
  min-width: 200px;
}
.fw-w {
  flex-wrap: wrap;
}

.card-item {
  min-width: 360px !important;
}

.left-chart-data {
  min-width: 500px !important;
}
.modal-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .check-boxes-draw {
    margin-bottom: 10px;
  }
  .modal-bottom {
    flex-wrap: wrap;
    justify-content: flex-end !important;
  }
  .modal-bottom-margin {
    width: 100%;
    justify-content: center;
  }
  .modal-control {
    margin-top: 10px;
  }
}

@media screen and (max-width: 450px) {
  .card-item {
    min-width: 100% !important;
  }
}

.pluses {
  display: flex;
  align-items: center;
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.price-table {
  overflow: auto;
  padding-bottom: 30px;
  min-width: 50%;
}

.price-table-big {
  width: 100%;
  max-width: 100%;
}

.phone-table {
  display: none !important;
}

.desktop-table {
  display: block !important;
}
.max-chart {
  max-width: 580px !important;
}
@media screen and (max-width: 1000px) {
  .price-table {
    min-width: 350px !important;
  }
  .max-chart {
    max-width: 100% !important;
  }
}
@media screen and (max-width: 570px) {
  .phone-table {
    display: block !important;
  }
  .price-table {
    max-width: 100%;
  }

  .desktop-table {
    display: none !important;
  }

  .left-chart-data {
    min-width: 100% !important;
  }
}

.mw-200 {
  min-width: 180px;
  max-width: 50%;
}

@media screen and (max-width: 570px) {
  .mw-200 {
    min-width: 180px;
    max-width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    max-width: 50%;
    width: 50%;
  }
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row:first-child {
    max-width: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 370px) {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    max-width: 100%;
    width: 100%;
  }
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row:first-child {
    max-width: 100%;
    width: 100%;
  }
}
</style>
