<template>
  <highchart
    :id="!isGraphPopUp ? 'line-chart' : 'pop-chart'"
    ref="lineChart"
    :plotdata="plotData"
    :selectedInstrument="selectedInstrument"
  ></highchart>
</template>
<script>
import Highchart from "@/components/Highchart";
export default {
  name: "Graph",
  props: {
    prebuiltStrategyData: null,
    selectedInstrument: null,
    standardDeviation: Number,
  },
  components: { Highchart },
  computed: {
    liveLtp(){
      return (el) => {
        let ltp = this.compactMarketDataScripts(el);
        if (
          ltp &&
          ltp["details"] &&
          (ltp["details"]["Last Traded Price"] == 0 ||
            ltp["details"]["Last Traded Price"])
        )
          return ltp["details"]["Last Traded Price"];
        else return null;
      }
    },
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
    insSub() {
      return (el) => {
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
  },
  data: () => ({
    plotData: null,
    isGraphPopUp: false,
  }),
  methods: {
    loadData() {
      setTimeout(() => {
        if (
          this.selectedInstrument.underlying_code != "" ||
          this.selectedInstrument.fut_code != ""
        ) {
          let spot_price =
        this.selectedInstrument.exchange == "NSE" ||
        this.selectedInstrument.exchange == "NFO"
          ? this.insSub(this.selectedInstrument.underlying_code)
            ? this.insSub(this.selectedInstrument.underlying_code).ltp
            : 0
          : this.insSub(this.selectedInstrument.fut_code)
          ? this.insSub(this.selectedInstrument.fut_code).ltp
          : 0;
          if(spot_price == null){
            spot_price = this.selectedInstrument.exchange=='NSE'? this.selectedInstrument.ltp: this.selectedInstrument.fut_ltp
          }
          this.plotData = {
            coordinate: this.prebuiltStrategyData.data.pl_data,
            Ltp: spot_price,
            pl_targeted: this.prebuiltStrategyData.data.pl_targeted,
            standardDeviation: this.standardDeviation,
          };
          if (this.plotData.standardDeviation != undefined) {
            this.isGraphPopUp = false;
          } else {
            this.isGraphPopUp = true;
          }
        }
      }, 200);
    },
  },
  watch: {
    prebuiltStrategyData() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global.scss";

// #pop-chart,#line-chart {
//   height: 100%;
//   overflow: auto;
// }
</style>
