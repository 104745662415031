<template>
  <div class="strategy-builder row1">
    <h2 class="heading1">strategy builder</h2>
    <div class="cards flex-between">
        <StrategyCard v-for="(card,index) in cards" :key="index" :title="card.title" :thumbnailsrc="card.img" :index="index"/>
    </div>
    <div class="dots flex-center">
        <div class="dot" id="left" @click="setPosition('left')"></div>
        <div class="dot" id="center" @click="setPosition('center')"></div>
        <div class="dot" id="right" @click="setPosition('right')"></div>
    </div>
  </div>
</template>

<script>
import StrategyCard from './StrategyCard.vue';
export default {
    components: { StrategyCard },
    data(){
        return{
            cards:[
                {img:'sb1.png', title:'Choose Market/ Stock View'},
                {img:'sb2.png', title:'Check out predefined strategies'},
                {img:'sb3.png', title:'Create your own custom Strategy'},
                {img:'sb6.png', title:'Just Place Order directly from the tool'},
                {img:'sb4.png', title:'LIX- to help you identify liquid instruments'},
                {img:'sb5.png', title:'Trend Predictor to indicate direction of Stock'},
            ]
        }
    },
     methods:{
        setPosition(pos){
            // var doc = document.documentElement;
            var cards = document.querySelector('.cards')
            if(pos == 'left'){cards.scrollLeft = 0}
            else if(pos == 'center'){cards.scrollLeft = 600}
            else if(pos == 'right'){cards.scrollLeft = 1200}
            
        },
        resetColor(){
            document.getElementById('left').style.backgroundColor = '#CFE8FF'
            document.getElementById('right').style.backgroundColor = '#CFE8FF'
            document.getElementById('center').style.backgroundColor = '#CFE8FF'
            
        },
        setShowScroll(){
            var doc = document.documentElement;
            var cards = document.querySelector('.cards')
            var position = (cards.pageXOffset || cards.scrollLeft)  - (doc.clientLeft || 0);
            this.resetColor()   
            if(window.innerWidth<=580 && position>=500){
                document.getElementById('right').style.backgroundColor = '#4179C7'
            }else if(position >= 1200){
                document.getElementById('right').style.backgroundColor = '#4179C7'
            }else if(position <= 100){
                document.getElementById('left').style.backgroundColor = '#4179C7'
            }else if(position > 0 && position<1200){
                document.getElementById('center').style.backgroundColor = '#4179C7'
            }
            }
  },
  mounted() {
      document.querySelector('.cards').addEventListener('scroll', this.setShowScroll);
      var cards = document.querySelector('.cards')
    setInterval(()=>{
        if(window.innerWidth>=1280){
            let incr = 430
            cards.scrollLeft+= incr
            if(cards.scrollLeft >=incr*3-100){
                cards.scrollLeft = 0
            }
        }else if(window.innerWidth>=980){
            cards.scrollLeft+=390
            cards.scrollLeft>1200?cards.scrollLeft = 0:'';
        }else if(window.innerWidth>=780){
            cards.scrollLeft+=450
            cards.scrollLeft>1600?cards.scrollLeft = 0:'';
        }else if(window.innerWidth>=580){
            cards.scrollLeft == 0?  cards.scrollLeft+=350:cards.scrollLeft+=430;
            cards.scrollLeft>1650? cards.scrollLeft = 0:'';
        }else if(window.innerWidth>=340){
            cards.scrollLeft+=180;
            cards.scrollLeft>540? cards.scrollLeft = 0:'';
        }

    }, 2000)
  },
  destroyed() {
    document.querySelector('.cards').removeEventListener('scroll', this.setShowScroll);
  }
}
</script>

<style lang="scss" scoped>
.strategy-builder{
    margin-bottom: 150px;
    @media only screen and (max-width:580px){
         margin-bottom: 84px;
    }
}
.heading1{
    font-weight: 600;
    font-size: 40px;
    text-transform: capitalize;
    text-align: center;
    @media only screen and (max-width:580px){
        font-size: 20px;
    }
}
.cards{
    padding: 63px 10px;
    padding-bottom: 20px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar{
        width: 0;
    }
}
.dots{
    margin: 20px 0;
    .dot{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: #CFE8FF;
        cursor: pointer;
        @media only screen and (max-width:580px) {
            width: 6px;
            height: 6px;
        }
    }
    #left{
        background-color: #4179C7;
    }
}


</style>