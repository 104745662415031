<template>
  <div class="header row1">
    <div class="navbar flex-between">
        <router-link to=""><img src="@/assets/logo.png" alt="logo image" class="logo"></router-link>
        <a :href="redirectUrl">
            <div class="loginbtn flex-center">
                {{isLoggedIn?"Dashboard":"Login with"}}
                <img src="@/assets/loginbtn.png" alt="">
            </div>
        </a>
    </div>
    <div class="sign-up" v-if="isAnonymous">
        Don't have an account? Click <a class="sign-up-text" :href="signUpURL">here</a> to open
    </div>
    <div class="hero flex-between">
        <div class="content1">
            <h1 class="main-heading">
                <p class="sub">All you need for</p>
                Options Trading
            </h1>
            <a :href="redirectUrl">
                <div class="startBtn flex-center">start trading</div>
            </a>
        </div>
        <img src="@/assets/hero.png" alt="hero image" class="hero-image">
    </div>
  </div>
</template>

<script>
import {aliceBlueLoginRedirectUrl,aliceBlueSignUpRedirectUrl} from "@/utils/aliceBlueUrls"
import UserDataMixin from "@/mixins/getUserDataMixin";
export default {
    mixins: [UserDataMixin],
    computed: {
        redirectUrl() {
            if(this.isAnonymous)
                return aliceBlueLoginRedirectUrl;
            else
                return window.location.origin + "/dashboard";
        },
        signUpURL(){
            return aliceBlueSignUpRedirectUrl;
        }
    },
}
</script>

<style lang="scss" scoped>
.header{
    margin-bottom: 146px;
    @media only screen and (max-width:580px){
        margin-bottom: 101px;
    }
}
.navbar{
    margin-bottom: 0;
    height:50px;
    .logo{
        width: 194px;
        height: 92px;
         @media only screen and (max-width:580px){
            width: 104px;
            height: 43px;
        }
    }
    .loginbtn{
        width: 148px;
        height: 33px;
        left: 1257px;
        top: 45px;
        border: 1px solid #4179C7;
        border-radius: 6px;
        font-weight: 600;
        font-size: 12px;
        color: #4179C7;
        transition: all .25s;
        @media only screen and (max-width:580px){
            width: 73px;
            height: 19px;
            font-size: 7px;
            border-radius: 3px;
        }
        img{
            width: 25px;
            height: 16px;
            margin-left: 10px;
            @media only screen and (max-width:580px){
                width: 12px;
                height: 12px;
                margin-left: 4px;
            }
        }
        &:hover{
            background: #4179C7;
            color: #fff;
        }

    }
}
.sign-up{
    text-align: end;
    font-size:9px;
    color: #4179C7;
}
.sign-up>a{
    color: #4179C7 !important;
    font-weight: 500;
    text-decoration: underline;
}
.sign-up-text{
    font-style: italic;
    color: #4179C7;
}
.hero{
   
     @media only screen and (max-width:980px){ 
        flex-direction: column;
        align-items: flex-start;
    }
    .content1{
        .main-heading{
            font-weight: 600;
            font-size: 60px;
            margin-bottom: 43px;
            @media only screen and (max-width:580px) {
                font-size: 35px;
                margin-bottom: 16px;
            }
            .sub{
                font-size: 45px;
                @media only screen and (max-width:580px) {
                    font-size: 20px;
                }
            }
        }
        .startBtn{
            width: 166px;
            height: 43px;
            background: #4179C7;
            border-radius: 6px;
            font-weight: 600;
            font-size: 16px;
            text-transform: capitalize;
            color: #fff;
            transition: all .25s;
            @media only screen and (max-width:580px) {
                width: 107px;
                height: 21px;
                font-size: 9px;
            }
            &:hover{
                border: 1px solid #4179C7;
                background: #fff;
                color: #4179C7;
            }
        }
    }
    .hero-image{
        width: 60%;
        @media only screen and (max-width:980px){
            width: 100%;
        }
    }
}

</style>