<template lang="html">
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xs"
    v-model="showDefaultPositionsDialog"
    width="auto"
  >
    <template>
      <v-card class="custom-position-dialog">
        <v-card-text>
          <v-row no-gutters class="pa-4 pb-1">
            <v-col>
              <div class="exit-position-label" v-if="createNewCustomCard">
                Create Group
              </div>
              <div v-else>Edit Group</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right">
              <v-icon
                style="cursor: pointer"
                @click="showDefaultPositionsDialog = false"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
          <v-row no-gutters class="px-4">
            <div style="color: var(--text-color)">
              Create Custom Groups of same underlying symbols
            </div>
          </v-row>
          <!-- Input for position name -->
          <v-row no-gutters class="px-4 mt-4">
            <v-col cols="12" sm="3">
              <v-text-field
                class="strategy-name"
                v-model="customPositionName"
                outlined
                placeholder="Enter Group Name"
              ></v-text-field>
              <div class="red-text" v-if="isCustomNameEmpty">
                Please enter Group Name
              </div>
            </v-col>
            <v-col cols="12" sm="3" offset-sm="6">
              <v-autocomplete
                class="instrument-dropdown"
                v-model="selectedInstrument"
                :items="instrumentList"
                outlined
                dense
                height="30px"
                @change="
                  getFilteredCustomPositions(selectedInstrument, 'dropdown')
                "
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- custom position table -->
          <v-row no-gutters>
            <div class="default-position-wrapper">
              <div>
                <v-data-table
                  v-if="!$vuetify.breakpoint.xs"
                  class="default-positions-table"
                  ref="defPosTable"
                  style="margin-top: 0.5rem"
                  :items="filteredCustomPositions"
                  :hide-default-footer="true"
                  :items-per-page="-1"
                  height="50vh"
                  :fixed-header="true"
                >
                  <template v-slot:header>
                    <tr>
                      <th>
                        <v-checkbox
                          class="mobile-checkbox"
                          :input-value="selectAllValue"
                          @click="toggleSelectAll"
                          :disabled="disableSelectAll"
                          color="var(--primary-button-color)"
                          hide-details
                        ></v-checkbox>
                      </th>
                      <th
                        style="position: relative"
                        class="d-flex align-center justify-space-between"
                      >
                        Instrument
                      </th>
                      <th class="text-center">Type</th>
                      <th class="text-center">Qty</th>
                      <th class="text-center">Buy Avg</th>
                      <th class="text-center">Sell Avg</th>
                      <th class="text-center">LTP</th>
                      <th class="text-center">Day's P&L</th>
                      <th class="text-center">Net P&L</th>
                      <th></th>
                    </tr>
                  </template>
                  <template v-slot:item="{ item, index }">
                    <tr
                      :class="
                        disabledPositionRows.includes(item) ? 'disable-row' : ''
                      "
                    >
                      <td>
                        <v-checkbox
                          class="mobile-checkbox"
                          :input-value="selectedPositions.includes(item)"
                          :disabled="disabledPositionRows.includes(item)"
                          @click="toggleSelection(item)"
                          color="var(--primary-button-color)"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td
                        style="white-space: nowrap"
                        class="d-flex align-center"
                        @click="toggleSelection(item)"
                      >
                        {{ item.formatted_ins_name }}
                      </td>
                      <td class="text-center">
                        <span
                          class="product-type"
                          :class="item.prod_type == 'NRML' ? 'nrml' : 'mis'"
                          >{{ item.prod_type }}</span
                        >
                      </td>
                      <td class="text-center" style="position: relative">
                        <span>
                          <span v-if="item.displayQty > 0" class="green-text"
                            >+</span
                          >
                          <span
                            :class="
                              item.displayQty >= 0 ? 'green-text' : 'red-text'
                            "
                            >{{ item.displayQty }}</span
                          >
                        </span>
                        <span class="asc-desc-icons">
                          <v-icon
                            style="top: 0px"
                            @click="splitQuantity('up', item, index)"
                            :disabled="
                              checkForDisabledIcon('up', item) ||
                              disabledPositionRows.includes(item)
                            "
                            >mdi-triangle-small-up</v-icon
                          >
                          <v-icon
                            style="bottom: 0px"
                            @click="splitQuantity('down', item, index)"
                            :disabled="
                              checkForDisabledIcon('down', item) ||
                              disabledPositionRows.includes(item)
                            "
                            >mdi-triangle-small-down</v-icon
                          >
                        </span>
                        <span
                          class="close-button-wrapper"
                          v-if="item.displayQty != item.net_quantity"
                        >
                          <button class="close-check-button">
                            <v-icon
                              class="check"
                              @click="adjustQuantity('check', item, index)"
                              >mdi-check</v-icon
                            >
                          </button>
                          <button
                            class="close-check-button"
                            @click="adjustQuantity('close', item, index)"
                          >
                            <v-icon class="close">mdi-close</v-icon>
                          </button>
                        </span>
                      </td>
                      <td class="text-center" @click="toggleSelection(item)">
                        <span v-if="item.symbol != 'USDINR'">
                          {{ item.buy_amount.toFixed(2) }}
                        </span>
                        <span v-else>
                          {{ item.buy_amount | float(4) }}
                        </span>
                      </td>
                      <td class="text-center" @click="toggleSelection(item)">
                        <span v-if="item.symbol != 'USDINR'">
                          {{ item.sell_amount.toFixed(2) }}
                        </span>
                        <span v-else>
                          {{ item.sell_amount | float(4) }}
                        </span>
                      </td>
                      <td class="text-center" @click="toggleSelection(item)">
                        <span v-if="insSub(item.instrument_token.toString())">
                          {{
                            insSub(
                              item.instrument_token.toString()
                            ).ltp.toFixed(2)
                          }}
                        </span>
                      </td>
                      <td class="text-center" @click="toggleSelection(item)">
                        <span>
                          <span
                            v-if="getDaysAndNetPL('days', item) > 0"
                            class="green-text"
                            >+</span
                          >
                          <span
                            :class="
                              getDaysAndNetPL('days', item) >= 0
                                ? 'green-text'
                                : 'red-text'
                            "
                            >{{
                              getDaysAndNetPL("days", item)
                                | currencyWithDecimal
                            }}
                          </span>
                        </span>
                      </td>
                      <td class="text-center" @click="toggleSelection(item)">
                        <span>
                          <span
                            v-if="getDaysAndNetPL('net', item) > 0"
                            class="green-text"
                            >+</span
                          >
                          <span
                            :class="
                              getDaysAndNetPL('net', item) >= 0
                                ? 'green-text'
                                : 'red-text'
                            "
                            >{{
                              getDaysAndNetPL("net", item) | currencyWithDecimal
                            }}</span
                          >
                        </span>
                      </td>
                      <td v-if="item.showMergeIcon" class="text-center">
                        <v-icon @click="mergeRows(item)"
                          >mdi-table-merge-cells</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <!-- Mobile responsive design -->
                <div v-else>
                  <div>
                    <div style="position: relative" class="d-flex">
                      <span>
                        <v-checkbox
                          :input-value="selectAllValue"
                          @click="toggleSelectAll"
                          :disabled="disabledPositionRows.includes(item)"
                          color="var(--primary-button-color)"
                          label="Select All"
                          hide-details
                          class="mobile-checkbox"
                        ></v-checkbox>
                      </span>
                    </div>
                    <div
                      class="mobile-instrument-default-rows pt-4"
                      v-for="(item, index) of filteredCustomPositions"
                      :key="index"
                      :class="
                        disabledPositionRows.includes(item) ? 'disable-row' : ''
                      "
                    >
                      <v-row no-gutters class="pb-3 justify-space-between">
                        <v-col cols="1">
                          <v-checkbox
                            class="mobile-checkbox"
                            :input-value="selectedPositions.includes(item)"
                            :disabled="disabledPositionRows.includes(item)"
                            @click="toggleSelection(item)"
                            color="var(--primary-button-color)"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="7" class="d-flex align-center pl-1">
                          {{ item.formatted_ins_name }}
                        </v-col>
                        <v-col
                          cols="4"
                          class="text-right pr-2"
                          style="position: relative"
                        >
                          <span>Qty: </span>
                          <span>
                            <span v-if="item.displayQty > 0" class="green-text"
                              >+</span
                            >
                            <span
                              :class="
                                item.displayQty >= 0 ? 'green-text' : 'red-text'
                              "
                              >{{ item.displayQty }}</span
                            >
                          </span>
                          <span class="asc-desc-icons">
                            <v-icon
                              style="top: 0px"
                              @click="splitQuantity('up', item, index)"
                              :disabled="
                                checkForDisabledIcon('up', item) ||
                                disabledPositionRows.includes(item)
                              "
                              >mdi-triangle-small-up</v-icon
                            >
                            <v-icon
                              style="bottom: 0px"
                              @click="splitQuantity('down', item, index)"
                              :disabled="
                                checkForDisabledIcon('down', item) ||
                                disabledPositionRows.includes(item)
                              "
                              >mdi-triangle-small-down</v-icon
                            >
                          </span>
                          <span
                            style="top: 100%; transform: translate(100%, 15%)"
                            class="close-button-wrapper"
                            v-if="item.displayQty != item.net_quantity"
                          >
                            <button class="close-check-button">
                              <v-icon
                                class="check"
                                @click="adjustQuantity('check', item, index)"
                                >mdi-check</v-icon
                              >
                            </button>
                            <button
                              class="close-check-button"
                              @click="adjustQuantity('close', item, index)"
                            >
                              <v-icon class="close">mdi-close</v-icon>
                            </button>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="pb-3">
                        <v-col cols="4" class="text-left pl-10">
                          <span
                            :class="
                              item.prod_type == 'NRML'
                                ? 'nrml-mobile'
                                : 'mis-mobile'
                            "
                          >
                            {{ item.prod_type }}
                          </span>
                        </v-col>
                        <v-col cols="4">
                          <span>Sell Avg: </span>
                          <span>
                            <span v-if="item.symbol != 'USDINR'">
                              {{ item.sell_amount.toFixed(2) }}
                            </span>
                            <span v-else>
                              {{ item.sell_amount | float(4) }}
                            </span>
                          </span>
                        </v-col>
                        <v-col cols="4" class="text-right pr-2">
                          <span>Day's P&L: </span>
                          <span>
                            <span
                              v-if="getDaysAndNetPL('days', item) > 0"
                              class="green-text"
                              >+</span
                            >
                            <span
                              :class="
                                getDaysAndNetPL('days', item) >= 0
                                  ? 'green-text'
                                  : 'red-text'
                              "
                              >{{
                                getDaysAndNetPL("days", item)
                                  | currencyWithDecimal
                              }}
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="pb-3">
                        <v-col cols="4" class="text-left pl-10">
                          <span> LTP: </span>
                          <span v-if="insSub(item.instrument_token.toString())">
                            {{ insSub(item.instrument_token.toString()).ltp }}
                          </span>
                        </v-col>
                        <v-col cols="4">
                          <span> Buy Avg: </span>
                          <span>
                            <span v-if="item.symbol != 'USDINR'">
                              {{ item.buy_amount.toFixed(2) }}
                            </span>
                            <span v-else>
                              {{ item.buy_amount | float(4) }}
                            </span>
                          </span>
                        </v-col>
                        <v-col cols="4" class="text-right pr-2">
                          <span>Net P&L: </span>
                          <span>
                            <span
                              v-if="getDaysAndNetPL('net', item) > 0"
                              class="green-text"
                              >+</span
                            >
                            <span
                              :class="
                                getDaysAndNetPL('net', item) >= 0
                                  ? 'green-text'
                                  : 'red-text'
                              "
                              >{{
                                getDaysAndNetPL("net", item)
                                  | currencyWithDecimal
                              }}</span
                            >
                          </span>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#b3b3b3"
            flat
            outlined=""
            @click.stop="showDefaultPositionsDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :disabled="selectedPositions.length == 0"
            color="var(--primary-button-color)"
            class="done-button primary-button"
            flat
            @click="setCustomPositionsCard()"
          >
            <v-icon>mdi-check</v-icon>
            Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: "CustomPositionDialog",
  props: {
    defaultPositionsList: [],
    isDefaultPositionsDialogVisible: Boolean,
    createNewCustomCard: Boolean,
    cardIndex: Number,
    selectedCard: [],
    selectedCustomPositions: [],
  },
  computed: {
    insSub() {
      return (el) => {
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    showDefaultPositionsDialog: {
      get() {
        return this.isDefaultPositionsDialogVisible; //
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      selectedPositions: [],
      selectAllValue: false,
      disabledPositionRows: [],
      customPositionName: "",
      isCustomNameEmpty: false,
      selectedInstrument: {},
      filteredCustomPositions: [],
      instrumentList: [],
      disableSelectAll: true,
    };
  },
  methods: {
    createInstrumentList() {
      this.instrumentList.push("Default");
      for (let ins of this.defaultPositionsList) {
        if (!this.instrumentList.includes(ins.symbol)) {
          this.instrumentList.push(ins.symbol);
        }
      }
      this.selectedInstrument = this.instrumentList[0];
    },

    /** @function filter the instruments based on dropdown selection and checkbox
     **/
    getFilteredCustomPositions(selected, key) {
      if ((selected == "Default" || selected == null) && key == "dropdown") {
        this.disableSelectAll = true;
        this.selectedInstrument = "Default";
        this.filteredCustomPositions = this.defaultPositionsList;
      } else {
        if (
          this.selectedPositions.length != 0 &&
          this.selectedPositions[0].symbol !== selected
        ) {
          this.disableSelectAll = true;
        } else {
          this.disableSelectAll = false;
        }
        let filtered = this.defaultPositionsList.reduce((result, obj1) => {
          const obj2 = selected === obj1.symbol;
          if (obj2) {
            result.push(obj1);
          }
          return result;
        }, []);
        if (key == "dropdown") {
          this.filteredCustomPositions = filtered;
        } else {
          this.selectedInstrument = selected;
          for (let pos of this.defaultPositionsList) {
            if (pos.symbol != selected) {
              this.disabledPositionRows.push(pos);
            } else {
              filtered.push(pos);
            }
          }
          if (this.selectedPositions.length == 0) {
            this.selectedInstrument = "Default";
            this.disableSelectAll = true;
            this.disabledPositionRows = [];
          }
        }
      }
    },
    //toggle selection rows functionality
    toggleSelection(item) {
      const index = this.selectedPositions.indexOf(item);
      if (index > -1) {
        this.selectedPositions.splice(index, 1);
      } else {
        this.selectedPositions.push(item);
      }
      this.getFilteredCustomPositions(item.symbol, "checkbox");

      this.selectAllValue =
        this.defaultPositionsList.length == this.selectedPositions.length
          ? true
          : false;
    },
    toggleSelectAll() {
      this.selectAllValue = !this.selectAllValue;
      if (this.selectAllValue) {
        for (let item of this.filteredCustomPositions) {
          if (!this.selectedPositions.includes(item))
            this.selectedPositions.push(item);
        }
      } else {
        this.selectedPositions = [];
      }
    },

    //get Days and Net pl for custom Positions
    getDaysAndNetPL(key, position) {
      let daysPL = 0;
      let netPL = 0;
      const token = position.instrument_token;
      let ltp = this.insSub(token)
        ? parseFloat(this.insSub(token).ltp).toFixed(2)
        : 0;
      let closePrice = this.insSub(token)
        ? parseFloat(this.insSub(token).close)
        : 0;
      if (position.net_quantity !== 0) {
        let amount =
          position.net_quantity > 0
            ? position.buy_amount
            : position.sell_amount;
        netPL = (
          parseFloat((ltp - amount) * position.net_quantity) +
          position.realized_mtm
        ).toFixed(2);
        daysPL =
          position.Type == "DAY1"
            ? netPL
            : position.Type == "DAYNET"
            ? (netPL - (closePrice - amount) * position.cf_quantity).toFixed(2)
            : (netPL - (closePrice - amount) * position.net_quantity).toFixed(
                2
              );
      } else {
        let amount =
          position.cf_quantity > 0 ? position.buy_amount : position.sell_amount;
        netPL = position.net_amount_mtm;
        daysPL =
          position.Type == "DAY1"
            ? netPL
            : position.Type == "DAYNET"
            ? (
                position.daynet_realised -
                (closePrice - amount) * position.cf_quantity
              ).toFixed(2)
            : (
                position.realized_mtm -
                (closePrice - amount) * position.cf_quantity
              ).toFixed(2);
      }
      return key == "days" ? daysPL : netPL;
    },

    //get selected positions in edit mode
    getSelectedCustomPositions() {
      this.customPositionName = this.selectedCard.name;
      const tradingSymbolList = this.defaultPositionsList.map(
        (p) => p.trading_symbol
      );
      for (let selected of this.selectedCard.positions) {
        if (tradingSymbolList.includes(selected.trading_symbol)) {
          this.selectedPositions.push(
            this.defaultPositionsList[
              tradingSymbolList.indexOf(selected.trading_symbol)
            ]
          );
        }
      }
      console.log(this.selectedCustomPositions);
      this.selectedInstrument = this.selectedCard.symbol;
      for (let pos of this.defaultPositionsList) {
        if (pos.symbol != this.selectedInstrument) {
          this.disabledPositionRows.push(pos);
        }
        pos.displayQty = pos.net_quantity;
        this.filteredCustomPositions.push(pos);
      }
    },

    setCustomPositionsCard() {
      let param_list = [];
      for (let position of this.selectedPositions) {
        param_list.push({
          code: position.code,
          exchange: position.exchange,
          expiry: position.expiry_date,
          formatted_ins_name: position.formatted_ins_name,
          name: position.symbol,
          op_pr: position.ltp,
          ltp: position.ltp,
          quantity: position.net_quantity,
          segment: position.segment,
          strike: position.strike_price,
          trading_symbol: position.trading_symbol,
        });
      }
      if (this.customPositionName == "") {
        this.isCustomNameEmpty = true;
      } else {
        this.isCustomNameEmpty = false;
        if (this.createNewCustomCard) {
          this.$emit("selectedCustomPositions", {
            name: this.customPositionName,
            param_list: param_list,
            positions: this.selectedPositions,
            symbol: this.selectedPositions[0].symbol,
          });
        } else {
          this.$emit("selectedCustomPositions", {
            id: this.selectedCard.id,
            name: this.customPositionName,
            param_list: param_list,
            positions: this.selectedPositions,
            symbol: this.selectedPositions[0].symbol,
          });
        }
        this.showDefaultPositionsDialog = false;
      }
    },

    //Handling Quantiy split in the below functions
    splitQuantity(value, item, index) {
      const tradingSymbolList = this.filteredCustomPositions.map(
        (p) => p.trading_symbol
      );
      if (item.splitted) {
        let firstIndex = tradingSymbolList.indexOf(item.trading_symbol);
        if (index > firstIndex) {
          if (value == "down") {
            this.filteredCustomPositions[firstIndex].displayQty +=
              item.lot_size;
          } else {
            this.filteredCustomPositions[firstIndex].displayQty -=
              item.lot_size;
          }
        } else {
          if (value == "down") {
            this.filteredCustomPositions[index + 1].displayQty += item.lot_size;
          } else {
            this.filteredCustomPositions[index + 1].displayQty -= item.lot_size;
          }
        }
      }
      if (value == "up") {
        item.displayQty += item.lot_size;
      } else {
        item.displayQty -= item.lot_size;
      }
      this.$set(this.filteredCustomPositions, index, item);
      this.checkForDisabledIcon("up", item);
      this.checkForDisabledIcon("down", item);
    },

    checkForDisabledIcon(value, item) {
      if (item.displayQty != item.net_quantity && item.net_quantity < 0) {
        if (Math.abs(item.displayQty) == item.lot_size) {
          return value == "up" ? true : false;
        }
      } else if (
        item.displayQty != item.net_quantity &&
        item.net_quantity > 0
      ) {
        if (Math.abs(item.displayQty) == item.lot_size) {
          return value == "down" ? true : false;
        }
      }
      if (Math.abs(item.displayQty) == item.lot_size) {
        return true;
      } else {
        if (item.displayQty < 0 && item.displayQty == item.net_quantity) {
          return value == "down" ? true : false;
        } else if (
          item.displayQty > 0 &&
          item.displayQty == item.net_quantity
        ) {
          return value == "up" ? true : false;
        }
      }
    },
    adjustQuantity(value, item, idx) {
      const tradingSymbolList = this.filteredCustomPositions.map(
        (p) => p.trading_symbol
      );
      if (value == "close") {
        item.displayQty = item.net_quantity;
        this.$set(this.filteredCustomPositions, idx, item);
      } else {
        let index = tradingSymbolList.indexOf(item.trading_symbol);
        if (item.splitted) {
          if (idx > index) {
            let newItem = this.filteredCustomPositions[index];
            newItem.net_quantity = newItem.displayQty;
            item.net_quantity = item.displayQty;
            this.$set(this.filteredCustomPositions, idx, item);
            this.$set(this.filteredCustomPositions, idx - 1, newItem);
          } else {
            let newItem = this.filteredCustomPositions[index + 1];
            item.net_quantity = item.displayQty;
            this.$set(this.filteredCustomPositions, idx, item);
            this.$set(this.filteredCustomPositions, idx + 1, newItem);
          }
        } else {
          item.splitted = true;
          let newItem = { ...item };
          newItem.displayQty = item.displayQty;
          newItem.net_quantity = newItem.displayQty;
          newItem.showMergeIcon = true;
          item.displayQty = item.net_quantity - newItem.displayQty;
          item.net_quantity = item.displayQty;
          this.filteredCustomPositions.splice(index + 1, 0, newItem);
          this.$set(this.filteredCustomPositions, idx, item);
          this.$set(this.filteredCustomPositions, idx + 1, newItem);
        }
      }
    },
    mergeRows(item) {
      const tradingSymbolList = this.filteredCustomPositions.map(
        (p) => p.trading_symbol
      );
      let index = tradingSymbolList.indexOf(item.trading_symbol);
      this.filteredCustomPositions[index].net_quantity =
        this.filteredCustomPositions[index].displayQty +
        this.filteredCustomPositions[index + 1].displayQty;
      this.filteredCustomPositions[index].displayQty =
        this.filteredCustomPositions[index].net_quantity;
      this.filteredCustomPositions.splice(index + 1, 1);
    },
  },
  mounted() {
    this.createInstrumentList();
    //if edit mode
    if (!this.createNewCustomCard) {
      this.getSelectedCustomPositions();
    } else {
      for (let position of this.defaultPositionsList) {
        position.displayQty = position.net_quantity;
        this.filteredCustomPositions.push(position);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.v-dialog > .custom-position-dialog.v-card > .v-card__text {
  padding: 0;
}
.exit-position-label {
  font-size: 1.2rem;
  font-family: "Gilroy-semibold";
  color: var(--text-color);
}
.default-position-wrapper {
  width: 100%;
  max-height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.default-position-wrapper:hover {
  overflow-y: auto !important;
}

.default-positions-table.v-data-table {
  width: 100%;

  tr {
    position: relative;
  }
  .add-exit-buttons {
    position: absolute;
    top: 0;
    left: 18%;
    padding: 0 16px;
    button {
      width: 68px;
      height: 30px;
      margin-right: 1rem;
      padding: 4px 16px;
      margin-top: 8px;
    }
  }
  td,
  th > span {
    font-size: 1em !important;
    color: var(--text-color);
  }

  table > tr > th {
    font-size: 1em !important;
    color: var(--text-color);
    background-color: var(--chip-background) !important;
    font-family: "Gilroy-medium";
    text-align: left;
    padding: 0 1em;
    font-weight: normal;
    white-space: nowrap;
    height: 40px !important;
  }
td{
    cursor: pointer;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 1em;
}

.v-input--selection-controls,
.v-application--is-ltr .v-input--selection-controls__input {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.instrument-filter {
  position: absolute;
  top: 1.5rem;
  left: 3rem;
  z-index: 1;
}
.product-type {
  padding: 4px 8px;
  border-radius: 4px;
}

.close-button-wrapper {
  position: absolute;
  display: flex;
  top: 50%;
  transform: translate(75%, -50%);

  .close-check-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    width: 25px;
    height: 25px;
    background: var(--cards-background-color);
    box-shadow: 0px 0px 5.42614px rgba(0, 0, 0, 0.4);
    border-radius: 4.53864px;
    margin-right: 0.5rem;
    .check.v-icon.v-icon {
      font-size: 1rem;
      color: var(--primary-button-color) !important;
    }
    .close.v-icon.v-icon {
      font-size: 1rem;
      color: var(--text-color) !important;
    }
  }
}

.nrml {
  color: var(--option-strike-text);
  background-color: var(--option-strike-bg);
}

.nrml-disable {
  color: var(--disable-nrml-text);
  background-color: var(--option-strike-bg);
}
.mis {
  color: var(--mis-label-text);
  background-color: var(--mis-label-bg);
}

.mis-disable {
  color: var(--disable-mis-text);
  background-color: var(--mis-label-bg);
}
.mobile-checkbox.v-icon {
  font-size: 1em !important;
}
.alert-icons {
  .v-icon.v-icon {
    font-size: 1em;
    color: var(--red-text);
  }
}

.done-button {
  .v-icon.v-icon {
    font-size: 1.2em;
  }
}

.disabled-icon {
  .v-icon.v-icon {
    color: var(--disable-red-text) !important;
  }
}
.disable-row {
  background-color: var(--option-even-row) !important;
  td {
    border: none !important;
    color: var(--disabled-text) !important;
  }
}

.asc-desc-icons {
  position: relative;
  margin-left: 4px;
  .v-icon {
    cursor: pointer;
    position: absolute;
    font-size: 1rem;
    width: 7px;
    height: 7px;
  }

  .v-icon.v-icon {
    color: var(--text-color) !important;
  }
  .v-icon.v-icon.v-icon--disabled {
    color: var(--disabled-text) !important;
  }
}
.default-position-wrapper::-webkit-scrollbar,
.default-position-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px; /* set the width of the scrollbar */
}
.default-position-wrapper::-webkit-scrollbar-thumb,
.default-position-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc; /* set the color of the scrollbar thumb */
  border-radius: 5px; /* set the border radius of the scrollbar thumb */
}
.default-position-wrapper,
.default-position-wrapper {
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* set the color of the scrollbar track */
  }
}

.theme--light.v-btn.primary-button {
  color: var(--primary-button-text-color) !important;
}
/*.theme--light.v-btn.v-btn--disabled.v-btn--has-bg.primary-button{
  color:#00000042 !important;
} */
@media only screen and (max-width: 600px) {
  .default-positions-table.v-data-table {
    td,
    th > span {
      font-size: 12px !important;
      height: 34px;
    }

    table > tr > th {
      font-size: 12px !important;
      height: 30px !important;
    }
  }

  .mobile-instrument-default-rows {
    font-size: 12px;
    color: var(--text-color);

    .nrml-mobile {
      color: var(--option-strike-text);
    }

    .nrml-mobile-disable {
      color: var(--disable-nrml-text);
    }

    .mis-mobile {
      color: var(--mis-label-text);
    }
    .mis-mobile-disable {
      color: var(--disable-mis-text);
    }
  }

  .mobile-instrument-header {
    background-color: var(--chip-background);
    padding: 4px 20px 4px 8px;
    color: var(--sub-text-color);
    font-size: 12px;
  }

  .mobile-instrument-rows {
    padding: 4px 20px 4px 8px;
    color: var(--text-color);
    font-size: 12px;
  }
  .alert-icons {
    .v-icon.v-icon {
      font-size: 12px;
      color: var(--red-text);
    }
  }

  .exit-position-label {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 400px) {
  .mobile-instrument-rows {
    font-size: 10px !important;
  }
  .mobile-instrument-header {
    font-size: 10px !important;
  }
}
</style>

<style lang="scss">
.add-exit-overlay.v-dialog {
  width: 100vw;
  position: absolute !important;
  bottom: 0px;
  margin: 0;
}

.strategy-name.v-text-field.v-text-field--enclosed .v-text-field__details,
.instrument-dropdown.v-text-field.v-text-field--enclosed
  .v-text-field__details {
  display: none;
}

.strategy-name.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.instrument-dropdown.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  min-height: 30px !important;
}

.instrument-dropdown.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__append-inner {
  margin-top: 5px !important;
}

.strategy-name.theme--light.v-input input,
.instrument-dropdown.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field
  input {
  font-size: 0.8em !important;
}
.add-exit-overlay.v-dialog > .v-card > .v-card__text {
  padding-top: 24px;
}
.mobile-checkbox .v-icon {
  font-size: 1.2em !important;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0 !important;
}

@media only screen and (max-width: 600px) {
  .mobile-checkbox.v-input--selection-controls .v-input__slot > .v-label {
    font-size: 14px !important;
    margin-left: 1rem !important;
  }

  .mobile-checkbox .v-icon {
    font-size: 16px !important;
  }
  .mobile-checkbox.v-application--is-ltr .v-input--selection-controls__input {
    font-size: 16px !important;
    margin-right: 1rem !important;
  }
}
.default-positions--desktop-table .v-data-table__wrapper {
  min-height: 400px !important;
}
</style>
