var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"symbol-hedge-wrapper"},[(_vm.selectedPositionFilter.value == 'Hedged')?_c('div',{staticClass:"mb-2 hedged-unhedged-heading",staticStyle:{"font-weight":"600"}},[_vm._v(" Hedged ")]):_vm._e(),_vm._l((_vm.hedgedPositionsList),function(card,index){return _c('div',{key:index,staticClass:"symbol-hedge-cards"},[_c('HedgedUnhedged',{attrs:{"selectedPositionFilter":_vm.selectedPositionFilter,"card":card,"positionAnalyticsData":_vm.positionAnalyticsData}})],1)}),(
      _vm.hedgedPositionsList.length == 0 &&
      _vm.selectedPositionFilter.value == 'Hedged'
    )?_c('div',{staticClass:"no-positions"},[_vm._v(" Sorry! No Hedged Positions available for now. ")]):_vm._e(),(_vm.selectedPositionFilter.value == 'Hedged' || _vm.selectedPositionFilter.value == 'Symbols')?_c('div',[(_vm.selectedPositionFilter.value == 'Hedged')?_c('div',{staticClass:"mb-2 hedged-unhedged-heading",staticStyle:{"font-weight":"600"}},[_vm._v(" Unhedged ")]):_vm._e(),_vm._l((_vm.unhedgedPositionsList),function(card,index){return _c('div',{key:index,staticClass:"symbol-hedge-cards"},[_c('HedgedUnhedged',{attrs:{"selectedPositionFilter":_vm.selectedPositionFilter,"card":card,"positionAnalyticsData":_vm.positionAnalyticsData}})],1)}),(
        _vm.unhedgedPositionsList.length == 0 &&
        _vm.selectedPositionFilter.value == 'Hedged'
      )?_c('div',{staticClass:"no-positions"},[_vm._v(" Sorry! No Unhedged Positions available for now. ")]):_vm._e(),(
        _vm.unhedgedPositionsList.length == 0 &&
        _vm.selectedPositionFilter.value !== 'Hedged'
      )?_c('div',{staticClass:"no-positions"},[_vm._v(" Sorry! No Positions available for now. ")]):_vm._e()],2):_vm._e(),(_vm.selectedPositionFilter.value == 'Expiry')?_c('div',[_vm._l((_vm.unhedgedPositionsList),function(card,index){return _c('div',{key:index,staticClass:"symbol-hedge-cards"},[_c('PositionExpiry',{attrs:{"selectedPositionFilter":_vm.selectedPositionFilter,"card":card,"positionAnalyticsData":_vm.positionAnalyticsData}})],1)}),(
      _vm.unhedgedPositionsList.length == 0 &&
      _vm.selectedPositionFilter.value == 'Expiry'
    )?_c('div',{staticClass:"no-positions"},[_vm._v(" Sorry! No Positions available for now. ")]):_vm._e()],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }