import { mapActions } from 'vuex';
export default {
  // ...
  methods: {
    ...mapActions([
      'subscribeToInstruments',// this.$store.dispatch('subscribeToInstruments', instrumentsArray);
      'unsubscribeToInstruments',// this.$store.dispatch('unsubscribeToInstruments', instrumentsArray);
      'unsubscribeFromLocation',// this.$store.dispatch('unsubscribeFromLocation', this.$route.name);
    ]),
  }
  // eqvilant to :
  // methods: {
  //   actionABC(payload) {
  //     return this.$store.dispatch('actionABC', payload);
  //   }
  // }
}