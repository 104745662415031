<template>
  <div>
    <v-dialog v-model="dialog" max-width="390">
      <template v-slot:activator="{ on, attrs }">
        <div>
          <span v-bind="attrs" v-on="on">
            Charges: 
            <span class="blue--text" v-if="insChargesArray.length>0">&#8377; {{ netCharges.total.toFixed(2) }}</span>
          </span>
          <span  v-if="insChargesArray.length>0" ><v-icon small class="blue--text mx-1" @click="$emit('refresh-charges')">mdi-reload</v-icon></span>
        </div>
      </template>

      <!-- main content -->
      <v-card>
        <v-card-title class="text-h5">
          <span style="color:var(--text-color)">Estimated Charges</span>
          <v-spacer></v-spacer>
          <div><v-icon @click="dialog = false">fas fa-close</v-icon></div>
        </v-card-title>
        <v-card-text>
          <v-simple-table class="brokerage-table">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Brokerage</td>
                  <td>&#8377; {{ parseFloat(netCharges.brokerage).toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>SEBI charges</td>
                  <td>&#8377; {{ netCharges.sebi.toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>Transaction Charges</td>
                  <td>&#8377; {{ parseFloat(netCharges.transactionCharge).toFixed(2) }}</td>
                </tr>
                <tr v-if="parseFloat(netCharges.stampCharges) && parseFloat(netCharges.stampCharges) != 0">
                  <td>Stamp duty</td>
                  <td>&#8377; {{ parseFloat(netCharges.stampCharges).toFixed(2) }}</td>
                </tr>
                <tr v-if="netCharges.ctt && netCharges.ctt != 0">
                  <td>CTT charges</td>
                  <td>&#8377; {{ netCharges.ctt.toFixed(2) }}</td>
                </tr>
                <tr v-if="netCharges.stt && netCharges.stt != 0">
                  <td>STT charges</td>
                  <td>&#8377; {{ netCharges.stt.toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>GST</td>
                  <td>&#8377; {{ netCharges.gst.toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>&#8377; {{ netCharges.total.toFixed(2) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    insChargesArray: Array
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed:{
    netCharges(){
      let chargesObj={
        brokerage: 0.0,
        stampCharges: 0.0,
        transactionCharge: 0.0,
        gst: 0.0,
        ctt: 0.0,
        stt: 0.0,
        cmcharges: 0.0,
        sebi: 0.0,
        total: 0.0
      }
      this.insChargesArray.forEach(el => {
        chargesObj.brokerage += parseFloat(el.brokerage);
        chargesObj.stampCharges += parseFloat(el.stampCharges);
        chargesObj.transactionCharge += parseFloat(el.transactionCharge);
        chargesObj.sebi += parseFloat(el.sebi);
        chargesObj.cmcharges += parseFloat(el.cmcharges);
        chargesObj.ctt += parseFloat(el.ctt);
        chargesObj.stt += parseFloat(el.stt);
        chargesObj.gst += parseFloat(el.gst);
        chargesObj.total += parseFloat(el.total);
      });
      return chargesObj
    }
  },
  watch:{
    insChargesArray(){
    }
  }
}
</script>
<style scoped>
table td{
  color: var(--text-color);
  height: 40px !important;
  border:none !important;
  padding: 0px !important;
}
table tr:last-child td{
  height: 50px !important;
}
</style>