<template>
  <div class="d-flex flex-column parent">
    <!-- Option OI chart -->
    <div class="col-12 child" :style="{ order: graphOrder.optionOi }">
      <!-- Functional Buttons -->
      <div class="move-btns d-flex justify-center">
        <v-icon @click="moveUp('optionOi')" v-if="graphOrder.optionOi != 1"
          >mdi-arrow-up</v-icon
        >
        <v-icon @click="moveDown('optionOi')" v-if="graphOrder.optionOi != 3"
          >mdi-arrow-down</v-icon
        >
        <v-icon
          @click="enableGraphFullScreen('optionOi')"
          v-if="$vuetify.breakpoint.xs"
          >mdi-arrow-expand</v-icon
        >
      </div>

      <!-- Buy Sell Popup Based on pointer -->
      <OptionChartBuySellPopUp
        v-if="
          showPopUp &&
          clickPointGraphData &&
          clickPointGraphData.chartName == 'optionOi' &&
          !fullScreenChart
        "
        v-click-outside="{
          handler: onClickOutside,
          closeConditional: function () {
            return showPopUp;
          },
        }"
        class="buttons-popup"
        @add-from-charts="
          (strike, segment, tranc) =>
            $emit('add-from-charts', strike, segment, tranc)
        "
        :clickPointGraphData="clickPointGraphData"
      />
      <highcharts
        class="hc"
        :options="chartOptionsOptionOi"
        ref="optionOiChart"
      ></highcharts>
    </div>

    <!-- CallGreeks Chart -->
    <div class="col-12 child" :style="{ order: graphOrder.callGreeks }">
      <!-- Functional Buttons -->
      <div class="move-btns d-flex justify-center">
        <v-icon @click="moveUp('callGreeks')" v-if="graphOrder.callGreeks != 1"
          >mdi-arrow-up</v-icon
        >
        <v-icon
          @click="moveDown('callGreeks')"
          v-if="graphOrder.callGreeks != 3"
          >mdi-arrow-down</v-icon
        >
        <v-icon
          @click="enableGraphFullScreen('callGreeks')"
          v-if="$vuetify.breakpoint.xs"
          >mdi-arrow-expand</v-icon
        >
      </div>

      <!-- Buy Sell Popup Based on pointer -->
      <OptionChartBuySellPopUp
        v-if="
          showPopUp &&
          clickPointGraphData &&
          clickPointGraphData.chartName == 'callGreeks' &&
          !fullScreenChart
        "
        v-click-outside="{
          handler: onClickOutside,
          closeConditional: function () {
            return showPopUp;
          },
        }"
        class="buttons-popup"
        @add-from-charts="
          (strike, segment, tranc) =>
            $emit('add-from-charts', strike, segment, tranc)
        "
        :clickPointGraphData="clickPointGraphData"
      />
      <highcharts
        class="hc"
        :options="chartOptionsCallGeeks"
        ref="callGreeksChart"
      >
      </highcharts>
    </div>

    <!-- PutGreeks Chart -->
    <div class="col-12 child" :style="{ order: graphOrder.putGreeks }">
      <!-- Functional Buttons -->
      <div class="move-btns d-flex justify-center">
        <v-icon @click="moveUp('putGreeks')" v-if="graphOrder.putGreeks != 1"
          >mdi-arrow-up</v-icon
        >
        <v-icon @click="moveDown('putGreeks')" v-if="graphOrder.putGreeks != 3"
          >mdi-arrow-down</v-icon
        >
        <v-icon
          @click="enableGraphFullScreen('putGreeks')"
          v-if="$vuetify.breakpoint.xs"
          >mdi-arrow-expand</v-icon
        >
      </div>

      <!-- Buy Sell Popup Based on pointer -->
      <OptionChartBuySellPopUp
        v-if="
          showPopUp &&
          clickPointGraphData &&
          clickPointGraphData.chartName == 'putGreeks' &&
          !fullScreenChart
        "
        v-click-outside="{
          handler: onClickOutside,
          closeConditional: function () {
            return showPopUp;
          },
        }"
        class="buttons-popup"
        @add-from-charts="
          (strike, segment, tranc) =>
            $emit('add-from-charts', strike, segment, tranc)
        "
        :clickPointGraphData="clickPointGraphData"
      />
      <highcharts
        class="hc"
        :options="chartOptionsPutGeeks"
        ref="callGreeksChart"
      >
      </highcharts>
    </div>

    <!-- Fullscreen Graph -->
    <div v-if="fullScreenChart != null && $vuetify.breakpoint.xs">
      <!-- Buy Sell Popup Based on pointer -->
      <OptionChartBuySellPopUp
        v-if="showPopUp && clickPointGraphData && fullScreenChartData"
        v-click-outside="{
          handler: onClickOutside,
          closeConditional: function () {
            return showPopUp;
          },
        }"
        class="fullscreen-chart-order-btns"
        @add-from-charts="
          (strike, segment, tranc) =>
            $emit('add-from-charts', strike, segment, tranc)
        "
        :clickPointGraphData="clickPointGraphData"
      />
      <highcharts
        class="hc fixed-fullscreen-chart"
        :options="fullScreenChartData"
        ref="fixedChart"
      ></highcharts>
      <div class="close-graph-button">
        <v-icon @click="fullScreenChart = null">mdi-close</v-icon>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import { EventBus } from "../event-bus.js";
import OptionChartBuySellPopUp from "./OptionChartsBuySellPopUp.vue";
export default {
  props: { plotData: Object, title: String },
  components: { highcharts: Chart, OptionChartBuySellPopUp },
  data() {
    return {
      graphOrder: { optionOi: 1, callGreeks: 2, putGreeks: 3 },
      graphOrderArray: ["optionOi", "callGreeks", "putGreeks"],
      clickPointGraphData: null,
      showPopUp: false,
      fullScreenChart: null,
      chartOptionsOptionOi: null,
      chartOptionsCallGeeks: null,
      chartOptionsPutGeeks: null,

      timerClickOutside: null,
    };
  },
  watch: {
    methods: {
      getData(strike, type) {
        console.log(strike, "->", type);
      },
    },
    fullScreenChart(newVal, oldVal) {
      if (newVal == "optionOi" || oldVal == "optionOi")
        this.setOptionOiChartData();
      else if (newVal == "callGreeks" || oldVal == "callGreeks")
        this.setCallGeeksChartData();
      else if (newVal == "putGreeks" || oldVal == "putGreeks")
        this.setPutGeeksChartData();
    },
  },
  created() {
    this.setOptionOiChartData();
    this.setCallGeeksChartData();
    this.setPutGeeksChartData();
    // Statements to prevent duplicate events of the same name
    EventBus.$off("handle-option-chain-graph-click");

    EventBus.$on("handle-option-chain-graph-click", (data, chartName) => {
      this.handleGraphClick(data, chartName);
    });
  },
  computed: {
    fullScreenChartData() {
      if (this.fullScreenChart == "optionOi") return this.chartOptionsOptionOi;
      else if (this.fullScreenChart == "callGreeks")
        return this.chartOptionsCallGeeks;
      else if (this.fullScreenChart == "putGreeks")
        return this.chartOptionsPutGeeks;
      else return null;
    },
  },
  methods: {
    moveUp(graphName) {
      const index = this.graphOrderArray.findIndex((el) => el == graphName);
      if (index == 0) return;
      else {
        let orderTemp = this.graphOrderArray;
        const oldName = this.graphOrderArray[index - 1];
        orderTemp[index - 1] = orderTemp[index];
        orderTemp[index] = oldName;
        this.graphOrderArray = orderTemp;
        this.setGraphOrder();
      }
    },
    moveDown(graphName) {
      const index = this.graphOrderArray.findIndex((el) => el == graphName);
      if (index >= this.graphOrderArray.length - 1) return;
      else {
        let orderTemp = this.graphOrderArray;
        const oldName = this.graphOrderArray[index + 1];
        orderTemp[index + 1] = orderTemp[index];
        orderTemp[index] = oldName;
        this.graphOrderArray = orderTemp;
        this.setGraphOrder();
      }
    },
    setGraphOrder() {
      let obj = {};
      this.graphOrderArray.forEach((el, i) => {
        obj[el] = i + 1;
      });
      this.graphOrder = obj;
    },
    handleGraphClick(data, chartName) {
      if (this.timerClickOutside) {
        this.timerClickOutside = null;
      }
      let seriesName = data.point.series.name.toLowerCase();

      const series = {
        name: data.point.series.name,
        val: data.point.options.y,
      };
      let segment;
      if (seriesName == "iv") {
        segment = chartName == "callGreeks" ? "ce" : "pe";
      } else segment = seriesName.includes("call") ? "ce" : "pe";
      this.clickPointGraphData = {
        chartName: chartName,
        segment: segment,
        seriesData: this.fullScreenChart ? series : null,
        strike: data.point.category,
        chartX: data.chartX,
        chartY: data.chartY,
      };
      this.showPopUp = true;
    },
    onClickOutside() {
      this.timerClickOutside = setTimeout(() => {
        this.showPopUp = false;
      }, 500);
    },
    enableGraphFullScreen(graphName) {
      if (this.fullScreenChart != null) {
        this.fullScreenChart = null;
        return;
      }
      this.fullScreenChart = graphName;
    },
    /**
     * @function SET_Option_Oi__DATA
     */
    setOptionOiChartData() {
      const selectedGraph = this.fullScreenChart == "optionOi";
      this.chartOptionsOptionOi = {
        chart: {
          inverted: selectedGraph,
          backgroundColor: "transparent",
        },
        tooltip: {
          enabled: !selectedGraph,
          headerFormat: "<b>Strike</b>: {point.key}<br>",
          pointFormat: "<b>{series.name}</b>: {point.y: .2f}<br>",
        },
        plotOptions: {
          series: {
            borderColor: "var(--table-row-hover)",
            states: {
              inactive: {
                enabled: true,
              },
              hover: {
                lineWidth: 5,
                brightness: -0.2,
              },
            },
            cursor: "pointer",
            point: {
              events: {
                click: function (e) {
                  EventBus.$emit(
                    "handle-option-chain-graph-click",
                    e,
                    "optionOi"
                  );
                },
              },
            },
          },
          line: {
            marker: {
              enabled: false,
            },
          },
        },
        legend: {
          align: "right",
          verticalAlign: selectedGraph ? "top" : "bottom",
          itemStyle: {
            color: "var(--text-color)",
          },
        },
        lang: {
          noData: "Loading...",
        },
        title: {
          text: "Option Vs OI",
          style: {
            color: "var(--text-color)",
            "font-family": "Gilroy-SemiBold",
            "font-size": "1.375em",
            transform: selectedGraph ? "rotate(90deg)" : "",
            "transform-origin": "center right",
          },
          x: selectedGraph ? -10 : 0,
          align: selectedGraph ? "right" : "left",
          verticalAlign: selectedGraph ? "middle" : "top",
          useHTML: true,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          tickInterval: 2,
          categories: this.plotData["strike"],
          title: {
            text: "Strike",
            rotation: selectedGraph ? 90 : 0,
            style: {
              color: "var(--text-color)",
            },
          },
          labels: {
            rotation: selectedGraph ? 90 : 0,
            style: {
              color: "var(--text-color)",
            },
          },
        },
        yAxis: [
          {
            title: {
              margin: 10,
              text: "Call Price vs Put Price",
              rotation: selectedGraph ? 180 : -90,
              style: {
                color: "var(--text-color)",
              },
            },
            labels: {
              rotation: selectedGraph ? 90 : 0,
              style: {
                color: "var(--text-color)",
              },
            },
          },
          {
            title: {
              margin: 10,
              text: "Call OI vs Put OI",
              style: {
                color: "var(--text-color)",
              },
            },
            labels: {
              rotation: selectedGraph ? 90 : 0,
              style: {
                color: "var(--text-color)",
              },
            },
            opposite: true,
          },
        ],
        series: [
          {
            name: "Call Price",
            data: this.plotData["call_price"],
            type: "line",
            color: "var(--green-text)",
            yAxis: 0,
          },
          {
            name: "Put Price",
            data: this.plotData["put_price"],
            type: "line",
            color: "var(--red-text)",
            yAxis: 0,
          },
          {
            name: "Call OI",
            data: this.plotData["call_oi"],
            type: "column",
            color: "var(--charts-bar-green)",
            yAxis: 1,
          },
          {
            name: "Put OI",
            data: this.plotData["put_oi"],
            type: "column",
            color: "var(--charts-bar-red)",
            yAxis: 1,
          },
        ],
      };
    },

    /**
     * @function SET_Call_Greeks__DATA
     */
    setCallGeeksChartData() {
      const selectedGraph = this.fullScreenChart == "callGreeks";
      this.chartOptionsCallGeeks = {
        chart: {
          inverted: selectedGraph,
          backgroundColor: "transparent",
        },
        tooltip: {
          enabled: !selectedGraph,
          headerFormat: "<b>Strike</b>: {point.key}<br>",
          pointFormat: "<b>{series.name}</b>: {point.y: .2f}<br>",
        },
        plotOptions: {
          series: {
            borderColor: "var(--table-row-hover)",
            states: {
              inactive: {
                enabled: true,
              },
              hover: {
                lineWidth: 5,
                brightness: -0.2,
              },
            },
            cursor: "pointer",
            point: {
              events: {
                click: function (e) {
                  EventBus.$emit(
                    "handle-option-chain-graph-click",
                    e,
                    "callGreeks"
                  );
                },
              },
            },
          },
          line: {
            marker: {
              enabled: false,
            },
          },
        },
        legend: {
          align: "right",
          verticalAlign: selectedGraph ? "top" : "bottom",
          itemStyle: {
            color: "var(--text-color)",
          },
        },
        lang: {
          noData: "Loading...",
        },
        title: {
          text: "Call Greeks",
          style: {
            color: "var(--text-color)",
            "font-family": "Gilroy-SemiBold",
            "font-size": "1.375em",
            transform: selectedGraph ? "rotate(90deg)" : "",
            "transform-origin": "center right",
          },
          x: selectedGraph ? -10 : 0,
          align: selectedGraph ? "right" : "left",
          verticalAlign: selectedGraph ? "middle" : "top",
          useHTML: true,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          tickInterval: 2,
          categories: this.plotData["strike"],
          title: {
            text: "Strike",
            rotation: selectedGraph ? 90 : 0,
            style: {
              color: "var(--text-color)",
            },
          },
          labels: {
            rotation: selectedGraph ? 90 : 0,
            style: {
              color: "var(--text-color)",
            },
          },
        },
        yAxis: [
          {
            title: {
              margin: 10,
              text: "Call Delta vs Call Vega",
              rotation: selectedGraph ? 180 : -90,
              style: {
                color: "var(--text-color)",
              },
            },
            labels: {
              rotation: selectedGraph ? 90 : 0,
              style: {
                color: "var(--text-color)",
              },
            },
          },
          {
            title: {
              margin: 10,
              text: "Call Theta vs Call Gamma",
              style: {
                color: "var(--text-color)",
              },
            },
            labels: {
              rotation: selectedGraph ? 90 : 0,
              style: {
                color: "var(--text-color)",
              },
            },
            opposite: true,
          },
        ],
        series: [
          {
            name: "Call Delta",
            data: this.plotData["delta_c"],
            type: "line",
            color: "var(--green-text)",
            yAxis: 0,
          },
          {
            name: "Call Vega",
            data: this.plotData["vega_c"],
            type: "line",
            color: "var(--primary-color)",
            yAxis: 0,
          },
          {
            name: "IV",
            data: this.plotData["iv"],
            type: "line",
            color: "var(--charts-bar-orange)",
            yAxis: 0,
          },
          {
            name: "Call Theta",
            data: this.plotData["theta_c"],
            type: "column",
            color: "var(--charts-bar-green)",
            yAxis: 1,
          },
          {
            name: "Call Gamma",
            data: this.plotData["gamma_c"],
            type: "column",
            color: "var(--charts-bar-red)",
            yAxis: 1,
          },
        ],
      };
    },

    /**
     * @function SET_Put_Greeks__DATA
     */
    setPutGeeksChartData() {
      const selectedGraph = this.fullScreenChart == "putGreeks";
      this.chartOptionsPutGeeks = {
        chart: {
          inverted: selectedGraph,
          backgroundColor: "transparent",
        },
        tooltip: {
          enabled: !selectedGraph,
          headerFormat: "<b>Strike</b>: {point.key}<br>",
          pointFormat: "<b>{series.name}</b>: {point.y: .2f}<br>",
        },
        plotOptions: {
          series: {
            borderColor: "var(--table-row-hover)",
            dataLabels: {
              style: {
                color: "var(--text-color)",
              },
            },
            states: {
              inactive: {
                enabled: true,
              },
              hover: {
                lineWidth: 5,
                brightness: -0.2,
              },
            },
            cursor: "pointer",
            point: {
              events: {
                click: function (e) {
                  EventBus.$emit(
                    "handle-option-chain-graph-click",
                    e,
                    "putGreeks"
                  );
                },
              },
            },
          },
          line: {
            marker: {
              enabled: false,
            },
          },
        },
        legend: {
          align: "right",
          verticalAlign: selectedGraph ? "top" : "bottom",
          itemStyle: {
            color: "var(--text-color)",
          },
        },
        lang: {
          noData: "Loading...",
        },
        title: {
          text: "Put Greeks",
          style: {
            color: "var(--text-color)",
            "font-family": "Gilroy-SemiBold",
            "font-size": "1.375em",
            transform: selectedGraph ? "rotate(90deg)" : "",
            "transform-origin": "center right",
          },
          x: selectedGraph ? -10 : 0,
          align: selectedGraph ? "right" : "left",
          verticalAlign: selectedGraph ? "middle" : "top",
          useHTML: true,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          tickInterval: 2,
          categories: this.plotData["strike"],
          title: {
            text: "Strike",
            rotation: selectedGraph ? 90 : 0,
            style: {
              color: "var(--text-color)",
            },
          },
          labels: {
            rotation: selectedGraph ? 90 : 0,
            style: {
              color: "var(--text-color)",
            },
          },
        },
        yAxis: [
          {
            title: {
              margin: 10,
              text: "Put Delta vs Put Vega",
              rotation: selectedGraph ? 180 : -90,
              style: {
                color: "var(--text-color)",
              },
            },
            labels: {
              rotation: selectedGraph ? 90 : 0,
              style: {
                color: "var(--text-color)",
              },
            },
          },
          {
            title: {
              margin: 10,
              text: "Put Theta vs Put Gamma",
              style: {
                color: "var(--text-color)",
              },
            },
            labels: {
              rotation: selectedGraph ? 90 : 0,
              style: {
                color: "var(--text-color)",
              },
            },
            opposite: true,
          },
        ],
        series: [
          {
            name: "Put Delta",
            data: this.plotData["delta_p"],
            type: "line",
            color: "var(--green-text)",
            yAxis: 0,
            dataLabels: {
              style: {
                color: "var(--text-color)",
              },
            },
          },
          {
            name: "Put Vega",
            data: this.plotData["vega_p"],
            type: "line",
            color: "var(--primary-color)",
            yAxis: 0,
          },
          {
            name: "IV",
            data: this.plotData["iv"],
            type: "line",
            color: "var(--charts-bar-orange)",
            yAxis: 0,
          },
          {
            name: "Put Theta",
            data: this.plotData["theta_p"],
            type: "column",
            color: "var(--charts-bar-green)",
            yAxis: 1,
          },
          {
            name: "Put Gamma",
            data: this.plotData["gamma_p"],
            type: "column",
            color: "var(--charts-bar-red)",
            yAxis: 1,
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global.scss";
.child {
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 11px;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
  .move-btns {
    position: absolute;
    right: 12px;
    z-index: 1;
  }
  .buttons-popup {
    position: absolute;
    z-index: 1;
  }
}
@media screen and(max-width:600px) {
  .fixed-fullscreen-chart {
    position: fixed;
    z-index: 10;
    // transform: rotate(90deg);
    // transform-origin: top left;
    top: 0vw;
    left: 0vw;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
    background: var(--cards-background-color);
    transition: 1s all;
  }
  .close-graph-button {
    position: fixed;
    padding: 10px;
    bottom: 0vh;
    right: 0vw;
    z-index: 10;
  }
  .fullscreen-chart-order-btns {
    z-index: 11;
    position: fixed;
    transform: rotate(90deg);
    transform-origin: top left;
  }
}
</style>
