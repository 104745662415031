<template>
  <div class="footer">
    <div class="content1 row1 flex-between">
        <div class="left">
            <img src="@/assets/logo.png" alt="logo" class="logo">
            <p class="text">A product of Inuvest Financial Technologies </p>
            <span class="text">Aliceblue Disclaimer: </span><a href="https://aliceblueonline.com/legal-documentation/disclaimer" class="sign-up-text" target="_blank">Click here</a>
            <ul class="social-icons">
                <li><router-link to=""><img src="@/assets/fb.png" alt=""></router-link></li>
                <li><router-link to=""><img src="@/assets/linkedin.png" alt=""></router-link></li>
                <li><router-link to=""><img src="@/assets/twitter.png" alt=""></router-link></li>
                <li><router-link to=""><img src="@/assets/yt.png" alt=""></router-link></li>
            </ul>
        </div>
        <div class="right">
            <p class="text">contact us:</p>
            <p class="email">support@inuvest.tech</p>
        </div>
    </div>
    <p class="copyright-text row1">&copy; 2022, All rights reserved. Inuvest Technologies</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped> 
.footer{
    width: 100%;
    height: 210px;
    background: #F7F7F7;
     @media only screen and (max-width:580px) {
        height: max-content;
        padding-bottom: 10px;
    }
    .content1{
        width: 100%;
        .left{
            padding-top: 24px;
             @media only screen and (max-width:580px) {
                padding-top: 16px;
            }
            .logo{
                width: 152px;
                height: 74px;
                @media only screen and (max-width:580px) {
                    width: 76px;
                    height: 32px;
                    margin-left: -5px;
                }
            }
            .text{
                font-weight: 400;
                font-size: 15px;
                color: #888888;
                margin-bottom: 9px;
                @media only screen and (max-width:580px) {
                   font-size: 6px;
                   margin-bottom: 0;
                }
            }
            .social-icons{
                display: none;
                list-style: none;
                // display: flex;
                img{
                    width: 9.4px;
                    margin-right: 8px;
                    @media only screen and (max-width:580px) {
                        width: 7.3px;
                    }
                }
            }
        }
        .right{
            font-weight: 500;
            font-size: 20px;
            text-transform: capitalize;
            text-align: right;
            margin-top: 40px;
            @media only screen and (max-width:580px) {
                font-size: 8px;
                margin-top: 30px;
            }
            .email{
                font-weight: 400;
                font-size: 15px;
                margin-top: 22px;
                color: #888888;
                text-transform: lowercase;
                @media only screen and (max-width:580px) {
                    font-size: 6px;
                    margin-top: 11px;
                }
            }
        }

    }
    .copyright-text{
        text-align: center;
        margin-top: 25px;
        font-weight: 400;
        font-size: 12px;
        color: #888888;
        @media only screen and (max-width:580px) {
            font-size: 6px;
        }
    }
}
.sign-up-text{
  font-style: italic;
  color: #4179C7 !important;
  text-decoration: underline;
}

</style>