<template>
  <div>
    <v-row no-gutters class="position-page-wrapper my-0 mx-auto">
      <v-col cols="12" md="4">
        <MultiStrikeList
          v-if="instrumentList.length != 0"
          :instrumentList="instrumentList"
          @selectedInstrument="getSelectedInstrument"
        ></MultiStrikeList>
      </v-col>
      <v-col cols="12" md="8">
        <StrikeCharts
          :selectedInstrument="selectedInstrument"
        ></StrikeCharts>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import MultiStrikeList from "./MutliStrikeList.vue";
import StrikeCharts from "./StrikeCharts.vue";
import { getTrendsAndSymbolsUrl } from "../getService";
export default {
  name: "MultiStrikeCharts",
  components: {
    MultiStrikeList,
    StrikeCharts,
  },
  data() {
    return {
      instrumentList: [],
      selectedInstrument: null,
    };
  },
  computed:{
    userId() {
      return this.$store.getters.getUserId;
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
  },
  methods: {
    getTrendsAndSymbols() {
      let payload = {
        path: "dashboard_symbols",
        broker: "aliceblue",
        user_id: this.userId,
        access_token: this.accessToken,
      };
      axios
        .post(getTrendsAndSymbolsUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.instrumentList = res.data;
        });
    },

    getSelectedInstrument(value) {
      this.selectedInstrument = value;
    },
  },
  mounted() {
    this.getTrendsAndSymbols();
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global.scss";
.position-page-wrapper {
  font-family: "Gilroy-Medium";
  max-width: 2500px;
  height: 88vh;
  color: var(--text-color);
}
</style>
