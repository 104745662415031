<template>
  <div class="payoff-chart row1 flex-col-center">
    <h2 class="heading1">Payoff Chart </h2>
    <h3 class="sub-heading">Payoff Chart helps in evaluating how a strategy may perform over a range of prices
 and time, thereby gaining an understanding of potential outcomes.</h3>
    <div class="chart">
        <img src="@/assets/payOffbg.png" alt="" class="lower-image">
        <img src="@/assets/payOffupper.png" class="upper-image" alt="">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.payoff-chart{
    margin-bottom: 240px;
    @media only screen and (max-width:980px){
        margin-bottom: 120px;
    }

    .heading1{
        text-align: center;
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 12px;
        @media only screen and (max-width:980px){
            font-size: 28px;
        }
        @media only screen and (max-width:580px){
            font-size: 20px;
        }
    }
    .sub-heading{
        max-width:771px;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        color: #888888;
        margin-bottom: 51px;
        @media only screen and (max-width:980px){
            font-size: 16px;
        }
        @media only screen and (max-width:580px){
            font-size: 6px;
            width: 60%;
            margin-bottom: 15px;
        }
    }
    .chart{
        position: relative;
        align-self: flex-end;
        background-color: #fff;  
        display: flex;     
        justify-content: flex-end;
        align-items: flex-start;
        @media only screen and (max-width:580px){
            justify-content: center;
        }
        .lower-image{
            width: 90%;
            max-width: 1197px;
            border-radius: 20px;
            @media only screen and (max-width:980px){
                width: 80%;
                border-radius: 5px;
            }
        }
        
        
        .upper-image{
            width: 802px;
            position: absolute;
            top: 112px;
            left: -112px;
             @media only screen and (max-width:1310px){
                width: 60%;   
                left: 10px;             
                top: 82px;
            }
            @media only screen and (max-width:980px){
                left: 50px;             
                top: 60px;            
            }
            @media only screen and (max-width:580px){
                left: 0px;             
                top: 40px;            
            }

        }
    }
}

</style>