<template>
  <div class="page-wapper white-card px-2 px-sm-3 d-flex flex-column align-center justify-center justify-md-start">
    <img class="image" :src=" !darkMode ? require('@/assets/LightMode/pageNotFound.png') : require('@/assets/DarkMode/pageNotFound.png')"/>
    <span>
      The page you requested could not be found.
    </span>
    <!-- <router-link to="/dashboard" class="primary-button ma-2">Go To Dashboard</router-link> -->
    <router-link to="/" class="primary-button ma-2">Go Home</router-link>
  </div>
</template>
<script>
export default{
  name: "PageNotFound",
  computed:{
    darkMode(){
      return this.$store.getters.getDarkMode;
    },
  },
}
</script>
<style scoped>
.primary-button{
  text-decoration: none;
  color: var(--primary-button-text-color) !important;
}
.image{
  width: 50vw;
  min-width: 300px;
}
.page-wapper{
  overflow: hidden;
  margin: auto;
  max-width: 1850px;
  height: 70vh;
  max-height: 1000px;
}
</style>