<template>
    <div class="container1 row1 flex-between" id="order-book">
      <div class="left-content">
          <h2 class="heading1">{{info.title}}</h2>
          <img :src="require(`@/assets/${info.img}`)" alt="Position analytics image" class="thumbnail mobile-only">
          <ul>
              <li v-for="(el, index) in info.points" :key="index"><div class="dot"></div> {{el}}</li>
          </ul>
      </div>
      <img :src="require(`@/assets/${info.img}`)" alt="Position analytics image" class="thumbnail desktop-only">
    </div>
  </template>
  
  <script>
  export default {
      data(){
        return{
            info:{
                title:'Order Book',
                alignment:'left',
                points:[
                    'Check the status of Orders placed.',
                    'Modify Pending Orders.',
                ],
                img:'orderbook.png'
            },
        }
      },
      mounted(){
          if(this.info.alignment === 'right'){
              document.getElementById('order-book').style.flexDirection = 'row-reverse';
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "../../assets/css/global.scss";
  .container1{
      margin-bottom: 88px;
      @media only screen and (max-width:980px) {
          flex-direction: column-reverse;
          margin-bottom: 63px;
      }
      .left-content{
          width: 564px;
           @media only screen and (max-width:980px) {
              width: 100%;
          }
          .heading1{
              font-weight: 600;
              font-size: 40px;
              margin-bottom: 32px;
              @media only screen and (max-width:980px) {
                  margin-bottom: 14px;
                  font-size: 24px;
              }
              @media only screen and (max-width:580px) {
                  font-size: 18px;
              }
          }
          ul{
              list-style: none;
              li{
                  display: flex;
                  align-items: center;
                  font-weight: 400;
                  font-size: 20px;
                  color: #888888;
                  margin-bottom: 21px;
                  @media only screen and (max-width:580px) {
                     font-size: 8px;
                     margin-bottom: 10px;
                  }
                  .dot{
                      width: 12px;
                      height: 12px;
                      border-radius: 50%;
                      background-color: #888888;
                      margin-right: 20px;
                      @media only screen and (max-width:580px) {
                          width: 5px;
                          height: 5px;
                      }
                  }
  
              }
          }
      }
      .thumbnail{
          width: 745px;
          border: 1px solid #E6E6E6;
          border-radius: 5px;
           @media only screen and (max-width:980px) {
              margin-left: 50%;
              transform: translateX(-50%);
              width: 100%;
              margin-bottom: 24px;
          }
      }
  }
  
  </style>