<template>
  <v-container>
    Redirecting....
    <!-- <div v-if="loading">Logging</div> -->
  </v-container>
</template>

<script>
export default {
  name: "LoginSuccess",
  data(){
    return{
      loading:false,
    }
  },
  // sample: http://localhost:8080/loginsuccess?authCode=fasdfadsfdsdf&userId=fdsaf545
  // http://localhost:8080/loginsuccess?authCode=OOS92G07CGBEDI466J0P&userId=759648
  // localhost:8080/loginsuccess?authCode=EJ645IPN8TZT7C86QMPG&userId=538405&appcode=oIEVWdKO5H1L0GT
  mounted() {
    this.login();
  },
  methods:{
    async login(){
      this.loading=true;
      const authCode= this.$route.query["authCode"];
      const userId= this.$route.query["userId"];
      const page= this.$route.query["page"];
      if(page){
        localStorage.setItem("redirectedPath",page)
      }
      await this.$store.dispatch("login", {authCode:authCode, userId:userId, broker:'aliceblue'} );
      this.loading=false;
    },
  }
};
</script>
