<template>
  <v-app class="app-background" :class="darkMode ? 'dark-mode' : ''">
    <!-- Navigation tab -->
    <v-app-bar
      absolute
      color="var(--cards-background-color)"
      class="navbar-container"
      app
    >
      <v-toolbar-title>
        <v-row>
          <v-col @click="get_landing()"
            ><img src="@/assets/1lyoptions.png" class="logo pa-0" alt="oops"
          /></v-col>
        </v-row>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="d-flex align-center">
        <router-link
          active-class="router-link-active"
          to="/dashboard"
          class="nav-btns hide-on-mobile"
        >
          <span class="primary-btn">Dashboard</span>
        </router-link>
        <router-link
          active-class="router-link-active"
          to="/position"
          class="nav-btns hide-on-mobile"
        >
          <span class="primary-btn">Position</span>
        </router-link>
        <router-link
          active-class="router-link-active"
          to="/option-chain"
          class="nav-btns hide-on-mobile"
        >
          <span class="primary-btn" style="white-space: nowrap"
            >Option Chain</span
          >
        </router-link>
        <span class="navbar-icons">
          <!-- ANALYSE MENU/BURGER MENU -->
          <v-col class="pa-0" v-if="!$vuetify.breakpoint.xsOnly">
            <v-menu
              transition="slide-y-transition"
              style="z-index: 10 !important"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="tool-dropdown nav-btns" icon>
                  <!-- <v-icon open-on-hover v-bind="attrs" v-on="on">mdi-account-circle-outline</v-icon> -->
                  <span open-on-hover class="" v-bind="attrs" v-on="on">
                    <span class="hidden-sm-and-down"
                      >Analytics Tool
                      <v-icon>mdi-chevron-down</v-icon>
                    </span>
                    <v-app-bar-nav-icon
                      style="var(--option-row-text)"
                      class="hidden-md-and-up"
                    ></v-app-bar-nav-icon>
                  </span>
                </v-btn>
              </template>
              <v-list class="header-dropdown">
                <v-divider class="hidden-sm-and-up"></v-divider>
                <v-list-item class="">
                  <v-list-item-title>
                    <router-link
                      to="/option-scanner"
                      class="user-control-item"
                      style="border-bottom: none !important"
                    >
                      <img src="@/assets/straddle-premium-icon.svg" alt="" />
                      Straddle Premium
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="">
                  <v-list-item-title>
                    <router-link
                      to="/open-interest-table"
                      class="user-control-item"
                      style="border-bottom: none !important"
                    >
                      <img src="@/assets/open-interest-icon.svg" alt="" />
                      Open Interest
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="">
                  <v-list-item-title>
                    <router-link
                      to="/strike-charts"
                      class="user-control-item"
                      style="border-bottom: none !important"
                    >
                      <img src="@/assets/multistrike.svg" alt="" />
                      Multi Strike Charts
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </span>
        <router-link
          active-class="router-link-active"
          to="/research-calls"
          class="nav-btns hide-on-mobile"
        >
          <span class="primary-btn" style="white-space: nowrap"
            >Research Calls</span
          >
        </router-link>
      </span>
      <v-spacer></v-spacer>
      <span
        class="d-flex align-center justify-end mx-md-2 mx-0 navbar-icons"
        style="margin-top: 0px"
      >
        <!-- ANNOUNCEMENT MENU -->
        <v-col class="pa-0 mr-3">
          <v-menu
            max-height="500"
            offset-y
            bottom
            transition="slide-y-transition"
            max-width="350"
            content-class="announcements-menu styled-scroll"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                :content="announcements.length"
                color="var(--red-text)"
                dot
                :value="!seenNotification"
              >
                <v-btn icon v-bind="attrs" v-on="on" style="position: relative">
                  <v-icon
                    class="announcement-icon"
                    style="font-size: 22px"
                    color="var(--text-color) !important"
                    >mdi-bullhorn</v-icon
                  >
                </v-btn>
              </v-badge>
            </template>
            <v-list>
              <!-- Disclaimer -->
              <v-list-item>
                <v-list-item-content class="py-2">
                  <v-list-item-title
                    style="font-size: 1.25em; font-weight: 500"
                  >
                    Disclaimer
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                style="color: var(--text-color)"
                @click="showDisclaimer = true"
                three-line
              >
                <v-list-item-avatar class="align-self-start">
                  <v-icon
                    class="announcement-icon"
                    style="rotate: 0deg; border: none"
                    color="var(--red-text)"
                  >
                    mdi-alert
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title style="font-size: 1em; font-weight: 500">{{
                    disclaimer.title
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ disclaimer.message }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mx-5"></v-divider>

              <!-- Menu Section Header -->
              <v-list-item v-if="announcements.length > 0">
                <v-list-item-content class="py-2">
                  <v-list-item-title style="font-size: 1.25em; font-weight: 500"
                    >Announcements</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="my-2" v-if="!seenNotification">
                  <v-btn
                    @click="seenNotification = true"
                    text
                    style="
                      color: var(--primary-button-color);
                      font-size: 0.575em !important;
                      text-decoration: underline;
                    "
                  >
                    Mark All Read
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <div
                v-for="(item, index) in announcements"
                :key="index + item.title"
              >
                <v-list-item
                  :to="goToFeature(item)"
                  style="color: var(--text-color)"
                >
                  <v-list-item-avatar class="align-self-start">
                    <v-icon
                      class="announcement-icon"
                      color="var(--primary-button-color)"
                    >
                      mdi-bullhorn
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content class="expanded-content">
                    <v-list-item-title
                      style="font-size: 1em; font-weight: 500"
                      >{{ item.title }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{ item.message }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mt-2">
                      {{ item.postedTime.toDateString() }},
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  class="mx-5"
                  v-if="index != announcements.length - 1"
                ></v-divider>
              </div>
            </v-list>
          </v-menu>
        </v-col>
        <!-- USER MENU -->
        <v-col class="pa-0">
          <v-menu
            transition="slide-y-transition"
            offset-y
            class="user-menu-dropdown"
            ref="userMenu"
            :disabled="isAnonymous"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon>
                <!-- <v-icon open-on-hover v-bind="attrs" v-on="on">mdi-account-circle-outline</v-icon> -->
                <img
                  v-bind="attrs"
                  v-on="on"
                  :src="
                    !darkMode
                      ? require('@/assets/LightMode/user.svg')
                      : require('@/assets/DarkMode/user.svg')
                  "
                  alt=""
                />
              </v-btn>
            </template>

            <v-list class="header-dropdown">
              <v-list-item>
                <v-list-item-title>
                  <button class="user-control-item">
                    <img
                      :src="
                        !darkMode
                          ? require('@/assets/LightMode/user.svg')
                          : require('@/assets/DarkMode/user.svg')
                      "
                      alt=""
                    />
                    {{ userId }}
                  </button>
                </v-list-item-title>
              </v-list-item>

              <v-list-item to="/orderbook">
                <v-list-item-title>
                  <button class="user-control-item">
                    <img
                      :src="
                        !darkMode
                          ? require('@/assets/LightMode/orderbook.svg')
                          : require('@/assets/DarkMode/orderbook.svg')
                      "
                      alt=""
                    />
                    Order Book
                  </button>
                </v-list-item-title>
              </v-list-item>

              <v-list-item to="/feedback">
                <v-list-item-title>
                  <button @click="feedback()" class="user-control-item">
                    <img
                      :src="
                        !darkMode
                          ? require('@/assets/LightMode/feedback.svg')
                          : require('@/assets/DarkMode/feedback.svg')
                      "
                      alt=""
                    />
                    Feed Back
                  </button>
                </v-list-item-title>
              </v-list-item>

              <v-list-item to="/settings">
                <v-list-item-title>
                  <button class="user-control-item">
                    <img
                      :src="
                        !darkMode
                          ? require('@/assets/LightMode/settings.svg')
                          : require('@/assets/DarkMode/settings.svg')
                      "
                      alt=""
                    />
                    Settings
                  </button>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>
                  <button
                    @click="logOut()"
                    class="user-control-item log-out-button"
                  >
                    <img
                      :src="
                        !darkMode
                          ? require('@/assets/LightMode/logout.svg')
                          : require('@/assets/DarkMode/logout.svg')
                      "
                      alt=""
                    />
                    Log Out
                  </button>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </span>
    </v-app-bar>

    <!-- Main Content -->
    <v-main class="main-main pb-15 pb-sm-0">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="800"
      v-model="showDisclaimer"
      content-class="disclaimer-dialog"
    >
      <template>
        <v-card class="pa-5">
          <div class="pa-4 text-center">
            <span style="color: var(--text-color); font-size: 1.6em"
              >Disclaimer</span
            >
          </div>
          <v-card-text
            style="font-size: 1em; word-spacing: 0.05em; line-height: inherit"
          >
            <div style="color: var(--text-color)">{{ disclaimer.message }}</div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <button
              @click="showDisclaimer = false"
              style="font-size: 1em"
              class="primary-button"
            >
              Continue
            </button>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <!-- Bottom navigation for mobile view -->
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.xsOnly"
      fixed
      class="mt-0"
      v-model="bottomNavVal"
      active
      color="var(--primary-button-color)"
    >
      <v-btn class="bottom-buttons" @click="dashboard()">
        <v-icon> mdi-view-dashboard-outline </v-icon>
        <div class="mt-1">
          Dashboard
        </div>  
      </v-btn>

      <v-btn class="bottom-buttons" @click="position()">
        <v-icon> mdi-chart-box-plus-outline </v-icon>
        <div class="mt-1">
          Position
        </div>  
        
      </v-btn>

      <v-btn class="bottom-buttons" @click="watchlist()">
        <v-icon> mdi-bookmark-outline </v-icon>
        <div class="mt-1">
          Watchlist
        </div>  
        
      </v-btn>

      <v-btn class="bottom-buttons" @click="option_chain()">
        <v-icon> mdi-link-variant </v-icon>
        <div class="mt-1">
          Option Chain
        </div>  
      </v-btn>
      <v-menu transition="slide-y-transition" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="tool-dropdown bottom-buttons">
            <span open-on-hover class="" v-bind="attrs" v-on="on">
              <div>
                <v-icon>mdi-menu</v-icon>
              </div>
              <div class="mt-1">
                Menu
        </div>  
            
            </span>
          </v-btn>
        </template>
        <v-list class="header-dropdown">
          <v-divider class="hidden-sm-and-up"></v-divider>
          <v-list-item class="">
            <v-list-item-title>
              <button @click="option_scanner()" class="user-control-item">
                <img src="@/assets/straddle-premium-icon.svg" alt="" />
                Straddle Premium
              </button>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="">
            <v-list-item-title>
              <button @click="open_interest()" class="user-control-item">
                <img src="@/assets/open-interest-icon.svg" alt="" />
                Open Interest
              </button>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="">
            <v-list-item-title>
              <router-link
                to="/strike-charts"
                class="user-control-item"
                style="border-bottom: none !important"
              >
                <img src="@/assets/multistrike.svg" alt="" />
                Multi Strike Charts
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="">
            <v-list-item-title>
              <router-link
                to="/research-calls"
                class="user-control-item"
                style="border-bottom: none !important"
              >
                <img src="@/assets/multistrike.svg" alt="" />
                Research Calls
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-bottom-navigation>
  </v-app>
</template>
<script>
import axios from "axios";
import { EventBus } from "../main";
import UserDataMixin from "@/mixins/getUserDataMixin";
import { getUserSettingsUrl } from "./getService.js";
export default {
  name: "Layout",
  mixins: [UserDataMixin],
  data() {
    return {
      bottomNavVal: 0,
      fontSizing:'m',

      // Announcement vars
      showDisclaimer: false,
      seenNotification: localStorage.getItem("seen_notification")
        ? true
        : false,
    };
  },
  mounted() {
    this.$store.dispatch("actionsOnReload");
    this.setSiteFontsize()
    window.addEventListener("resize", () => {
      this.setSiteFontsize();
    });
    setTimeout(() => {
      this.getUserSettings();
      
    }, 500)

    if (localStorage.getItem("darkMode")) {
      if (localStorage.getItem("darkMode") == "false") {
        this.onSwitched(false);
      } else {
        this.onSwitched(true);
      }
    }
    this.setBottomNavVal();
  },
  watch: {
    seenNotification(val) {
      localStorage.setItem("seen_notification", val);
    },
    $route() {
      this.setBottomNavVal();
    },
  },
  methods: {
    setBottomNavVal() {
      const routeName = this.$route.name;
      if (routeName == "Dashboard") {
        this.bottomNavVal = 0;
      } else if (routeName == "Position") {
        this.bottomNavVal = 1;
      } else if (routeName == "Watchlist") {
        this.bottomNavVal = 2;
      } else if (routeName == "OptionChain") {
        this.bottomNavVal = 3;
      } else {
        this.bottomNavVal = 4;
      }
    },
    getUserSettings() {
      let payload = {
        user_id: this.userId,
        path: "get_user_settings",
        access_token: this.accessToken,
      };
      axios
        .post(getUserSettingsUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          if (res.data.errorMessage) throw res.data;
          let userSettings = res.data;
          localStorage.setItem("user_settings", JSON.stringify(userSettings));
          EventBus.$emit("user_settings", userSettings);
          if (userSettings.modes == "light_mode") {
            this.onSwitched(false);
          } else {
            this.onSwitched(true);
          }
          this.fontSizing=userSettings.font_size;
          this.setSiteFontsize();
        })
        .catch((err) => {
          console.log("error while loading user settings", err);
        });
    },
    setSiteFontsize(){
      const sizing=this.fontSizing;
      let fontSize="1vw";
      const screenWidth=window.innerWidth
      if (sizing == "s") {
        if (screenWidth < 601) {
          fontSize = "3.1vw";
        } else if (screenWidth < 961) {
          fontSize = "1.5vw";
        } else {
          fontSize = "0.85vw";
        }
      } else if (sizing == "m") {
        if (screenWidth < 601) {
          fontSize = "3.5vw";
        } else if (screenWidth < 961) {
          fontSize = "1.7vw";
        } else {
          fontSize = "0.9vw";
        }
      } else if (sizing == "l") {
        if (screenWidth < 601) {
          fontSize = "3.9vw";
        } else if (screenWidth < 961) {
          fontSize = "1.8vw";
        } else {
          fontSize = "1vw";
        }
      }
      document.documentElement.style.fontSize = fontSize;
    },
    goToFeature(item) {
      if (
        item.path &&
        this.$router.options.routes.find((el) => el.path == "/" + item.path)
      ) {
        return { path: item.path }
      }
    },
    openMenu() {
      if (this.$refs.userMenu) {
        this.$refs.userMenu.isActive = true;
      }
    },
    onSwitched(switched) {
      this.$store.commit("setDarkMode", switched);
    },
    get_landing() {
      this.$router.push({ name: "Home" });
    },
    dashboard() {
      this.$router.push({ name: "Dashboard" });
    },
    position() {
      this.$router.push({ name: "Position" });
    },
    watchlist() {
      this.$router.push({ name: "Watchlist" });
    },
    option_chain() {
      this.$router.push({ name: "OptionChain" });
    },
    open_interest() {
      this.$router.push({ name: "OpenInterestFilter" });
    },
    option_scanner() {
      this.$router.push({ name: "OptionScanner" });
    },
    orderbook() {
      this.$router.push({ name: "Orderbook" });
    },
    feedback() {
      this.$router.push({ name: "Feedback" });
    },
    settings() {
      this.$router.push({ name: "Settings" });
    },
    logOut() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    announcements() {
      return this.$store.getters.getAnnouncements;
    },
    disclaimer() {
      return this.$store.getters.getDisclaimer;
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn--icon.v-size--default .v-icon {
  color: var(--option-row-text) !important;
}
.mis-nrml-label {
  padding: 4px;
  // font-size: 1rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  display: flex;

  span {
    padding: 3px 12px;
    cursor: pointer;
    width: 80px;
    display: flex;
    text-align: center;
    white-space: nowrap;

    img {
      margin-right: 5px;
    }
  }

  .selected-span {
    background-color: var(--primary-button-color);
    color: var(--primary-button-text-color);
    border-radius: 4px;
  }

  .unselected-span {
    background-color: transparent;
    color: var(--text-color);
  }
}

.navbar-container {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem !important;
  line-height: 22px;
  letter-spacing: 2px;
}

.router-link-active,
.router-link-exact-active {
  border-bottom: 2px solid #2196f3 !important;
}
/* .mobile-view{
    display: none;
} */
a {
  text-decoration: none;
}
.announcement-icon {
  border-radius: 50%;
  rotate: -30deg;
  font-size: 1.6em;
  border: 1px solid var(--primary-button-color);
}
</style>
<style lang="scss">
.navbar-icons.v-toolbar__content .v-btn.v-btn--icon.v-size--default,
.navbar-icons .v-btn--icon.v-size--default {
  width: auto !important;
  height: auto !important;
}

.app-background {
  background-color: var(--app-background-color) !important;
}
.user-menu-dropdown .v-menu__content.menuable__content__active {
  min-width: 48px;
  top: 56px;
  left: 85%;
  transform-origin: right top !important;
  z-index: 11;
}
.user-menu-dropdown,
.header-dropdown {
  z-index: 11 !important;
}
.disclaimer-dialog {
  border-radius: 15px !important;
}
.announcements-menu,
.disclaimer-dialog {
  font-size: max(13px, 1em);
  .v-list-item__subtitle {
    font-size: 0.825em;
    color: var(--text-color) !important;
  }
  .expanded-content .v-list-item__subtitle {
    overflow: auto;
    white-space: normal;
  }
}
.main-main {
  padding-top: 65px !important;
  position: relative;
}

.title-nav {
  p {
    margin: 0;
  }
}
.user-control-item {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    height: 16px;
  }
}
a.user-control-item {
  color: var(--text-color) !important;
  text-decoration: none !important;
}
.log-out-button {
  color: #df514c;
}
.navbar-icons {
  .col > .tool-dropdown {
    font-weight: 600;
  }
}
.bottom-buttons {
  font-size: 10px !important;
  text-align: center;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  .v-btn__content {
    flex-direction: column !important;
    font-family: "Gilroy-SemiBold";
  }
  img {
    width: 15px;
    height: 15px;
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 959px) {
  .navbar-icons {
    min-width: 98px;
    max-width: 120px;
    .col > .tool-dropdown {
      min-width: 47px;
      max-width: 18px;
      margin: 0;
    }
  }
}
@media screen and (max-width: 601px) {
  .hide-on-desktop {
    display: none;
  }
  .navbar-icons {
    min-width: 50px;
    max-width: 50px;
  }
}
@media screen and (max-width: 600px) {
  .header-dropdown {
    min-width: 125px;
    .user-control-item {
      font-size: 10px;
    }
    .v-list-item {
      min-height: 35px;
      padding: 0;
      margin-left: 10px;
    }
  }
  .hide-on-mobile {
    display: none;
  }
  .layout-default-font {
    font-size: 12px;
    margin-bottom: 20px;
  }

  // .tool-dropdown{
  //     margin-left: 10% !important;
  //     color: #000 !important;
  //     border: none;
  //     text-transform: none;
  //     margin-top:20px;
  // }
  .logo {
    max-width: 100px !important;
  }
  .toolbar-item {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .nav-btns {
    margin: 0 0px !important;
  }

  .title-nav {
    p {
      position: absolute;
      left: 0;
      top: 45px;
      width: 100%;
      text-align: center;
      width: 100%;
      min-width: 100%;
      padding: 0px 10px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .bottom-buttons {
    font-size: 10px !important;
    min-width: 70px !important;
  }
}

.nav-btns {
  margin: 0 8px;
  box-shadow: none;
  background-color: var(--cards-background-color) !important;
}
.primary-btn {
  background: none !important;
  box-shadow: none;
  color: var(--text-color);
  // text-transform: uppercase;
}
.primary-btn:hover {
  background: var(--cards-background-color) !important;
  border-radius: none !important;
  border-bottom: 2px solid #2196f3 !important;
  padding-bottom: 8px;
}
.tool-dropdown {
  color: var(--text-color) !important;
  border: none;
  text-transform: none;
}
.tool-dropdown::before {
  visibility: hidden;
}
.logo {
  max-width: 166px;
  height: auto;
}
.tabs .v-slide-group__content {
  background: #ecf8fe !important;
}
.tabs {
  margin-bottom: 20px;
}
.announcements-menu{
  z-index: 10 !important;
}
</style>

<style>
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 30px !important;
}
.tool-dropdown.v-btn {
  text-transform: none !important;
}
</style>
