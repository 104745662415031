import axios from 'axios';

import { Router } from '@/main';

import { 
  POSITION_CACHE_NAME,
  SNACKBAR_CLEAR_TIMEOUT, 
  SNACKBAR_COLOR_SCHEME as sbColors,
  DEFAULT_SNACKBAR_TYPE as defaultSbType
 } from "@/utils/constants";

import { getUserSettingsUrl, announcementUrl, getBalanceCall } from '@/components/getService';
import {
  getMCXPositionSearchJSON,
  getCDSPositionSearchJSON,
  getHoldingsSearchCSV,
  getNFOPositionSearchJSON
} from "@/components/getService";

export default {
  state () {
    return{
      announcements:[],
      userSettings: JSON.parse(localStorage.getItem("user_settings")),
      userBalance: +localStorage.getItem("balance"),

      networkStatus: "connected",
      networkStatusInterval: null,

      snackbarMsg:'',
      snackbarMsgObj:null,
    }
  },
  getters: {
    getAnnouncements: state => state.announcements,
    getUserSettings: state => state.userSettings,
    getBalance: state => state.userBalance,
    getNetworkStatus: (state) => state.networkStatus,
    
    getSnackbarMsg: (state) => state.snackbarMsgObj ? state.snackbarMsgObj.message : null,
    getSnackbarMsgColor: (state) => state.snackbarMsgObj ? state.snackbarMsgObj.color : sbColors[defaultSbType],
    getSnackbarMsgTimeout: (state) => state.snackbarMsgObj ? state.snackbarMsgObj.timeout : null,
    getSnackbarMsgObj: (state) => state.snackbarMsgObj,
  },
  mutations: {
    setAnnouncements(state, payload){
      state.announcements = payload;
    },
    setUserSettings(state, settings){
      state.userSettings = settings;
      localStorage.setItem("user_settings", JSON.stringify(settings));
    },
    setBalance(state, balance){
      state.userBalance = balance;
      localStorage.setItem("balance", balance);
    },

    // SNACKBAR IN APP COMPONENT
    setSnackbarMsg(state, payload) {
      const isObj = typeof (payload) == 'object';
      const message = isObj ? payload.message : payload;
      const color = sbColors[payload.type] ?? sbColors[defaultSbType];
      const timeout= payload.timeout??SNACKBAR_CLEAR_TIMEOUT
      state.snackbarMsgObj = {
        message: message,
        color: color,
        timeout:timeout
      }
      setTimeout(() => state.snackbarMsgObj = null, timeout)
    },

    // USER ONLINE CHECK MUTATIONS
    setNetworkStatus: (state, status) => state.networkStatus = status,
    setNetworkStatusInterval(state, interval) {
      if (state.networkStatusInterval)
        clearInterval(state.networkStatusInterval);
      state.networkStatusInterval = interval;
    },
  },
  actions:{
    // Set user settings
    async getUserSettings({ commit, rootGetters }){
      const user=rootGetters.getUser;
      const payload = {
        user_id: user.userId,
        path: "get_user_settings",
        access_token: user.accessToken,
      }
      axios
      .post(getUserSettingsUrl, payload, {
        headers: {
          "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3",
        },
      })
      .then((res) => {
        const settings = res.data;
        commit('setUserSettings', settings);
        const landing_page = settings.landing_page == 'dashboard' ? 'Dashboard' : settings.landing_page;
        const targetRoute = Router.options.routes.find(el => el.name == landing_page);

        const storedPath = localStorage.getItem("redirectedPath")
        const path = storedPath ? storedPath : targetRoute ? targetRoute.path : 'dashboard';
        Router.push({ path: path });
        localStorage.removeItem("redirectedPath");
      })
      .catch(err=>{
        console.group("ERROR LOADING SETTINGS");
        console.log(err);
        console.log('error occured while fetching settings');
        console.groupEnd()
        Router.push({ path: '/' })
      });
    },
    // Get announcements
    async getAnnouncements({ commit }){
      const payload={
        operation:"get"
      }
      axios.post(announcementUrl,payload)
      .then(res=>{
        const announcements=res.data;
        announcements.forEach(el=>{
          el['postedTime']=new Date(el.live_from.toLocaleString())
        })
        commit('setAnnouncements',announcements);
      })
      .catch(err=>{
        console.log("error while loading announcements",err)
      })
    },
    // get user Balance
    async getBalance({ commit,rootGetters }){
      const access_token=rootGetters.getAccessToken;
      const userId=rootGetters.getUserId;
      const payload={auth_code: access_token, userId: userId};

      axios.post(getBalanceCall, payload)
      .then(res => {
        if (res.data.status) {
          let balance = res?.data?.data?.net;
          if(balance)
            commit('setBalance',balance);
        }
      })
      .catch(err => {
        console.log("error while getting balance",err)
      })
    },
    async setCachedData({dispatch}) {
      const fileUrls=[
        getNFOPositionSearchJSON,
        getMCXPositionSearchJSON,
        getCDSPositionSearchJSON,
        getHoldingsSearchCSV
      ];
      for (const url of fileUrls) {
        const cacheName = POSITION_CACHE_NAME;
        let cachedData = await dispatch('getCachedData',cacheName, url);
        if (!cachedData) {
          const cacheStorage = await caches.open(cacheName);
          await cacheStorage.add(url);
        }
      }
    },
    async getCachedData(_,cacheName, url) {
      const cacheStorage = await caches.open(cacheName);
      const cachedResponse = await cacheStorage.match(url);
      if (!cachedResponse || !cachedResponse.ok) {
        return false;
      }
      return await cachedResponse.text();
    },
    async setNetworkStatusCheckInterval({ getters, commit, dispatch }) {
      const networkStatus = ()=>getters.getNetworkStatus;
      const interval = setInterval(() => {
        if (navigator.onLine) {
          if (networkStatus() == "disconnected") {
            dispatch("actionsAfterReconnectingToInternet", { root: true });
          }
          commit("setNetworkStatus", "connected");
        } else {
          console.log("disconnected")
          commit("setNetworkStatus", "disconnected");
        }
      }, 3000);
      commit("setNetworkStatusInterval", interval);
    },
    async clearNetworkStatusInterval({ commit }) {
      commit("setNetworkStatusInterval",null);
    },
  },
}