export default {
  // test:{
  //   get: function () {
  //     return this.firstName + ' ' + this.lastName
  //   },
  //   set: function (newValue) {
  //     var names = newValue.split(' ')
  //     this.firstName = names[0]
  //     this.lastName = names[names.length - 1]
  //   }
  // },
  gammaCE(){
    if(!this.greeksData) return [];
    const list=[ ...this.greeksData.gammaCE ];
    if(this.isReversed) list.reverse();
    return list;
  },
  gammaPE(){
    if(!this.greeksData) return [];
    const list=[ ...this.greeksData.gammaPE ];
    if(this.isReversed) list.reverse();
    return list;
  },
  vegaCE(){
    if(!this.greeksData) return [];
    const list=[ ...this.greeksData.vegaCE ];
    if(this.isReversed) list.reverse();
    return list;
  },
  vegaPE(){
    if(!this.greeksData) return [];
    const list=[ ...this.greeksData.vegaPE ];
    if(this.isReversed) list.reverse();
    return list;
  },
  deltaCE(){
    if(!this.greeksData) return [];
    const list=[ ...this.greeksData.deltaCE ];
    if(this.isReversed) list.reverse();
    return list;
  },
  deltaPE(){
    if(!this.greeksData) return [];
    const list=[ ...this.greeksData.deltaPE ];
    if(this.isReversed) list.reverse();
    return list;
  },
  thetaCE(){
    if(!this.greeksData) return [];
    const list=[ ...this.greeksData.thetaCE ];
    if(this.isReversed) list.reverse();
    return list;
  },
  thetaPE(){
    if(!this.greeksData) return [];
    const list=[ ...this.greeksData.thetaPE ];
    if(this.isReversed) list.reverse();
    return list;
  },
  oiCE(){
    if(!this.optionChainFallBackData) return [];
    const list=[...this.optionChainFallBackData.oiCE]
    if(this.isReversed) list.reverse();
    return list;
  },
  oiPE(){
    if(!this.optionChainFallBackData) return [];
    const list=[...this.optionChainFallBackData.oiPE]
    if(this.isReversed) list.reverse();
    return list;
  },
  ivCE(){
    if(!this.optionChainFallBackData) return [];
    const list=[...this.optionChainFallBackData.ivCE]
    if(this.isReversed) list.reverse();
    return list;
  },
  ivPE(){
    if(!this.optionChainFallBackData) return [];
    const list=[...this.optionChainFallBackData.ivPE]
    if(this.isReversed) list.reverse();
    return list;
  },
  volumeCE(){
    if(!this.optionChainFallBackData) return [];
    const list=[...this.optionChainFallBackData.volumeCE]
    if(this.isReversed) list.reverse();
    return list;
  },
  volumePE(){
    if(!this.optionChainFallBackData) return [];
    const list=[...this.optionChainFallBackData.volumePE]
    if(this.isReversed) list.reverse();
    return list;
  },
  ltpCE(){
    if(!this.optionChainFallBackData) return [];
    const list=[...this.optionChainFallBackData.ltpCE]
    if(this.isReversed) list.reverse();
    return list;
  },
  ltpPE(){
    if(!this.optionChainFallBackData) return [];
    const list=[...this.optionChainFallBackData.ltpPE]
    if(this.isReversed) list.reverse();
    return list;
  },
  priceChangeCE(){
    if(!this.optionChainFallBackData) return [];
    const list=[...this.optionChainFallBackData.priceChangeCE]
    if(this.isReversed) list.reverse();
    return list;
  },
  priceChangePE(){
    if(!this.optionChainFallBackData) return [];
    const list=[...this.optionChainFallBackData.priceChangePE]
    if(this.isReversed) list.reverse();
    return list;
  },
}