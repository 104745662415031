<template>
  <div class="card">
    <div class="thumbnail" :id="`thumbnail${index}`">        
    </div>
    <p class="title">{{title}}</p>
  </div>
</template>

<script>
export default {
    props:['title','thumbnailsrc', 'index'],
    mounted(){ 
        document.getElementById(`thumbnail${this.index}`).style.backgroundImage = `url(${require('@/assets/'+this.thumbnailsrc)})`    
    }

}
</script>

<style lang="scss" scoped>
.card{
    width: 376px;
    height: 465px;
    box-shadow: 0px 4px 20px #DBEFFF;
    border-radius: 10px;
    text-align: center;
    padding: 41px 30px;
    margin-right: 53px;
    @media only screen and (max-width:580px) {
        padding: 18px 12px;
        width: 140px;
        height: 209px;
        margin-right: 30px;
    }
    .thumbnail{
        width: 315px;
        height: 182px;
        border-radius: 5px;

        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
        margin-bottom: 77px;
        margin-left: 50%;
        transform: translateX(-50%);
        @media only screen and (max-width:980px) {
            
        }
        @media only screen and (max-width:580px) {
            width: 117px;
            height: 81px;
            margin-bottom: 34px;
        }
    }
    .title{
        font-weight: 600;
        font-size: 24px;
        color: #4179C7;
        text-transform: capitalize;
        padding: 0 50px;
         @media only screen and (max-width:580px) {
            font-size: 12px;
            padding: 0;
        }
    }
}

</style>