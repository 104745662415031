import ReconnectingWebSocket from "reconnecting-websocket";

import GetSunscribePayload from "./subscribeABPayload";
import GetUnsubscribePatload from "./unsubscribeABPayload";

import { aliceblueWebsocketUrl } from '@/utils/aliceBlueUrls';
export default {
  state() {
    return {
      // websocket vars
      ws: null,
      heartbeatInterval: null,
      subscriptionRecord: {},
    };
  },
  getters: {
    getSubscriptionRecord: (state) => state.subscriptionRecord,
    getConnectWSPayload: (state, getters, rootState, rootGetters) => {
      const wsSession = rootGetters.getWsSession;
      const userId = rootGetters.getUserId;
      return JSON.stringify({
        susertoken: wsSession,
        t: "c",
        actid: userId + "_API",
        uid: userId + "_API",
        source: "API",
      })
    },
    getWebsocket: (state) => state.ws,
    getHeartbeatPayload: () => JSON.stringify({ k: ``, t: "h" }),
  },
  mutations: {
    // WEBSOCKET MUTATIONS
    setWS: (state, ws) => state.ws = ws,
    closeAndClearWS(state) {
      if (!state.ws) return;
      state.ws.close();
      state.ws = null;
    },

    // HEARTBEAT MUTATIONS
    setHeartbeatInterval: (state, interval) => state.heartbeatInterval = interval,
    clearHeartbeatInterval(state) {
      if (!state.heartbeatInterval) return;
      clearInterval(state.heartbeatInterval);
      state.heartbeatInterval = null;
    },

    // SUBSCRIPTION RECORD MUTATIONS
    addToSubscriptionRecord(state, payload) {
      state.subscriptionRecord = { ...state.subscriptionRecord, ...payload };
    },
    removeFromSubscriptionRecord(state, payload) {
      Object.keys(payload).forEach(key => {
        delete state.subscriptionRecord[key];
      });
    },
  },
  actions: {
    startWebsocket({ dispatch, commit, getters }) {
      const prevWs = getters.getWebsocket;
      if (prevWs) {
        commit('closeAndClearWS');
      }
      const payload = getters.getConnectWSPayload;
      const ws = new ReconnectingWebSocket(aliceblueWebsocketUrl);
      ws.send(payload);
      ws.onopen = () => dispatch("onWSopen");
      ws.onclose = () => dispatch("onWSClose");
      ws.onerror = (err) => dispatch("onWSError", err);
      ws.onmessage = (event) => dispatch("onWSMessage", event);

      commit("setWS", ws);
    },
    onWSopen({dispatch,getters}) {
      // console.log("Websocket Opened");

      // set heartbeat
      const heartbeat = getters.getHeartbeatInterval;
      if (!heartbeat) dispatch("setWSHeartbeat");

      // resubscribe to instruments
      if(Object.keys(getters.getSubscriptionRecord).length>0)
        dispatch('resubscribeToInstruments');
    },
    async onWSMessage({ commit }, event) {
      const msg = JSON.parse(event.data);
      // commit("handleWSMessage", msg, { root: true });
      commit("setcompactData", msg, { root: true });
      commit("setSubscribedInstrumentsData", msg, { root: true });
    },
    onWSError({dispatch,getters}, err) {
      console.log("error while establishing Websocket");
      console.log(err);

      const ws = getters.getWebsocket;
      if (!ws) return;
      
      console.log("trying to reconnect");
      dispatch('startWebsocket');
    },
    /**
     * @description reconnects to websocket when closed
     * uses the latest instance of the websocket from store
     * to completely close the websocket , state.ws should be set to null
     * if the websocket is set to null then it will not reconnect
     */
    onWSClose({ getters,dispatch }) {
      // console.log("Websocket Closed");
      const ws = getters.getWebsocket;
      if (!ws) return;
      console.log("restarting websocket");
      dispatch('startWebsocket');
    },
    /**
     * @description Sends heartbeat to greeksoft websocket every interval
     * @description Is set to fetch the latest instance of the websocket from store
     * so even if the websocket might me new the heartbeat will be sent to the latest instance
     */
    setWSHeartbeat({ getters, commit }) {
      let payload = () => getters.getHeartbeatPayload;
      let getWS = () => getters.getWebsocket;
      const interval = setInterval(() => {
        const ws = getWS();
        if (!ws || ws.readyState != 1) return;
        ws.send(payload());
      }, 10000);
      commit("setHeartbeatInterval", interval);
    },
    /**
     * @description completely close the websocket permenantly
     * set the websocket to null
     * set the heartbeat interval to null
     */
    permanentlyCloseWs({ commit, getters }) {
      const ws = getters.getWebsocket;
      if (!ws) return;
      commit('closeAndClearWS');
      commit('clearHeartbeatInterval');
    },
    async subscribeToInstruments({ getters, commit }, instruments) {
      const ws = getters.getWebsocket;
      const getPayload = (exchange, code) => GetSunscribePayload[exchange.toUpperCase()](code);
      const record = {};
      instruments.forEach(ins => {
        const payload = getPayload(ins.exchange, ins.code);
        ws.send(payload);
        record[ins.code] = { exchange: ins.exchange, code: ins.code, loc:ins.loc??null };
      });
      commit('addToSubscriptionRecord', record);
    },
    async unsubscribeToInstruments({ getters, commit }, instruments) {
      const ws = getters.getWebsocket;
      const getPayload = (exchange, code) => GetUnsubscribePatload[exchange.toUpperCase()](code);
      const removeFromRecord = {};
      instruments.forEach(ins => {
        const payload = getPayload(ins.exchange, ins.code);
        ws.send(payload);
        removeFromRecord[ins.code] = ins;
        commit("removeFromSubscribedInstrumentsData", ins.code, { root: true });
        commit("removeFromCompactMarketData", ins.code, { root: true });
      });
      commit('removeFromSubscriptionRecord', removeFromRecord);
    },
    unsubscribeFromLocation({ getters, dispatch }, location) {
      const record = getters.getSubscriptionRecord;
      const instruments = Object.values(record).filter(ins => ins.loc == location);
      dispatch('unsubscribeToInstruments', instruments);
    },
    async resubscribeToInstruments({ getters, dispatch }) {
      const record = getters.getSubscriptionRecord;
      const instruments = Object.values(record);
      dispatch('subscribeToInstruments', instruments);
    }
  },
};
