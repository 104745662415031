<template>
  <div v-if="positionsLoading" style="height: 90vh; width: 100%">
    <SkeletonLoader />
  </div>
  <div
    v-else
    class="white-card position-analytics-wrapper mx-0 my-2 my-md-0 ml-md-2"
    :class="{'funds-visible':showFunds}"
  >
    <ShareVerifiedPLModal
      :showModal="showShareVerifiedPLModal"
      :defaultPositionsList="defaultPositionsList"
      :realizedPL="realizedPL"
      :unrealisedPL="getTotalUnrealizedPL()"
      @close-modal="showShareVerifiedPLModal = false"
    />
    <!-- Tabs -->
    <v-row no-gutters>
      <v-col cols="10" md="5" class="position-label header">
        <v-tabs height="30px" color="var(--primary-button-color)">
          <v-tab @click="selectedAnalyticsTab = 'positions'; getPositionAnalyticsApi(selectedPositionFilter.value, true)"
            >Positions ({{ totalOpenPositions }})</v-tab
          >
          <v-tab @click="selectedAnalyticsTab = 'holdings'"
            >Holdings ({{ totalHoldings }})</v-tab
          >
        </v-tabs>
      </v-col>
      <v-col
        cols="12"
        offset-sm="0"
        md="7"
        class="text-sm-right text-center my-3 my-md-0"
      >
        <span class="alert-verify-label text justify-content-between">
          <span
            class="mr-6 header-op-buttons blue-text"
            @click="openShareVerifiedModal()"
          >
            <v-icon class="mr-1">mdi-share-variant-outline</v-icon>
            Verified P&L
          </span>
          <span
            class="header-op-buttons"
            :class="showFunds ? 'blue-text' : 'grey-text'"
            @click="setProgressBarData()"
          >
            <v-icon class="mr-1 eye" v-if="showFunds">mdi-eye-outline</v-icon>
            <v-icon class="mr-1 eye-off" v-if="!showFunds"
              >mdi-eye-off-outline</v-icon
            >
            View Funds
          </span>
        </span>
      </v-col>
    </v-row>

    <!-- Funds Progress bar -->
    <v-row no-gutters v-if="showFunds">
      <v-col>
        <div
          class="d-flex justify-space-between justify-sm-start px-1 mt-2 positions-heading"
        >
          <span> Fund Details </span>
          <span class="d-flex d-sm-none ml-2">
            Total Fund:
            <span class="neutral-text">{{ totalFund | integerFormat }}</span>
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="showFunds" class="mb-8">
      <v-col cols="12" sm="10" color="transparent" class="funds-bar">
        <div class="parent-progress-bar" v-if="totalFund!=0">
          <div class="progress-red"></div>
          <div class="progress-green"></div>
          <div class="fund-label d-sm-block" style="left: 0">
            Utilized Fund <br />
            <span class="mr-1 red-text">{{ utilizedFund | integerFormat }}</span
            ><span class="red-text">({{ utilizedFundWidth }}%)</span>
          </div>
          <div class="fund-label d-sm-block text-right" style="right: 0">
            Available Fund
            <br />
            <span class="mr-1 green-text">{{
              availableFund | integerFormat
            }}</span
            ><span class="green-text">({{ availableFundWidth }}%)</span>
          </div>
        </div>
        <div class="parent-progress-bar" v-else>
          <div class="progress-grey"></div>
        </div>
        <div class="d-none d-sm-flex ml-2" style="min-width: 170px">
          Total Fund:
          <span class="neutral-text ml-1">{{ totalFund | integerFormat }}</span>
        </div>
      </v-col>
    </v-row>

    <template v-if="selectedAnalyticsTab == 'positions'">
      <!-- Realized and Unrealized P&L section -->
      <v-row no-gutters class="my-2">
        <v-col cols="12" md="5" class="text-center header-labels my-3">
          <v-row>
            <v-col cols="6">
              Realized P&L
              <div class="green-text header-values">
                <span v-if="realizedPL > 0" class="green-text">+</span>
                <span :class="realizedPL >= 0 ? 'green-text' : 'red-text'"
                  >{{ realizedPL | integerFormat }}
                </span>
              </div>
            </v-col>
            <v-divider class="d-none d-md-block" vertical></v-divider>
            <v-col cols="6">
              Unrealized P&L
              <div class="green-text header-values">
                <span v-if="getTotalUnrealizedPL() > 0" class="green-text"
                  >+</span
                ><span
                  :class="
                    getTotalUnrealizedPL() >= 0 ? 'green-text' : 'red-text'
                  "
                  >{{ getTotalUnrealizedPL() | integerFormat }}
                </span>
              </div>
            </v-col>
            <v-divider class="d-none d-md-block" vertical></v-divider>
          </v-row>
        </v-col>
        <v-divider
          class="d-sm-block d-md-none horizontal-divider"
          inset
        ></v-divider>
        <v-col cols="12" md="7" class="text-center header-labels my-3">
          <v-row>
            <v-col cols="4">
              <div>
                Net Decay
                <v-tooltip
                  content-class="header-tooltips"
                  top
                  color="var(--cards-background-color)"
                  max-width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="info-icon">
                      <v-icon>mdi-information-variant-circle-outline</v-icon>
                    </span>
                  </template>
                  <span
                    >This indicates the net premium that would be gained (short
                    positions) and lost (long positions), if the contracts are
                    left to expire, excluding the underlying movement.</span
                  >
                </v-tooltip>
                <div class="header-values">
                  <span v-if="getNetDecay() > 0" class="green-text">+</span>
                  <span :class="getNetDecay() >= 0 ? 'green-text' : 'red-text'"
                    >{{ getNetDecay() | integerFormat }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-divider class="d-none d-md-block" vertical></v-divider>

            <v-col cols="4">
              <div>
                Max Profit
                <v-tooltip
                  content-class="header-tooltips"
                  top
                  color="var(--cards-background-color)"
                  max-width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="info-icon">
                      <v-icon>mdi-information-variant-circle-outline</v-icon>
                    </span>
                  </template>
                  <span
                    >Maximum potential profit on the open positions until
                    expiry.</span
                  >
                </v-tooltip>
                <div class="header-values">
                  <span v-if="maxProfit == 'Unlimited'">
                    {{ maxProfit }}
                  </span>
                  <span v-else>
                    {{ maxProfit | integerFormat }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-divider class="d-none d-md-block" vertical></v-divider>
            <v-col cols="4">
              <div>
                Max Loss
                <v-tooltip
                  content-class="header-tooltips"
                  top
                  color="var(--cards-background-color)"
                  max-width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="info-icon">
                      <v-icon>mdi-information-variant-circle-outline</v-icon>
                    </span>
                  </template>
                  <span
                    >Maximum potential loss on the open positions until
                    expiry.</span
                  >
                </v-tooltip>
                <div class="header-values">
                  <span v-if="maxLoss == 'Unlimited'">
                    {{ maxLoss }}
                  </span>
                  <span v-else>
                    {{ maxLoss | integerFormat }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Position Filter row -->
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="3"
          style="position: relative"
          class="mb-1 d-flex analyze-section"
        >
          <span>
            <v-select
              style="min-width: 205px; font-size: 1.05rem"
              class="analyze-dropdown"
              outlined
              v-model="selectedPositionFilter"
              :items="positionItems"
              item-text="label"
              item-value="value"
              label="Default"
              single-line
              return-object
              @change="getFilteredAnalytics"
            ></v-select>
          </span>
          <span class="refresh-icon d-flex ml-2">
            <v-icon @click="refreshPositions();refreshClicked=true;" >
              mdi-refresh
            </v-icon>
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="9"
          class="text-right alert-labels d-flex d-sm-block d-md-flex justify-end mb-2 align-center flex-wrap"
        >
          <div class="red-text d-flex justify-end align-center">
            <span v-if="positionLowLiquidity != 0" class="d-flex">
              <img
                class="mr-1 low-liquidity-icon"
                :src="
                  !darkMode
                    ? require('@/assets/Positions/low-liquidity.svg')
                    : require('@/assets/Positions/low-liquidity-dark.svg')
                "
              />
              {{ positionLowLiquidity }} Position(s) with Low Liquidity
            </span>
          </div>
          <div class="ml-2 red-text">
            <span v-if="positionAgainstTrends != 0">
              <v-icon class="mr-1">mdi-close-circle</v-icon>
              {{ positionAgainstTrends }} Position(s) against Trends
            </span>
          </div>
          <div class="red-text ml-2">
            <span v-if="positionAgainstDeliveryRisk != 0">
              <v-icon class="mr-1">mdi-clock-fast</v-icon>
              {{ positionAgainstDeliveryRisk }} Position(s) has Delivery Risk
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="defaultPositionsList.length == 0" no-gutters>
        <v-col class="text-center mt-7 no-positions">
          <div>
            <img
              style="width: 50%"
              class="mr-1"
              :src="
                !darkMode
                  ? require('@/assets/Positions/no-positions.svg')
                  : require('@/assets/Positions/no-positions-dark.svg')
              "
            />
          </div>
          <div class="no-position-text">No Positions Found</div>
        </v-col>
      </v-row>
      <v-row no-gutters style="height:80%; margin-top: 1rem">
        <DefaultPositions
          v-if="
            selectedPositionFilter.value == 'Default' &&
            defaultPositionsList.length != 0
          "
          :defaultPositionsList="defaultPositionsList"
          :refreshDefaultPositions="refreshDefaultPositions"
          :positionAnalyticsData="positionAnalyticsData"
          :instrumentList="instrumentList"
          :isDefaultPositions="true"
          @totalOpenPositions="getTotalOpenPositions"
          @positionAgainstTrends="getAgainstTrendPositions"
          @positionAgainstDeliveryRisk="getAgainstDeliveryRiskPositions"
          @positionLowLiquidity="getPositionLowLiquidity"
        />
        <PositionSymbolHedge
          v-else-if="
            (selectedPositionFilter.value == 'Symbols' ||
              selectedPositionFilter.value == 'Hedged' ||
              selectedPositionFilter.value == 'Expiry') &&
            defaultPositionsList.length != 0
          "
          :selectedPositionFilter="selectedPositionFilter"
          :symbolHedgePositionList="symbolHedgePositionList"
          :positionAnalyticsData="positionAnalyticsData"
        />
        <PositionCustom
          v-else-if="
            selectedPositionFilter.value == 'Custom' &&
            defaultPositionsList.length != 0 &&
            customPositionList.length != 0 &&
            instrumentList.length != 0
          "
          :instrumentList="instrumentList"
          :customPositionList="customPositionList"
          :positionAnalyticsData="positionAnalyticsData"
          :selectedPositionFilter="selectedPositionFilter"
        />
      </v-row>
    </template>
    <template v-else>
      <PositionHoldings
        :holdingsData="holdingsData"
        :instrumentList="instrumentList"
        @refreshHoldings="
          refreshHoldingsClicked = true;
          getHoldingsApi();
        "
      />
    </template>
  </div>
</template>
<script>
// import SAMPLE from '@/samples/holdings.json'
import axios from "axios";
import { EventBus } from "../../main.js";

import UserDataMixin from "@/mixins/getUserDataMixin";
import WebsocketMethodsMixin from "@/mixins/websocketMethodsMixin";

import {
  getBalanceCall,
  positionAnalyticsUrl,
  getTrendsAndSymbolsUrl,
  getHoldingsURL
} from "../getService";
import { getPlaceOrderData } from "./common/positionServices";
import { calculateTargetDays } from "../Dashboard/dashboardService";
import { getCustomStrategyUrl } from "../getService";

import DefaultPositions from "./DefaultPositions.vue";
import PositionSymbolHedge from "./PositionSymbolHedge.vue";
import ShareVerifiedPLModal from "./modals/ShareVerifiedPLModal.vue";
import PositionCustom from "./PositionCustom.vue";
import PositionHoldings from "./PositionHoldings.vue";
import SkeletonLoader from "../modals/SkeletonLoader.vue";

export default {
  name: "PositionAnalytics",
  components: {
    DefaultPositions,
    PositionSymbolHedge,
    PositionCustom,
    PositionHoldings,
    ShareVerifiedPLModal,
    SkeletonLoader,
  },
  mixins:[UserDataMixin, WebsocketMethodsMixin],
  created() {
    EventBus.$on("refreshPositionsScreen", () => {
      setTimeout(()=> this.refreshPositions(),200);
    });
  },
  destroyed(){
    EventBus.$off("refreshPositionsScreen");
  },
  computed: {
    insSub() {
      return (el) => {
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
    validDefaultPositions(){
      return this.defaultPositionsList.filter(el=>!el.isNa);
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  data() {
    return {
      showShareVerifiedPLModal: false,
      selectedAnalyticsTab: "positions",
      instrumentList: [],
      totalOpenPositions: 0,
      totalHoldings: 0,
      showFunds: false,
      totalFund: 0,
      utilizedFund: 0,
      utilizedFundWidth: 0,
      availableFund: 0,
      availableFundWidth: 0,
      realizedPL: 0,
      unrealizedPL: 0,
      totalDecay: 0,
      maxProfit: 0,
      maxLoss: 0,
      positionAgainstTrends: 0,
      positionLowLiquidity: 0,
      positionAgainstDeliveryRisk: 0,
      selectedPositionFilter: { label: "Default", value: "Default" },
      positionItems: [
        { label: "Default", value: "Default" },
        { label: "Analyze by Symbol", value: "Symbols" },
        { label: "Analyze by Hedge", value: "Hedged" },
        { label: "Analyze by Expiry", value: "Expiry" },
        { label: "Custom", value: "Custom" },
      ],
      defaultPositionsList: [],
      symbolHedgePositionList: [],
      expiryPositionsList: [],
      customPositionList: [],
      isDarkMode: false,
      refreshClicked: false,
      positionAnalyticsData: null,
      holdingsData: null,
      refreshDefaultPositions: false,
      positionsLoading: true,
    };
  },
  methods: {
    /**
      @function to get the instrument list for filters
    */
    getTrendsAndSymbols() {
      const payload = {
        path: "dashboard_symbols",
        broker: "aliceblue",
        user_id:this.userId,
        access_token: this.accessToken,
      };
      axios
        .post(getTrendsAndSymbolsUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          let newInsList = [];
          for (let ins of res.data) {
            if (
              ins.name === "NIFTY" ||
              ins.name === "FINNIFTY" ||
              ins.name === "BANKNIFTY"
            ) {
              newInsList.push(ins);
            } else {
              this.instrumentList.push(ins);
            }
          }
          this.instrumentList = newInsList.concat(this.instrumentList);
          // this.instrumentList = res.data;
        });
    },

    /**
      @function to get the authorization and the funds available and utilized
    */
    getBalanceApi() {
      let payload = {
        auth_code:this.accessToken,
        userId:this.userId,
      };
      axios.post(getBalanceCall, payload).then((res) => {
        this.totalFund = parseInt(res.data.data.credits);
        this.availableFund = parseInt(res.data.data.net);
        this.utilizedFund = this.totalFund - this.availableFund;
        this.availableFundWidth = parseInt(
          (this.availableFund / this.totalFund) * 100
        );
        this.utilizedFundWidth = 100 - this.availableFundWidth;
      });
    },
    async refreshPositions(){
      this.maxProfit = 0;
      this.maxLoss = 0;
      const gotUpdatedPositions = await this.getPositionAnalyticsApi(this.selectedPositionFilter.value, true);
      if(gotUpdatedPositions){
        this.refreshDefaultPositions = this.selectedPositionFilter.value == "Default" ? true : false;
        if(this.refreshClicked){
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: "Positions are refreshed",
          });
          this.refreshClicked=false;
        }
      }
    },
    /**
      @function to get the table data based on the condition
    */
    getPositionAnalyticsApi(key, computeHeader = false) {
      let payload = {
        access_token:this.accessToken,
        user_id:this.userId,
        filter_type: key == "Default" || key == "Symbols" ? "Default" : key,
      };
      return axios
        .post(positionAnalyticsUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.positionsLoading = false;
          this.customPositionList = [];
          this.positionAnalyticsData = res.data.analytics;
          this.realizedPL = res.data.analytics.realised_pnl;
          this.unrealizedPL = res.data.analytics.unrealised_pnl;
          if (res.data.default_data.length != 0) {
            if (key == "Default") {
              this.defaultPositionsList = res.data.default_data;
              this.subscribeToLtpValues(this.defaultPositionsList);
              this.symbolHedgePositionList = res.data.symbol_hedged;
              if (computeHeader) {
                for (let item of this.symbolHedgePositionList.unhedged) {
                  this.subscribeToLtpValues(item.positions);
                  setTimeout(() => {
                    this.customStrategyApi(item);
                  }, 1000);
                }
              }
            } else if (key == "Symbols" || key == "Hedged" || key == "Expiry") {
              this.symbolHedgePositionList = res.data.symbol_hedged;
              for (let item of this.symbolHedgePositionList.hedged) {
                this.subscribeToLtpValues(item.positions);
              }
              for (let item of this.symbolHedgePositionList.unhedged) {
                this.subscribeToLtpValues(item.positions);
              }
            } else if (key == "Custom") {
              for (let item of this.validDefaultPositions) {
                if (item.net_quantity !== 0) {
                  this.customPositionList.push(item);
                }
              }
            }
          }
          return true
        });
    },

    /**
     * @function subscribeToLtpValues to subscribe to all the LTPs
     */
    subscribeToLtpValues(positions) {
      const insList=[]
      for (let item of positions) {
        if (item.exchange == "NSE" || item.exchange == "NFO") {
          insList.push({code:item.instrument_token,exchange:"NSE"});
          insList.push({code:item.instrument_token,exchange:"NFO"});
          insList.push({code:item.underlying_code,exchange:"NSE"});
          insList.push({code:item.underlying_code,exchange:"NFO"});
        }else if (item.exchange == "BFO"){
          insList.push({code:item.fut_code,exchange:"BFO"});
          insList.push({code:item.instrument_token,exchange:"BFO"});
        }
         else if (item.exchange == "CDS") {
          insList.push({code:item.fut_code,exchange:"CDS"});
          insList.push({code:item.instrument_token,exchange:"CDS"});
        } else if (item.exchange == "MCX") {
          insList.push({code:item.fut_code,exchange:"MCX"});
          insList.push({code:item.instrument_token,exchange:"MCX"});
        }
      }
      insList.forEach((el)=>el['loc']=this.$route.name);
      this.subscribeToInstruments(insList);//function form websocketMethodsMixin
    },

    getNetDecay() {
      let totalDecay = 0;
      for (let item of this.validDefaultPositions) {
        if (item.net_quantity != 0 && item.segment !== "XX") {
          const token = item.instrument_token;
          let ltp = this.insSub(token)
            ? parseFloat(this.insSub(token).ltp).toFixed(2)
            : 0;
          let spot_price =
            item.exchange == "NSE" || item.exchange == "NFO"
              ? this.insSub(item.underlying_code)
                ? this.insSub(item.underlying_code).ltp
                : 0
              : this.insSub(item.fut_code)
              ? this.insSub(item.fut_code).ltp
              : 0;
          let result = 0;
          if (item.segment == "PE") {
            let max = Math.max(item.strike_price - spot_price, 0);
            result = -(Math.abs(max - parseFloat(ltp)) * item.net_quantity);
          } else {
            let max = Math.max(spot_price - item.strike_price, 0);
            result = -(Math.abs(max - parseFloat(ltp)) * item.net_quantity);
          }
          totalDecay = totalDecay + result;
        }
      }
      return totalDecay.toFixed(2);
    },

    getTotalUnrealizedPL() {
      let totalNetPL = 0;
      for (let position of this.validDefaultPositions) {
        if (position.net_quantity !== 0) {
          const token = position.instrument_token.toString();
          let ltp = this.insSub(token)
            ? parseFloat(this.insSub(token).ltp).toFixed(2)
            : 0;

          let amount =
            position.net_quantity > 0
              ? position.buy_amount
              : position.sell_amount;
          let unrealisedPL = ((ltp - amount) * position.net_quantity).toFixed(
            2
          );
          totalNetPL = parseFloat(totalNetPL) + parseFloat(unrealisedPL);
        }
      }
      return totalNetPL.toFixed(2);
    },

    /**
     * @function customStrategyApi to get the custom strategy data
     */
    customStrategyApi(item) {
      this.selectedInstrument = getPlaceOrderData(item, "instrument", null);
      let spot_price =
        this.selectedInstrument.exchange == "NSE"
          ? this.insSub(this.selectedInstrument.underlying_code)
            ? this.insSub(this.selectedInstrument.underlying_code).ltp
            : 0
          : this.insSub(this.selectedInstrument.fut_code)
          ? this.insSub(this.selectedInstrument.fut_code).ltp
          : 0;
      for (let param of item.param_list) {
        if(this.selectedInstrument.exchange == "MCX"|| this.selectedInstrument.exchange == "CDS"){
          param.quantity = param.quantity/this.selectedInstrument.lot_size
        }
        param.segment =
          param.segment == "PE"
            ? "p"
            : param.segment == "CE"
            ? "c"
            : param.segment == "FUT"
            ? "fut"
            : param.segment;
        param.ltp = param.op_pr;
      }
      let payload = {
        user_id: this.userId,
        access_token:this.accessToken,
        ws_session:this.wsSession,
        param_list: item.param_list,
        path: "custom_strategy",
        broker: "aliceblue",
        name: this.selectedInstrument.name,
        lot_size: this.selectedInstrument.lot_size,
        exchange:
          this.selectedInstrument.exchange == "NSE"
            ? "NFO"
            : this.selectedInstrument.exchange,
        days_to_expire: calculateTargetDays(item.param_list),
        spot_price: spot_price,
        target_days: 0,
        iv_percent: 0,
      };
      axios
        .post(getCustomStrategyUrl, payload, {
          headers: {
            "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3",
          },
        })
        .then((res) => {
          if (res.data.data.pl_data.loss == "Unlimited") {
            this.maxLoss = "Unlimited";
          } else if (this.maxLoss != "Unlimited") {
            this.maxLoss = this.maxLoss + res.data.data.pl_data.loss;
          }
          if (res.data.data.pl_data.profit == "Unlimited") {
            this.maxProfit = "Unlimited";
          } else if (this.maxProfit != "Unlimited") {
            this.maxProfit = this.maxProfit + res.data.data.pl_data.profit;
          }
        });
    },
    getFilteredAnalytics(key) {
      if (key.value !== null) {
        this.selectedPositionFilter = key;
        this.getPositionAnalyticsApi(key.value, false);
      }
    },
    setProgressBarData() {
      this.showFunds = !this.showFunds;
      document.documentElement.style.setProperty(
        "--red-width",
        this.utilizedFundWidth + "%"
      );
      document.documentElement.style.setProperty(
        "--green-width",
        this.availableFundWidth + "%"
      );
    },

    getHoldingsApi() {
      const payload = {
        broker: "aliceblue",
        user_id:this.userId,
        access_token:this.accessToken,
      };
      let holdingsUrl = getHoldingsURL;
      axios
        .post(holdingsUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          try {
            // res=SAMPLE;
            this.holdingsData = res.data;
            this.totalHoldings =!res.data?.errorMessage?res.data.length:0;
            this.subscribeToHoldingsLtp();
            if (this.refreshHoldingsClicked) {
              this.$notify({
                group: "success",
                type: "success",
                title: "Success",
                text: "Holdings are refreshed",
              });
            }
          } catch (error) {
            if(!error)
            console.log(error)
          }
        });
    },
    openShareVerifiedModal() {
      const open=this.defaultPositionsList?.every(el=>{
        return !!this.insSub(el.code+"")
      })
      if(open){
        this.showShareVerifiedPLModal = true;
      }
      else{
        console.log('Unable to open verified P&L!! Please wait while data loads!')
      }
    },
    subscribeToHoldingsLtp() {
      const insList=[];
      for (let item of this.holdingsData) {
        if (item.ExchSeg1 != null) {
          insList.push({code:item.Token1,exchange:item.ExchSeg1,loc:this.$route.name})
        } else {
          insList.push({code:item.Token2,exchange:item.ExchSeg2,loc:this.$route.name})
        }
      }
      this.subscribeToInstruments(insList);//function form websocketMethodsMixin
    },
    getTotalOpenPositions(value) {
      this.totalOpenPositions = value;
    },
    getAgainstDeliveryRiskPositions(value) {
      this.positionAgainstDeliveryRisk = value;
    },
    getAgainstTrendPositions(value) {
      this.positionAgainstTrends = value;
    },
    getPositionLowLiquidity(value) {
      this.positionLowLiquidity = value;
    },
  },
  mounted() {
    this.getHoldingsApi();
    this.getTrendsAndSymbols();
    this.getBalanceApi();
    this.getPositionAnalyticsApi("Default", true);
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global.scss";
$red-width: 20%;
$green-width: 80%;
.header-tooltips {
  font-size: 0.8em;
  text-align: center;
  color: var(--text-color);
  background-color: var(--cards-background-color) !important;
  border: 1px solid var(--line-color) !important;
  border-radius: 4px;
  opacity: 1;
}

.analyze-section {
  height: 45px !important;
}
.position-analytics-wrapper {
  padding: 1rem 12px;
  font-family: "Gilroy-medium";
  position: relative;
  font-size: 0.9rem;
  .header {
    font-size: 1em;

    .v-tabs {
      border-bottom: 1px solid var(--border-color);
      font-size: 1em;
      .v-tab {
        text-transform: none !important;
        font-size: 1.3em;
        letter-spacing: inherit;
      }
    }
  }
  .no-positions {
    transform: translate(0, 45%);
  }

  .no-position-text {
    font-size: 1.4em;
  }
  .positions-heading {
    font-size: 1.5em;
  }
  .text {
    font-size: 1em;
  }

  .alert-verify-label {
    align-items: center;
  }
  .alert-verify-label .v-icon.v-icon {
    font-size: 1.1em;
    color: var(--primary-button-color) !important;
  }

  .refresh-icon.v-icon.v-icon {
    color: var(--text-color) !important;
    font-size: 1.8em !important;
  }
  .header-op-buttons {
    padding: 6px 12px;
    box-shadow: 0px 0px 8px rgba(132, 132, 132, 0.25);
    border-radius: 4px;
    font-size: 1.1em;
  }
  .eye.v-icon.v-icon {
    font-size: 1.25em;
    color: var(--primary-button-color) !important;
  }

  .info-icon .v-icon.v-icon {
    color: var(--text-color) !important;
    font-size: 1.2em !important;
    cursor: pointer;
  }

  .eye-off.v-icon.v-icon {
    font-size: 1.25em;
    color: var(--sub-text-color) !important;
  }

  .alert-verify-label > span {
    cursor: pointer;
  }
  .funds-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
  }

  .parent-progress-bar {
    position: relative;
    width: 100%;
    height: 7px;
    align-self: center;
    cursor: pointer;
    .progress-green {
      position: absolute;
      width: var(--green-width);
      top: 0;
      right: 0;
      height: 100%;
      background-color: var(--green-text);
      border-radius: 0 50px 50px 0;
      font-size: 0.87em;
      .header-labels {
        font-size: 1em;
      }
    }
    .progress-red {
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      width: var(--red-width);
      background-color: var(--red-text);
      border-radius: 50px 0 0 50px;
    }

    .progress-grey {
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background-color: var(--disabled-text);
      border-radius: 50px;
    }

    .fund-label {
      position: absolute;
      top: 15px;
      font-size: var(--font-14);
    }
  }
  .header-values {
    font-size: 1.4em;
  }

  .alert-labels {
    font-size: 0.87em;
    .v-icon.v-icon {
      font-size: 1em;
      color: var(--red-text);
    }
  }

  .low-liquidity-icon {
    width: 1.2em;
    height: 1.4em;
  }
}
.grey-text {
  color: var(--sub-text-color) !important;
}
.blue-text {
  color: var(--primary-button-color);
}
.outlined-filter {
  width: 100%;
  position: absolute;
  top: 0;
  height: 44px;
  background: var(--cards-background-color);
  padding: 10px 12px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  min-width: 205px;
  .v-icon.v-icon {
    color: var(--primary-color);
  }
}

// @media only screen and (max-width: 960px) {
//   .position-analytics-wrapper {
//     font-size: 1.5rem;
//   }
// }
@media only screen and (max-width: 600px) {
  // .position-analytics-wrapper {
  //   font-size: 4.5rem;
  // }
  .refresh-icon.v-icon.v-icon {
    font-size: 1em !important;
  }

  .no-position-text {
    font-size: 1em !important;
  }
  .analyze-section {
    height: 36px !important;
  }
  // .header {
  //   .v-tabs {
  //     .v-tab {
  //       font-size: 1.3em;
  //     }
  //   }
  // }
  .fund-label {
    font-size: 0.8em !important;
  }
  .positions-heading {
    font-size: 1em !important;
  }
  .text {
    font-size: 0.67em !important;
  }

  .position-analytics-wrapper .refresh-icon .v-icon.v-icon {
    color: var(--text-color) !important;
  }
  .alert-verify-label .v-icon.v-icon {
    font-size: 0.87em !important;
  }
  .alert-verify-label {
    // justify-content: flex-end;
    justify-content: space-between;
    display: flex;
  }

  .header-op-buttons {
    padding: 0.25em 3rem !important;
  }

  .parent-progress-bar {
    width: 100% !important;
  }
  .header-labels {
    font-size: 0.75em;
  }
  .header-values {
    font-size: 1.4em !important;
  }

  // .header-op-buttons {
  //   font-size: 0.87em !important;
  // }
  .eye.v-icon.v-icon {
    font-size: 1em !important;
  }

  .eye-off.v-icon.v-icon {
    font-size: 1em !important;
  }
  .low-liquidity-icon {
    width: 12px !important;
    height: 12px !important;
  }
}
</style>

<style lang="scss">
.horizontal-divider.v-application--is-ltr
  .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 1px !important;
}
.horizontal-divider.v-divider--inset:not(.v-divider--vertical) {
  max-width: 100% !important;
  margin-left: 1px !important;
}

.analyze-dropdown.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 44px;
}

.analyze-dropdown.v-text-field--enclosed .v-input__append-inner {
  margin-top: 10px !important;
}

.analyze-dropdown.v-input .v-label {
  top: 12px !important;
  font-size: 1em;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0;
  }

  .analyze-dropdown.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    min-height: 30px;
  }

  .analyze-dropdown.v-text-field--enclosed .v-input__append-inner {
    margin-top: 4px !important;
  }

  .analyze-dropdown.v-input .v-label {
    top: 12px !important;
    font-size: 0.875em;
  }
  .analyze-dropdown.v-input {
    font-size: 0.775em;
  }
  .alert-labels {
    font-size: 0.625em !important;
    .v-icon.v-icon {
      font-size: 0.74em !important;
      color: var(--red-text);
    }
  }
}
</style>
