export const TOKEN_EXPIRATION = "23:30:00"; //token expires in at 11:30 // in Store Auth module
export const WEBSOCKET_CRASH_LIMIT=500;
export const POSITION_CACHE_NAME='position_search';

// Snackbar constants
export const SNACKBAR_CLEAR_TIMEOUT=2000;
export const SNACKBAR_COLOR_SCHEME={
  success:'var(--green-text)',
  error:'var(--red-text)',
  info:'#74b9f9',
}
export const DEFAULT_SNACKBAR_TYPE="success";

export const HEADER_FOR_CORS={ "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" }