<template>
  <div class="custom-positions-wrapper">
    <v-row v-if="customPositionCards.length == 0" no-gutters class="mt-16">
      <v-col class="text-center no-custom-positions">
        <div>
          <img
            style="width: 50%"
            class="mr-1"
            :src="
              !darkMode
                ? require('@/assets/Positions/no-positions.svg')
                : require('@/assets/Positions/no-positions-dark.svg')
            "
          />
        </div>
        <div class="mt-4">
          <button
            class="primary-button"
            @click="
              showDefaultPositionsDialog = true;
              createNewCustomCard = true;
              isEditCustomPosition = false;
            "
          >
            + Create Group
          </button>
        </div>
      </v-col>
    </v-row>

    <div v-else class="styled-scroll">
      <div
        class="symbol-hedge-cards"
        v-for="(card, index) of customPositionCards"
        :key="index"
      >
        <PositionExpiry
          v-if="card.selectedPositions && symbolsList.length!=0"
          :selectedPositionFilter="selectedPositionFilter"
          :card="card"
          :positionAnalyticsData="positionAnalyticsData"
          :cardIndex="index + 1"
          @deleteCustomStrategyCardId="deleteItem(card.id)"
          @editPositionCard="setEditCustomPositions(card)"
          :symbolsList="symbolsList"
        />
      </div>
      <div class="text-center">
        <button
          class="primary-button"
          style="cursor: pointer; font-size: 1.4em"
          @click="
            showDefaultPositionsDialog = true;
            createNewCustomCard = true;
            isEditCustomPosition = false;
          "
        >
          + Create Group
        </button>
      </div>
    </div>
    <v-row no-gutters>
      <CustomPositionDialog
        :cardIndex="customPositionCards.length + 1"
        v-if="showDefaultPositionsDialog"
        :isDefaultPositionsDialogVisible="showDefaultPositionsDialog"
        :createNewCustomCard="createNewCustomCard"
        :instrumentList="instrumentList"
        :selectedCard="!createNewCustomCard ? positionEdited : []"
        @close="showDefaultPositionsDialog = false"
        :defaultPositionsList="
          !createNewCustomCard ? customPositionList : remainingDefaultPositions
        "
        :selectedCustomPositions="
          !createNewCustomCard ? selectedCustomPositions : []
        "
        @selectedCustomPositions="createCustomCards"
      />
    </v-row>
  </div>
</template>
<script>
// import {openDB} from '../indexDB.js'
import CustomPositionDialog from "./modals/CustomPositionDialog.vue";
import PositionExpiry from "./PositionExpiry.vue";
import { getMarginUtilizedFromListUrl, getTrendsAndSymbolsUrl, getLixData } from "../getService";
import axios from "axios";
import UserDataMixin from "@/mixins/getUserDataMixin";

export default {
  name: "PositionCustom",
  props: {
    customPositionList: [], //same as default Position List
    positionAnalyticsData: null,
    selectedPositionFilter: null,
    instrumentList: [],
  },
  components: {
    CustomPositionDialog,
    PositionExpiry,
  },
  computed: {
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  watch: {
    customPositionList() {
      this.getRemainingPositions();
    },
  },
  mixins:[
    UserDataMixin
  ],
  data() {
    return {
      items: [],
      db: null,
      objectStoreName: "customPositions",
      showDefaultPositionsDialog: false,
      createNewCustomCard: true,
      customPositionCards: [],
      remainingDefaultPositions: [],
      selectedCustomPositions: [],
      isEditCustomPosition: false,
      positionEdited: null,
      symbolsList:[]
    };
  },
  mounted() {
    const userId = this.userId;
    if (!userId) {
      console.error("User ID not found in localStorage");
      return;
    }
    this.getDashboardSymbols();
    this.openDataBase(userId);
  },
  methods: {
    print(val){console.log(val)},
    getDashboardSymbols(){
      const userId = this.userId;
    const accessToken = this.accessToken;
    let payload = {
        path: "dashboard_symbols",
        broker: "aliceblue",
        user_id: userId,
        access_token: accessToken,
      };
      axios
        .post(getTrendsAndSymbolsUrl, payload,{
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.symbolsList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Open the IndexedDB database for this user
    openDataBase(userId) {
      const dbName = `${userId}_db`;
      const dbVersion = 2;
      const request = indexedDB.open(dbName, dbVersion);
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore("customPositions", {
          keyPath: "id",
          autoIncrement: true,
        });
      };
      request.onsuccess = (event) => {
        this.db = event.target.result;
        this.getItems();
      };
      request.onerror = () => {
        console.error(`Could not open IndexedDB database for user ${userId}`);
      };
    },

    // Get all the items from the IndexedDB database
    getItems() {
      const transaction = this.db.transaction(
        [this.objectStoreName],
        "readonly"
      );
      const objectStore = transaction.objectStore(this.objectStoreName);
      const request = objectStore.getAll();
      request.onsuccess = () => {
        this.customPositionCards = request.result;
        this.checkExpiredPositions();
      };
    },

    // Delete an item from the IndexedDB database
    deleteItem(id) {
      const transaction = this.db.transaction(
        [this.objectStoreName],
        "readwrite"
      );
      const objectStore = transaction.objectStore(this.objectStoreName);
      const request = objectStore.delete(id);
      request.onsuccess = () => {
        this.customPositionCards = this.customPositionCards.filter(
          (item) => item.id !== id
        );
        this.checkExpiredPositions();
      };
    },

    // Add/edit custom cards to the IndexedDB database
    createCustomCards(value) {
      const transaction = this.db.transaction(
        [this.objectStoreName],
        "readwrite"
      );
      const objectStore = transaction.objectStore(this.objectStoreName);
      const item = value;
      if (this.createNewCustomCard) {
        const request = objectStore.add(item);
        request.onsuccess = () => {
          this.customPositionCards.push({ id: request.result, ...item });
          this.checkExpiredPositions();
        };
      } else {
        const getRequest = objectStore.get(value.id);
        getRequest.onsuccess = (event) => {
          const itemToUpdate = event.target.result;

          if (itemToUpdate) {
            // Update the data for the object with the specified id
            itemToUpdate.name = value.name;
            itemToUpdate.param_list = value.param_list;
            itemToUpdate.positions = value.positions;
            itemToUpdate.symbol = value.symbol;
            const putRequest = objectStore.put(itemToUpdate);
            putRequest.onsuccess = () => {
              // this.customPositionCards.push({ id: request.result, ...item });
              this.getItems();
            };
            putRequest.onerror = () => {
              console.log("Failed to update object");
            };
          } else {
            console.log("Object not found");
          }
        };
        getRequest.onerror = () => {
          console.log("Failed to retrieve object");
        };
      }
    },

    checkExpiredPositions() {
      const customPositionName = this.customPositionList.map(
        (obj) => obj.trading_symbol
      );
      for (let card of this.customPositionCards) {
        card.selectedPositions = [];
        card.expiredPositions = [];
        for (let position of card.positions) {
          if (customPositionName.indexOf(position.trading_symbol) != -1) {
            card.selectedPositions.push(position);
            position.expired = false;
          } else {
            card.expiredPositions.push(position);
            position.expired = true;
          }
        }
      }
      this.loadCustomCards();
    },
    loadCustomCards() {
      this.selectedCustomPositions = [];
      for (let i = 0; i < this.customPositionCards.length; i++) {
        for (let param of this.customPositionCards[i].param_list) {
          param.lot_size = this.customPositionCards[i].positions[0].lot_size;
        }
        this.getMarginUtilizedFromList(
          i,
          this.customPositionCards[i].param_list
        );
        this.$set(this.customPositionCards[i], "showInstrumentTable", false);
        this.getCardLixValue(i, this.customPositionCards[i].positions);
        for (let pos of this.customPositionCards[i].positions) {
          this.selectedCustomPositions.push(pos);
        }
      }
      this.getRemainingPositions();
    },

    getRemainingPositions() {
      this.remainingDefaultPositions = [];
      const tradingSymbolList = this.selectedCustomPositions.map(
        (p) => p.trading_symbol
      );
      for (let pos of this.customPositionList) {
        if (!tradingSymbolList.includes(pos.trading_symbol)) {
          this.remainingDefaultPositions.push(pos);
        }
      }
    },
    /** @function getMarginUtilizedFromList is used to get the margin value for all the positions */
    getMarginUtilizedFromList(index, param_list) {
      for(let param of param_list) {
        param.quantity = param.exchange == 'MCX' || param.exchange == 
        'CDS'?param.quantity/param.lot_size:param.quantity;
      }
      axios
        .post(getMarginUtilizedFromListUrl, { param_list: param_list })
        .then((res) => {
          this.$set(
            this.customPositionCards[index],
            "margin_utilized",
            res.data.margin
          );
        });
    },

    /** @function getCardLixValue is used to get the lix value for all the positions */
    getCardLixValue(index, position) {
      for (let i = 0; i < position.length; i++) {
        let payload = {
          user_id:  this.userId,
          access_token: this.accessToken,
          ws_token:  this.wsSession,
          param_list: [position[i].param_list],
        };
        axios.post(getLixData, payload).then((res) => {
          this.$set(
            this.customPositionCards[index].positions[i],
            "lix_value",
            res.data.param_list[0].lix_value
          );
        });
      }
    },

    setEditCustomPositions(card) {
      this.positionEdited = card;
      this.showDefaultPositionsDialog = true;
      this.createNewCustomCard = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-positions-wrapper {
  width: 100%;
  height: 80%;
  overflow-y: auto;

  .no-custom-positions {
  }
  .primary-button {
    font-size: 1.5em !important;
  }
}

.symbol-hedge-cards {
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 24px;
}
@media only screen and(max-width:600px) {
  .custom-positions-wrapper {
    .primary-button {
      font-size: 0.8em !important;
    }
  }
  .symbol-hedge-cards {
    min-width: 400px !important;
  }
}
@media only screen and (max-width: 400px) {
  .symbol-hedge-cards {
    padding: 10px !important;
    min-width: 340px !important;
  }
}
</style>
