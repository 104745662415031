export const iconsUrl = "../../../assets/icons/";
export const BULLISH_STRATEGY = [
  {
    label: "Buy Call",
    value: "buy_call",
    imageUrl: require("@/assets/icons/Bullish/buy_call.png"),
    buy_sell: [
      {
        label: "B(1)",
        value: "OTM1 CE",
      },
    ],
  },
  {
    label: "Sell Put",
    value: "sell_put",
    imageUrl: require("@/assets/icons/Bullish/sell_put.png"),
    buy_sell: [
      {
        label: "S(1)",
        value: "ATM PE",
      },
    ],
  },
  {
    label: "Bull Call Spread",
    value: "bull_call_spread",
    imageUrl: require("@/assets/icons/Bullish/bull_call_spread.png"),
    buy_sell: [
      {
        label: "B(1)",
        value: "OTM2 CE",
      },
      {
        label: "S(1)",
        value: "OTM5 CE",
      },
    ],
  },
  {
    label: "Bull Put Spread",
    value: "bull_put_spread",
    imageUrl: require("@/assets/icons/Bullish/bull_put_spread.png"),
    buy_sell: [
      {
        label: "S(1)",
        value: "ATM PE",
      },
      {
        label: "B(1)",
        value: "OTM5 PE",
      },
    ],
  },
  {
    label: "Call Ratio Back Spread",
    value: "call_ratio_back_spread",
    imageUrl: require("@/assets/icons/Bullish/call_ratio_back_spread.png"),
    buy_sell: [
      {
        label: "S(1)",
        value: "ITM2 CE",
      },
      {
        label: "B(2)",
        value: "OTM3 CE",
      },
    ],
  },
];

export const BEARISH_STRATEGY = [
  {
    label: "Buy Put",
    value: "buy_put",
    imageUrl: require("@/assets/icons/Bearish/buy_put.png"),

    buy_sell: [
      {
        label: "B(1)",
        value: "OTM1 PE",
      },
    ],
  },
  {
    label: "Sell Call",
    value: "sell_call",
    imageUrl: require("@/assets/icons/Bearish/sell_call.png"),
    buy_sell: [
      {
        label: "S(1)",
        value: "ATM CE",
      },
    ],
  },
  {
    label: "Bear Put Spread",
    value: "bear_put_spread",
    imageUrl: require("@/assets/icons/Bearish/bear_put_spread.png"),
    buy_sell: [
      {
        label: "B(1)",
        value: "OTM2 PE",
      },
      {
        label: "S(1)",
        value: "OTM5 PE",
      },
    ],
  },
  {
    label: "Bear Call Spread",
    value: "bear_call_spread",
    imageUrl: require("@/assets/icons/Bearish/bear_call_spread.png"),
    buy_sell: [
      {
        label: "S(1)",
        value: "ATM CE",
      },
      {
        label: "B(1)",
        value: "OTM5 CE",
      },
    ],
  },
  {
    label: "Put Ratio Back Spread",
    value: "put_ratio_back_spread",
    imageUrl: require("@/assets/icons/Bearish/put_ratio_back_spread.png"),
    buy_sell: [
      {
        label: "S(1)",
        value: "ITM2 PE",
      },
      {
        label: "B(2)",
        value: "OTM2 PE",
      },
    ],
  },
];

export const NEUTRAL_STRATEGY = [
  {
    label: "Short Straddle",
    value: "short_straddle",
    imageUrl: require("@/assets/icons/Neutral/short_straddle.png"),
    buy_sell: [
      {
        label: "S(1)",
        value: "ATM CE",
      },
      {
        label: "S(1)",
        value: "ATM PE",
      },
    ],
  },
  {
    label: "Short Strangle",
    value: "short_strangle",
    imageUrl: require("@/assets/icons/Neutral/short_strangle.png"),
    buy_sell: [
      {
        label: "S(1)",
        value: "OTM8 CE",
      },
      {
        label: "S(1)",
        value: "OTM8 PE",
      },
    ],
  },
  {
    label: "Iron Butterfly",
    value: "iron_butterfly",
    imageUrl: require("@/assets/icons/Neutral/iron_butterfly.png"),
    buy_sell: [
      {
        label: "S(1)",
        value: "ATM CE",
      },
      {
        label: "B(1)",
        value: "OTM8 CE",
      },
      {
        label: "S(1)",
        value: "ATM PE",
      },
      {
        label: "B(1)",
        value: "OTM8 PE",
      },
    ],
  },
  {
    label: "Iron Condor",
    value: "iron_condor",
    imageUrl: require("@/assets/icons/Neutral/iron_condor.png"),
    buy_sell: [
      {
        label: "S(1)",
        value: "OTM8 CE",
      },
      {
        label: "B(1)",
        value: "OTM15 CE",
      },
      {
        label: "S(1)",
        value: "OTM8 PE",
      },
      {
        label: "B(1)",
        value: "OTM15 PE",
      },
    ],
  },
];

export const BUY_SELL_CHIPS =[
  {
    name:"CALL",
    type:"buy",
    label:'c'
  },
  {
    name:"CALL",
    type:"sell",
    label:'c'
  },
  {
    name:"PUT",
    type:"buy",
    label:'p'
  },
  {
    name:"PUT",
    type:"sell",
    label:'p'
  },
  {
    name:"FUT",
    type:"buy",
    label:'fut'
  },
  {
    name:"FUT",
    type:"sell",
    label:'fut'
  },
]