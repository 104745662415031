<template>
  <div class="filter-instrument-wrapper">
    <div class="ins-menu-section d-flex align-center" v-if="show">
      <!-- INPUT -->
      <div class="mt-2 mb-4 select-instrument-input-text">
        <v-text-field
          v-model="searchedText"
          filled
          dense
          autofocus
          hide-details
          maxlength="25"
          placeholder="NIFTY"
          prepend-inner-icon="mdi-magnify"
          @input="filteredInsList()"
        ></v-text-field>
        <div>
          <v-checkbox
            :input-value="selectAllValue"
            @click="toggleSelectAll"
            color="var(--primary-button-color)"
            label="Select All"
            hide-details
            class="mobile-filter-checkbox"
          ></v-checkbox>
        </div>
        <div class="ins-list">
          <div v-for="(ins, index) in filterIns" :key="index">
            <v-checkbox
              class="mobile-filter-checkbox"
              :input-value="filteredInstrumentList.includes(ins)"
              @click="toggleSelection(ins)"
              color="var(--primary-button-color)"
              :label="ins"
              hide-details
            ></v-checkbox>
          </div>
        </div>
        <div class="apply-reset-button d-flex justify-space-around">
          <button class="outlined-button" @click="removeAllFilters()">
            Reset
          </button>
          <button class="primary-button" @click="applyFilters()">Apply</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FilterInstrument",
  computed: {
    show: {
      get() {
        return this.menuVisible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  props: {
    menuVisible: Boolean,
    instrumentList: [],
    filteredInstrumentList: [],
  },
  data() {
    return {
      searchedText: "",
      selectAllValue: false,
      filterIns: [],
    };
  },
  methods: {
    filteredInsList() {
      this.filterIns = this.instrumentList.filter((el) => {
        const search = this.searchedText
          ? el.name.toLowerCase().includes(this.searchedText.toLowerCase())
          : true;
        return search;
      });
    },

    toggleSelection(item) {
      const index = this.filteredInstrumentList.indexOf(item);
      if (index > -1) {
        this.filteredInstrumentList.splice(index, 1);
      } else {
        this.filteredInstrumentList.push(item);
      }
      this.selectAllValue =
        this.instrumentList.length == this.filteredInstrumentList.length
          ? true
          : false;
    },
    toggleSelectAll() {
      this.selectAllValue = !this.selectAllValue;
      if (this.selectAllValue) {
        for (let item of this.instrumentList) {
          if (!this.filteredInstrumentList.includes(item)) {
            this.filteredInstrumentList.push(item);
          }
        }
      } else {
        this.filteredInstrumentList = [];
      }
    },
    removeAllFilters() {
      this.filteredInstrumentList = [];
      this.$emit("findfilteredInstrumentList", this.filteredInstrumentList);
      this.$emit("clickedReset", true);
      this.show = false;
    },
    applyFilters() {
      console.log(this.filteredInstrumentList)
      this.$emit("findfilteredInstrumentList", this.filteredInstrumentList);
      this.show = false;
    },
  },
  mounted() {
    this.filterIns = this.instrumentList;
    this.selectAllValue =
      this.instrumentList.length == this.filteredInstrumentList.length
        ? true
        : false;
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/global.scss";
.filter-instrument-wrapper {
  background-color: var(--cards-background-color);
  box-shadow: 0px 0px 20px rgba(132, 132, 132, 0.4);
  padding: 8px;
  border-radius: 8px;
}
.ins-list {
  max-height: 200px;
  overflow-y: auto;
}
</style>

<style>
.mobile-filter-checkbox.v-input--selection-controls .v-input__slot > .v-label {
  font-size: 0.875em !important;
}

.mobile-filter-checkbox .v-icon {
  font-size: 1em !important;
}
.mobile-filter-checkbox.v-application--is-ltr
  .v-input--selection-controls__input {
  font-size: 1em !important;
  margin-right: 1rem !important;
}
</style>
