<template>
  <div class="modal-backdrop">
    <div class="modal-loader">
      <section class="modal-body modal-content">
        <slot name="body">
          <div class="progress-val">{{ value }}%</div>
          <div class="progress-bar"></div>
          <slot name="body"> </slot>
          <slot name="footer"> </slot>
        </slot>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Number },
  },
  name: "loaderModal",
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.info-text {
  margin-top: 15px;
  padding: 0 10px;
  color: var(--text-color);
}

.modal-backdrop {
  z-index: 99;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.modal-loader {
  font-size: 0.875em;
  background: var(--loader-background);
  /* box-shadow: 2px 2px 20px 1px; */
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
  border-radius: 2px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: center;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  text-align: center;
}

.modal-content {
  padding: 30px 100px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 1.25em;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: rgb(13, 69, 147);
  border: 1px solid rgb(13, 69, 147);
  border-radius: 2px;
}
</style>
