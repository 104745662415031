<template>
  <div
    class="white-card option-chain-wrapper pa-3 px-5 my-0 mx-auto"
    :style="
      $vuetify.breakpoint.xs && !loadedAsPage ? { 'min-height': '100vh' } : {}
    "
  >
    <PlaceOrderModal
      v-if="showPlaceOrderDialog"
      :visible="showPlaceOrderDialog"
      @close="showPlaceOrderDialog = false"
      :strategyCreator="cart"
      :selectedInstrument="selectedInstrument"
      @clear-items="cart = []"
      :order_location="order_location"
    />
    <!-- PAGE HEADER SECTION // DASHBOARD TOP SECTION-->
    <div class="row no-gutters justify-space-between align-center my-1 my-sm-3">
      <div
        class="page-heading"
        :class="{ 'mb-2 mb-sm-0 col-12 col-sm-auto': !loadedAsPage }"
        @click="scrollIntoViewATM"
      >
        <span v-if="!isMultiStrikeChart">Option Chain</span>
        <span v-if="selectedInstrument && !loadedAsPage && !isMultiStrikeChart">
          -
        </span>
        <span
          v-if="(selectedInstrument && !loadedAsPage) || isMultiStrikeChart"
        >
          {{ selectedInstrument.name }}
        </span>
      </div>

      <v-spacer class="d-none d-sm-block" v-if="isMultiStrikeChart"></v-spacer>

      <!-- EXPIRY -->
      <div
        class="expiry-input d-flex align-center ma-0 mx-sm-3"
        v-if="!loadedAsPage"
      >
        <span
          class="mx-2 d-none d-sm-inline"
          v-if="!isMultiStrikeChart"
          style="font-size: 1.1em"
          >Expiry Date</span
        >
        <span style="width: 115px">
          <v-select
            :items="expiryList"
            v-model="selectedExpiry"
            :loading="loadingInstrumentData"
            outlined
            dense
            append-icon="mdi-calendar"
            background-color="white"
            hide-details
          >
            <template v-slot:selection="data">
              <span>{{ data.item | dateDDMM }}</span>
            </template>
            <template v-slot:item="data">
              {{ data.item | dateDDMM }}
            </template>
          </v-select>
        </span>
      </div>

      <!-- Time Input for Multistrike Charts -->
      <v-dialog
        ref="dialog"
        v-model="timePickerModal"
        :return-value.sync="selectedTime"
        width="300px"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            v-if="isMultiStrikeChart && selectedStrikeTab != 'strike'"
            style="width: min-content"
          >
            <v-text-field
              style="width: 100px"
              class="ml-2"
              label="Time"
              readonly
              v-bind="attrs"
              v-on="on"
              variant="outlined"
              v-model="selectedTime"
              prepend-icon="mdi-clock-time-four-outline"
            ></v-text-field>
          </span>
        </template>
        <v-time-picker
          :allowed-hours="allowedHours"
          :allowed-minutes="allowedMinutes"
          v-if="timePickerModal"
          format="24hr"
          v-model="selectedTime"
          full-width
          min="9:15"
          max="15:29"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="timePickerModal = false">
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="
              $refs.dialog.save(selectedTime);
              getUnderlyingLtp();
            "
          >
            OK
          </v-btn>
        </v-time-picker>
      </v-dialog>

      <!-- <v-spacer class="d-none d-sm-block" v-if="!isMultiStrikeChart"></v-spacer> -->
      <v-spacer v-if="!isMultiStrikeChart"></v-spacer>

      <!-- MAX PAIN & PCR -->
      <div
        class="align-center max-pain-pcr mx-2 d-flex col-6 col-sm-auto justify-end"
        style="white-space: nowrap"
        v-if="!(loadedAsPage && $vuetify.breakpoint.xs) && !isMultiStrikeChart"
      >
        <span class="mx-1">
          <span>Max Pain: </span>
          <span>{{ max_pain ? max_pain : "-" }}</span>
        </span>
        <span class="mx-1">
          <span>PCR: </span>
          <span :class="pcr >= 1 ? 'green-text' : 'red-text'">
            <span v-if="pcr">{{ pcr | float(2) }}</span>
            <span v-else>-</span>
          </span>
        </span>
      </div>

      <v-spacer
        class="d-sm-block d-none"
        v-if="!loadedAsPage && !isMultiStrikeChart"
      ></v-spacer>

      <!-- LTP AND GREEKS SELECTIONS -->
      <div
        class="table-toggler mt-2 mt-sm-0"
        v-if="!loadedAsPage && !isMultiStrikeChart"
      >
        <v-btn-toggle mandatory v-model="selectedTab" active-class="active-tab">
          <v-btn small value="oc">LTP</v-btn>
          <v-btn small value="greeks">Greeks</v-btn>
        </v-btn-toggle>
      </div>

      <!-- PAGE SETTINGS POPUP -->
      <div class="mx-2 d-flex align-center" v-if="!isMultiStrikeChart">
        <v-menu
          :close-on-content-click="false"
          offset-y
          v-model="showSettings"
          left
          :nudge-width="250"
          origin="top right"
          rounded="xl"
          :disabled="disableSettings"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon>
              <v-icon
                v-bind="attrs"
                v-on="on"
                :style="
                  disableSettings
                    ? { cursor: 'default', opacity: 0.56 }
                    : { cursor: 'pointer' }
                "
                >mdi mdi-cog</v-icon
              >
            </v-btn>
          </template>
          <v-card class="option-chain-settings pa-2">
            <v-card-title class="pa-3 d-flex justify-space-between">
              <span style="color: var(--text-color)">Settings</span>
              <v-icon small @click="showSettings = false">mdi-close</v-icon>
            </v-card-title>

            <v-card-text class="px-3 py-0">
              <v-slider
                @end="getInstrumentData"
                v-model="strikeLength"
                hide-details
                track-color="#E0E0E0"
                :color="color"
                min="10"
                max="90"
                dense
                :thumb-label="true"
                :label="'No. of Strikes'"
              ></v-slider>
            </v-card-text>

            <v-list class="colunms-togglers-list">
              <!-- OPTION CHAIN TABLE ITEMS -->
              <div v-if="selectedTab == 'oc'">
                <v-list-item v-if="false">
                  <v-list-item-title>OI Buildup</v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="OCTableColumns.oiBuildup"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>OI</v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="OCTableColumns.oi"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>Volume</v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="OCTableColumns.volume"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title> LIX </v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="OCTableColumns.lix"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>Bid Ask</v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="OCTableColumns.buyBid"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>Buy/Sell</v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="OCTableColumns.buySell"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
              </div>

              <!-- GREEKS TABLE ITEMS -->
              <div v-if="selectedTab == 'greeks'">
                <v-list-item>
                  <v-list-item-title>Gamma</v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="greeksTableColumns.gamma"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>Vega</v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="greeksTableColumns.vega"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Theta</v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="greeksTableColumns.theta"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>Delta</v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="greeksTableColumns.delta"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>Buy/Sell</v-list-item-title>
                  <v-list-item-action class="bg-white ma-0">
                    <v-switch
                      v-model="greeksTableColumns.buySell"
                      :color="color"
                      hide-details
                      inset
                      dense
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-menu>
      </div>
      <!-- PAGE SETTINGS POPUP ENDS-->

      <!-- Download Option Chain -->
      <div v-if="loadedAsPage">
        <v-tooltip bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="" text icon v-bind="attrs" v-on="on">
              <v-icon
                :style="
                  disableOCDownload
                    ? { cursor: 'default', opacity: 0.56 }
                    : { cursor: 'pointer' }
                "
                @click="downloadOptionChain()"
                >mdi-arrow-collapse-down</v-icon
              >
            </v-btn>
          </template>
          <span>Download</span>
        </v-tooltip>
      </div>
    </div>
    <!-- MIDDLE SECTION -->
    <div
      class="row no-gutters mt-3 justify-space-between ma-auto"
      v-if="loadedAsPage"
    >
      <!-- Expiry & Instrument Filters -->
      <div class="d-flex col-12 col-md-auto px-0">
        <!-- SELECTING MAIN INSTRUMENT -->
        <div class="d-flex align-center">
          <span class="mr-2" style="width: auto">
            <SelectInstrument
              @change-instrument="(ins) => (selectedInstrument = ins)"
              :instrumentList="instrumentsList"
              :selectedInstrument="selectedInstrument"
              :pageName="'option-chain'"
            />
          </span>
        </div>

        <!-- EXPIRY -->
        <div class="d-flex align-center expiry-input">
          <span class="mx-2 d-none d-sm-inline">Expiry Date</span>
          <span
            class="mx-0 mx-sm-2"
            :style="{ width: $vuetify.breakpoint.xs ? '100px' : '115px' }"
          >
            <v-select
              :items="expiryList"
              v-model="selectedExpiry"
              :loading="loadingInstrumentData"
              outlined
              dense
              background-color="white"
              hide-details
            >
              <template v-slot:selection="data">
                <span>{{ data.item | dateDDMM }}</span>
              </template>
              <template v-slot:item="data">
                {{ data.item | dateDDMM }}
              </template>
            </v-select>
          </span>
        </div>

        <v-spacer></v-spacer>

        <!-- MAX PAIN & PCR -->
        <div
          class="max-pain-pcr mx-2 d-block d-sm-none"
          style="white-space: nowrap"
          v-if="loadedAsPage"
        >
          <div class="mx-1">
            <span>Max Pain: </span>
            <span>{{ max_pain }}</span>
          </div>
          <div class="mx-1">
            <span>PCR: </span>
            <span :class="pcr >= 1 ? 'green-text' : 'red-text'">{{
              pcr | float(2)
            }}</span>
          </div>
        </div>
      </div>

      <!-- Spot & Future Price-->
      <div
        @click="print(selectedInstrument)"
        v-if="selectedInstrument"
        class="d-flex col-12 col-md-auto justify-sm-center align-center header-live-ltps py-0 px-sm-2 my-2"
        style="white-space: nowrap"
      >
        <!-- SPOT PRICE -->
        <span class="mx-2" v-if="liveLtp(selectedInstrument.underlying_code)">
          <span>
            {{ liveLtp(selectedInstrument.underlying_code) | float(2) }}
          </span>
          <span
            v-if="liveChange(selectedInstrument.underlying_code) >= 0"
            class="sub-text-change green-text"
          >
            (+{{ liveChange(selectedInstrument.underlying_code) | float(2) }}%)
          </span>
          <span v-else class="sub-text-change red-text">
            ({{ liveChange(selectedInstrument.underlying_code) | float(2) }}%)
          </span>
        </span>

        <!-- FUT PRICE -->
        <span class="mx-2" v-if="liveLtp(selectedInstrument.fut_code) != null">
          <span>
            {{ liveLtp(selectedInstrument.fut_code) | float(2) }}
          </span>
          <span
            v-if="liveChange(selectedInstrument.fut_code) >= 0"
            class="sub-text-change green-text"
          >
            +({{ liveChange(selectedInstrument.fut_code) }}%)
          </span>
          <span v-else class="sub-text-change red-text">
            ({{ liveChange(selectedInstrument.fut_code) }}%)
          </span>
          <span class="neutral-text sub-text-change">FUT</span>
        </span>
      </div>

      <!-- <v-spacer
        v-if="
          selectedInstrument &&
          (selectedInstrument.exchange == 'MCX' ||
            selectedInstrument.exchange == 'CDS')
        "
      ></v-spacer> -->

      <!-- Cart & Toggle Graph, Greeks & LTP Tables Toggler -->
      <div
        class="d-flex col-12 align-center justify-space-between justify-sm-end px-0 px-sm-2 py-0"
        :class="loadedAsPage ? 'col-lg-auto' : 'col-md-auto'"
      >
        <div class="d-flex cart_font">
          <v-checkbox
            v-model="cartActive"
            :disabled="cart.length > 0"
            :label="$vuetify.breakpoint.width >= 600 ? 'Use Cart' : ''"
            hide-details
            :color="color"
          ></v-checkbox>

          <div
            v-if="loadedAsPage"
            style="position: relative"
            :style="{ cursor: cart.length > 0 ? 'pointer' : 'default' }"
            class="mr-3 ml-sm-3"
            @click="showPlaceOrderDialog = cart.length > 0 ? true : false"
          >
            <v-icon :color="!cartActive ? 'grey' : ''">mdi-cart-outline</v-icon>
            <div class="circle-popup cart-count-popup" v-if="cart.length > 0">
              {{ cart.length }}
            </div>
          </div>
        </div>

        <div class="table-toggler">
          <v-btn-toggle
            mandatory
            dense
            v-model="selectedTab"
            active-class="active-tab"
          >
            <v-btn small value="oc">LTP</v-btn>
            <v-btn small value="greeks">Greeks</v-btn>
            <v-btn small value="graphs" v-if="loadedAsPage">Graphs</v-btn>
          </v-btn-toggle>
        </div>
      </div>
    </div>

    <!-- Error getching symbols -->
    <div v-if="errorGettingSymbols" class="text-center text-h5">
      <div>Oops!</div>
      <div>Error occured while fetching Data</div>
    </div>

    <!-- Error getting symbol instruments -->
    <div v-else-if="errorGettingInstrumentData" class="text-center text-h5">
      <div>Oops!</div>
      <div>
        Error occured while fetching {{ selectedInstrument.name }}'s Instruments
      </div>
    </div>

    <!-- Tables Section -->
    <div class="tables-section mt-3 mt-sm-5" v-else-if="!loadingInstrumentData">
      <!-- OPTION CHAIN TABLE -->
      <v-simple-table
        v-if="selectedTab == 'oc'"
        :height="
          loadedAsPage
            ? $vuetify.breakpoint.xs
              ? '90vh'
              : '76vh'
            : $vuetify.breakpoint.xs
            ? '82vh'
            : '70vh'
        "
      >
        <template v-slot:default>
          <thead class="fixed-table-header">
            <tr
              v-if="!isMultiStrikeChart"
              style="background-color: var(--calput-header-background)"
            >
              <th
                style="color: var(--text-color)"
                :colspan="OCTableActiveColCount"
                class="text-center"
              >
                CALL
              </th>
              <th
                style="color: var(--text-color)"
                :colspan="OCTableActiveColCount"
                class="text-center"
              >
                PUT
              </th>
            </tr>
            <tr class="table-header">
              <th class="text-left" v-if="OCTableColumns.oiBuildup">
                OI Buildup
              </th>

              <!-- Call Oi Header Tool-tip -->
              <th class="text-center tool-tip" v-if="OCTableColumns.oi">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">OI</div>
                  </template>
                  <span>In Thousands(k)</span>
                </v-tooltip>
              </th>

              <!-- Call Volume Header Tool-tip -->
              <th class="text-center tool-tip" v-if="OCTableColumns.volume">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">Volume</div>
                  </template>
                  <span>In Thousands(k)</span>
                </v-tooltip>
              </th>

              <!-- Call LIX Header Tool-tip -->
              <th class="text-center tool-tip" v-if="OCTableColumns.lix">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      LIX
                      <v-icon small style="margin-bottom: 7px">
                        mdi-information-outline
                      </v-icon>
                    </div>
                  </template>
                  <span>
                    Liquidity Index (LIX) indicates the liquidity of the given
                    instrument <br />ranging from 0 (low Liquidity) to 10 (High
                    Liquidity) at the given point.
                  </span>
                </v-tooltip>
              </th>

              <th class="text-center bids" v-if="OCTableColumns.buyBid">
                Bid - Ask
              </th>
              <th class="text-center" v-if="!isMultiStrikeChart">LTP</th>
              <th class="text-center" v-if="OCTableColumns.buySell">
                {{ isMultiStrikeChart ? "Call" : "" }}
              </th>
              <th class="text-center strike" style="cursor: pointer">
                <div @click="isReversed = !isReversed">
                  Strike
                  <v-icon style="font-size: 1.5em">
                    {{
                      isReversed ? "mdi-sort-descending" : "mdi-sort-ascending"
                    }}
                  </v-icon>
                </div>
              </th>
              <th class="text-center iv" v-if="!isMultiStrikeChart">IV</th>
              <th class="text-center" v-if="OCTableColumns.buySell">
                {{ isMultiStrikeChart ? "Put" : "" }}
              </th>
              <th class="text-center" v-if="!isMultiStrikeChart">LTP</th>
              <th class="text-center bids" v-if="OCTableColumns.buyBid">
                Bid - Ask
              </th>

              <!-- Put LIX Header Tool-tip -->
              <th class="text-center tool-tip" v-if="OCTableColumns.lix">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      LIX
                      <v-icon small style="margin-bottom: 7px">
                        mdi-information-outline
                      </v-icon>
                    </div>
                  </template>
                  <span>
                    Liquidity Index (LIX) indicates the liquidity of the given
                    instrument <br />
                    ranging from 0 (low Liquidity) to 10 (High Liquidity) at the
                    given point.
                  </span>
                </v-tooltip>
              </th>

              <!-- Put Volume Header Tool-tip -->
              <th class="text-center tool-tip" v-if="OCTableColumns.volume">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">Volume</div>
                  </template>
                  <span>In Thousands(k)</span>
                </v-tooltip>
              </th>

              <!-- Put OI Header Tool-tip -->
              <th class="text-center tool-tip" v-if="OCTableColumns.oi">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">OI</div>
                  </template>
                  <span>In Thousands(k)</span>
                </v-tooltip>
              </th>

              <th class="text-right" v-if="OCTableColumns.oiBuildup">
                OI Buildup
              </th>
            </tr>
          </thead>
          <tbody v-if="filteredStrikesList.length > 0">
            <tr
              :class="{
                'smaller-than-atm-row': smallerThanATM(index),
                'greater-than-atm-row': greaterThanATM(index),
                'atm-row': isAtm(index),
              }"
              v-for="(item, index) in filteredStrikesList"
              :key="'oc-' + item.strike"
            >
              <!-- CALL OI Buildup (disabled till work in progress) -->
              <td class="call text-left" v-if="OCTableColumns.oiBuildup">
                OI Buildup
              </td>

              <!-- CALL OI -->
              <td
                class="call text-center"
                v-if="OCTableColumns.oi"
                style="position: relative"
              >
                <span v-if="liveOI(item.ceCode)">
                  {{
                    liveOI(item.ceCode)
                      | divideBy(computedLotSize)
                      | inThousands
                  }}
                </span>
                <span v-else>
                  {{ oiCE.at(index) | divideBy(computedLotSize) | inThousands }}
                </span>

                <div
                  :style="{
                    width:
                      parseFloat((oiCE.at(index) / maxValOiCE) * 100).toFixed(
                        2
                      ) + '%',
                  }"
                  class="percent-bar"
                  style="left: 0px; background-color: var(--buy-bar)"
                ></div>
              </td>

              <!-- CALL Volume -->
              <td class="call text-center" v-if="OCTableColumns.volume">
                <span v-if="liveVolume(item.ceCode)">
                  {{
                    liveVolume(item.ceCode)
                      | divideBy(computedLotSize)
                      | inThousands
                  }}
                </span>
                <span v-else>
                  {{
                    volumeCE.at(index) | divideBy(computedLotSize) | inThousands
                  }}
                </span>
              </td>

              <!-- CALL LIX -->
              <td class="call text-center" v-if="OCTableColumns.lix">
                {{ lixCE[index] | float(1) }}
              </td>

              <!-- CALL BUY BID -->
              <td class="call text-center bids" v-if="OCTableColumns.buyBid">
                <span v-if="selectedInstrument.name != 'USDINR'">
                  {{ liveBuyBid(item.ceCode) | float(2) }} -
                  {{ liveSellBid(item.ceCode) | float(2) }}
                </span>
                <span v-else>
                  {{ liveBuyBid(item.ceCode) | float(4) }} -
                  {{ liveSellBid(item.ceCode) | float(4) }}
                </span>
              </td>

              <!-- CALL LTP -->
              <td
                class="call text-center"
                @click="print(insSub(item.ceCode))"
                v-if="!isMultiStrikeChart"
              >
                <span v-if="selectedInstrument.name != 'USDINR'">
                  <span
                    v-if="liveLtp(item.ceCode)"
                    :class="{
                      'green-text': liveChange(item.ceCode) >= 0,
                      'red-text': liveChange(item.ceCode) < 0,
                    }"
                  >
                    {{ liveLtp(item.ceCode) | float(2) }}
                    <span>({{ liveChange(item.ceCode) | float(2) }}%)</span>
                  </span>
                  <span
                    v-else
                    :class="{
                      'green-text': priceChangeCE.at(index) >= 0,
                      'red-text': priceChangeCE.at(index) < 0,
                    }"
                  >
                    {{ ltpCE.at(index) | float(2) }}
                    <span>({{ priceChangeCE.at(index) | float(2) }}%)</span>
                  </span>
                </span>
                <span v-else>
                  <span
                    v-if="liveLtp(item.ceCode)"
                    :class="{
                      'green-text': liveChange(item.ceCode) >= 0,
                      'red-text': liveChange(item.ceCode) < 0,
                    }"
                  >
                    {{ liveLtp(item.ceCode) | float(4) }}
                    <span>({{ liveChange(item.ceCode) | float(2) }}%)</span>
                  </span>
                  <span
                    v-else
                    :class="{
                      'green-text': priceChangeCE.at(index) >= 0,
                      'red-text': priceChangeCE.at(index) < 0,
                    }"
                  >
                    {{ ltpCE.at(index) | float(4) }}
                    <span>({{ priceChangeCE.at(index) | float(2) }}%)</span>
                  </span>
                </span>
              </td>

              <!-- CALL BUTTONS -->
              <td class="call px-0" v-if="OCTableColumns.buySell">
                <div
                  class="d-flex justify-center"
                  :class="{
                    'light-btns-div':
                      loadingListInDashboard ||
                      (loadingListInDashboard && cart.length == 1),
                  }"
                >
                  <div
                    v-if="isMultiStrikeChart && selectedStrikeTab !== 'strike'"
                    style="min-width: 70px"
                    class="text-center"
                  >
                    {{ callItmOtm[index] }}
                  </div>
                  <div class="remove-ins-btn-container">
                    <v-hover
                      v-slot="{ hover }"
                      style="cursor: pointer"
                      v-if="callInsStateList[index].buyCount > 0"
                    >
                      <v-icon
                        color="var(--primary-color)"
                        @click="removeFromCart(item.ceCode, 'b', 'ce')"
                      >
                        {{
                          hover
                            ? "mdi-minus-circle"
                            : "mdi-minus-circle-outline"
                        }}
                      </v-icon>
                    </v-hover>
                  </div>

                  <!-- BUY BUTTON -->
                  <button
                    @click="
                      manageInstrumentSelection(index, item.ceCode, 'b', 'ce')
                    "
                    class="buy-ins-btn"
                    :class="{ 'selected-buy': callInsStateList[index].buy }"
                  >
                    <span>B</span>

                    <div
                      class="circle-popup"
                      v-if="callInsStateList[index].buyCount > 0"
                    >
                      {{ callInsStateList[index].buyCount }}
                    </div>
                  </button>

                  <!-- SELL BUTTON -->
                  <button
                    @click="
                      manageInstrumentSelection(index, item.ceCode, 's', 'ce')
                    "
                    class="sell-ins-btn"
                    :class="{ 'selected-sell': callInsStateList[index].sell }"
                  >
                    <span>S</span>

                    <div
                      class="circle-popup"
                      v-if="callInsStateList[index].sellCount > 0"
                    >
                      {{ callInsStateList[index].sellCount }}
                    </div>
                  </button>

                  <div class="remove-ins-btn-container">
                    <v-hover
                      v-slot="{ hover }"
                      style="cursor: pointer"
                      v-if="callInsStateList[index].sellCount > 0"
                    >
                      <v-icon
                        color="var(--red-text)"
                        @click="removeFromCart(item.ceCode, 's', 'ce')"
                      >
                        {{
                          hover
                            ? "mdi-minus-circle"
                            : "mdi-minus-circle-outline"
                        }}
                      </v-icon>
                    </v-hover>
                  </div>
                </div>
              </td>

              <td class="text-center strike">
                {{ item.strike }}
                <div v-if="isAtm(index)" class="spot-price-box">
                  <span
                    class="mx-2"
                    v-if="liveLtp(selectedInstrument.underlying_code)"
                  >
                    <span>
                      {{
                        liveLtp(selectedInstrument.underlying_code) | float(2)
                      }}
                    </span>
                  </span>
                  <span class="mx-2" v-else>
                    <span>
                      {{ liveLtp(selectedInstrument.fut_code) | float(2) }}
                    </span>
                  </span>
                </div>
              </td>
              <td class="text-center iv" v-if="!isMultiStrikeChart">
                {{ ivValList[index] | float(2) }}
              </td>

              <!-- PUT BUTTONS -->
              <td class="put px-0" v-if="OCTableColumns.buySell">
                <div
                  class="d-flex justify-center"
                  :class="{ 'light-btns-div': loadingListInDashboard }"
                >
                  <div class="remove-ins-btn-container">
                    <v-hover
                      v-slot="{ hover }"
                      style="cursor: pointer"
                      v-if="putInsStateList[index].buyCount > 0"
                    >
                      <v-icon
                        color="var(--primary-color)"
                        @click="removeFromCart(item.peCode, 'b', 'pe')"
                      >
                        {{
                          hover
                            ? "mdi-minus-circle"
                            : "mdi-minus-circle-outline"
                        }}
                      </v-icon>
                    </v-hover>
                  </div>

                  <!-- BUY BUTTON -->
                  <button
                    @click="
                      manageInstrumentSelection(index, item.peCode, 'b', 'pe')
                    "
                    class="buy-ins-btn"
                    :class="{ 'selected-buy': putInsStateList[index].buy }"
                  >
                    <span>B</span>
                    <div
                      class="circle-popup"
                      v-if="putInsStateList[index].buyCount > 0"
                    >
                      {{ putInsStateList[index].buyCount }}
                    </div>
                  </button>

                  <!-- SELL BUTTON -->
                  <button
                    @click="
                      manageInstrumentSelection(index, item.peCode, 's', 'pe')
                    "
                    class="sell-ins-btn"
                    :class="{ 'selected-sell': putInsStateList[index].sell }"
                  >
                    <span>S</span>
                    <div
                      class="circle-popup"
                      v-if="putInsStateList[index].sellCount > 0"
                    >
                      {{ putInsStateList[index].sellCount }}
                    </div>
                  </button>

                  <div class="remove-ins-btn-container">
                    <v-hover
                      v-slot="{ hover }"
                      style="cursor: pointer"
                      v-if="putInsStateList[index].sellCount > 0"
                    >
                      <v-icon
                        color="var(--red-text)"
                        @click="removeFromCart(item.peCode, 's', 'pe')"
                      >
                        {{
                          hover
                            ? "mdi-minus-circle"
                            : "mdi-minus-circle-outline"
                        }}
                      </v-icon>
                    </v-hover>
                  </div>
                  <div
                    v-if="isMultiStrikeChart && selectedStrikeTab != 'strike'"
                    style="min-width: 70px"
                    class="text-center"
                  >
                    {{ putItmOtm[index] }}
                  </div>
                </div>
              </td>
              <!-- PUT LTP -->
              <td
                class="put text-center"
                @click="print(insSub(item.peCode))"
                v-if="!isMultiStrikeChart"
              >
                <span v-if="selectedInstrument.name != 'USDINR'">
                  <span
                    v-if="liveLtp(item.peCode)"
                    :class="{
                      'green-text': liveChange(item.peCode) >= 0,
                      'red-text': liveChange(item.peCode) < 0,
                    }"
                  >
                    {{ liveLtp(item.peCode) | float(2) }}
                    <span>({{ liveChange(item.peCode) | float(2) }}%)</span>
                  </span>
                  <span
                    v-else
                    :class="{
                      'green-text': priceChangePE.at(index) >= 0,
                      'red-text': priceChangePE.at(index) < 0,
                    }"
                  >
                    {{ ltpPE.at(index) | float(2) }}
                    <span>({{ priceChangePE.at(index) | float(2) }}%)</span>
                  </span>
                </span>
                <span v-else>
                  <span
                    v-if="liveLtp(item.peCode)"
                    :class="{
                      'green-text': liveChange(item.peCode) >= 0,
                      'red-text': liveChange(item.peCode) < 0,
                    }"
                  >
                    {{ liveLtp(item.peCode) | float(4) }}
                    <span>({{ liveChange(item.peCode) | float(2) }}%)</span>
                  </span>
                  <span
                    v-else
                    :class="{
                      'green-text': priceChangePE.at(index) >= 0,
                      'red-text': priceChangePE.at(index) < 0,
                    }"
                  >
                    {{ ltpPE.at(index) | float(4) }}
                    <span>({{ priceChangePE.at(index) | float(2) }}%)</span>
                  </span>
                </span>
              </td>

              <!-- PUT BUY BID -->
              <td class="put text-center bids" v-if="OCTableColumns.buyBid">
                <span v-if="selectedInstrument.name != 'USDINR'">
                  {{ liveBuyBid(item.peCode) | float(2) }} -
                  {{ liveSellBid(item.peCode) | float(2) }}
                </span>
                <span v-else>
                  {{ liveBuyBid(item.peCode) | float(4) }} -
                  {{ liveSellBid(item.peCode) | float(4) }}
                </span>
              </td>

              <!-- PUT LIX -->
              <td class="put text-center" v-if="OCTableColumns.lix">
                {{ lixPE[index] | float(1) }}
              </td>

              <!-- PUT Volume -->
              <td class="put text-center" v-if="OCTableColumns.volume">
                <span v-if="liveVolume(item.peCode)">
                  {{
                    liveVolume(item.peCode)
                      | divideBy(computedLotSize)
                      | inThousands
                  }}
                </span>
                <span v-else>
                  {{
                    volumePE.at(index) | divideBy(computedLotSize) | inThousands
                  }}
                </span>
              </td>

              <!-- PUT OI -->
              <td
                class="put text-center"
                v-if="OCTableColumns.oi"
                style="position: relative"
              >
                <span v-if="liveOI(item.peCode)">
                  {{
                    liveOI(item.peCode)
                      | divideBy(computedLotSize)
                      | inThousands
                  }}
                </span>
                <span v-else>
                  {{ oiPE.at(index) | divideBy(computedLotSize) | inThousands }}
                </span>

                <div
                  :style="{
                    width:
                      parseFloat((oiPE.at(index) / maxValOiPE) * 100).toFixed(
                        2
                      ) + '%',
                  }"
                  class="percent-bar"
                  style="right: 0px; background-color: var(--red-text)"
                ></div>
              </td>

              <!-- PUT OI Buildup (disabled till work in progress) -->
              <td v-if="OCTableColumns.oiBuildup" class="put text-right">
                OI Buildup
              </td>
            </tr>
          </tbody>
          <tfoot
            class="total-data"
            v-if="OCTableColumns.oi || OCTableColumns.volume"
          >
            <tr>
              <td class="text-center" v-if="OCTableColumns.oi">
                {{ totalOICE | divideBy(computedLotSize) | inLakhs }}
              </td>
              <td class="text-center" v-if="OCTableColumns.volume">
                {{ totalVolumeCE | divideBy(computedLotSize) | inLakhs }}
              </td>
              <td class="text-center total-tag" :colspan="bottomTotalColspan">
                Total
              </td>
              <td class="text-center" v-if="OCTableColumns.volume">
                {{ totalVolumePE | divideBy(computedLotSize) | inLakhs }}
              </td>
              <td class="text-center" v-if="OCTableColumns.oi">
                {{ totalOIPE | divideBy(computedLotSize) | inLakhs }}
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>

      <!-- GREEKS TABLE -->
      <v-simple-table
        v-if="selectedTab == 'greeks'"
        :height="
          loadedAsPage
            ? $vuetify.breakpoint.xs
              ? '90vh'
              : '76vh'
            : $vuetify.breakpoint.xs
            ? '82vh'
            : '70vh'
        "
      >
        <template v-slot:default>
          <thead class="fixed-table-header">
            <tr style="background-color: var(--calput-header-background)">
              <th
                style="color: var(--text-color)"
                class="text-center"
                :colspan="greeksTableActiveColCount"
              >
                CALL
              </th>
              <th
                style="color: var(--text-color)"
                class="text-center"
                :colspan="greeksTableActiveColCount"
              >
                PUT
              </th>
            </tr>
            <tr class="table-header">
              <th class="text-left" v-if="greeksTableColumns.gamma">Gamma</th>
              <th class="text-center" v-if="greeksTableColumns.vega">Vega</th>
              <th class="text-center" v-if="greeksTableColumns.theta">Theta</th>
              <th class="text-center" v-if="greeksTableColumns.delta">Delta</th>
              <th class="text-center">LTP</th>
              <th v-if="greeksTableColumns.buySell"></th>
              <th
                class="text-center strike"
                :style="{ cursor: loadedAsPage ? 'pointer' : 'default' }"
              >
                <div @click="isReversed = loadedAsPage ? !isReversed : false">
                  Strike
                  <v-icon v-if="loadedAsPage" style="font-size: 1.5em">{{
                    isReversed ? "mdi-sort-descending" : "mdi-sort-ascending"
                  }}</v-icon>
                </div>
              </th>
              <th class="text-center iv">IV</th>
              <th v-if="greeksTableColumns.buySell"></th>
              <th class="text-center">LTP</th>
              <th class="text-center" v-if="greeksTableColumns.delta">Delta</th>
              <th class="text-center" v-if="greeksTableColumns.theta">Theta</th>
              <th v-if="greeksTableColumns.vega" class="text-center">Vega</th>
              <th class="text-right" v-if="greeksTableColumns.gamma">Gamma</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{
                'smaller-than-atm-row': smallerThanATM(index),
                'greater-than-atm-row': greaterThanATM(index),
                'atm-row': isAtm(index),
              }"
              v-for="(item, index) in filteredStrikesList"
              :key="'og-' + item.strike"
            >
              <td class="call text-left" v-if="greeksTableColumns.gamma">
                {{ gammaCE[index] | float(4) }}
              </td>
              <td class="call text-center" v-if="greeksTableColumns.vega">
                {{ vegaCE[index] | float(2) }}
              </td>
              <td class="call text-center" v-if="greeksTableColumns.theta">
                {{ thetaCE[index] | float(2) }}
              </td>
              <td class="call text-center" v-if="greeksTableColumns.delta">
                {{ deltaCE[index] | float(2) }}
              </td>

              <!-- CALL LTP -->
              <td class="call text-center">
                <span
                  v-if="liveLtp(item.ceCode)"
                  :class="{
                    'green-text': liveChange(item.ceCode) >= 0,
                    'red-text': liveChange(item.ceCode) < 0,
                  }"
                >
                  {{ liveLtp(item.ceCode) | float(2) }}
                  <span>({{ liveChange(item.ceCode) | float(2) }}%)</span>
                </span>
                <span
                  v-else
                  :class="{
                    'green-text': priceChangeCE.at(index) >= 0,
                    'red-text': priceChangeCE.at(index) < 0,
                  }"
                >
                  {{ ltpCE.at(index) | float(2) }}
                  <span>({{ priceChangeCE.at(index) | float(2) }}%)</span>
                </span>
              </td>

              <!-- CALL BUTTONS -->
              <td class="call" v-if="greeksTableColumns.buySell">
                <div
                  class="d-flex justify-center"
                  :class="{ 'light-btns-div': loadingListInDashboard }"
                >
                  <div class="remove-ins-btn-container">
                    <v-hover
                      v-slot="{ hover }"
                      style="cursor: pointer"
                      v-if="callInsStateList[index].buyCount > 0"
                    >
                      <v-icon
                        color="var(--primary-color)"
                        @click="removeFromCart(item.ceCode, 'b', 'ce')"
                      >
                        {{
                          hover
                            ? "mdi-minus-circle"
                            : "mdi-minus-circle-outline"
                        }}
                      </v-icon>
                    </v-hover>
                  </div>

                  <!-- BUY BUTTON -->
                  <button
                    @click="
                      manageInstrumentSelection(index, item.ceCode, 'b', 'ce')
                    "
                    class="buy-ins-btn"
                    :class="{ 'selected-buy': callInsStateList[index].buy }"
                  >
                    <span>B</span>
                    <div
                      class="circle-popup"
                      v-if="callInsStateList[index].buyCount > 0"
                    >
                      {{ callInsStateList[index].buyCount }}
                    </div>
                  </button>

                  <!-- SELL BUTTON -->
                  <button
                    @click="
                      manageInstrumentSelection(index, item.ceCode, 's', 'ce')
                    "
                    class="sell-ins-btn"
                    :class="{ 'selected-sell': callInsStateList[index].sell }"
                  >
                    <span>S</span>
                    <div
                      class="circle-popup"
                      v-if="callInsStateList[index].sellCount > 0"
                    >
                      {{ callInsStateList[index].sellCount }}
                    </div>
                  </button>

                  <div class="remove-ins-btn-container">
                    <v-hover
                      v-slot="{ hover }"
                      style="cursor: pointer"
                      v-if="callInsStateList[index].sellCount > 0"
                    >
                      <v-icon
                        color="var(--red-text)"
                        @click="removeFromCart(item.ceCode, 's', 'ce')"
                      >
                        {{
                          hover
                            ? "mdi-minus-circle"
                            : "mdi-minus-circle-outline"
                        }}
                      </v-icon>
                    </v-hover>
                  </div>
                </div>
              </td>

              <td class="text-center strike">{{ item.strike }}</td>
              <td class="text-center iv">{{ ivValList[index] | float(2) }}</td>

              <!-- PUT BUTTONS -->
              <td class="put" v-if="greeksTableColumns.buySell">
                <div
                  class="d-flex justify-center"
                  :class="{ 'light-btns-div': loadingListInDashboard }"
                >
                  <div class="remove-ins-btn-container">
                    <v-hover
                      v-slot="{ hover }"
                      style="cursor: pointer"
                      v-if="putInsStateList[index].buyCount > 0"
                    >
                      <v-icon
                        color="var(--primary-color)"
                        @click="removeFromCart(item.peCode, 'b', 'pe')"
                      >
                        {{
                          hover
                            ? "mdi-minus-circle"
                            : "mdi-minus-circle-outline"
                        }}
                      </v-icon>
                    </v-hover>
                  </div>

                  <!-- BUY BUTTON -->
                  <button
                    @click="
                      manageInstrumentSelection(index, item.peCode, 'b', 'pe')
                    "
                    class="buy-ins-btn"
                    :class="{ 'selected-buy': putInsStateList[index].buy }"
                  >
                    <span>B</span>
                    <div
                      class="circle-popup"
                      v-if="putInsStateList[index].buyCount > 0"
                    >
                      {{ putInsStateList[index].buyCount }}
                    </div>
                  </button>

                  <!-- SELL BUTTON -->
                  <button
                    @click="
                      manageInstrumentSelection(index, item.peCode, 's', 'pe')
                    "
                    class="sell-ins-btn"
                    :class="{ 'selected-sell': putInsStateList[index].sell }"
                  >
                    <span>S</span>
                    <div
                      class="circle-popup"
                      v-if="putInsStateList[index].sellCount > 0"
                    >
                      {{ putInsStateList[index].sellCount }}
                    </div>
                  </button>

                  <div class="remove-ins-btn-container">
                    <v-hover
                      v-slot="{ hover }"
                      style="cursor: pointer"
                      v-if="putInsStateList[index].sellCount > 0"
                    >
                      <v-icon
                        color="var(--red-text)"
                        @click="removeFromCart(item.peCode, 's', 'pe')"
                      >
                        {{
                          hover
                            ? "mdi-minus-circle"
                            : "mdi-minus-circle-outline"
                        }}
                      </v-icon>
                    </v-hover>
                  </div>
                </div>
              </td>

              <!-- PUT LTP -->
              <td class="put text-center">
                <span
                  v-if="liveLtp(item.peCode)"
                  :class="{
                    'green-text': liveChange(item.peCode) >= 0,
                    'red-text': liveChange(item.peCode) < 0,
                  }"
                >
                  {{ liveLtp(item.peCode) | float(2) }}
                  <span>({{ liveChange(item.peCode) | float(2) }}%)</span>
                </span>

                <span
                  v-else
                  :class="{
                    'green-text': priceChangePE.at(index) >= 0,
                    'red-text': priceChangePE.at(index) < 0,
                  }"
                >
                  {{ ltpPE.at(index) | float(2) }}
                  <span>({{ priceChangePE.at(index) | float(2) }}%)</span>
                </span>
              </td>

              <td class="put text-center" v-if="greeksTableColumns.delta">
                {{ deltaPE[index] | float(2) }}
              </td>
              <td class="put text-center" v-if="greeksTableColumns.theta">
                {{ thetaCE[index] | float(2) }}
              </td>
              <td class="put text-center" v-if="greeksTableColumns.vega">
                {{ vegaPE[index] | float(2) }}
              </td>
              <td class="put text-right" v-if="greeksTableColumns.gamma">
                {{ gammaPE[index] | float(4) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <!-- GRAPH -->
      <div v-if="selectedTab == 'graphs'">
        <OptionCharts
          :plotData="chartData"
          @add-from-charts="addInstrumentFromGraph"
        />
      </div>
    </div>
  </div>
</template>
<script>
const XLSX = require("xlsx");
import axios from "axios";
import ComputedValues from "./OptionsComputedValues";

import SubscribeInstrumentsMixin from "@/mixins/SubscribedInstrumentMixin";
import UserDataMixin from "@/mixins/getUserDataMixin";
import WebsocketMethodsMixin from "@/mixins/websocketMethodsMixin";

import SelectInstrument from "../modals/SelectInstrument.vue";
import PlaceOrderModal from "../modals/PlaceOrderModal.vue";
import OptionCharts from "./OptionCharts.vue";

import {
  getTrendsAndSymbolsUrl,
  getStrikePriceListV2,
  getLixData,
  getMaxPainAndPCR,
  getUnderlyingLtpWithTime,
} from "../getService";

export default {
  components: {
    SelectInstrument,
    PlaceOrderModal,
    OptionCharts,
  },
  mixins: [SubscribeInstrumentsMixin, UserDataMixin, WebsocketMethodsMixin],
  props: {
    instrumentFromDashboard: { type: Object, default: null },
    expiryFromDashboard: { type: String, default: null },
    strategyCreatorList: {
      default: function () {
        return [];
      },
    },
    selectedStrikeTab: {
      default: function () {
        return "";
      },
    },
    modalState: { type: Boolean },
    loadingListInDashboard: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    isMultiStrikeChart: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  created() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.5/xlsx.full.min.js"
    );
    document.head.appendChild(recaptchaScript);
  },
  emits: [
    "add-instrument",
    "change-instrument-quantity",
    "remove-instrument-at-index",
  ],
  destroyed() {
    this.unsubscribeFromLocation(this.$route.name); //function form websocketMethodsMixin
    if (this.updateTotalOiVolInterval) {
      clearInterval(this.updateTotalOiVolInterval);
    }
  },
  mounted() {
    this.getTrendsAndSymbols();
    if (this.isMultiStrikeChart) {
      const currentDate = new Date();
      const hours =
        currentDate.getHours() > 15
          ? "15"
          : String(currentDate.getHours()).padStart(2, "0");
      const minutes =
        currentDate.getHours() > 15
          ? "29"
          : String(currentDate.getMinutes()).padStart(2, "0");
      this.selectedTime = `${hours}:${minutes}`;
      this.getUnderlyingLtp();
      return;
    }
    this.updateTotalOiVolInterval = setInterval(this.setTotalVolumneOI, 3000);
    this.applyUserSettings();
  },
  data() {
    return {
      userSettings: JSON.parse(localStorage.getItem("user_settings")),
      expirySelect: "",

      // Loading ,Flags, Counters, Interval and Toggling Vars
      loadingInstruments: false,
      loadingInstrumentData: false,
      showPlaceOrderDialog: false,
      selectedTab: "oc",
      loadedAsPage: this.instrumentFromDashboard ? false : true,
      errorGettingInstrumentData: false,
      errorGettingSymbols: false,
      isReversed: false,
      updateTotalOiVolInterval: null,

      // vars for Multistrike charts popup
      timePickerModal: false,
      customATM: 0, // Editable ATM to be used when loaded as multistrike charts pop up
      selectedTime: null,

      // Table Control Vars
      showSettings: false,
      OCTableColumns: {
        buySell: true,
        oiBuildup: false,
        buyBid: !this.isMultiStrikeChart,
        oi: !this.isMultiStrikeChart,
        lix: !this.isMultiStrikeChart,
        volume: !this.isMultiStrikeChart,
      },
      greeksTableColumns: {
        buySell: true,
        gamma: true,
        vega: true,
        theta: true,
        delta: true,
      },

      // Static properties var
      color: "var(--primary-button-color)",

      // Main symbol(e.g NIFTY) related data
      selectedInstrument: this.instrumentFromDashboard,
      lotSize: null,
      instrumentsList: [],
      strikeLength: 30,

      expiryList: [],
      selectedExpiry: this.expiryFromDashboard,
      max_pain: null,
      pcr: null,

      // Var consisting main data for table
      strikesList: [],
      greeksData: null,
      optionChainFallBackData: null,

      // Option Chain table vars
      atmStaticVal: null, // Using as atm incase selectedInstrument ltp websocket not working
      strikesListCE: [],
      strikesListPE: [],
      // oiCE: [],
      // oiPE: [],
      // ltpCE: [],
      // ltpPE: [],
      // priceChangeCE: [],
      // priceChangePE: [],
      // volumeCE: [],
      // volumePE: [],
      // ivCE: [],
      // ivPE: [],

      lixObjListCE: [],
      lixObjListPE: [],
      totalOICE: 0,
      totalOIPE: 0,
      totalVolumeCE: 0,
      totalVolumePE: 0,

      // Greeks Table Vars
      // gammaCE: [],
      // vegaCE: [],
      // thetaCE: [],
      // deltaCE: [],
      // gammaPE: [],
      // vegaPE: [],
      // thetaPE: [],
      // deltaPE: [],

      // Cart Vars
      cart: this.strategyCreatorList,
      cartActive: true,

      // CHART VARS
      chartData: null,
      order_location: "option_chain",
    };
  },
  watch: {
    // When Loaded from dashboard
    strategyCreatorList: {
      handler() {
        this.reformatStrategiesCreatorList();
      },
      deep: true,
    },
    loadingListInDashboard(val) {
      if (!val && this.modalState) {
        this.$notify({
          group: "success",
          type: "success",
          title: "Success",
          text: "Strategy Creator updated",
        });
      }
    },
    modalState(newVal) {
      if (newVal == true) {
        this.selectedExpiry = this.expiryFromDashboard;
      } else {
        this.unsubscribeOptionChainInstruments();
      }
    },
    // When Loaded from dashboard
    instrumentFromDashboard(newVal) {
      this.selectedInstrument = newVal;
      this.setComponentViewDefault();
      this.getUnderlyingLtp();
    },
    // When Loaded from dashboard
    expiryFromDashboard(newVal) {
      this.selectedExpiry = newVal;
      this.setComponentViewDefault();
    },
    showPlaceOrderDialog(newVal) {
      if (newVal == false && !this.cartActive) {
        this.cart = [];
      }
    },
    // INSRTUMENT CHANGE TRIGGERS A MAJOR CHANGE IN DATA FOR THE COMPONENT
    selectedInstrument(newVal, oldVal) {
      if (oldVal) {
        this.unsubscribeToSymbol(oldVal);
        this.unsubscribeOptionChainInstruments(oldVal);
      }
      this.subscribeToSymbol(newVal);
      let sortedExpiryList = newVal.option_expiry_dates;
      sortedExpiryList.sort((a, b) => new Date(a) - new Date(b));
      this.strikeLength = this.userSettings.optionchain_default_strikes;
      this.expiryList = sortedExpiryList;

      let expiryParam = null;

      // Condition to catch expiry from url
      if (this.$route.query.expiry) {
        const dt = new Date(this.$route.query.expiry);
        expiryParam = sortedExpiryList.find((el) => {
          const dt2 = new Date(el);
          if (
            dt2.getDate() == dt.getDate() &&
            dt2.getMonth() == dt.getMonth() &&
            dt2.getFullYear() == dt.getFullYear()
          )
            return el;
        });
      }
      if (this.userSettings.optionchain_expiry == "Current Week") {
        this.expirySelect = this.expiryList[0];
      } else {
        const currentDate = new Date(this.expiryList[0]);
        const currentMonth = currentDate.getMonth();

        let lastDateIndex = -1;

        for (let i = 0; i < this.expiryList.length; i++) {
          const expiryDate = new Date(this.expiryList[i]);
          if (expiryDate.getMonth() === currentMonth) {
            lastDateIndex = i;
          } else {
            break;
          }
        }

        if (lastDateIndex !== -1) {
          this.expirySelect = this.expiryList[lastDateIndex];
        } else {
          this.expirySelect = this.expiryList[0];
        }
      }
      expiryParam = this.selectedExpiry ? null : expiryParam;
      this.selectedExpiry =
        this.expiryFromDashboard ?? expiryParam ?? this.expirySelect;
      if (this.loadedAsPage) {
        this.cart = [];
      }
      this.getInstrumentData();
    },
    selectedExpiry() {
      this.getInstrumentData();
      if (!this.loadedAsPage) this.reformatStrategiesCreatorList();
    },
    selectedTab(newVal) {
      if (newVal != "graphs") {
        setTimeout(() => {
          this.scrollIntoViewATM();
        }, 100);
      }
    },
  },
  computed: {
    ...ComputedValues,
    disableSettings() {
      return (
        this.selectedTab == "graphs" ||
        this.loadingInstrumentData ||
        this.errorGettingSymbols ||
        this.errorGettingInstrumentData
      );
    },
    disableOCDownload() {
      return (
        this.loadingInstrumentData ||
        this.errorGettingSymbols ||
        this.errorGettingInstrumentData
      );
    },
    strikesDiffrence() {
      const strikes = [];
      this.filteredStrikesList.forEach((el) => strikes.push(el.strike));
      return Math.abs(strikes[0] - strikes[1]);
    },
    computedLotSize() {
      return parseFloat(
        this.lotSize ? this.lotSize : this.selectedInstrument?.lot_size
      ).toFixed(0);
    },
    atmValue() {
      if (!this.selectedInstrument) return null;
      const key = ["MCX", "CDS"].includes(this.selectedInstrument.exchange)
        ? "fut_code"
        : "underlying_code";
      if (this.isMultiStrikeChart)
        return this.customATM ?? this.liveLtp(this.selectedInstrument[key]);
      return (
        this.liveLtp(this.selectedInstrument[key]) ??
        this.atmStaticVal.strike_price
      );
    },
    filteredStrikesList() {
      const strikes = [...this.strikesList];
      if (this.isReversed) strikes.reverse();
      return strikes;
    },
    lixCE() {
      let lixArr = Array(this.strikesList.length).fill(0);
      this.lixObjListCE.forEach((el) => (lixArr[el.index] = el.obj.lix_value));
      if (this.isReversed) lixArr.reverse();
      return lixArr;
    },
    lixPE() {
      let lixArr = Array(this.strikesList.length).fill(0);
      this.lixObjListPE.forEach((el) => (lixArr[el.index] = el.obj.lix_value));
      if (this.isReversed) lixArr.reverse();
      return lixArr;
    },
    callItmOtm() {
      if (this.strikesList.length == 0 || !this.isMultiStrikeChart) return [];
      const strikes = [];
      this.filteredStrikesList.forEach((el) => strikes.push(el.strike));
      const itm = "ITM-";
      const otm = "OTM ";
      const strikesDiff = this.strikesDiffrence;
      const roundedATM = Math.ceil(+this.atmValue / strikesDiff) * strikesDiff;
      const atmIndex = this.binarySearch(strikes, roundedATM);
      const arr = Array(this.strikesList.length).fill(null);
      arr[atmIndex] = "ATM";
      for (let i = 1; atmIndex - i >= 0; i++) {
        arr[atmIndex - i] = (this.isReversed ? otm : itm) + i;
      }
      for (let i = 1; atmIndex + i <= strikes.length; i++) {
        arr[atmIndex + i] = (this.isReversed ? itm : otm) + i;
      }
      return arr;
    },
    putItmOtm() {
      if (this.strikesList.length == 0 || !this.isMultiStrikeChart) return [];
      const strikes = [];
      this.filteredStrikesList.forEach((el) => strikes.push(el.strike));
      const itm = "ITM-";
      const otm = "OTM ";
      const strikesDiff = Math.abs(strikes[0] - strikes[1]);
      const roundedATM = Math.ceil(+this.atmValue / strikesDiff) * strikesDiff;
      const atmIndex = this.binarySearch(strikes, roundedATM);

      const arr = Array(this.strikesList.length).fill(null);
      arr[atmIndex] = "ATM";
      for (let i = 1; atmIndex - i >= 0; i++) {
        arr[atmIndex - i] = (this.isReversed ? itm : otm) + i;
      }
      for (let i = 1; atmIndex + i <= strikes.length; i++) {
        arr[atmIndex + i] = (this.isReversed ? otm : itm) + i;
      }
      return arr;
    },
    // Call Buttons State Management Array Acc to cart
    callInsStateList() {
      let callStateList = [];

      const callCartInsList = this.cart.filter((el) => el.segment == "c");

      this.filteredStrikesList.forEach((el) => {
        let buySellStatus = {
          sell: false,
          buy: false,
          buyCount: 0,
          sellCount: 0,
        };

        const insBuy = callCartInsList.find(
          (ins) => ins.code == el.ceCode && ins.quantity > 0
        );
        const insSell = callCartInsList.find(
          (ins) => ins.code == el.ceCode && ins.quantity < 0
        );
        if (insBuy) {
          // const quantity = this.loadedAsPage ? insBuy.quantity : insBuy.netQuantity;
          const quantity = insBuy.quantity;
          buySellStatus.buy = true;
          buySellStatus.buyCount = Math.abs(
            Math.abs(quantity) / this.computedLotSize
          );
        }

        if (insSell) {
          // const quantity = this.loadedAsPage ? insSell.quantity : insSell.netQuantity;
          const quantity = insSell.quantity;
          buySellStatus.sell = true;
          buySellStatus.sellCount = Math.abs(
            Math.abs(quantity) / this.computedLotSize
          );
        }
        callStateList.push(buySellStatus);
      });
      return callStateList;
    },
    // Put Buttons State Management Array Acc to cart
    putInsStateList() {
      let putStateList = [];

      const putCartInsList = this.cart.filter((el) => el.segment == "p");

      this.strikesList.forEach((el) => {
        let buySellStatus = {
          sell: false,
          buy: false,
          buyCount: 0,
          sellCount: 0,
        };

        const insBuy = putCartInsList.find(
          (ins) => ins.code == el.peCode && ins.quantity > 0
        );
        const insSell = putCartInsList.find(
          (ins) => ins.code == el.peCode && ins.quantity < 0
        );
        if (insBuy) {
          // const quantity = this.loadedAsPage ? insBuy.quantity : insBuy.netQuantity;
          const quantity = insBuy.quantity;
          buySellStatus.buy = true;
          buySellStatus.buyCount = Math.abs(
            Math.abs(quantity) / this.computedLotSize
          );
        }

        if (insSell) {
          // const quantity = this.loadedAsPage ? insSell.quantity : insSell.netQuantity;
          const quantity = insSell.quantity;
          buySellStatus.sell = true;
          buySellStatus.sellCount = Math.abs(
            Math.abs(quantity) / this.computedLotSize
          );
        }
        putStateList.push(buySellStatus);
      });
      return putStateList;
    },
    ivValList() {
      let valArray = [];
      const ltp = this.liveLtp(this.selectedInstrument.underlying_code);
      const spotPrice = ltp ? ltp : parseFloat(this.atmStaticVal.strike_price);

      for (let i = 0; i < this.strikesList.length; i++) {
        if (this.strikesList[i].strike <= spotPrice)
          valArray.push(this.ivPE[i].toFixed(2));
        else valArray.push(this.ivCE[i].toFixed(2));
      }
      return valArray;
    },
    maxValOiCE() {
      return Math.max(...this.oiCE);
    },
    maxValOiPE() {
      return Math.max(...this.oiPE);
    },
    OCTableActiveColCount() {
      const restOfTheColumns = 2;
      let sum = 0;
      Object.values(this.OCTableColumns).forEach((num) => (sum += num));
      return sum + restOfTheColumns;
    },
    greeksTableActiveColCount() {
      const restOfTheColumns = 2;
      let sum = 0;
      Object.values(this.greeksTableColumns).forEach((num) => (sum += num));
      return sum + restOfTheColumns;
    },
    bottomTotalColspan() {
      const activeOcCols = this.OCTableActiveColCount;
      const remainingCol = this.OCTableColumns.oi + this.OCTableColumns.volume;
      return (activeOcCols - remainingCol) * 2;
    },
  },
  methods: {
    print(a) {
      console.log(a);
    },
    /**
     *   @function fetching_INSTRUMENTS
     *   Loads when the page is mounted (called once when page initially loads)
     */
    getTrendsAndSymbols() {
      this.loadingInstruments = true;
      const payload = {
        path: "dashboard_symbols",
        broker: "aliceblue",
        user_id: this.userId,
        access_token: this.accessToken,
      };
      axios
        .post(getTrendsAndSymbolsUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          if (res.data.errorMessage) {
            throw res;
          }
          // PUTTING NIFTY BANK, NIFTY & FINNIFTY AT TOP
          let niftyIndexes = res.data.filter((el) => el.name.includes("NIFTY"));
          let nonNiftyIndexes = res.data.filter(
            (el) => !el.name.includes("NIFTY")
          );
          this.instrumentsList = niftyIndexes.concat(nonNiftyIndexes);

          // Setting default selected instrument as NIFTY or the instrument sent from Dashboard
          let defaultInstrumentName;
          if (this.selectedInstrument) {
            defaultInstrumentName = this.selectedInstrument.name;
          }
          // Checking for route query parameters
          else {
            const paramIns = this.$route.query.trading_symbol;
            if (
              paramIns &&
              this.instrumentsList.find(
                (el) => el.name == paramIns.toUpperCase()
              )
            ) {
              defaultInstrumentName = paramIns;
            } else {
              // defaultInstrumentName = "NIFTY";
              defaultInstrumentName = this.userSettings.optionchain_symbol;
            }
          }
          this.selectedInstrument = this.instrumentsList.find(
            (el) => el.name == defaultInstrumentName
          );
          this.errorGettingSymbols = false;
          this.loadingInstruments = false;
        })
        .catch((err) => {
          console.log("error occoured while loading instruments");
          console.log(err);
          this.errorGettingSymbols = true;
          this.loadingInstruments = false;
        });
    },
    // Function to subscribe to new selected instrument and unsubscribe to old one;
    subscribeToSymbol(ins) {
      if (!ins) return;
      let insList = [];
      switch (ins.exchange) {
        case "CDS":
        case "MCX":
          insList = [
            {
              code: ins.fut_code,
              exchange: ins.exchange,
              loc: this.$route.name,
            },
          ];
          break;
        case "BFO":
          insList = [
            { code: ins.fut_code, exchange: "BFO", loc: this.$route.name },
            {
              code: ins.underlying_code,
              exchange: "BSE",
              loc: this.$route.name,
            },
          ];
          break;
        default:
          insList = [
            { code: ins.fut_code, exchange: "NFO", loc: this.$route.name },
            {
              code: ins.underlying_code,
              exchange: "NSE",
              loc: this.$route.name,
            },
          ];
          break;
      }
      this.subscribeToInstruments(insList); //function form websocketMethodsMixin
    },
    unsubscribeToSymbol(ins) {
      let insList = [];
      switch (ins.exchange) {
        case "CDS":
        case "MCX":
          insList = [{ code: ins.fut_code, exchange: ins.exchange }];
          break;
        case "BFO":
          insList = [
            { code: ins.fut_code, exchange: "BFO" },
            { code: ins.underlying_code, exchange: "BSE" },
          ];
          break;
        default:
          insList = [
            { code: ins.fut_code, exchange: "NFO" },
            { code: ins.underlying_code, exchange: "NSE" },
          ];
          break;
      }
      this.unsubscribeToInstruments(insList); //function form websocketMethodsMixin
    },
    /**
		*   @function fetching_SELECTED_INSTRUMENT_DATA
        Dependent on selectedExpiry, limitlength and ofcourse selected instrument
        Is called whenever one of its dependency changes (called multiple times) (check Watch Properties)
        Sets majority of the data on the page
    */
    getInstrumentData() {
      if (this.loadingInstrumentData) {
        return;
      }
      this.isReversed = false;
      this.loadingInstrumentData = true;

      const expiry = this.reformatDate(this.selectedExpiry);

      const diff = Math.abs(new Date(expiry) - new Date());
      const timeForExp = (diff + 36000000) / (24 * 3600 * 1000) / 365;
      const payload = {
        name: this.selectedInstrument.name,
        expiry: expiry,
        limit: this.strikeLength.toString(),
        exchange: this.selectedInstrument.exchange,
        access_token: this.accessToken,
        user_id: this.userId,
        days_to_expire: timeForExp,
        ws_token: this.wsSession,
      };
      axios
        .post(getStrikePriceListV2, payload)
        .then((res) => {
          if (res.data.errorMessage) {
            throw res;
          }
          //functions needs to be in this exact sequence
          this.setInstrumentData(res);
          this.getValuesMaxPainAndPCR();
          this.setChartData();
          this.subscribeOptionChainInstruments();
          if (!this.isMultiStrikeChart) this.setLixValues();
          this.loadingInstrumentData = false;
          this.errorGettingInstrumentData = false;
          setTimeout(() => {
            this.scrollIntoViewATM();
            this.setTotalVolumneOI();
          }, 300);
        })
        .catch((err) => {
          console.log("error occoured while loading instrument data");
          console.log(err);
          this.loadingInstrumentData = false;
          this.errorGettingInstrumentData = true;
          setTimeout(() => {
            this.scrollIntoViewATM();
          }, 300);
        });
    },
    subscribeOptionChainInstruments() {
      if (!this.selectedInstrument) return;
      const exchange = this.selectedInstrument.exchange;

      const ex = exchange == "NSE" ? "NFO" : exchange;
      const insList = [];
      this.strikesList.forEach((el) => {
        insList.push({ code: el.ceCode, exchange: ex, loc: this.$route.name });
        insList.push({ code: el.peCode, exchange: ex, loc: this.$route.name });
      });
      this.subscribeToInstruments(insList); //function form websocketMethodsMixin
    },
    unsubscribeOptionChainInstruments(
      selectedInstrument = this.selectedInstrument
    ) {
      const exchange = selectedInstrument.exchange;

      const ex = exchange == "NSE" ? "NFO" : exchange;
      const insList = [];
      this.strikesList.forEach((el) => {
        insList.push({ code: el.ceCode, exchange: ex });
        insList.push({ code: el.peCode, exchange: ex });
      });
      this.unsubscribeToInstruments(insList);
    },
    setInstrumentData(res) {
      const resData = res.data;
      this.lotSize = resData.lot_size;
      this.atmStaticVal = resData.atm_values;
      this.customATM = resData.atm_values.strike_price;

      // Set Strikes Related Data
      const strikes = resData.strike_list;
      this.strikeLength = strikes.length;
      this.strikesListCE = resData.strike_list_ce;
      this.strikesListPE = resData.strike_list_pe;

      const strikeObjList = [];
      for (let i = 0; i < strikes.length; i++) {
        strikeObjList.push({
          ceCode: this.strikesListCE[i].code,
          peCode: this.strikesListPE[i].code,
          strike: strikes[i],
        });
      }
      this.strikesList = strikeObjList;

      // Set Greeks Data
      this.greeksData = {
        gammaCE: resData.greeks.gamma_ce,
        gammaPE: resData.greeks.gamma_pe,
        vegaCE: resData.greeks.vega_ce,
        vegaPE: resData.greeks.vega_pe,
        deltaCE: resData.greeks.delta_ce,
        deltaPE: resData.greeks.delta_pe,
        thetaCE: resData.greeks.theta_ce,
        thetaPE: resData.greeks.theta_pe,
      };

      // Fall Back Values
      this.optionChainFallBackData = {
        oiCE: resData.oi_ce_list,
        oiPE: resData.oi_pe_list,
        ivCE: resData.iv_val_ce,
        ivPE: resData.iv_val_pe,
        volumeCE: resData.ce_volume,
        volumePE: resData.pe_volume,
        ltpCE: resData.ltp_ce_list,
        ltpPE: resData.ltp_pe_list,
        priceChangeCE: resData.pc_list_ce,
        priceChangePE: resData.pc_list_pe,
      };
    },
    setChartData() {
      this.chartData = {
        call_oi: this.oiCE,
        put_oi: this.oiPE,
        call_price: this.ltpCE,
        put_price: this.ltpPE,
        delta_c: this.deltaCE.map((el) => el * 100),
        delta_p: this.deltaPE.map((el) => el * 100),
        gamma_c: this.gammaCE,
        gamma_p: this.gammaPE,
        theta_c: this.thetaCE,
        theta_p: this.thetaPE,
        vega_c: this.vegaCE,
        vega_p: this.vegaPE,
        iv: this.ivValList,
        strike: this.strikesList.map((el) => el.strike),
      };
    },
    /**
     *   @function fetching_LIX_VALUES for call and put
     */
    async setLixValues() {
      let lixObjsCE = [];
      let lixObjsPE = [];
      this.strikesListCE.forEach((ins, index) => {
        let insObj = {
          ...ins,
          op_pr: this.liveLtp(ins.code)
            ? parseInt(this.liveLtp(ins.code))
            : this.ltpCE.at(index),
          exchange: this.selectedInstrument.exchange,
        };
        this.getLixValue([insObj]).then((res) => {
          if (res) {
            lixObjsCE.push({ index: index, obj: res[0] });
          }
        });
      });
      this.lixObjListCE = lixObjsCE;

      this.strikesListPE.forEach((ins, index) => {
        let insObj = {
          ...ins,
          op_pr: this.liveLtp(ins.code)
            ? parseInt(this.liveLtp(ins.code))
            : this.ltpPE.at(index),
          exchange: this.selectedInstrument.exchange,
        };
        this.getLixValue([insObj]).then((res) => {
          if (res) {
            lixObjsPE.push({ index: index, obj: res[0] });
          }
        });
      });
      this.lixObjListPE = lixObjsPE;
    },
    async getLixValue(insObj) {
      let retVal = null;
      const payload = {
        access_token: this.accessToken,
        user_id: this.userId,
        ws_token: this.wsSession,
        param_list: insObj,
      };
      await axios
        .post(getLixData, payload)
        .then((res) => {
          if (!res.data.param_list) throw res;
          retVal = res.data.param_list;
        })
        .catch(() => {
          // console.log('error while fetching lix');
          // console.log(err);
        });
      return retVal;
    },
    /**
     *   @function fetching_MaxPain_AND_PCR
     */
    async getValuesMaxPainAndPCR() {
      const expiry = this.reformatDate(this.selectedExpiry);

      const diff = Math.abs(new Date(expiry) - new Date());
      const timeForExp = (diff + 36000000) / (24 * 3600 * 1000) / 365;
      const payload = {
        path: "pcr_maxpain",
        name: this.selectedInstrument.name,
        expiry: expiry,
        limit: this.strikeLength,
        exchange: this.selectedInstrument.exchange,
        access_token: this.accessToken,
        user_id: this.userId,
        days_to_expire: timeForExp,
        ws_token: this.wsSession,
      };
      axios
        .post(getMaxPainAndPCR, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.max_pain = res.data.max_pain;
          this.pcr = res.data.pcr;
        })
        .catch((err) => {
          console.log("Error fetching Max Pain And PCR");
          console.log(err);
        });
    },
    setTotalVolumneOI() {
      if (!this.strikesList?.length > 0) return;
      const strikesObj = this.strikesList;
      let oiCE = 0;
      let oiPE = 0;
      let volumeCE = 0;
      let volumePE = 0;
      strikesObj.forEach((el) => {
        oiCE += this.liveOI(el.ceCode);
        oiPE += this.liveOI(el.peCode);
        volumeCE += this.liveVolume(el.ceCode);
        volumePE += this.liveVolume(el.peCode);
      });
      this.totalOICE = oiCE;
      this.totalOIPE = oiPE;
      this.totalVolumeCE = volumeCE;
      this.totalVolumePE = volumePE;
    },
    downloadOptionChain() {
      if (!this.strikesList?.length > 0) return;
      const strikesObj = this.strikesList;
      const greeksRows = [];
      const chainRows = [];
      const divByLot = (v) => (v / this.computedLotSize).toFixed(2);
      strikesObj.forEach((el, index) => {
        const ceObj = this.insSub(el.ceCode);
        const peObj = this.insSub(el.peCode);
        chainRows.push({
          "Call-OI": divByLot(ceObj.oi),
          "Call-Volume": divByLot(ceObj.volume),
          "Call-LIX": this.lixCE[index],
          "Call-Bid": ceObj.bid,
          "Call-ASK": ceObj.ask,
          "Call-LTP": ceObj.ltp,
          STRIKE: el.strike,
          IV: this.ivValList[index],
          "Put-LTP": peObj.ltp,
          "Put-ASK": peObj.ask,
          "Put-Bid": peObj.bid,
          "Put-LIX": this.lixPE[index],
          "Put-Volume": divByLot(peObj.volume),
          "Put-OI": divByLot(peObj.oi),
        });
        greeksRows.push({
          "Call-GAMMA": this.gammaCE[index],
          "Call-VEGA": this.vegaCE[index],
          "Call-THETA": this.thetaCE[index],
          "Call-DELTA": this.deltaCE[index],
          STRIKE: el.strike,
          IV: this.ivValList[index],
          "Put-DELTA": this.deltaPE[index],
          "Put-THETA": this.thetaPE[index],
          "Put-VEGA": this.vegaPE[index],
          "Put-GAMMA": this.gammaPE[index],
        });
      });
      this.downloadExcelData([
        { name: "option-chain", sheet: chainRows },
        { name: "option-greeks", sheet: greeksRows },
      ]);
    },
    downloadExcelData(sheets) {
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const workBook = XLSX.utils.book_new();
      sheets.forEach((sheet) => {
        const workSheet = XLSX.utils.json_to_sheet(sheet.sheet);
        XLSX.utils.book_append_sheet(workBook, workSheet, sheet.name);
      });
      const excelBuffer = XLSX.write(workBook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
      const blobUrl = URL.createObjectURL(blob);

      let a = document.createElement("A");
      a.setAttribute("href", blobUrl);
      a.setAttribute("download", "option-chain" + ".xlsx");
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    getUnderlyingLtp() {
      const payload = {
        symbol: this.selectedInstrument.name,
        time: this.selectedTime,
      };
      axios
        .post(getUnderlyingLtpWithTime, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.customATM = res.data;
          this.$emit("spotPriceFromOptionChain", this.customATM);
        });
    },
    // ************CART MANAGEMENT **************
    // Instrument Selection Handeling (for dashboard and page)
    manageInstrumentSelection(index, code, type, segment, multiplyer = 1) {
      const lotMultiplyer = multiplyer;
      let oldBtnsState =
        segment == "ce"
          ? this.callInsStateList[index]
          : this.putInsStateList[index];
      let newBtnsState;

      if (oldBtnsState.buy && oldBtnsState.sell) {
        newBtnsState = oldBtnsState;
      } else if (type == "s") {
        newBtnsState = { sell: true, buy: oldBtnsState.buy };
      } else {
        newBtnsState = { sell: oldBtnsState.sell, buy: true };
      }

      // FOR DASHBOARD
      if (!this.loadedAsPage) {
        if (this.loadingListInDashboard) {
          return;
        }
        const ins = this.createNewCartInstrument(
          code,
          type,
          segment,
          lotMultiplyer
        );
        if (
          (!oldBtnsState.sell && newBtnsState.sell) ||
          (!oldBtnsState.buy && newBtnsState.buy)
        ) {
          this.$emit("add-instrument", ins);
        } else {
          const index = this.strategyCreatorList.findIndex((el) => {
            const symCheck = el.trading_symbol == ins.trading_symbol;
            const qty = ins.quantity > 0 ? el.quantity > 0 : el.quantity < 0;
            const dt = ins.expiry == el.expiry;
            return symCheck && qty && dt;
          });

          // NEW
          const scList = JSON.parse(JSON.stringify(this.strategyCreatorList));
          const newQty =
            scList[index].quantity +
            this.computedLotSize * (type == "b" ? 1 : -1);
          this.$emit(
            "change-instrument-quantity",
            "quantity",
            newQty,
            index,
            true
          );
        }
      }
      // FOR OPTION CHAIN PAGE
      else {
        const newInstrument = this.createNewCartInstrument(
          code,
          type,
          segment,
          lotMultiplyer
        );

        const cartInsIndex = this.cart.findIndex((ins) => {
          const qtyCheck = type == "s" ? ins.quantity < 0 : ins.quantity > 0;
          const symbolCheck =
            newInstrument.trading_symbol == ins.trading_symbol;
          return qtyCheck && symbolCheck;
        });
        // if instrument doesnt exists in cart
        if (cartInsIndex == -1) {
          this.cart.push(newInstrument);
          if (!this.cartActive) {
            this.showPlaceOrderDialog = true;
          }
        }
        // Adding only quantity if instrument already in cart
        else {
          let cartInsCopy = JSON.parse(JSON.stringify(this.cart[cartInsIndex]));
          const addQty = this.computedLotSize * (type == "s" ? -1 : 1);
          cartInsCopy.quantity += addQty;
          this.$set(this.cart, cartInsIndex, cartInsCopy);
        }
      }
    },
    createNewCartInstrument(code, type, segment, quantityMultiplyer = 1) {
      const isCall = segment == "ce";
      const insList = isCall ? this.strikesListCE : this.strikesListPE;
      const index = insList.findIndex((el) => el.code == code);
      const ins = insList[index];

      const ltpBackup = isCall ? this.ltpCE.at(index) : this.ltpPE.at(index);

      const insLotSize = this.computedLotSize;
      const quantity = type == "s" ? -insLotSize : insLotSize;

      const setInsVal = {
        trading_symbol: ins.name,
        ltp: this.liveLtp(ins.code) ?? ltpBackup,
        name: this.selectedInstrument.name,
        strike: ins.strike_price,
        segment: isCall ? "c" : "p",
        code: ins.code,
        exchange:
          this.selectedInstrument.exchange == "NSE"
            ? "NFO"
            : this.selectedInstrument.exchange,
        expiry: this.selectedExpiry,
        quantity: quantity * quantityMultiplyer,
        op_pr: this.liveLtp(ins.code) ?? ltpBackup,
        formatted_ins_name: ins.formatted_ins_name,
      };

      // for multistrikes chart
      if (this.isMultiStrikeChart) {
        const itmOtm = isCall ? this.callItmOtm[index] : this.putItmOtm[index];
        setInsVal["moneyness"] = itmOtm;
        setInsVal["atmValue"] = this.atmValue;
        setInsVal["selectedTime"] = this.selectedTime;
      }
      return setInsVal;
    },
    removeFromCart(code, type, segment) {
      const seg = segment == "ce" ? "c" : "p";
      // FOR DASHBOARD
      if (!this.loadedAsPage) {
        if (this.loadingListInDashboard) {
          return;
        }
        // getting instrument to remove from cart
        const removeIns = this.cart.find((el) => {
          const codeCheck = el.code == code;
          const segmentCheck = el.segment == seg;
          const qtyCheck = type == "s" ? el.quantity < 0 : el.quantity > 0;
          return codeCheck && segmentCheck && qtyCheck;
        });
        if (
          this.cart.length == 1 &&
          this.computedLotSize == Math.abs(removeIns.quantity) &&
          !this.isMultiStrikeChart
        ) {
          this.$notify({
            group: "success",
            type: "warn",
            title: "Warning",
            text: "Need atleast one instrument!",
          });
          return;
        }

        // fetching its copies in the strategies creator list
        let indexList = [];
        const insInStrategyCreator = this.strategyCreatorList.filter(
          (ins, index) => {
            const qtyCheck = type == "s" ? ins.quantity < 0 : ins.quantity > 0;
            const symbolCheck = ins.trading_symbol == removeIns.trading_symbol;
            if (qtyCheck && symbolCheck) {
              indexList.push(index);
              return true;
            }
          }
        );

        /*  Check if the qty of first occurance of instrument in strategy creator is more than lot size
            (i.e) Qty should not be 0 after substracting
            If it is then remove the instrument to reduce the qty
        */
        const firstOccurance = insInStrategyCreator[0];
        const substractQty = this.computedLotSize * (type == "s" ? -1 : 1);
        if (firstOccurance.quantity - substractQty != 0) {
          const newQty = firstOccurance.quantity - substractQty;
          this.$emit(
            "change-instrument-quantity",
            "quantity",
            newQty,
            indexList[0],
            false
          );
        } else {
          this.$emit("remove-instrument-at-index", indexList[0]);
        }
      }
      // FOR OPTION CHAIN PAGE
      else {
        const cartInsIndex = this.cart.findIndex((el) => {
          const codeCheck = el.code == code;
          const segmentCheck = el.segment == seg;
          const qtyCheck =
            type == "s" ? parseInt(el.quantity) < 0 : parseInt(el.quantity) > 0;
          return codeCheck && segmentCheck && qtyCheck;
        });
        // Check to wether remove instrumet or just reduce qty
        const remInsCheck =
          Math.abs(this.cart[cartInsIndex].quantity) - this.computedLotSize;
        if (remInsCheck == 0) {
          this.cart = this.cart.filter((el) => {
            const codeCheck = el.code == code;
            const segmentCheck = el.segment == seg;
            const qtyCheck =
              type == "s"
                ? parseInt(el.quantity) < 0
                : parseInt(el.quantity) > 0;
            return !(codeCheck && segmentCheck && qtyCheck);
          });
        } else {
          let cartInsCopy = JSON.parse(JSON.stringify(this.cart[cartInsIndex]));
          const subQty = this.computedLotSize * (type == "s" ? -1 : 1);
          cartInsCopy.quantity -= subQty;
          this.$set(this.cart, cartInsIndex, cartInsCopy);
        }
      }
    },
    /**
     *   @function Add_Instrument_To_Cart_From_Graphs
     *   handles instrument addition from optioncharts chaild component
     *   Reforms the input and passes to manageInstrumentSelection function
     */
    addInstrumentFromGraph(strike, segment, transactionType) {
      const insIndex = this.strikesList.findIndex((el) => el.strike == strike);
      const ins = this.strikesList[insIndex];
      const code = segment == "ce" ? ins.ceCode : ins.peCode;
      this.manageInstrumentSelection(insIndex, code, transactionType, segment);
    },
    reformatStrategiesCreatorList() {
      if (this.strategyCreatorList.length < 1) {
        this.cart = [];
        return;
      }

      let filteredList = JSON.parse(JSON.stringify(this.strategyCreatorList)); // deep copy
      filteredList = filteredList.filter(
        (el) => el.expiry == this.selectedExpiry && el.segment != "fut"
      );

      filteredList.forEach((el) => {
        el["purchaseType"] = el.quantity > 0 ? "buy" : "sell";
      });

      let uniqueList = [];
      /*  Removing duplicates from instrument list from dashboard (strategyCreatorList)
          Merging multiple instances of an instrument into one (adding their quantities)

          Conditions for being unique :
          -> Instrument trading symbol should be unique and buySell type should be same i.e(quantity either positive or negative)
      */
      filteredList.forEach((ins) => {
        const instrument = uniqueList.find((el) => {
          return (
            el.trading_symbol == ins.trading_symbol &&
            el.purchaseType == ins.purchaseType
          );
        });
        if (!instrument) {
          uniqueList.push(ins);
        }
      });
      uniqueList.forEach((el) => {
        let insOccurance = filteredList.filter((ins) => {
          return (
            el.trading_symbol == ins.trading_symbol &&
            el.purchaseType == ins.purchaseType
          );
        });
        let netQuantity = 0;
        insOccurance.forEach((ins) => (netQuantity += ins.quantity));
        el["netQuantity"] = netQuantity;
        el.quantity = netQuantity;
      });
      this.cart = uniqueList;
    },
    reformatDate(dateStr) {
      let d = new Date(dateStr);
      const month = (d.getMonth() < 9 ? "0" : "") + (d.getMonth() + 1);
      const year = d.getFullYear();
      const date = (d.getDate() <= 9 ? "0" : "") + d.getDate();
      const ans = [year, month, date].join("-");
      return ans;
    },
    // FUCNTIONS to COMPARE AND EVALUATE IF ROW's LTP IS SMALLER THAN OR GREATER THAN ATM(at the money)
    smallerThanATM(index) {
      const list = this.isReversed
        ? this.filteredStrikesList
        : this.strikesList;
      return parseFloat(list.at(index).strike) < this.atmValue;
    },
    greaterThanATM(index) {
      const list = this.isReversed
        ? this.filteredStrikesList
        : this.strikesList;
      return parseFloat(list[index].strike) >= this.atmValue;
    },
    isAtm(index) {
      const list = this.isReversed
        ? this.filteredStrikesList
        : this.strikesList;
      if (index == 0) return false;
      if (this.isReversed) {
        const c1 = parseFloat(list.at(index - 1).strike) >= this.atmValue;
        const c2 = parseFloat(list.at(index).strike) < this.atmValue;
        return c1 && c2;
      } else {
        const c1 = parseFloat(list.at(index - 1).strike) < this.atmValue;
        const c2 = parseFloat(list.at(index).strike) >= this.atmValue;
        return c1 && c2;
      }
    },
    scrollIntoViewATM() {
      let el = document.querySelector(".tables-section .v-data-table__wrapper");
      if (!el) return;
      const liveLtp = this.atmValue;
      const index = this.strikesList.findIndex((el) => {
        if (el.strike >= liveLtp) {
          return true;
        }
      });
      const normalizedScroll = (index + 1) / this.strikeLength;
      const vertical_shift =
        el.scrollHeight * normalizedScroll - el.offsetHeight / 2;
      el.scrollTo({ top: vertical_shift, behavior: "smooth" });
    },
    // only to be called when component is loaded on as popup
    setComponentViewDefault() {
      if (this.isMultiStrikeChart) return;
      this.OCTableColumns = {
        buySell: true,
        oiBuildup: false,
        oi: true,
        lix: true,
        volume: true,
      };
      this.greeksTableColumns = {
        buySell: true,
        gamma: true,
        vega: true,
        theta: true,
        delta: true,
      };
    },
    applyUserSettings() {
      this.strikeLength = this.userSettings.optionchain_default_strikes;
      this.greeksTableColumns.gamma = this.userSettings.optionchain_gamma;
      this.greeksTableColumns.vega = this.userSettings.optionchain_vega;
      this.greeksTableColumns.theta = this.userSettings.optionchain_theta;
      this.greeksTableColumns.delta = this.userSettings.optionchain_delta;
      this.OCTableColumns.oi = this.userSettings.optionchain_oi;
      this.OCTableColumns.lix = this.userSettings.optionchain_lix;
      this.OCTableColumns.volume = this.userSettings.optionchain_volume;
      this.OCTableColumns.buyBid = this.userSettings.optionchain_bid;
    },
    binarySearch(arr, x) {
      let start = 0,
        end = arr.length - 1;
      while (start <= end) {
        let mid = Math.floor((start + end) / 2);
        if (arr[mid] == x) return mid;
        else if (arr[mid] < x) start = mid + 1;
        else end = mid - 1;
      }
      return -1;
    },
    allowedHours(v) {
      const currentDate = new Date();
      let time = currentDate.getHours() > 15 ? 15 : currentDate.getHours();
      return v <= time;
    },
    allowedMinutes(v) {
      const currentDate = new Date();
      let time =
        currentDate.getHours() > parseInt(this.selectedTime.split(":")[0])
          ? 60
          : parseInt(this.selectedTime.split(":")[1]);
      return v <= time;
    },
  },
  filters: {
    dateDDMM: function (val) {
      return val.slice(0, 6);
    },
    divideBy: function (val, divider, decimal = 0) {
      return (val / divider).toFixed(decimal);
    },
    inThousands: function (val) {
      let num = val / 1000;
      return (
        num.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + "K"
      );
    },
    inLakhs: function (val) {
      let num = val / 100000;
      return (
        num.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + "L"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global.scss";
.default-style {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  // padding: 0 1rem;
  color: var(--text-color);
  background-color: transparent;
}
.option-chain-wrapper {
  max-width: 2500px;
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  // padding: 0 1rem;
  color: var(--text-color);
  background-color: var(--cards-background-color);
  .page-heading {
    font-size: 1.5em;
    line-height: 28px;
    font-weight: 600;
  }
  .header-live-ltps {
    font-size: 1.1em;
    .sub-text-change {
      font-size: 0.75em;
    }
  }
}
.option-chain-settings {
  .colunms-togglers-list .v-list-item__title {
    font-size: 1em !important;
  }
}

.circle-popup {
  position: absolute;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  // padding: 1px;
  font-size: 0.625em;
  text-align: center;
  background: var(--cards-background-color);
}
.cart-count-popup {
  border: 1px solid var(--primary-button-color);
  color: var(--primary-button-color);
  bottom: -4px;
  left: 13px;
}

.bg-white {
  background-color: transparent !important;
}
// ALL THE TABLES STYLINGS
.tables-section {
  .table-header {
    background-color: var(--option-header-bg);
    th {
      background-color: var(--option-header-bg);
      color: var(--text-color) !important;
    }
    th.tool-tip {
      cursor: pointer;
    }
  }
  thead.fixed-table-header {
    position: sticky;
    top: 0;
    z-index: 6;
  }
  tfoot.total-data {
    position: sticky;
    bottom: 0;
    z-index: 6;
    td {
      background-color: var(--cards-background-color) !important;
    }
    td.total-tag {
      font-size: 1.1em;
      letter-spacing: 0.1em;
    }
  }
  td,
  th {
    color: var(--option-row-text);
    padding: 0 8px !important;
  }
  th {
    height: 36px !important;
    border-bottom: none !important;
    white-space: nowrap;
  }
  td {
    // background-color: white;
    border-bottom: none !important;
    height: 40px !important;
  }
  tbody {
    tr:nth-child(even) {
      background-color: var(--option-even-row);
    }
    // Some default styles for columns
    td.strike {
      background-color: var(--option-strike-bg);
      color: var(--option-strike-text);
    }
    td.iv {
      background-color: var(--option-iv-bg);
      color: var(--option-iv-text);
    }
    th.bids,
    td.bids {
      width: 130px;
      white-space: nowrap;
    }
  }

  // STRIKE POSITION STICKY
  th.strike,
  td.strike {
    min-width: 62px;
    position: sticky;
  }
  td.strike {
    z-index: 2;
  }
  th.strike {
    z-index: 3;
  }
  th.strike,
  td.strike {
    left: 0;
    right: 0;
  }

  // At the money design change (sequence of css important)
  tr.atm-row {
    td {
      background-image: linear-gradient(
        to right,
        #2f4aed 63%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: top;
      background-size: 20px 2px;
      background-repeat: repeat-x;
    }
    .spot-price-box {
      background-color: var(--primary-button-color);
      color: var(--primary-button-text-color);
      padding: 2px 10px;
      font-size: var(--font-12);
      position: absolute;
      top: -25%;
      z-index: 2;
      border-radius: 5px;
    }
  }
  tr.smaller-than-atm-row:nth-child(even) {
    td.call {
      background-color: var(--option-above-atm-even);
    }
  }
  tr.smaller-than-atm-row:nth-child(odd) {
    td.call {
      background-color: var(--option-above-atm-odd);
    }
  }
  tr.greater-than-atm-row:nth-child(even) {
    td.put {
      background-color: var(--option-above-atm-even);
    }
  }

  tr.greater-than-atm-row:nth-child(odd) {
    td.put {
      background-color: var(--option-above-atm-odd);
    }
  }
  tr:hover {
    td.call,
    td.put {
      background-color: var(--callput-row-hover-bg) !important;
    }
  }
  .percent-bar {
    border-radius: 5px;
    position: absolute;
    bottom: -3px;
    z-index: 1;
    height: 5px;
  }
  .remove-ins-btn-container {
    display: flex;
    align-items: center;
    width: 20px;
    button {
      font-size: 1.25em;
    }
  }
  .buy-ins-btn,
  .sell-ins-btn {
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid var(--text-color);
    border-radius: 5px;
    position: relative;
  }
  .buy-ins-btn {
    margin-left: 2px;
    margin-right: 6px;
  }
  .sell-ins-btn {
    margin-left: 6px;
    margin-right: 2px;
  }
  .buy-ins-btn:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
  .sell-ins-btn:hover {
    color: var(--red-text);
    border-color: var(--red-text);
  }
  .selected-buy {
    color: var(--primary-color);
    background-color: var(--buy-background);
    border: none;
    .circle-popup {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      left: 15px;
      top: -8px;
    }
  }
  .selected-sell {
    color: var(--red-text);
    background-color: var(--put-background);
    border: none;
    .circle-popup {
      border: 1px solid var(--red-text);
      color: var(--red-text);
      left: 15px;
      top: -8px;
    }
  }
  .light-btns-div {
    opacity: 0.55;
  }
}
.table-toggler {
  button {
    background-color: var(--chip-background) !important;
    color: var(--text-color);
  }
  button.active-tab {
    background-color: var(--primary-button-color) !important;
    color: var(--primary-button-text-color) !important;
  }
}
.circle-counter {
  position: absolute;
  bottom: -4px;
  left: 13px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding: 1px;
  background: transparent;
  border: 1px solid var(--primary-button-color);
  color: var(--primary-button-text-color);
  font-size: 0.5625em;
  text-align: center;
}

.add-strike-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding: 12px 12px;
  bottom: 0;
  left: 0;
  button {
    font-size: var(--font-20);
    width: 48%;
  }
}

@media screen and (min-width: 961px) and (max-width: 1850px) {
  .option-chain-wrapper {
    font-size: 1rem;
  }
  .page-heading {
    font-size: 1.6rem !important;
  }
  .v-input {
    font-size: 1rem;
  }
  .header-live-ltps {
    font-size: 1.15rem !important;
  }
  .fixed-table-header {
    font-size: 1rem !important;
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.875em;
  height: 48px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.75em;
  height: 48px;
}

@media screen and (max-width: 600px) {
  .page-heading {
    font-size: 1.25rem !important;
    line-height: 22px !important;
  }
  .tables-section td,
  .max-pain-pcr {
    font-size: 0.75rem !important;
  }
  .header-live-ltps {
    font-size: 0.85rem !important;
  }
  .table-toggler .v-btn-toggle .v-btn {
    height: 25px !important;
  }
}
// VUETIFY COMPONENTS FORCED CSS
.symbols-autocomplete {
  label {
    left: 12px !important;
  }
}
</style>
<style lang="scss">
@import "../../assets/css/vuetify-components.scss";
.cart_font {
  .v-input--selection-controls .v-input__slot > .v-label,
  .v-input--selection-controls .v-radio > .v-label {
    font-size: 1em;
  }
}
.tables-section {
  table {
    margin-bottom: 5px;
  }
}
</style>
