<template>
  <div>
    <div
      class="white-card prebuilt-strategy-section px-2 px-sm-4"
      :style="isVisible ? { minHeight: '350px' } : {}"
      v-if="!isPrebuiltStrategyDialog"
    >
      <div class="prebuilt-strategy-header">
        <v-tabs height="30px" color="var(--primary-button-color)">
          <v-tab style="00.875em" @click="showStrategyTab('Pre-built')"
            >Pre-Built</v-tab
          >
          <v-tab style="00.875em" @click="showStrategyTab('Saved')"
            >Saved</v-tab
          >
          <v-spacer></v-spacer>
          <v-icon v-if="isVisible" @click="isVisible = false"
            >mdi-chevron-up</v-icon
          >
          <v-icon v-if="!isVisible" @click="isVisible = true"
            >mdi-chevron-down</v-icon
          >
        </v-tabs>
      </div>

      <!-- PreBuilt Strategies Section -->
      <div v-if="selectedPrebuiltTab === 'Pre-built' && isVisible">
        <div class="prebuilt-strategy-content justify-space-start">
          <div
            v-for="strategy in prebuiltStrategyList"
            class="prebuilt-strategy-cards"
            :key="strategy.value"
            :class="
              strategy === selectedStrategy
                ? 'selected-card'
                : 'unselected-card'
            "
            @click="selectStrategy(strategy)"
          >
            <div>
              <img class="card-image" :src="strategy.imageUrl" alt="" />
            </div>
            <div style="font-size: var(--font-14); color: var(--text-color)">
              {{ strategy.label }}
            </div>
            <div
              v-if="strategy.buy_sell.length !== 0"
              style="font-size: var(--font-12); margin-top: 8px"
            >
              <span v-for="(bs, i) in strategy.buy_sell" :key="i + bs">
                <span
                  :class="bs.label.includes('B') ? 'green-text' : 'red-text'"
                  >{{ bs.label }}
                </span>
                <span>{{ bs.value }} </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- SAVED STRATEGY -->
      <div
        style="margin-top: 1rem"
        ref="savedStrategies"
        v-if="selectedPrebuiltTab === 'Saved' && isVisible"
      >
        <div>
          <!-- Check Boxex -->
          <div class="d-flex justify-space-between select-checkbox my-3">
            <v-checkbox
              v-model="selectedLabel"
              :label="`Bullish (${bullishCount.toString()})`"
              value="bullish"
              text-color="var(--primary-button-color)"
              color="var(--primary-button-color)"
              hide-details
              :disabled="bullishCount == 0 ? true : false"
              @click="getFilteredSavedStrategies()"
            ></v-checkbox>
            <v-checkbox
              class="ml-3"
              v-model="selectedLabel"
              :label="`Bearish (${bearishCount.toString()})`"
              value="bearish"
              color="var(--primary-button-color)"
              hide-details
              :disabled="bearishCount == 0 ? true : false"
              @click="getFilteredSavedStrategies()"
            ></v-checkbox>
            <v-checkbox
              class="ml-3"
              v-model="selectedLabel"
              :label="`Neutral (${neutralCount.toString()})`"
              value="neutral"
              color="var(--primary-button-color)"
              hide-details
              :disabled="neutralCount == 0 ? true : false"
              @click="getFilteredSavedStrategies()"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-icon
              :disabled="filteredSavedStrategies.length == 0"
              @click="isExpandedSavedStrategies = true"
            >
              mdi-arrow-expand
            </v-icon>
          </div>

          <!-- Saved strategies cards -->
          <div class="saved-strategy-content .styled-scroll">
            <div
              class="saved-strategy-cards"
              v-for="(savedStrategy, index) in filteredSavedStrategies"
              :key="savedStrategy.id"
            >
              <div class="saved-strategy-header">
                <span class="strategy-name">{{ savedStrategy.name }}</span>
                <span
                  :class="
                    savedStrategy.direction === 'Bullish'
                      ? 'bullish-text'
                      : savedStrategy.direction === 'Bearish'
                      ? 'bearish-text'
                      : 'neutral-text'
                  "
                  >{{ savedStrategy.direction }}</span
                >
              </div>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="strategy-table-header">
                    <tr>
                      <th class="text-left">Type</th>
                      <th class="text-center">Lot</th>
                      <th class="text-center">Strike</th>
                      <th class="text-center">Expiry</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, index) in savedStrategy.structure"
                      :key="row.code + '' + index"
                    >
                      <td
                        class="text-left"
                        v-if="
                          index >= savedStrategy.startIndex &&
                          index < savedStrategy.endIndex
                        "
                      >
                        <span v-if="row.segment == 'CE'" class="bullish-text"
                          >Call</span
                        >
                        <span v-if="row.segment == 'PE'" class="bearish-text"
                          >Put</span
                        >
                        <span v-if="row.segment == 'FUT'" class="neutral-text"
                          >Fut</span
                        >
                      </td>
                      <td
                        class="text-center"
                        v-if="
                          index >= savedStrategy.startIndex &&
                          index < savedStrategy.endIndex
                        "
                      >
                        {{ row.quantity }}
                      </td>
                      <td
                        class="text-center"
                        v-if="
                          index >= savedStrategy.startIndex &&
                          index < savedStrategy.endIndex
                        "
                      >
                        <span v-if="row.segment == 'CE' || row.segment == 'PE'">
                          {{ row.strike_options }}
                          <span v-if="row.strike_limit < 0">
                            &nbsp;{{ row.strike_limit }}
                          </span>
                          <span v-else> &nbsp;+{{ row.strike_limit }} </span>
                        </span>
                      </td>
                      <td
                        class="text-center"
                        v-if="
                          index >= savedStrategy.startIndex &&
                          index < savedStrategy.endIndex
                        "
                      >
                        <span v-if="row.expiry_options == 'w'"
                          >Current Weekly</span
                        >
                        <span v-else>Current Monthly</span>
                        <span v-if="row.expiry_limit > 0">
                          &nbsp;+{{ row.expiry_limit }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div class="strategy-card-footer">
                <div style="d-flex">
                  <span style="margin-right: 15px">
                    <img
                      src="@/assets/edit.png"
                      alt="delete card"
                      style="cursor: pointer"
                      @click="showSaveStrategyDialog(index)"
                    />
                  </span>
                  <span style="margin-right: 15px">
                    <img
                      :src="
                        !darkMode
                          ? require('@/assets/LightMode/delete.svg')
                          : require('@/assets/DarkMode/delete.svg')
                      "
                      alt="delete card"
                      style="cursor: pointer; width: 20px; height: 20px"
                      @click="
                        isDeleteStrategyClicked = true;
                        deleteStrategyPayload = savedStrategy;
                      "
                    />
                  </span>
                  <span v-if="savedStrategy.structure.length > 2">
                    <img
                      @click="getIndex(index, savedStrategy.structure.length)"
                      v-if="
                        savedStrategy.endIndex != savedStrategy.structure.length
                      "
                      src="@/assets/down.png"
                      alt
                    />
                    <img
                      @click="getIndex(index, 2)"
                      v-if="
                        savedStrategy.endIndex == savedStrategy.structure.length
                      "
                      src="@/assets/up.png"
                      alt
                    />
                  </span>
                </div>
                <div style="display: flex; align-items: center">
                  <span
                    v-if="savedStrategy.valid"
                    style="margin-right: 10px; width: 25px; height: 17px"
                  >
                    <img
                      :src="
                        !darkMode
                          ? require('@/assets/LightMode/graph.png')
                          : require('@/assets/DarkMode/graph.png')
                      "
                      alt="show chart"
                      style="cursor: pointer; width: 100%; height: 100%"
                      @click="getCompareStrategies(savedStrategy, 'saved')"
                    />
                  </span>
                  <button
                    class="outlined-button d-flex mr-1"
                    @click="getCompareStrategies(savedStrategy, 'compare')"
                  >
                    <img
                      style="width: 20px; margin-right: 2px"
                      :src="
                        !darkMode
                          ? require('@/assets/LightMode/compare.svg')
                          : require('@/assets/DarkMode/compare.svg')
                      "
                      alt=""
                    />
                    <span>Compare</span>
                  </button>
                  <button
                    class="primary-button"
                    style="white-space: nowrap"
                    @click="getSavedStrategiesToPlaceOrder(savedStrategy)"
                  >
                    Place Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Empty data handeling and Delete Confirmation box -->
        <div>
          <div v-if="filteredSavedStrategies.length == 0" class="text-center">
            <span v-if="selectedLabel.length == 0"
              >Please select at least one label!</span
            >
            <span v-else-if="!isSaveStrategyLoading"
              >Oops! No saved strategies.</span
            >
            <span v-else>Loading....</span>
          </div>
        </div>
      </div>

      <v-dialog
        v-if="
          selectedPrebuiltTab === 'Saved' &&
          isVisible &&
          isExpandedSavedStrategies
        "
        :fullscreen="$vuetify.breakpoint.xs"
        v-model="isExpandedSavedStrategies"
        width="90%"
        height="80vh"
      >
        <template>
          <v-card>
            <v-card-text>
              <div class="d-flex justify-space-between pt-6">
                <div
                  style="font-size: var(--font-24); color: var(--text-color)"
                >
                  Saved Strategies
                </div>
                <div>
                  <v-icon @click="isExpandedSavedStrategies = false"
                    >mdi-close</v-icon
                  >
                </div>
              </div>
              <div>
                <!-- Check Boxex -->
                <div
                  class="d-flex justify-space-between select-checkbox my-3"
                  style="max-width: 400px"
                >
                  <v-checkbox
                    v-model="selectedLabel"
                    :label="`Bullish (${bullishCount.toString()})`"
                    value="bullish"
                    text-color="var(--primary-button-color)"
                    color="var(--primary-button-color)"
                    hide-details
                    :disabled="bullishCount == 0 ? true : false"
                    @click="getFilteredSavedStrategies()"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="selectedLabel"
                    :label="`Bearish (${bearishCount.toString()})`"
                    value="bearish"
                    color="var(--primary-button-color)"
                    hide-details
                    :disabled="bearishCount == 0 ? true : false"
                    @click="getFilteredSavedStrategies()"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="selectedLabel"
                    :label="`Neutral (${neutralCount.toString()})`"
                    value="neutral"
                    color="var(--primary-button-color)"
                    hide-details
                    :disabled="neutralCount == 0 ? true : false"
                    @click="getFilteredSavedStrategies()"
                  ></v-checkbox>
                </div>

                <!-- Saved strategies cards -->
                <div
                  class="saved-strategy-content d-flex flex-wrap justify-space-start .styled-scroll"
                  style="max-height: 600px"
                >
                  <div
                    v-if="
                      filteredSavedStrategies &&
                      filteredSavedStrategies.length == 0
                    "
                    style="
                      width: 100%;
                      text-align: center;
                      margin: 2rem;
                      color: var(--text-color);
                      font-size: var(--font-18);
                      line-height: 2rem;
                    "
                  >
                    Please select at least one label!
                  </div>
                  <div
                    class="saved-strategy-cards d-flex flex-column justify-space-between"
                    style="
                      width: 32%;
                      margin: 0.5rem;
                      min-width: 375px;
                      margin-left: 0;
                    "
                    v-for="(savedStrategy, index) in filteredSavedStrategies"
                    :key="savedStrategy.id"
                  >
                    <div>
                      <div class="saved-strategy-header">
                        <span class="strategy-name">{{
                          savedStrategy.name
                        }}</span>
                        <span
                          :class="
                            savedStrategy.direction === 'Bullish'
                              ? 'bullish-text'
                              : savedStrategy.direction === 'Bearish'
                              ? 'bearish-text'
                              : 'neutral-text'
                          "
                          >{{ savedStrategy.direction }}</span
                        >
                      </div>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="strategy-table-header">
                            <tr>
                              <th class="text-left">Type</th>
                              <th class="text-center">Lot</th>
                              <th class="text-center">Strike</th>
                              <th class="text-center">Expiry</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(row, index) in savedStrategy.structure"
                              :key="row.code + '' + index"
                              class="dialog-rows"
                            >
                              <td
                                class="text-left"
                                v-if="
                                  index >= savedStrategy.startIndex &&
                                  index < savedStrategy.endIndex
                                "
                              >
                                <span
                                  v-if="row.segment == 'CE'"
                                  class="bullish-text"
                                  >Call</span
                                >
                                <span
                                  v-if="row.segment == 'PE'"
                                  class="bearish-text"
                                  >Put</span
                                >
                                <span
                                  v-if="row.segment == 'FUT'"
                                  class="neutral-text"
                                  >Fut</span
                                >
                              </td>
                              <td
                                class="text-center"
                                v-if="
                                  index >= savedStrategy.startIndex &&
                                  index < savedStrategy.endIndex
                                "
                              >
                                {{ row.quantity }}
                              </td>
                              <td
                                class="text-center"
                                v-if="
                                  index >= savedStrategy.startIndex &&
                                  index < savedStrategy.endIndex
                                "
                              >
                                <span
                                  v-if="
                                    row.segment == 'CE' || row.segment == 'PE'
                                  "
                                >
                                  {{ row.strike_options }}
                                  <span v-if="row.strike_limit < 0">
                                    &nbsp;{{ row.strike_limit }}
                                  </span>
                                  <span v-else>
                                    &nbsp;+{{ row.strike_limit }}
                                  </span>
                                </span>
                              </td>
                              <td
                                class="text-center"
                                v-if="
                                  index >= savedStrategy.startIndex &&
                                  index < savedStrategy.endIndex
                                "
                              >
                                <span v-if="row.expiry_options == 'w'"
                                  >Current Weekly</span
                                >
                                <span v-else>Current Monthly</span>
                                <span v-if="row.expiry_limit > 0">
                                  &nbsp;+{{ row.expiry_limit }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>

                    <div class="strategy-card-footer">
                      <div style="d-flex">
                        <span style="margin-right: 7px">
                          <img
                            src="@/assets/edit.png"
                            alt="delete card"
                            style="cursor: pointer"
                            @click="showSaveStrategyDialog(index)"
                          />
                        </span>
                        <span style="margin-right: 7px">
                          <img
                            :src="
                              !darkMode
                                ? require('@/assets/LightMode/delete.svg')
                                : require('@/assets/DarkMode/delete.svg')
                            "
                            alt="delete card"
                            style="cursor: pointer; width: 20px; height: 20px"
                            @click="
                              isDeleteStrategyClicked = true;
                              deleteStrategyPayload = savedStrategy;
                            "
                          />
                        </span>
                        <span v-if="savedStrategy.structure.length > 2">
                          <img
                            @click="
                              getIndex(index, savedStrategy.structure.length)
                            "
                            v-if="
                              savedStrategy.endIndex !=
                              savedStrategy.structure.length
                            "
                            src="@/assets/down.png"
                            alt
                          />
                          <img
                            @click="getIndex(index, 2)"
                            v-if="
                              savedStrategy.endIndex ==
                              savedStrategy.structure.length
                            "
                            src="@/assets/up.png"
                            alt
                          />
                        </span>
                      </div>
                      <div style="display: flex; align-items: center">
                        <span
                          v-if="savedStrategy.valid"
                          style="margin-right: 10px; width: 25px; height: 17px"
                        >
                          <img
                            :src="
                              !darkMode
                                ? require('@/assets/LightMode/graph.png')
                                : require('@/assets/DarkMode/graph.png')
                            "
                            alt="show chart"
                            style="cursor: pointer; width: 100%; height: 100%"
                            @click="
                              getCompareStrategies(savedStrategy, 'saved')
                            "
                          />
                        </span>
                        <button
                          class="outlined-button d-flex mr-1"
                          @click="
                            getCompareStrategies(savedStrategy, 'compare')
                          "
                        >
                          <img
                            style="width: 20px; margin-right: 2px"
                            :src="
                              !darkMode
                                ? require('@/assets/LightMode/compare.svg')
                                : require('@/assets/DarkMode/compare.svg')
                            "
                            alt=""
                          />
                          <span>Compare</span>
                        </button>
                        <button
                          class="primary-button"
                          style="white-space: nowrap"
                          @click="getSavedStrategiesToPlaceOrder(savedStrategy)"
                        >
                          Place Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog
        class="delete-strategy-dialog"
        v-model="isDeleteStrategyClicked"
        width="400px"
      >
        <template>
          <v-card>
            <v-toolbar color="var(--app-background-color)" light>
              <v-toolbar-title style="color: var(--text-color)">
                Delete Strategy
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.stop="isDeleteStrategyClicked = false">
                <v-icon style="color: var(--text-color)">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <div
                style="
                  margin-top: 1rem;
                  font-size: var(--font-16);
                  color: var(--option-row-text);
                "
              >
                Are you sure you want to delete this strategy?
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#B3B3B3"
                flat
                @click.stop="isDeleteStrategyClicked = false"
                >Cancel</v-btn
              >
              <v-btn
                color="var(--red-text)"
                dark
                flat
                @click="deleteSavedStrategy()"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <SaveStrategy
        v-if="isSavedStrategyClicked"
        :saveStrategyVisible="isSavedStrategyClicked"
        @close="isSavedStrategyClicked = false"
        :saveStrategyDataPass="saveStrategyData"
        :isEditStrategy="isEditStrategy"
        :selectedInstrument="selectedInstrument"
      ></SaveStrategy>
      <PlaceOrderModal
        v-if="showPlaceOrderDialog"
        :visible="showPlaceOrderDialog"
        @close="showPlaceOrderDialog = false"
        :strategyCreator="strategyCreatorList"
        :selectedInstrument="selectedInstrument"
        :selectedDirection="selectedTab"
      ></PlaceOrderModal>
    </div>

    <!-- show prebuilt strategy dialog in compare strategy -->
    <div v-else>
      <v-dialog :fullscreen="$vuetify.breakpoint.xs" v-model="show" width="650">
        <template>
          <v-card class="prebuilt-strategy-section pa-3">
            <!-- Heading -->
            <v-row no-gutters class="justify-space-between">
              <v-col class="text-left mx-2">
                <div class="prebuilt-strategy-header" style="width: 100%">
                  <v-tabs
                    height="30px"
                    color="var(--primary-button-color)"
                    width="100%"
                  >
                    <v-tab @click="showStrategyTab('Pre-built')"
                      >Pre-Built</v-tab
                    >
                    <v-tab @click="showStrategyTab('Saved')">Saved</v-tab>
                  </v-tabs>
                </div>
              </v-col>
              <v-col
                :class="
                  isMultiStrikeList ? 'justify-space-between' : 'justify-end'
                "
                class="d-flex text-right my-2 my-sm-0 mx-2"
              >
                <div
                  v-if="isMultiStrikeList"
                  class="expiry-input d-flex align-center ma-0 mx-sm-3"
                >
                  <!-- <span class="mx-2 d-none d-sm-inline" style="font-size: 1.1em"
                    >Expiry Date</span
                  > -->
                  <span style="width: 115px">
                    <v-select
                      :items="expiryDatesList"
                      v-model="selectedExpiry"
                      outlined
                      dense
                      append-icon="mdi-calendar"
                      background-color="white"
                      hide-details
                    >
                      <template v-slot:selection="data">
                        <span>{{ data.item | dateDDMM }}</span>
                      </template>
                      <template v-slot:item="data">
                        {{ data.item | dateDDMM }}
                      </template>
                    </v-select>
                  </span>
                  <span>
                    <v-dialog
                      ref="dialog"
                      v-model="timePickerModal"
                      :return-value.sync="selectedTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          style="width: 100px"
                          class="ml-2"
                          label="Time"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          variant="outlined"
                          v-model="selectedTime"
                          prepend-icon="mdi-clock-time-four-outline"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        :allowed-hours="allowedHours"
                        :allowed-minutes="allowedMinutes"
                        v-if="timePickerModal"
                        format="24hr"
                        v-model="selectedTime"
                        full-width
                        min="9:15"
                        max="15:29"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="timePickerModal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialog.save(selectedTime);
                            getUnderlyingLtp();
                          "
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </span>
                </div>
                <div class="d-flex">
                  <v-icon @click.stop="show = false">mdi-close</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <div v-if="selectedPrebuiltTab === 'Pre-built' && isVisible">
                <div class="d-flex justify-space-start select-checkbox my-3">
                  <v-radio-group v-model="selectedDialogTab" row>
                    <v-radio
                      label="Bullish"
                      value="bullish"
                      color="var(--primary-button-color)"
                      @click="changeDialogTab('bullish')"
                    ></v-radio>
                    <v-radio
                      label="Bearish"
                      value="bearish"
                      color="var(--primary-button-color)"
                      @click="changeDialogTab('bearish')"
                    ></v-radio>
                    <v-radio
                      label="Neutral"
                      value="neutral"
                      color="var(--primary-button-color)"
                      @click="changeDialogTab('neutral')"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <div class="prebuilt-strategy-content justify-space-start">
                  <div
                    v-for="strategy in prebuiltStrategyDialogList"
                    class="prebuilt-strategy-cards"
                    :key="strategy.value"
                    :class="
                      strategy === selectedStrategy
                        ? 'selected-card'
                        : 'unselected-card'
                    "
                    @click="getPrebuiltStrategies(strategy)"
                  >
                    <div>
                      <img class="card-image" :src="strategy.imageUrl" alt="" />
                    </div>
                    <div
                      style="
                        font-size: var(--font-12);
                        color: var(--text-color);
                      "
                    >
                      {{ strategy.label }}
                    </div>
                    <div
                      v-if="strategy.buy_sell.length !== 0"
                      style="font-size: var(--font-10); margin-top: 8px"
                    >
                      <span v-for="(bs, i) in strategy.buy_sell" :key="i + bs">
                        <span
                          :class="
                            bs.label.includes('B') ? 'green-text' : 'red-text'
                          "
                          >{{ bs.label }}
                        </span>
                        <span>{{ bs.value }} </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ref="savedStrategies"
                style="width: 100%"
                v-if="selectedPrebuiltTab === 'Saved' && isVisible"
              >
                <div style="width: 100%">
                  <!-- Check Boxex -->
                  <div class="d-flex justify-space-start select-checkbox my-3">
                    <v-checkbox
                      v-model="selectedLabel"
                      :label="`Bullish (${bullishCount.toString()})`"
                      value="bullish"
                      text-color="var(--primary-button-color)"
                      color="var(--primary-button-color)"
                      hide-details
                      :disabled="bullishCount == 0 ? true : false"
                      @click="getFilteredSavedStrategies()"
                    ></v-checkbox>
                    <v-checkbox
                      class="ml-3"
                      v-model="selectedLabel"
                      :label="`Bearish (${bearishCount.toString()})`"
                      value="bearish"
                      color="var(--primary-button-color)"
                      hide-details
                      :disabled="bearishCount == 0 ? true : false"
                      @click="getFilteredSavedStrategies()"
                    ></v-checkbox>
                    <v-checkbox
                      class="ml-3"
                      v-model="selectedLabel"
                      :label="`Neutral (${neutralCount.toString()})`"
                      value="neutral"
                      color="var(--primary-button-color)"
                      hide-details
                      :disabled="neutralCount == 0 ? true : false"
                      @click="getFilteredSavedStrategies()"
                    ></v-checkbox>
                  </div>

                  <div
                    class="d-flex ml-2"
                    style="
                      color: var(--primary-button-color);
                      font-size: var(--font-12);
                    "
                  >
                    <img
                      style="width: 12px; margin-right: 2px"
                      :src="
                        !darkMode
                          ? require('@/assets/LightMode/compare.svg')
                          : require('@/assets/DarkMode/compare.svg')
                      "
                      alt=""
                    />
                    <span>Click on strategy to add it to compare</span>
                  </div>
                  <!-- Saved strategies cards -->
                  <div
                    class="saved-strategy-content dialog-content justify-start .styled-scroll"
                  >
                    <div
                      class="saved-strategy-cards dialog-cards"
                      v-for="(savedStrategy, index) in filteredSavedStrategies"
                      :key="savedStrategy.id + index"
                      @click="getCompareStrategies(savedStrategy, 'compare')"
                    >
                      <div class="saved-strategy-header dialog-header">
                        <span class="dialog-strategy-name strategy-name">{{
                          savedStrategy.name
                        }}</span>
                        <span
                          class="dialog-strategy-name"
                          :class="
                            savedStrategy.direction === 'Bullish'
                              ? 'bullish-text'
                              : savedStrategy.direction === 'Bearish'
                              ? 'bearish-text'
                              : 'neutral-text'
                          "
                          >{{ savedStrategy.direction }}</span
                        >
                      </div>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="strategy-table-header">
                            <tr>
                              <th class="text-left">Type</th>
                              <th class="text-center">Lot</th>
                              <th class="text-center">Strike</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(row, index) in savedStrategy.structure"
                              :key="row.code + '' + index"
                            >
                              <td class="text-left">
                                <span
                                  v-if="row.segment == 'CE'"
                                  class="bullish-text"
                                  >Call</span
                                >
                                <span
                                  v-if="row.segment == 'PE'"
                                  class="bearish-text"
                                  >Put</span
                                >
                                <span
                                  v-if="row.segment == 'FUT'"
                                  class="neutral-text"
                                  >Fut</span
                                >
                              </td>
                              <td class="text-center">
                                {{ row.quantity }}
                              </td>
                              <td class="text-center">
                                <span
                                  v-if="
                                    row.segment == 'CE' || row.segment == 'PE'
                                  "
                                >
                                  {{ row.strike_options }}
                                  <span v-if="row.strike_limit < 0">
                                    &nbsp;{{ row.strike_limit }}
                                  </span>
                                  <span v-else>
                                    &nbsp;+{{ row.strike_limit }}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </div>
                </div>
                <!-- Empty data handeling and Delete Confirmation box -->
                <div>
                  <div
                    v-if="filteredSavedStrategies.length == 0"
                    class="text-center"
                  >
                    <span v-if="selectedLabel.length == 0"
                      >Please select atleast one label!</span
                    >
                    <span v-else-if="!isSaveStrategyLoading"
                      >Oops! No saved strategies.</span
                    >
                    <span v-else>Loading....</span>
                  </div>
                </div>
              </div>
            </v-row>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {
  BULLISH_STRATEGY,
  BEARISH_STRATEGY,
  NEUTRAL_STRATEGY,
} from "./dashboardConstants.js";
import axios from "axios";
import moment from "moment";
import { EventBus } from "../../main.js";
import {
  getStrategyStructure,
  getDeleteStrategy,
  getCustomStrategyUrl,
} from "../getService";
import { getExpiredDays } from "./dashboardService";
import PlaceOrderModal from "../modals/PlaceOrderModal.vue";
import SaveStrategy from "../modals/SaveStrategy.vue";
import {
  getPrebuiltStrategyUrl,
  getUnderlyingLtpWithTime,
} from "../getService";
import UserDataMixin from "@/mixins/getUserDataMixin";

export default {
  mixins: [UserDataMixin],
  data: () => ({
    showPlaceOrderDialog: false,
    selectedPrebuiltTab: "Pre-built",
    prebuiltStrategyList: [],
    prebuiltStrategyDialogList: BULLISH_STRATEGY,
    selectedStrategy: "",
    scrollTop: false,
    selectedLabel: ["bullish", "bearish", "neutral"],
    selectedDialogTab: "bullish",
    savedStrategies: [],
    filteredSavedStrategies: [],
    isDeleteStrategyClicked: false,
    startIndex: 0,
    endIndex: 2,
    isExpanded: false,
    deleteStrategyPayload: null,
    strategyCreatorList: [],
    isVisible: true,
    customStrategyInstrument: null,
    isSavedStrategyClicked: false,
    isSaveStrategyLoading: false,
    saveStrategyData: null,
    isEditStrategy: true,
    targetDays: 0,
    ivPercent: 0,
    bullishCount: 0,
    bearishCount: 0,
    neutralCount: 0,
    isExpandedSavedStrategies: false,
    userSettings: JSON.parse(localStorage.getItem("user_settings")),
    hoursList: [],
    minutesList: [],
    selectedHour: "HH",
    selectedMinute: "MM",
    spotPrice: 0,
    selectedTime: null,
    timePickerModal: false,
  }),
  components: {
    PlaceOrderModal,
    SaveStrategy,
  },
  filters: {
    dateDDMM: function (val) {
      return val.slice(0, 6);
    },
  },
  props: {
    selectedExpiry: String,
    selectedTab: String,
    prebuiltStrategyData: [],
    selectedInstrument: null,
    isPrebuiltStrategyDialog: Boolean,
    dialogOpen: Boolean,
    isMultiStrikeList: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    expiryDatesList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  created() {
    EventBus.$on("getSavedStrategiesApi", () => {
      this.getSavedStrategies();
    });
    EventBus.$on("targetDays", (days) => {
      this.targetDays = days;
    });
    EventBus.$on("ivPercent", (percent) => {
      this.ivPercent = percent;
    });
  },
  watch: {
    selectedTab() {
      this.loadPrebuiltStrategy();
    },
    selectedExpiry: {
      handler(){
        this.loadPrebuiltStrategy();
        this.getSavedStrategies();
      },
      deep:true,
    },
    selectedInstrument: {
      handler(){
        this.getSavedStrategies();
      },
      deep:true,
    },
  },
  computed: {
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    show: {
      get() {
        return this.dialogOpen;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    showStrategyTab(value) {
      this.selectedPrebuiltTab = value;
      if (value == "Saved" && this.filteredSavedStrategies.length == 0) {
        this.getSavedStrategies();
      }
    },
    selectStrategy(value) {
      this.scrollTop = true;
      EventBus.$emit("scrollTop", { top: 0 });
      EventBus.$emit("changeTargetDays", 0);
      this.selectedStrategy = value;
      this.$emit("selectedStrategy", this.selectedStrategy);
      this.$parent.$refs.targetComponent.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    },
    loadPrebuiltStrategy() {
      if (this.selectedTab == "Bullish") {
        this.prebuiltStrategyList = BULLISH_STRATEGY;
      } else if (this.selectedTab == "Bearish") {
        this.prebuiltStrategyList = BEARISH_STRATEGY;
      } else {
        this.prebuiltStrategyList = NEUTRAL_STRATEGY;
      }
      this.selectedStrategy = this.prebuiltStrategyList.find(
        (str) => str.value === this.userSettings.dashboard_strategy
      );
    },

    changeDialogTab(value) {
      this.selectedDialogTab = value;
      if (this.selectedDialogTab == "bullish") {
        this.prebuiltStrategyDialogList = BULLISH_STRATEGY;
      } else if (this.selectedDialogTab == "bearish") {
        this.prebuiltStrategyDialogList = BEARISH_STRATEGY;
      } else {
        this.prebuiltStrategyDialogList = NEUTRAL_STRATEGY;
      }
      if (this.selectedDialogTab == "") {
        this.selectedDialogTab = "bullish";
      }
    },
    getSavedStrategies() {
      this.isSaveStrategyLoading = true;
      if (this.selectedPrebuiltTab == "Saved") {
        let expiry = moment(this.selectedExpiry).format("YYYY-MM-DD");
        this.savedStrategies = [];
        let spotPrice;
        if (
          (this.selectedInstrument.exchange == "NSE" &&
            this.compactMarketDataScripts(
              this.selectedInstrument.underlying_code
            )) ||
          (this.selectedInstrument.exchange != "NSE" &&
            this.compactMarketDataScripts(this.selectedInstrument.fut_code))
        ) {
          spotPrice =
            this.selectedInstrument.exchange == "NSE"
              ? this.compactMarketDataScripts(
                  this.selectedInstrument.underlying_code
                )["details"]["Last Traded Price"]
              : this.compactMarketDataScripts(this.selectedInstrument.fut_code)[
                  "details"
                ]["Last Traded Price"];
        } else {
          spotPrice =
            this.selectedInstrument.exchange == "NSE"
              ? this.selectedInstrument.ltp
              : this.selectedInstrument.fut_ltp;
        }

        let payload = {
          user_id: this.userId,
          expiry_date: expiry,
          spot_price: spotPrice,
          exchange: this.selectedInstrument.exchange,
          instrument: this.selectedInstrument.name,
        };
        axios.post(getStrategyStructure, payload).then((res) => {
          this.isSaveStrategyLoading = false;
          this.savedStrategies = res.data;
          this.getFilteredSavedStrategies(this.savedStrategies);
        });
      }
    },
    getFilteredSavedStrategies() {
      this.bullishCount = 0;
      this.bearishCount = 0;
      this.neutralCount = 0;

      let saved = [];
      if (this.selectedLabel.length == 0) {
        saved = [];
      }
      for (const strategy of this.savedStrategies) {
        if (this.selectedLabel.includes(strategy.direction.toLowerCase())) {
          saved.push(strategy);
        }
        if (strategy.direction == "Bullish") {
          this.bullishCount = this.bullishCount + 1;
        } else if (strategy.direction == "Bearish") {
          this.bearishCount = this.bearishCount + 1;
        } else if (strategy.direction == "Neutral") {
          this.neutralCount = this.neutralCount + 1;
        }
      }
      // let labels =[]
      // for(let label of this.selectedLabel) {
      //   labels.push(label)
      // }
      if (this.bullishCount == 0) {
        this.selectedLabel =  this.selectedLabel .filter((label) => label !== "bullish");
      }
      if (this.bearishCount == 0) {
        this.selectedLabel =  this.selectedLabel .filter((label) => label !== "bearish");
      }
      if (this.neutralCount == 0) {
        this.selectedLabel =  this.selectedLabel .filter((label) => label !== "neutral");
      }

      this.filteredSavedStrategies = saved;
      for (let index in this.filteredSavedStrategies) {
        this.$set(this.filteredSavedStrategies[index], "startIndex", 0);
        this.$set(this.filteredSavedStrategies[index], "endIndex", 2);
      }
    },
    getIndex(index, value) {
      this.$set(this.filteredSavedStrategies[index], "endIndex", value);
    },

    deleteSavedStrategy() {
      let payload = {
        strategy_id: this.deleteStrategyPayload.id,
        user_id: this.userId,
      };
      axios.post(getDeleteStrategy, payload).then((res) => {
        if (res.data.status_code != 200) {
          this.$notify({
            group: "success",
            type: "error",
            title: "Error",
            text: "Strategy is not saved",
          });
        } else {
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: res.data.body.message,
          });
        }
        this.isDeleteStrategyClicked = false;
        this.getSavedStrategies();
      });
    },
    getSavedStrategiesToPlaceOrder(strategy) {
      this.strategyCreatorList = strategy.selected_instruments;
      this.showPlaceOrderDialog = true;
    },
    showSaveStrategyDialog(index) {
      this.isSavedStrategyClicked = true;
      this.saveStrategyData = this.filteredSavedStrategies[index];
    },
    getCompareStrategies(savedStrategies, change) {
      let param_list = [];
      for (let ins of savedStrategies.selected_instruments) {
        param_list.push({
          code: ins.code,
          exchange: ins.exchange,
          expiry: ins.expiry,
          ltp: ins.ltp,
          name: ins.name,
          op_pr: ins.op_pr,
          quantity: ins.quantity,
          segment:
            ins.segment == "CE" ? "c" : ins.segment == "PE" ? "p" : "fut",
          strike: ins.strike,
          trading_symbol: ins.trading_symbol,
          formatted_ins_name: ins.formatted_ins_name,
        });
      }
      let spot_price =
        this.selectedInstrument.exchange == "NSE"
          ? this.compactMarketDataScripts(
              this.selectedInstrument.underlying_code
            )["details"]
            ? this.compactMarketDataScripts(
                this.selectedInstrument.underlying_code
              )["details"]["Last Traded Price"]
            : 0
          : this.compactMarketDataScripts(this.selectedInstrument.fut_code)[
              "details"
            ]
          ? this.compactMarketDataScripts(this.selectedInstrument.fut_code)[
              "details"
            ]["Last Traded Price"]
          : 0;
      if (spot_price == 0) {
        spot_price =
          this.selectedInstrument.exchange == "NSE"
            ? this.selectedInstrument.ltp
            : this.selectedInstrument.fut_ltp;
      }
      let payload = {
        path: "custom_strategy",
        broker: "aliceblue",
        user_id: this.userId,
        access_token: this.accessToken,
        ws_session: this.wsSession,
        name: this.selectedInstrument.name,
        spot_price: spot_price,
        exchange:
          this.selectedInstrument.exchange == "NSE"
            ? "NFO"
            : this.selectedInstrument.exchange,
        lot_size: this.selectedInstrument.lot_size,
        days_to_expire: getExpiredDays(this.selectedExpiry),
        target_days: this.targetDays,
        iv_percent: this.ivPercent,
        param_list: param_list,
      };
      axios
        .post(getCustomStrategyUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          if (change == "saved") {
            this.$emit("changePrebuiltStrategyDataSaved", res.data);
            this.$parent.$refs.targetComponent.scrollIntoView({
              behavior: "smooth",
            });
          } else {
            if (!this.isPrebuiltStrategyDialog) {
              this.$parent.$refs.compareStrategyComponent.scrollIntoView({
                behavior: "smooth",
              });
            }
            EventBus.$emit("addToCompareStrategiesFromSaved", {
              selectedInstrument: this.selectedInstrument,
              selectedTab: !this.isPrebuiltStrategyDialog
                ? this.selectedTab
                : this.selectedDialogTab,
              payload: payload,
              name: savedStrategies.name,
              data: res.data,
              selectedStrategy: savedStrategies.structure,
            });
            if (this.isPrebuiltStrategyDialog) {
              this.show = false;
            }
          }
          if (this.isExpandedSavedStrategies) {
            this.isExpandedSavedStrategies = false;
          }
        });
    },

    /**
     * @function gets the prebuilt strategies
     */
    getPrebuiltStrategies(strategy) {
      let payload = {
        exchange: this.selectedInstrument.exchange,
        path: "prebuilt_strategy",
        broker: "aliceblue",
        access_token: this.accessToken,
        user_id: this.userId,
        expiry: this.selectedExpiry,
        direction: this.selectedDialogTab.toLowerCase(),
        strategy: strategy.value,
        days_to_expire: getExpiredDays(this.selectedExpiry),
        name: this.selectedInstrument.name,
        code:
          this.selectedInstrument.exchange === "NSE"
            ? this.selectedInstrument.underlying_code
            : this.selectedInstrument.fut_code,
        ltp: this.isMultiStrikeList
          ? this.spotPrice
          : this.selectedInstrument.ltp,
        target_days: 0,
        iv_percent: 0,
      };
      axios
        .post(getPrebuiltStrategyUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          let spot_price = 0;
          payload.param_list = res.data.instrument;
          payload.path = "custom_strategy";

          spot_price =
            this.selectedInstrument.exchange == "NSE"
              ? this.compactMarketDataScripts(
                  this.selectedInstrument.underlying_code
                )["details"]
                ? this.compactMarketDataScripts(
                    this.selectedInstrument.underlying_code
                  )["details"]["Last Traded Price"]
                : 0
              : this.compactMarketDataScripts(this.selectedInstrument.fut_code)[
                  "details"
                ]
              ? this.compactMarketDataScripts(this.selectedInstrument.fut_code)[
                  "details"
                ]["Last Traded Price"]
              : 0;
          if (spot_price == 0) {
            spot_price =
              this.selectedInstrument.exchange == "NSE"
                ? this.selectedInstrument.ltp
                : this.selectedInstrument.fut_ltp;
          }
          payload.spot_price = spot_price;
          EventBus.$emit("addToCompareStrategiesFromCreator", {
            selectedInstrument: this.selectedInstrument,
            selectedTab: this.selectedDialogTab,
            payload: payload,
            name: !this.isPrebuiltStrategyDialog
              ? "Current Strategy"
              : strategy.label,
            data: res.data,
            selectedStrategy: strategy.buy_sell,
          });
          if (this.isPrebuiltStrategyDialog) {
            this.show = false;
          }
        });
    },
    getUnderlyingLtp() {
      let payload = {
        symbol: this.selectedInstrument.name,
        time: this.selectedTime,
      };
      axios
        .post(getUnderlyingLtpWithTime, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.spotPrice = res.data;
        });
    },
    allowedHours(v){
      const currentDate = new Date();
      let time =currentDate.getHours()>15?15:currentDate.getHours()
      return v<=time
    },
    allowedMinutes(v){
      const currentDate = new Date();
      let time =currentDate.getHours()>parseInt(this.selectedTime.split(":")[0])?60: parseInt(this.selectedTime.split(":")[1])
      return v<=time
    }
  },
  mounted() {
    if (this.isMultiStrikeList) {
      const currentDate = new Date();
      const hours =currentDate.getHours()>15?"15": String(currentDate.getHours()).padStart(2, "0");
      const minutes =currentDate.getHours()>15?"29": String(currentDate.getMinutes()).padStart(2, "0");
      this.selectedTime = `${hours}:${minutes}`;
      this.getUnderlyingLtp();
    }
    this.loadPrebuiltStrategy();
  },
};
</script>

<style lang="scss">
@import "../../assets/css/vuetify-components.scss";
.prebuilt-strategy-section {
  .prebuilt-strategy-header {
    .v-tabs {
      border-bottom: 1px solid var(--border-color);
      font-size: var(--font-16);
      .v-tab {
        text-transform: none !important;
      }
    }
    .v-icon.v-icon {
      color: var(--text-color) !important;
    }
  }

  .select-checkbox .v-label {
    font-size: var(--font-14);
  }
  .prebuilt-strategy-content {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .prebuilt-strategy-cards {
      padding: 12px 8px;
      border: 1px solid var(--border-color);
      width: 30%;
      max-width: 160px;
      height: auto;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      margin: 0.5rem 0.3rem;
    }

    .selected-card {
      background-color: var(--chip-background);
    }
    .unselected-card {
      background-color: var(--cards-background-color);
    }
    .card-image {
      width: 50px;
      height: 50px;
    }
  }
}

.saved-strategy-content {
  overflow-y: auto;
  max-height: 350px;

  .saved-strategy-cards {
    width: 100%;
    margin: auto;
    border-radius: 12px;
    max-width: 500px;
    border: 1px solid var(--border-color);
    margin-bottom: 1.5rem;

    .saved-strategy-header {
      display: flex;
      justify-content: space-between;
      padding: 1rem;

      .strategy-name {
        color: var(--text-color);
        font-size: var(--font-18);
      }

      .dialog-strategy-name {
        font-size: var(--font-16) !important;
      }
    }

    .dialog-header {
      padding: 7px !important;
    }
    .strategy-card-footer {
      padding: 1rem;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      img {
        cursor: pointer;
        width: 17px;
        height: 17px;
      }
    }

    .v-data-table {
      color: var(--text-color);
      td {
        border-bottom: none !important;
        font-size: var(--font-14);
      }
      td,
      th {
        height: 36px !important;
      }
    }
  }
  .dialog-rows td {
    font-size: var(--font-14) !important;
  }
  .strategy-table-header {
    background: var(--chip-background);

    th {
      color: var(--text-color) !important;
      font-size: var(--font-16);
      border-bottom: none !important;
    }
  }
}

.dialog-content {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  .dialog-cards {
    width: 35% !important;
    margin: 0.5rem 0.3rem !important;
    cursor: pointer;
    min-width: 290px !important;
  }
}
.saved-strategy-content::-webkit-scrollbar {
  width: 8px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.saved-strategy-content::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* set the color of the scrollbar track */
}
.saved-strategy-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ltp-input {
  border: none;
  border-bottom: 1px solid #b3b3b3;
}
.ltp-input:focus {
  outline: none;
}

@media screen and (max-width: 600px) {
  .saved-strategy-content {
    max-height: 85vh !important;
  }
}
</style>
