<template>
  <div class="other-tools row1">
    <h2 class="heading1">other tools</h2>
    <div class="contents flex-between">
        <div class="left-container">
            <img :src="require('@/assets/'+image)" class="image" alt="" v-for="image in currentItem.images" :key="image">
        </div>
        <div class="right-content">
            <ul class="nav">
                <li class="tab-item active" @click="updateTab('optiontab')">option chain</li>
                <li class="tab-item" @click="updateTab('straddletab')">Straddle Premium </li>
                <li class="tab-item" @click="updateTab('opentab')">Open Interest</li>
            </ul>
            <div class="bar">
                <div class="active-bar"></div>
            </div>
            <ul class="points">
                <li class="item" v-for="(el, index) in currentItem.points" :key="index"><div class="dot"></div> {{el}}</li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    data(){
        return{
            currentItem:{
                title:'option chain',
                images:['optionchain3.png','optionchain1.png','optionchain2.png'],
                points:[
                    'Live Market feed of option chain data.',
                    'Analyse OI, IV, & Greeks.',
                    'Place order directly from the page.',
                    'Option Chain visualise on graphs to spot opportunities.'
                ]
            },
            info:[
                {
                    title:'option chain',
                    images:['optionchain3.png','optionchain1.png','optionchain2.png'],
                    points:[
                        'Live Market feed of option chain data.',
                        'Analyse OI, IV, & Greeks.',
                        'Place order directly from the page.',
                        'Option Chain visualise on graphs to spot opportunities.'
                    ]
                },
                {
                    title:'Straddle Premium ',
                    images:['straddle.png'],
                    points:[
                        'Spot Stocks with high premium demand against Price and Margin.',
                        'Liquidity Index Value to help identifying liquid instruments.'
                    ]
                },
                {
                    title:'Open Interest',
                    images:['openInterest.png'],
                    points:[
                        'Analyse Future Open Interest Data.',
                        'Customisable to check outlook on historically different periods.',
                        'Apply Filters.',
                        'Check support and resistance levels.'
                    ]
                },
            ]
        }
    },
    methods:{
        updateTab(tab){
            const activeBar = document.querySelector('.active-bar')
            const tabItems = document.querySelectorAll('.tab-item')
            for(let i=0; i<tabItems.length; i++){
                tabItems[i].classList.remove('active')
            }


            event.target.classList.add('active')
            if(tab==='optiontab'){
                activeBar.style.left = '0%'
                activeBar.style.width = '28%'
                if(window.innerWidth<980 && window.innerWidth>780){
                    activeBar.style.width = '20%'
                }
                this.currentItem = this.info[0]
            }else if(tab==='straddletab'){
                activeBar.style.left = '35%'
                 if(window.innerWidth>980){
                    activeBar.style.left = '32%'
                }
                 if(window.innerWidth<780){
                    activeBar.style.left = '30%'
                }
                activeBar.style.width = '35%'
                this.currentItem = this.info[1]
            }else if(tab==='opentab'){
                activeBar.style.left = '68%'
                if(window.innerWidth<980){
                    activeBar.style.left = '77%'
                }
                if(window.innerWidth<780){
                    activeBar.style.left = '70%'
                }
                activeBar.style.width = '50%'
                this.currentItem = this.info[2]
            }

            //updating content
            // const leftContainer = document.querySelector('.left-container')
            // leftContainer.style.backgroundImage = `url(${require('@/assets/'+this.currentItem.images[0])})`   

        }
    },
    mounted() {
        var cards = document.querySelector('.left-container')
        setInterval(()=>{
            let innerWidth = cards.offsetWidth
            cards.scrollLeft+=innerWidth
            let threshold = 2*innerWidth-200
            if(cards.scrollLeft >=threshold){
                cards.scrollLeft = 0
            }
        }, 2000)
  },
  destroyed() {
    document.querySelector('.cards').removeEventListener('scroll', this.setShowScroll);
  }
}
</script>

<style lang="scss" scoped>
.other-tools{
    margin-top: 177px;
    margin-bottom: 177px;
    @media only screen and (max-width:580px){
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .heading1{
        font-weight: 600;
        font-size: 40px;
        text-align: center;
        margin-bottom: 58px;
        text-transform: capitalize;
        @media only screen and (max-width:580px){
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
    .contents{
        @media only screen and (max-width:980px){
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .left-container{
        position: relative;
        width: 684px;
        border-radius: 5px;
        margin-right: 20px;
        display: flex;
        overflow-x: scroll;
        scroll-behavior: smooth;
        &::-webkit-scrollbar{
            width: 0;
        }
        .image{
            width: 100%;
            @media only screen and (max-width:580px){
                margin: 0;
            }
        }
        @media only screen and (max-width:980px){
            width: 100%;
            margin-bottom: 30px;
            margin-right: 0px;
        }
        .btn{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #E8E8E8;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            opacity: .4;
            transition: all .25s;
            &:hover{
                cursor: pointer;
                opacity: .8;
            }
        }
        .left-btn{
            background-image: url('../../assets/left-btn.png');
            left: 10px;
        }
        .right-btn{
            background-image: url('../../assets/right-btn.png');
            right: 10px;
        }
    }
    .right-content{
        width: 540px;
        align-self: flex-start;
        @media only screen and (max-width:1180px){
            align-self: center;
        }
        @media only screen and (max-width:980px){
            width: 760px;
            align-self: center;
        }
        @media only screen and (max-width:780px){
            width: 560px;
        }
        @media only screen and (max-width:580px){
            width: 340px;
        }
        .nav{
            list-style: none;
            display: flex;
            justify-content: space-between;
            .tab-item{
                font-weight: 600;
                font-size: 20px;
                margin-right: 20px;
                min-width:max-content;
                padding-bottom: 10px;
                text-transform: capitalize;
                padding: 5px 10px;
                border-radius: 10px 10px 0 0;
                @media only screen and (max-width:580px){
                    margin-right: 10px;
                    font-weight: 700;
                    font-size: 12px;
                    padding: 5px;
                }
                &::selection{
                    background-color: transparent;
                }
                &:hover{
                    cursor: pointer;
                    background-color: #E8E8E8;
                }
            }
            .active{
                color: #4179C7;
            }
        }
        .bar{
            position: relative;
            width: 100%;
            height: 10px;
            border-radius: 5px;
            background-color: #E8E8E8;
            margin-bottom: 51px;
            overflow: hidden;
             @media only screen and (max-width:580px){
                margin-bottom: 23px;
            }
            .active-bar{
                position: absolute;
                top: 0;
                left: 0;
                width: 30%;
                height: 10px;
                border-radius: 5px;
                background-color: #4179C7;
                transition: all .25s;
                @media only screen and (max-width:980px){
                    max-width: 200px;
                    
                }
            }
        }
        .points{
            list-style: none;
            li{
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 20px;
                color: #888888;
                margin-bottom: 21px;
                @media only screen and (max-width:580px){
                    font-size: 8px;
                    margin-bottom: 11px;
                }
                .dot{
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #888888;
                    margin-right: 20px;
                    margin-top: 10px;
                    align-self: flex-start;
                    @media only screen and (max-width:580px){
                       width: 5px;
                       height: 5px;
                    }
                }

            }
        }
    }
}
</style>