<template>
    <div class="home">
      <HeaderComp/>
      <StrategyBuilder/>
      <MarginBenefit/>
      <PayOffChart/>
      <!-- Position Analytics -->
      <CommonComp :info="positionAnalytics" :_id="1"/>  
   <!-- Alerts -->
      <CommonComp :info="alerts" :_id="2"/>
      <!-- Other Tools -->
      <OtherTools/>
  <!-- OrderBook -->
      <OrderBook/>
      <FooterComp/>
    </div>
  </template>
  
  <script>
  import HeaderComp from '@/components/landing/HeaderComp.vue';
  import StrategyBuilder from '../components/landing/StrategyBuilder.vue';
  import MarginBenefit from '@/components/landing/MarginBenefit.vue';
  import PayOffChart from '@/components/landing/PayOffChart.vue';
  import CommonComp from '@/components/landing/CommonComp.vue';
  import FooterComp from '../components/landing/FooterComp.vue';
  import OtherTools from '@/components/landing/OtherTools.vue';
  import OrderBook from '@/components/landing/OrderBook.vue';
  export default {
      name: "HomeView",
      components: { HeaderComp, StrategyBuilder, MarginBenefit, PayOffChart, CommonComp, FooterComp, OtherTools, OrderBook },
      data(){
        return{
          positionAnalytics:{
              title:'Position Analytics',
              alignment:'left',
              points:[
                  'Positions grouped as Hedge and by Symbols.',
                  'Graph to give you expected P&L until expiry.',
                  'Check margin utilised on each strategy and live P&L.'
              ],
              img:'posAnalytics.png'
              },
          alerts:{
              title:'Alerts',
              alignment:'right',
              points:[
                  'Instruments trading  against Trend.',
                  'Open position with Low Liquidity.',
                  'Risk of Margin Penalty .'
              ],
              img:'alert.png'
              },
        }
      },
  }
  </script> 
  <style>
  .home{
    font-family: 'Open Sans',Roboto, sans-serif;
  }
  </style> 