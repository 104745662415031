export default {
  NSE:(code) => {
    return JSON.stringify({
      k: `NSE|${code}#NSE`,
      t: "d",
      m: "compact_marketdata",
    })
  },
  NFO:(code) => {
    return JSON.stringify({
      k: `NFO|${code}#NFO`,
      t: "d",
      m: "compact_marketdata",
    })
  },
  MCX:(code) => {
    return JSON.stringify({
      k: `MCX|${code}#MCX`,
      t: "d",
      m: "compact_marketdata",
    })
  },
  CDS:(code) => {
    return JSON.stringify({
      k: `CDS|${code}#CDS`,
      t: "d",
      m: "compact_marketdata",
    })
  },
  BSE:(code) => {
    return JSON.stringify({
      k: `BSE|${code}#BSE`,
      t: "d",
      m: "compact_marketdata",
    })
  },
  BFO:(code) => {
    return JSON.stringify({
      k: `BFO|${code}#BFO`,
      t: "d",
      m: "compact_marketdata",
    })
  },
}