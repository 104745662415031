<template>
  <div class="card flex-col-center">
    <p class="type flex-center" :id="`type${index}`">{{cardInfo.type}}</p>
    <div class="card-row" v-for="item in cardInfo.rows" :key="item.id">
        <p class="id">{{item.id}}</p>
        <p class="point">{{item.points}}</p>        
        <p class="rate">{{item.rate}}</p>
    </div>
    <div class="last-row flex-between">
        <div class="left flex-between">
            <p>Margin</p>
            <p class="margin-rate">{{cardInfo.marginRate}}</p>
            <p class="mb">(MB: {{cardInfo.mb}})</p>
        </div>
        <div class="orderBtn flex-center">
            Place Order
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['cardInfo', 'index'],

    mounted(){
        if(this.cardInfo.type === 'recomended'){
            document.getElementById(`type${this.index}`).style.backgroundColor = '#FF8C22';
        }
    }
}
</script>

<style lang="scss" scoped>
.card{
    justify-content: space-between;
    width: 100%;
    max-width: 405px;
    min-height: 159px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px #DBEFFF;
    border-radius: 4px;
    padding: 32px 23px 17px 23px;
    position: relative;


    @media only screen and (max-width:1140px) {
        margin-left: 50%;
        transform: translateX(-50%);
    }
    @media only screen and (max-width:580px) {
        max-width: 344px;
        padding: 32px 15px;
    }
    .type{
        position: absolute;
        top: 0;
        left: 0;
        width: 119px;
        height: 22px;
        color: #fff;
        background: #C5C5C5;
        border-radius: 4px 0 80px 0;
        font-size: 14px;
        text-transform: capitalize;
    }

    .card-row{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        justify-content: space-between;

        width: 100%;
        font-weight: 600;
        font-size: 12px;
        color: #979797;
        margin-bottom: 22px;
        .rate,.point{
            color: #4AAF39;
            text-align: right;
        }
    }
    .last-row{
        width: 100%;
        .left{
            text-transform: uppercase;
            width: 216px;
            height: 30px;
            background: #E4F4FF;
            border-radius: 50px;
            font-weight: 400;
            font-size: 14px;
            padding: 0 10px;
            .margin-rate{
                font-weight: 500;     
            }
            .mb{
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: #4AAF39;

            }
        }
        .orderBtn{
            width: 97px;
            height: 30px;
            background: #4179C7;
            border-radius: 6px;
            font-weight: 400;
            font-size: 14px;    
            color: #fff;
            transition: all .25s;
            &:hover{
                cursor: pointer;
                border: 1px solid #4179C7;
                background: #fff;
                color: #4179C7;
            }
            @media only screen and (max-width:580px) {
                width: 80px;
                font-size: 12px;  
                
            }
        }
    }
}

</style>